import { StoreSetupActions, StoreSetupAction, StoreSetupState } from "../@types/storeSetup";
// import * as fakeStoresData from "../../datajsons/fakeStoresData.json";
import { MarketplaceName } from "../../@type/StoreSetup/MarketplaceNames";
import { storeStatusOptions } from "../../@type/StoreSetup/Store";

const initialState: StoreSetupState = {
    stores: [],
    showingStores: [],
    showInActiveStores: true,
    currentMarketplaceName: MarketplaceName.default,
    isLoading: false,
    isZeroStore: false,
    storesRefresh: [],
};

export function storeSetupReducer(
    state = initialState,
    action: StoreSetupAction
): StoreSetupState {
    switch (action.type) {
        case StoreSetupActions.SET_STORES:
            return { ...state, stores: action.payload, showingStores: action.payload }
        case StoreSetupActions.SHOW_INACTIVE_STORES:
            return {
                ...state, showingStores: action.payload
                    ? state.stores
                    : state.stores.filter(store => store.accountStatus === storeStatusOptions.active),
                showInActiveStores: action.payload
            }
        case StoreSetupActions.UPDATE_CURRENT_STORE:
            return {
                ...state,
                stores: state.stores.map(store => store.id === action.payload.id ? action.payload : store),
                showingStores: state.showInActiveStores
                    ? state.showingStores.map(store => store.id === action.payload.id ? action.payload : store)
                    : state.showingStores.filter(store => store.id !== action.payload.id)
            }
        case StoreSetupActions.SET_CURRENT_MARKETPLACE_NAME:
            return { ...state, currentMarketplaceName: action.payload }
        case StoreSetupActions.SET_LOADING:
            return { ...state, isLoading: action.payload }
        case StoreSetupActions.SET_ZERO_STORE:
            return { ...state, isZeroStore: action.payload }
        case StoreSetupActions.SET_STORES_REFRESH:
            return { ...state, storesRefresh: action.payload }
        case StoreSetupActions.UPDATE_STORE_REFRESH:
            return {
                ...state,
                storesRefresh: state.storesRefresh.map((e) => e.store.id === action.payload.id ? {
                    ...e,
                    updateStatus: action.payload.updateStatus,
                    store: {
                        ...e.store,
                        lastUpdatedOn: action.payload.lastUpdatedOn ? action.payload.lastUpdatedOn : e.store.lastUpdatedOn,
                        storeUpdateStatus: action.payload.storeUpdateStatus ? action.payload.storeUpdateStatus : e.store.storeUpdateStatus,
                    }
                } : e),
            }
        default:
            return state;
    }
}