import { CarrierServiceActions, CarrierServiceAction, CarrierServiceState } from "../../@types/quickQuote/carrierService";
import { v4 as uuidv4 } from 'uuid';
import { compareByPrice, compareByTransitDay, getTop3Rates } from "../../../functionUtilities/filtersAndTop3Rates";

const initialState: CarrierServiceState = {
    availableRates: [],
    filteredRates: [],
    isFilteredByPrice: true,
    top3Rates: [],
    expanded: false,
    jumpToRateId: "",
    pageIndex: 0,
};

export function carrierServiceReducer(
    state = initialState,
    action: CarrierServiceAction
): CarrierServiceState {
    switch (action.type) {
        case CarrierServiceActions.SET_RATES:
            return { ...state, availableRates: action.payload.map(rate => { return { id: uuidv4(), ...rate } }) }
        case CarrierServiceActions.FILTER_RATES_BY_PRICE:
            const filteredRates = state.availableRates;
            filteredRates.sort(compareByPrice);
            return { ...state, filteredRates: filteredRates, isFilteredByPrice: true }
        case CarrierServiceActions.FILTER_RATES_BY_TRANSIT_DAY:
            const filteredRates2 = state.availableRates;
            filteredRates2.sort(compareByTransitDay);
            return { ...state, filteredRates: filteredRates2, isFilteredByPrice: false }
        case CarrierServiceActions.GET_TOP3_RATES:
            return { ...state, top3Rates: getTop3Rates(state.availableRates) }
        case CarrierServiceActions.EXPAND_RATE:
            return { ...state, expanded: action.payload }
        case CarrierServiceActions.TOP3_JUMP_TO_RATE:
            return { ...state, jumpToRateId: action.payload }
        case CarrierServiceActions.SET_PAGE_INDEX:
            return { ...state, pageIndex: action.payload }
        default:
            return state;
    }
}