import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MENU_MAP } from "../masterPage/common";
import { refreshTokenKey } from "../../environment/env_dev";
import { setRefreshToken } from "../../functionUtilities/getTokenAndExpireTime";

const CustomerLogin = () => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const token = new URLSearchParams(search).get("accessToken");
    const expireAt = new URLSearchParams(search).get("expireAt");
    const refreshToken = new URLSearchParams(search).get("refreshToken");
    const refreshExpireAt = new URLSearchParams(search).get("refreshExpireAt");

    window.localStorage.setItem("accesstoken", token);
    window.localStorage.setItem("expireAt", expireAt);

    if (refreshToken) {
      setRefreshToken(refreshTokenKey, refreshToken, refreshExpireAt);
    }

    const timeout = setTimeout(() => {
      history.push(MENU_MAP.DASHBOARD.route);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "30px", fontSize: "24px" }}>
      <CircularProgress /> Please wait while you are redirected...
    </div>
  );
};

export default CustomerLogin;
