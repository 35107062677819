import { withStyles } from "@material-ui/styles";
// import { Theme } from "material-react-toastify";
import React from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DropdownWrapper from "../utilities/DropdownButton/DropdownWrapper";
import { Theme, Tooltip } from "@material-ui/core";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 200,
    fontSize: "0.9rem",
    lineHeight: "1.5",
    textAlign: "center",
  },
}))(Tooltip);

const availableCurrencies = [
  { label: "Canadian Dollar (CA $)", value: "CAD" },
  { label: "US Dollar (US $)", value: "USD" },
];

const CurrenciesDropdown = ({
  currency,
  handleSelect,
  placeholder,
  showLabel = false,
}) => {
  const CurrencyDropdownButton = () => {
    if (!currency) return <div>{placeholder}</div>;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          {availableCurrencies.find((e) => e.value === currency)?.label}
        </div>
      </div>
    );
  };

  return (
    <>
      {showLabel && (
        <div style={{ marginBottom: "10px" }}>
          Select Currency{" "}
          <LightTooltip
            id="emailHelpIcon"
            title={
              <>
                <p>
                  CAD can be used to pay for CA Postage(Shipments) Ex: CA POST,
                  UPS(CA), CANPAR
                </p>
                <p>
                  USD can be used to pay for US Postage(Shipments) Ex: USPS,
                  UPS(US)
                </p>
              </>
            }
            placement="top"
          >
            <HelpOutlineIcon />
          </LightTooltip>
        </div>
      )}
      <DropdownWrapper
        btnName={currency ? currency : placeholder}
        btnNameCustomEl={<CurrencyDropdownButton />}
        color="default"
        variant="outlined"
        size="medium"
        list={[...availableCurrencies.map((e) => e.value)]}
        setSelect={handleSelect}
      />
    </>
  );
};

export default CurrenciesDropdown;
