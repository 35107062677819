import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useStoreSetupActions } from "../redux/actions/storeSetupActions";
import { useNotificationActions } from "../redux/actions/notificationActions";
import { storeUpdateStatusOptions } from "../@type/StoreSetup/Store";
import { SERVER_URL } from "../environment/env_dev";
import {
  initializeLabelBatchDialog,
  setOrderSuccessNumber,
  setOrderErrorsNumber,
  setLabelBatchDialogStatus,
} from "../redux/actions/labelBatchActions";
import { labelBatchStateType } from "../redux/@types/orders/labelBatchState";
import { useEffect } from "react";
import { useOrdersActions } from "../redux/actions/ordersActions";
import { loadHoldOrderChangesData } from "../redux/actions/holdOrdersRestoreActions";
import { notifyRefreshReferralAlerts } from "../redux/actions/referralProgramInfoActions";


const useSignalRConnection = (userId) => {
  const dispatch = useDispatch();
  const { updateStoreRefresh } = useStoreSetupActions();
  const { addNotification } = useNotificationActions();
  const { removeOrder } = useOrdersActions();
  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    if (!connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${SERVER_URL}/jobprogress`)
        .configureLogging(LogLevel.Warning)
        .withAutomaticReconnect()
        .build()
      newConnection.serverTimeoutInMilliseconds = 1000000000;
      newConnection.keepAliveIntervalInMilliseconds = 3000000;
      setConnection(newConnection);
    }

    return () => {
      console.log('Gracefully closing SignalR connection');
      if (connection) {
        connection.stop();
      }
    }
    // eslint-disable-next-line
  }, [])

  const setupEvents = () => {
    connection.on("NotifyMarketplaceUpdate", (storeObj) => {

      // when we got the object response back for updated status, change the view status and last updated on
      if (storeObj.storeUpdateStatus === storeUpdateStatusOptions.completed) {
        updateStoreRefresh(
          storeObj.id,
          "success",
          storeObj.lastStoreUpdatedOn,
          storeObj.storeUpdateStatus
        );
      } else if (
        storeObj.storeUpdateStatus === storeUpdateStatusOptions.failed
      ) {
        updateStoreRefresh(storeObj.id, "failed");
      }
    });

    connection.on("NotifyTicketUpdate", (notificationObj) => {
      addNotification(notificationObj);
    });

    connection.on("NotifyFeedUpdate", (notificationObj) => {
      addNotification(notificationObj);
    });

    // This SignalR is called for each order in the shipment batch
    // Lets you know if the order shipment was successful for particular order or it had any issues
    connection.on("NotifyOrderBatchShipment", (notificationObj) => {
      console.log(
        "NotifyOrderBatchShipment for Order Id: " +
        notificationObj.data.orderId,
        { notificationObj }
      );

      if (notificationObj.status) {
        // Order Successfully Shipped
        dispatch(setOrderSuccessNumber("ADD", 1));
        // Removing order from the state if it is shipped
        removeOrder(notificationObj.data.orderId);

      } else {
        // An error occurred while shipping the order
        dispatch(setOrderErrorsNumber("ADD", 1));
      }
    });

    // This signalR is called when the Batch Shipment is Started or Completed
    connection.on("NotifyOrderBatchUpdate", (notificationObj) => {
      console.log(
        "NotifyOrderBatchUpdate | Batch ID: " +
        notificationObj.referenceId +
        " | " +
        notificationObj.data.batchStatus,
        { notificationObj }
      );

      if (notificationObj.data.batchStatus === "PROCESSING") {
        // Started Processing
        var order_count = notificationObj.data.order_count
          ? notificationObj.data.order_count
          : 0;

        // Setting the LabelBatchDialogState to show the dialog and start the processing
        const labelBatchInitialState: labelBatchStateType = {
          completion_status: notificationObj.data.batchStatus,
          isOpen: true,
          orders_count: order_count,
          order_success: 0,
          order_errors: 0,
          batchId: notificationObj.referenceId,
          data: notificationObj.data,
          progress_percentage: 0,
          shipDate: notificationObj.data.shipDate,
          processDate: notificationObj.data.processedDate,
        };

        dispatch(initializeLabelBatchDialog(labelBatchInitialState));
      } else {
        // Finished processing the Batch
        dispatch(setLabelBatchDialogStatus(notificationObj.data.batchStatus));
      }
    });
    connection.on("NotifyUpdatedHoldOrder", (holdOrderIdChanges: number[]) => {
      dispatch(loadHoldOrderChangesData(holdOrderIdChanges));
    });

    connection.on("NotifyRefreshReferralAlerts", () => {
      dispatch(notifyRefreshReferralAlerts());
    });
  }

  const startConnection = useCallback(async () => {
    try {
      if (userId && connection) {
        setupEvents();

        await connection.start();
        connection.invoke("AddToGroup", userId.toString());

        connection.onreconnected(() => {
          connection.invoke("AddToGroup", userId.toString());
          console.log("---------------- Reconnection Successful -------------");

        })

        connection.onclose(async (er) => {
          console.log('closing connection', er);
          // try {
          //   await connection.start();
          // } catch (error) {
          //   console.log(error);
          // }
        })
      }
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line
  }, [connection, userId])


  useEffect(() => {
    startConnection();
  }, [startConnection]);

};


export default useSignalRConnection;