type OPEN_POPOVER = "OPEN_POPOVER";
type CLOSE_POPOVER = "CLOSE_POPOVER";

type actionType = {
    OPEN_POPOVER: OPEN_POPOVER;
    CLOSE_POPOVER: CLOSE_POPOVER;
};

export const PopoverActions: actionType = {
    OPEN_POPOVER: "OPEN_POPOVER",
    CLOSE_POPOVER: "CLOSE_POPOVER"
};

export type PopoverAction =
    | { type: OPEN_POPOVER, payload: HTMLElement, payload2: string }
    | { type: CLOSE_POPOVER };