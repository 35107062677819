import axios from 'axios';
import { refreshService } from '../../apis/loginService';
import { 
  tokenCookieName, 
  refreshTokenKey, 
  accessTokenSessionKey, 
  accessTokenSessionExpiryKey, 
  getToken
} from '../../environment/env_dev';
import { getRefreshToken, clearTokens, saveTokens } from '../../functionUtilities/getTokenAndExpireTime';

import { SET_TOKEN_STRING, SET_TOKEN_EXPIRE_AT, SET_TOKEN_ACCOUNT_NO } from '../@types/token/tokenAction';

export const setTokenString = (data) => ({
  type: SET_TOKEN_STRING, data,
});

export const setTokenExpireAt = (data) => ({
  type: SET_TOKEN_EXPIRE_AT, data,
});

export const setTokenAccountNo = (data) => ({
  type: SET_TOKEN_ACCOUNT_NO, data,
});

export const setAuthorizationToken = (tokenString) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokenString}`;
};

export const addAccessTokenWatcher = () => (dispatch) => {
  /*
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      const response = error.response;
      const { status } = response;

      if (status === 401) {
        window.location.reload();

        return;
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
  );
  */

  createAxiosResponseInterceptor();
};

// taken and modified from
// https://stackoverflow.com/questions/51646853/automating-access-token-refreshing-via-interceptors-in-axios
function createAxiosResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
          // Reject promise if usual error
          if (error.response.status !== 401) {
              return Promise.reject(error);
          }
          
          /* 
           * When response code is 401, try to refresh the token.
           * Eject the interceptor so it doesn't loop in case
           * token refresh causes the 401 response
           */
          axios.interceptors.response.eject(interceptor);

          return refreshService(getRefreshToken(refreshTokenKey).refreshToken, getToken())
          .then(response => {
            const accesstokenInSession = sessionStorage.getItem('accesstoken');
              saveTokens(response, !accesstokenInSession);
              error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token.tokenString;
              return axios(error.response.config);
          }).catch(error => {
              clearTokens(tokenCookieName, accessTokenSessionKey, accessTokenSessionExpiryKey, refreshTokenKey);
              window.location.reload();
              return Promise.reject(error);
          }).finally(createAxiosResponseInterceptor);
      }
  );
}