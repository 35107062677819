import { Store, StoreRefresh } from "../../../@type/StoreSetup/Store";
import { MarketplaceName } from "../../../@type/StoreSetup/MarketplaceNames";

type SET_STORES = "SET_STORES";
type SHOW_INACTIVE_STORES = "SHOW_INACTIVE_STORES";
type UPDATE_CURRENT_STORE = "UPDATE_CURRENT_STORE";
type SET_CURRENT_MARKETPLACE_NAME = "SET_CURRENT_MARKETPLACE_NAME";
type SET_LOADING = "SET_LOADING";
type SET_ZERO_STORE = "SET_ZERO_STORE";
type SET_STORES_REFRESH = "SET_STORES_REFRESH";
type UPDATE_STORE_REFRESH = "UPDATE_STORE_REFRESH";

type actionType = {
    SET_STORES: SET_STORES;
    SHOW_INACTIVE_STORES: SHOW_INACTIVE_STORES;
    UPDATE_CURRENT_STORE: UPDATE_CURRENT_STORE;
    SET_CURRENT_MARKETPLACE_NAME: SET_CURRENT_MARKETPLACE_NAME;
    SET_LOADING: SET_LOADING;
    SET_ZERO_STORE: SET_ZERO_STORE;
    SET_STORES_REFRESH: SET_STORES_REFRESH;
    UPDATE_STORE_REFRESH: UPDATE_STORE_REFRESH;
};

export const StoreSetupActions: actionType = {
    SET_STORES: "SET_STORES",
    SHOW_INACTIVE_STORES: "SHOW_INACTIVE_STORES",
    UPDATE_CURRENT_STORE: "UPDATE_CURRENT_STORE",
    SET_CURRENT_MARKETPLACE_NAME: "SET_CURRENT_MARKETPLACE_NAME",
    SET_LOADING: "SET_LOADING",
    SET_ZERO_STORE: "SET_ZERO_STORE",
    SET_STORES_REFRESH: "SET_STORES_REFRESH",
    UPDATE_STORE_REFRESH: "UPDATE_STORE_REFRESH",
};

export type StoreSetupAction =
    | { type: SET_STORES, payload: Store[] }
    | { type: SHOW_INACTIVE_STORES, payload: boolean }
    | { type: UPDATE_CURRENT_STORE, payload: Store }
    | { type: SET_CURRENT_MARKETPLACE_NAME, payload: MarketplaceName }
    | { type: SET_LOADING, payload: boolean }
    | { type: SET_ZERO_STORE, payload: boolean }
    | { type: SET_STORES_REFRESH, payload: StoreRefresh[] }
    | { type: UPDATE_STORE_REFRESH, payload: any };