import shipvistaAPI from "./shipvistaAPI";

export const getUserService = () => {
  return shipvistaAPI.get(`/User`);
};

export const updateUserService = (body) => {
  return shipvistaAPI.post(`/User/UpdateUserDetails`, body);
};

export const requestEmailCodeService = () => {
  const domainArr = window.location.href.split("/");
  const link = `${domainArr[0]}//${domainArr[2]}/settings/verify-email`;

  return shipvistaAPI.get(
    `/User/RequestVerificationEmail?verificationLink=${link}`
  );
};

export const updateEmailService = (body: any) => {
  return shipvistaAPI.put(`/User/UpdateEmail`, body);
};

export const verifyEmailService = (token) => {
  return shipvistaAPI.get(`/register/verifyemailaddress?token=${token}`);
};

export const activateOffer = (offerType: string) => {
  return shipvistaAPI.get(`/Offers/ActivateOffer?offerType=${offerType}`);
};

export const claimOffer = (offerType: string) => {
  return shipvistaAPI.get(`/Offers/ClaimOffer?offerType=${offerType}`);
};

export const getRequestCodeGuest = (config) => {
  return shipvistaAPI.get(`/User/RequestCode`, config);
};

export const markFreightIntroComplete = () => {
  return shipvistaAPI.get(`/User/FreightIntroComplete`);
};
