import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import DropdownButton from '../DropdownButton/DropdownButton';

import { formatDateFilter } from '../../../functionUtilities/formatDateFilter';

import { useAlertActions } from '../../../redux/actions';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: 10,
    },
    datesText: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: 20,
    },
    dateText: {
      fontWeight: 700,
      fontSize: '0.8rem',
    },
    btn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 20,
    },
  }),
);

interface DateFilterProps {
  startDate: Date;
  endDate: null | Date;
  setAnchorEl: (value: React.SetStateAction<HTMLButtonElement>) => void;
  setDateFilter: (value: React.SetStateAction<string>) => void;
  onChange: (dates: [Date, Date]) => void;
  setStartDate: (value: React.SetStateAction<Date>) => void;
  setEndDate: (value: any) => void;
  goToFirstPage?: () => void;
  minDate?: Date;
  maxDate?: Date;
};

const DateFilter = (props: DateFilterProps) => {
  const classes = useStyles();
  const { startDate, endDate, setStartDate, setEndDate, onChange, setDateFilter, setAnchorEl, goToFirstPage, minDate, maxDate } = props;

  const { openAlertBar, closeAlertBar } = useAlertActions();

  const applyBtnOnClick = () => {
    if (endDate) {
      if (formatDateFilter(startDate) === formatDateFilter(endDate)) {
        setDateFilter(formatDateFilter(startDate))
      } else {
        setDateFilter(formatDateFilter(startDate) + ' - ' + formatDateFilter(endDate))
      }

      setAnchorEl(null);
    } else {
      openAlertBar('Please select the end date', false);

      setTimeout(() => {
        closeAlertBar();
      }, 2000);
    }
    if (goToFirstPage) {
      goToFirstPage();
    }
  };

  const cancelBtnOnClick = () => {
    setStartDate(new Date());
    setEndDate(null);
    setDateFilter('');
    setAnchorEl(null);
    if (goToFirstPage) {
      goToFirstPage();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.datesText}>
        <div className={classes.dateText}>From: {startDate ? formatDateFilter(startDate) : 'Please select'}</div>
        <div className={classes.dateText}>To: {endDate ? formatDateFilter(endDate) : 'Please select'}</div>
      </div>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        minDate={minDate}
        maxDate={maxDate}
      />
      <div className={classes.btn}>
        {
          endDate && (
            <Button
              style={{ marginRight: 10 }}
              onClick={cancelBtnOnClick}
              size="small"
              variant="outlined"
              color="secondary"
            >
              Remove Filter
            </Button>
          )
        }
        <Button
          onClick={applyBtnOnClick}
          size="small"
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
      </div>
    </div >
  );
};

interface TableDateFilterProps {
  label: string;
  dateFilter: string;
  setDateFilter: (value: React.SetStateAction<string>) => void;
  goToFirstPage?: () => void;
  minDate?: Date;
  maxDate?: Date;
};

const TableDateFilter = ({
  label = 'DATE',
  dateFilter,
  setDateFilter,
  goToFirstPage,
  minDate,
  maxDate,
}: TableDateFilterProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!dateFilter) {
      setStartDate(null);
      setEndDate(null);

      return;
    }

    let _startDate = '';
    let _endDate = '';

    if (dateFilter.includes('-')) {
      const arrDates = dateFilter.split(' - ');

      _startDate = arrDates[0].replaceAll('/', '-');
      _endDate = arrDates[1].replaceAll('/', '-');
    } else {
      _startDate = _endDate = dateFilter.replaceAll('/', '-');
    }

    setStartDate(moment(_startDate).toDate());
    setEndDate(moment(_endDate).toDate());
  }, [dateFilter]);

  const onDateFilterChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DropdownButton
      btnName=""
      btnNameCustomEl={(
        <div style={{ color: '#0B57A8', fontWeight: 'bold' }}>
          {dateFilter ? dateFilter : label}
        </div>
      )}
      color="primary"
      variant={dateFilter ? 'outlined' : 'text'}
      anchorEl={anchorEl}
      size="medium"
      setAnchorEl={(e) => { setAnchorEl(e); }}
    >
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setDateFilter={setDateFilter}
        onChange={onDateFilterChange}
        setAnchorEl={(e) => { setAnchorEl(e); }}
        goToFirstPage={goToFirstPage}
        minDate={minDate}
        maxDate={maxDate}
      />
    </DropdownButton>
  );
};

export default TableDateFilter;
