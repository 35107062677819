// the library has bug when taking parameter to transform in array item
var objectMapper = require('object-mapper');

export const addressMapper = (data: any) => {
    let req = objectMapper(data, map);
    req.status = "Active";
    return req;
}

const map = {
    "id": "id",
    "countryCode": "countryCode",
    "postalCode": "postalCode",
    "name": "name",
    "companyName": "companyName",
    "streetAddress": "streetAddress",
    "streetAddress2": "streetAddress2",
    "city": "city",
    "country": "country",
    "state": "state",
    "stateCode": "stateCode",
    "phone": "phone",
    "isResidence": {
        key: "isResidence",
        transform:(value:boolean)=>{
            return value ? true : false
        }
    },
    "isDefault": "isDefault",
    "nickname": "nickname",
    "isSave":"isSave",
    "email": {
        key: "email",
        transform: function (value: string) {
            return value !== "" ? value : null;
        }
    },
}