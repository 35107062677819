import shipvistaAPI from "./shipvistaAPI";

export const getNotifications = () => {
  return shipvistaAPI.get(`/Notifications`);
};

export const updateNotification = (id, isRead) => {
  if (!id) {
    return shipvistaAPI.put(
      `/Notifications?isRead=${isRead ? "true" : "false"}`,
      null
    );
  }

  return shipvistaAPI.put(
    `/Notifications?notificationId=${id}&isRead=${isRead ? "true" : "false"}`,
    null
  );
};
