import { useState, useContext, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SubscriptionContext from './SubscriptionContext';
import FixedDialogWrapper from '../../../../utilities/FixedDialogWrapper';
import SelectCardDropdown from './SelectCardDropdown';
import { MasterPageContext } from '../../../masterPageContext';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      height: 240,
      padding: 30,
    },
    icon: {
      width: 70,
    },
  }),
);

interface Props {
  open: boolean,
  setOpen: (value: boolean) => void,
  onSubmit: (value: any) => void,
  // billingInformationDetails: any,
};

const ChangeBillingInformationDialog = ({ open, setOpen, onSubmit }: Props) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [defaultCard, setDefaultCard] = useState(null);
  const { userSubscription } = useContext(SubscriptionContext);

  const { cardFunctions: { setAddCardDialogVisible, creditCardData } } = useContext(MasterPageContext);

  const { data } = userSubscription
  const { billingDetails } = data
  const { billingAddress, cardType, cardNumber, expiryYear, cardHolderName } = billingDetails

  useEffect(() => {
    if (billingDetails) {
      let billingAddr = {
        billingAddress: billingAddress,
        cardHolderName: cardHolderName,
        cardNumber: cardNumber,
        cardType: cardType,
        expiryYear: expiryYear,
      };

      setDefaultCard(billingAddr);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    setIsLoading(false);
    onSubmit(defaultCard);
  };

  const handleAddNewCardSubmit = (e) => {
    setOpen(false);
    onSubmit(e);
  };

  return (
    <FixedDialogWrapper
      dialogId={'change-billing-information-dialog'}
      open={open}
      title={'Change Billing Information'}
      maxWidth={false}
      disableFullWidth
      setOpen={setOpen}
      closeDisabled={false}
      buttonDefinitions={[
        {
          label: 'Add New Card',
          color: 'secondary',
          onClick: () => setAddCardDialogVisible(true)
        },
        { label: 'Change', color: 'primary', disabled: isLoading, loading: isLoading, onClick: (e) => handleSubmit() },
      ]}
    >
      <div className={classes.root}>
        <img
          className={classes.icon}
          src="/images/subscription/credit-card.png"
          alt="credit card icon"
        />
        <SelectCardDropdown
          selectedCard={defaultCard}
          setSelectedCard={setDefaultCard}
          cardData={creditCardData}
          onSubmit={(e) => { handleAddNewCardSubmit(e); }}
        />
      </div>
    </FixedDialogWrapper>
  );
};

export default ChangeBillingInformationDialog;
