import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Grid, LinearProgress, Paper, Theme } from "@material-ui/core";
import FixedDialogWrapper from "./FixedDialogWrapper";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
  CalendarTodayOutlined,
  LocationOn,
  QueryBuilder,
} from "@material-ui/icons";
import { RootState } from "../../redux/reducers";
import {
  setLabelBatchDialogVisibility,
  resetLabelBatchDialog,
} from "../../redux/actions/labelBatchActions";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import LabelSummaryDialog from '../masterPage/shipments/batchHistory/BatchHistoryTable/LabelSummaryDialog'
import { useAlertActions } from '../../redux/actions/alertActions'
import { getOrdersBatchHistoryMapService } from '../../apis/getOrders'
import Button from '@material-ui/core/Button'
import { MENU_MAP } from '../masterPage/common'
import { MasterPageContext } from "../masterPage/masterPageContext";

/*  This Component/Dialog is being controlled by:
 *   LabelBatchReducer and SignalR
 *   Managed Entirely by Redux Store and States
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
    },
    divider: {
      borderColor: "inherit",
    },
    header: {
      fontWeight: 500,
      backgroundColor: "#e5f2ff",
      padding: "5px",
    },
    body: {
      padding: "10px 25px",
    },
    details: {
      marginTop: "10px 20px",
    },
    batchDetail: {
      color: "#777",
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",
    },
    detailIcon: {
      marginRight: "10px",
    },
    notice: {
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #c9e3ff",
      padding: "10px",
      backgroundColor: "#cce5ff",
      color: "#235faa",
      display: "flex",
      alignItems: "center",
      lineHeight: "1.5rem",
    },
    notice_success: {
      color: "green",
      border: "1px solid green",
      backgroundColor: "#e0f7e1",
    },
    notice_errors: {
      border: "1px solid red",
      color: "red",
      backgroundColor: "#ffefef",
    },
    yesIconSmall: {
      height: "30px",
    },
    yesIconBig: {
      height: "40px",
      marginRight: "25px",
    },
    processingLabel: {
      color: "#235faa",
      fontWeight: 500,
      marginLeft: "10px",
      textDecoration: "underline",
    },
    labelError: {
      color: "red",
    },
    labelSuccess: {
      color: "green",
    },
    mb5: {
      marginBottom: "5px",
    },
    flexGrow: {
      flexGrow: 1,
      flexBasis: 0,
    },
    linearProgressSection: {
      display: "flex",
      alignItems: "center",
    },
    linearProgressLabel: {
      marginLeft: "15px",
    },
    link: {
      textDecoration: "underline",
      color: "inherit",
      "&:hover": {
        color: "black",
      },
    },
    shipmentHistoryButton: {
      margin: '16px'
    }
  })
);

const yesIconPath = "/images/common/yes.png";

const LabelBatchDialog = () => {
  const dispatch = useDispatch();
  const { openAlertBar } = useAlertActions()
  const labelBatchState = useSelector((state: RootState) => state.labelBatch);
  const userState = useSelector((state: RootState) => state.user.user);
  const [percentage, setPercentage] = useState(0);
  const [shipDate, setShipDate] = useState("");
  const [processDate, setProcessDate] = useState("");
  const [labelsRemaining, setLabelsRemaining] = useState(0);
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = useState<boolean>(false);
  const [orderBatchesHistory, setOrderBatchesHistory] = useState<any[]>([]);

  const {
    resolveHeaderMenuKey,
    resolveSelectedMenuKey
  } = useContext(MasterPageContext);

  const history = useHistory();

  useEffect(() => {
    // console.log("Label Batch Dialog Active..");
  }, []);

  const setDialogVisibility = (show: boolean) => {
    dispatch(setLabelBatchDialogVisibility(show));
    if (!show) {
      // Reset LabelBatchDialog when the user closes it.
      dispatch(resetLabelBatchDialog());
    }
  };

  // PERCENTAGE, LABELS-REMAINING: Calculating and Setting percentage for the label batch dialog box
  useEffect(() => {
    if (labelBatchState.orders_count > 0) {
      var orders_done = labelBatchState.order_success + labelBatchState.order_errors;
      orders_done = orders_done === 0 ? 1 : orders_done;
      var percent = orders_done * (100 / labelBatchState.orders_count);
      percent = Math.trunc(percent > 100 ? 100 : percent);
      setLabelsRemaining(labelBatchState.orders_count - orders_done);
      setPercentage(percent);
    }
  }, [
    labelBatchState.orders_count,
    labelBatchState.order_errors,
    labelBatchState.order_success,
    setLabelsRemaining
  ]);

  // PROCESSING DATE: Parsing and setting the shipment processing date
  useEffect(() => {
    var ProcessDate = labelBatchState.processDate
      ? new Date(labelBatchState.processDate)
      : new Date();
    var options = { hour12: true, hour: "numeric", minute: "numeric" } as const;
    setProcessDate(ProcessDate.toLocaleString("en-CA", options));
  }, [labelBatchState.processDate]);

  // SHIPPING DATE: Parsing and setting the shipment date
  useEffect(() => {
    if (labelBatchState.shipDate) {
      var shipdate = new Date(labelBatchState.shipDate);
      var datestring = `${shipdate.getMonth() + 1
        }/${shipdate.getDate()}/${shipdate.getFullYear()}`;
      setShipDate(datestring);
    }
  }, [labelBatchState.shipDate]);

  const classes = useStyles();

  // on click on label message, fetch orders
  const onLabelClick = () => {
    setOrderBatchesHistory([]);
    getOrdersBatchHistoryMapService(labelBatchState.batchId).then(res => {
      if (res.data.status) {
        if (res.data.data.length !== 0) {
          setOrderBatchesHistory(res.data.data)
          setOrderDetailsDialogOpen(true);
        } else {
          setOrderBatchesHistory([]);
        }
      } else {
        openAlertBar(res.data.message, false);
      }
    }).catch(error => {
      console.log("error: " + JSON.stringify(error.response));
      openAlertBar(JSON.stringify(error.response.data.errors), false);
    });
  }
  const handleBatchHistoryClick = () => {
    resolveSelectedMenuKey(MENU_MAP.SHIPMENTS_BATCH_HISTORY);
    resolveHeaderMenuKey(MENU_MAP.SHIPMENTS.route);
    history.push(MENU_MAP.SHIPMENTS_BATCH_HISTORY.route);
    setDialogVisibility(false)
  }

  const handleShipmentHistoryClick = () => {
    resolveSelectedMenuKey(MENU_MAP.SHIPMENTS_SHIPMENT_HISTORY.route);
    resolveHeaderMenuKey(MENU_MAP.SHIPMENTS.route);
    history.push(MENU_MAP.SHIPMENTS_SHIPMENT_HISTORY.route);
    setDialogVisibility(false)
  }
  return (
    <>
      <FixedDialogWrapper
        dialogId="labelBatchStatus"
        title="Label Batch Status"
        maxWidth="md"
        setOpen={setDialogVisibility}
        open={labelBatchState.isOpen}
      >
        <Paper variant="outlined" className={classes.root}>
          <div className={classes.header}>
            Label Batch #{labelBatchState.batchId}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.body}>
            <div className={classes.mb5}>
              <img
                src={yesIconPath}
                alt="yes icon"
                className={classes.yesIconSmall}
              />
              <em className={classes.processingLabel}>
                {labelBatchState.completion_status}
              </em>
            </div>
            <div className={classes.linearProgressSection}>
              <LinearProgress
                variant="determinate"
                value={percentage}
                className={classes.flexGrow}
              />
              <span className={classes.linearProgressLabel}>{percentage}%</span>
            </div>
            <Grid
              container={true}
              spacing={3}
              justify="space-between"
              className={classes.details}
            >
              <Grid item={true} xs={7}>
                <div className={classes.batchDetail}>
                  <LocationOn className={classes.detailIcon} />
                  Ship from My Default Location
                </div>
                <div className={classes.batchDetail}>
                  <QueryBuilder className={classes.detailIcon} />
                  Processed Today at {processDate} by {userState.user_name}
                </div>
                <div className={classes.batchDetail}>
                  <CalendarTodayOutlined className={classes.detailIcon} />
                  Ship on {shipDate}
                </div>
              </Grid>
              <Grid item={true} xs={3} style={{ textAlign: "right", marginRight: "3.5em" }}>
                {/*----- Labels Remaining ------*/}
                {labelsRemaining > 0 && (
                  <span
                    className={classes.processingLabel}
                  >
                    <b>{labelsRemaining}</b> <em>label(s) remaining</em>
                  </span>
                )}
                {/*----- Labels Succeeded -------*/}
                {labelBatchState.order_success > 0 && (
                  <span
                    className={`${classes.processingLabel} ${classes.labelSuccess}`}
                  >
                    <b>{labelBatchState.order_success}</b>{" "}
                    <Button style={{ textTransform: 'none', padding: '0' }} variant="text" onClick={onLabelClick}><em>label(s) succeeded</em></Button>
                  </span>
                )}
                <br />
                {/*----- Labels Failed -------*/}
                {labelBatchState.order_errors > 0 && (
                  <span
                    className={`${classes.processingLabel} ${classes.labelError}`}
                  >
                    <b>{labelBatchState.order_errors}</b>
                    <Button style={{ textTransform: 'none', padding: '0' }} variant="text" onClick={onLabelClick}><em>label(s) failed</em></Button>
                  </span>
                )}
              </Grid>
            </Grid>

            {
              /*-- Setting the Error or Success Message after the processing is FINISHED */
              labelBatchState.completion_status === "FINISHED" &&
              (labelBatchState.order_errors > 0 ? (
                <div className={`${classes.notice} ${classes.notice_errors}`}>
                  <ReportProblemOutlinedIcon
                    style={{ margin: "0 15px", fontSize: "3rem" }}
                  />
                  <div className={classes.flexGrow}>
                    <h6 className={classes.mb5}>
                      Your batch completed, but there were one or more errors
                    </h6>
                    <p className={classes.mb5}>
                      Review the error details by clicking the labels summary
                      link above
                    </p>
                  </div>
                </div>
              ) : (
                <div className={`${classes.notice} ${classes.notice_success}`}>
                  <img
                    src={yesIconPath}
                    alt="yes icon"
                    className={classes.yesIconBig}
                  />
                  <div className={classes.flexGrow}>
                    <h6 className={classes.mb5}>
                      All labels have been generated successfully
                    </h6>
                    <p className={classes.mb5}>
                      You can always find this and other batches in the{" "}
                      <Button
                        style={{ textTransform: 'none', padding: '0' }}
                        variant="text"
                        className={classes.link}
                        onClick={() => handleBatchHistoryClick()}>
                        Batch History
                      </Button>{" "}
                      page
                    </p>
                  </div>
                </div>
              ))
            }
            {
              /*-- Setting the PROCESSING message while the processing is happening */
              labelBatchState.completion_status === "PROCESSING" && (
                <div className={classes.notice}>
                  <img
                    src={yesIconPath}
                    alt="yes icon"
                    className={classes.yesIconBig}
                  />
                  <div className={classes.flexGrow}>
                    <h6 className={classes.mb5}>
                      You don't need to wait in this window. You will get notified
                      when this batch is finished
                    </h6>
                    <p className={classes.mb5}>
                      You can always find this and other batches in the{" "}
                      <Button style={{ textTransform: 'none', padding: '0' }} variant="text" className={classes.link} onClick={() => handleBatchHistoryClick()}>
                        Batch History
                      </Button>{" "}
                      page
                    </p>
                  </div>
                </div>
              )
            }
          </div>
        </Paper>
        <Grid container={true} justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            className={classes.shipmentHistoryButton}
            onClick={handleShipmentHistoryClick}
          >
            Go To Shipment History
          </Button>
        </Grid>
      </FixedDialogWrapper>
      <LabelSummaryDialog
        orderDetailsDialogOpen={orderDetailsDialogOpen}
        setOrderDetailsDialogOpen={setOrderDetailsDialogOpen}
        orderBatchesHistory={orderBatchesHistory}
      />
    </>
  );
};

export default LabelBatchDialog;
