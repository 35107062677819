import Axios from "axios";

import {
  SERVER_URL,
  accessTokenSessionExpiryKey,
  accessTokenSessionKey,
  getLandingPageURL,
  getToken,
  refreshTokenKey,
  tokenCookieName,
} from "../environment/env_dev";
import { refreshService } from "./loginService";
import { getRefreshToken } from "../functionUtilities/getTokenAndExpireTime";
import { saveTokens } from "../functionUtilities/getTokenAndExpireTime";
import { clearTokens } from "../functionUtilities/getTokenAndExpireTime";

const shipvistaAPI = Axios.create({
  baseURL: `${SERVER_URL}/api`,
  headers: {},
});

// shipvistaAPI.CancelToken = Axios.CancelToken;

shipvistaAPI.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem(accessTokenSessionKey);

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});

const interceptor = shipvistaAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    shipvistaAPI.interceptors.response.eject(interceptor);

    return refreshService(
      getRefreshToken(refreshTokenKey).refreshToken,
      getToken()
    )
      .then((response) => {
        const accesstokenInSession = sessionStorage.getItem("accesstoken");
        saveTokens(response, !accesstokenInSession);
        error.response.config.headers[
          "Authorization"
        ] = `Bearer ${response.data.access_token.tokenString}`;

        return shipvistaAPI(error.response.config);
      })
      .catch((error) => {
        clearTokens(
          tokenCookieName,
          accessTokenSessionKey,
          accessTokenSessionExpiryKey,
          refreshTokenKey
        );

        window.location.href = `${getLandingPageURL()}/?e=logout`;
        return Promise.reject(error);
      });
  }
);

export default shipvistaAPI;
