import { ReferralProgramInfoState } from "./referralProgramInfoState";

type FETCH_REFERRAL_INFO_DATA = "FETCH_REFERRAL_INFO_DATA";
type REFERRAL_INFO_DATA_LOADING = "REFERRAL_INFO_DATA_LOADING";
type LOAD_REFERRAL_INFO_DATA = "LOAD_REFERRAL_INFO_DATA";
type CLEAR_REFERRAL_INFO_DATA = "CLEAR_REFERRAL_INFO_DATA";
type NOTIFY_REFRESH_REFERRAL_ALERTS = "NOTIFY_REFRESH_REFERRAL_ALERTS";

type actionType = {
    FETCH_REFERRAL_INFO_DATA: FETCH_REFERRAL_INFO_DATA;
    REFERRAL_INFO_DATA_LOADING: REFERRAL_INFO_DATA_LOADING;
    LOAD_REFERRAL_INFO_DATA: LOAD_REFERRAL_INFO_DATA;
    CLEAR_REFERRAL_INFO_DATA: CLEAR_REFERRAL_INFO_DATA;
    NOTIFY_REFRESH_REFERRAL_ALERTS: NOTIFY_REFRESH_REFERRAL_ALERTS;
};

export const ReferralProgramInfoActions: actionType = {
    FETCH_REFERRAL_INFO_DATA: "FETCH_REFERRAL_INFO_DATA",
    REFERRAL_INFO_DATA_LOADING: "REFERRAL_INFO_DATA_LOADING",
    LOAD_REFERRAL_INFO_DATA: "LOAD_REFERRAL_INFO_DATA",
    CLEAR_REFERRAL_INFO_DATA: "CLEAR_REFERRAL_INFO_DATA",
    NOTIFY_REFRESH_REFERRAL_ALERTS: "NOTIFY_REFRESH_REFERRAL_ALERTS",
};

export type ReferralProgramInfoAction =
    | { type: FETCH_REFERRAL_INFO_DATA }
    | { type: REFERRAL_INFO_DATA_LOADING; payload: boolean; }
    | { type: LOAD_REFERRAL_INFO_DATA; payload: ReferralProgramInfoState; }
    | { type: CLEAR_REFERRAL_INFO_DATA; }
    | { type: NOTIFY_REFRESH_REFERRAL_ALERTS; }