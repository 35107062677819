import { useRef, useCallback, useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import analytics from "../environment/analytics";
import TagManager from "react-gtm-module";
import { MENU_MAP } from "../components/masterPage/common";
import useUpdateEffect from "../components/utilities/useUpdateEffect";

type MandatoryParameters = {
  page: { url?: string };
};

type CustomEventType =
  | "registration"
  | "getRatesGuest"
  | "shipNowGuest"
  | "trackShipment"
  | "bannerPromoCodeToChangePlan"
  | "getRatesToCreateLabel"
  | "updatePlan"
  | "getRatesToCreateLabelOrders"
  | "connectStoreOrMarketplace";
// withoutGTM = without Google Tag Manager - GTM and GA4 scripts conflict with each other
export default function useGoogleAnalytics(
  { withoutGTM } = { withoutGTM: false }
) {
  const loc = useLocation();
  const setupRef = useRef<boolean>(false);
  const setupRefGTM = useRef<boolean>(false);

  useEffect(() => {
    if (withoutGTM) {
      setupRef.current = analytics.init();
    } else {
      setupRefGTM.current = analytics.initGTM();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    const currentPath = loc.pathname + loc.hash + loc.search;
    const fullUrl =
      document.location.origin +
      document.location.pathname +
      document.location.search;
    if (setupRef.current) {
      analytics.sendPageview(currentPath);
    }
    if (setupRefGTM.current) {
      const match = Object.values(MENU_MAP).find((e) =>
        matchPath(loc.pathname, { path: e.route, exact: true })
      );
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: {
            url: fullUrl,
            title: match?.label ?? document.title,
          },
        },
      });
    }
  }, [loc]);

  const sendCustomEvent = useCallback(
    (
      eventName: CustomEventType,
      params?: MandatoryParameters & { params?: object }
    ) => {
      if (eventName) {
        if (setupRef.current) {
          analytics.sendEvent({
            hitType: eventName,
            page: params?.page?.url,
          });
        }
        if (setupRefGTM.current) {
          const fullUrl =
            document.location.origin +
            document.location.pathname +
            document.location.search;
          TagManager.dataLayer({
            dataLayer: {
              page: {
                ...params.page,
                params: params.params,
                url: params.page?.url ?? fullUrl,
              },
              event: eventName,
            },
          });
        }
      }
    },
    []
  );

  return {
    sendCustomEvent,
  };
}
