import React, { useEffect } from 'react';
import { useTable, useRowSelect } from 'react-table';
import _ from 'lodash';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, CircularProgress } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import IndeterminateCheckbox from './IndeterminateCheckbox';
import DropdownWrapper from './DropdownButton/DropdownWrapper';
import TableSortingHeader from './MainTableComponents/TableSortingHeader';

const useStyles = makeStyles(() =>
  createStyles({
    reactTable: {
      width: '100%',
      border: 'none',
      borderCollapse: 'separate',
      borderSpacing: 'unset',
      fontSize: '0.9rem',
      '& tbody': {
        '& tr': {
          cursor: 'pointer',
          '&:hover': {
            background: '#F1F2F3',
          },
        },
      },
      '& th': {
        position: 'sticky',
        top: '0',
        zIndex: 10,
        padding: '10px',
        backgroundColor: '#F3F3F3',
        borderLeft: '1px solid #DDDDDD',
        fontWeight: 600,
        '&:first-child': {
          borderLeft: '3px solid #DDDDDD',
        },
        '&:last-child': {
          borderRight: '1px solid #DDDDDD',
        },
      },
      '& td': {
        padding: '4px',
        borderBottom: '1px solid #DDDDDD',
        '&:first-child': {
          borderLeft: '3px solid #DDDDDD',
        },
        '&:last-child': {
          borderRight: '1px solid #DDDDDD',
        },
      },
    },
    headSortable: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    rowSelected: {
      background: '#E6F7FF',
      cursor: 'pointer',
      '&:hover': {
        background: '#F1F2F3',
      },
    },
    rowUnselectable: {
      cursor: 'unset !important',
      '&:hover': {
        background: '#F1F2F3',
      },
    },
    iconBtn: {
      padding: 3,
    },
    pagination: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#F3F3F3',
      padding: 5,
      whiteSpace: 'nowrap',
      fontSize: '0.9rem',
      fontWeight: 600,
    },
    paginItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    paginPerPage: {
      marginLeft: 5,
      fontWeight: 600,
    },
    paginInfo: {
      fontSize: '0.8rem',
      fontStyle: 'italic',
      marginRight: '10px',
    },
    tableOuter: {
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'auto',
      position: 'relative',
    },
    placeholderMsg: {
      fontSize: 16,
      fontWeight: 600,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      textAlign: 'center',
    },
    progressGrey: {
      color: 'grey',
      marginRight: 5,
    },
  }),
);

interface Props {
  columns: Array<object>;
  data: Array<object>;
  selectable?: boolean,
  multiSelect?: boolean,
  showCheckboxSelection?: boolean,
  selectedRowIds?: object,
  onSelectedRowIdsChange?: (value: object) => void;
  isRowClickable?: boolean; // if true when user clicks on row, checkbox will be checked. if false user has to click on checkbox
  isLoading?: boolean,
  placeholder?: any,
  placeholderStyle?: object,
  fontSize?: string,
  paginInfo?: string,
  sortVal?: string;
  setSortVal?: (value: string) => void;
  sortType?: "ASC" | "DESC" | null;
  setSortType?: (value: "ASC" | "DESC" | null) => void;
  itemsTotal?: number,
  pageIndex?: number,
  setPageIndex?: (value: number) => void,
  pageSizes?: Array<number>,
  pageSize?: number,
  setPageSize?: (value: number) => void,
  hiddenColumns?: string[],

};

const ReusableControlledTable = ({
  columns,
  data = [],
  selectable = false,
  multiSelect = false,
  showCheckboxSelection = false,
  selectedRowIds: _selectedRowIds = {},
  onSelectedRowIdsChange = () => { },
  isLoading = false,
  placeholder = 'No Data',
  placeholderStyle = {},
  fontSize = '0.9rem',
  paginInfo = '',
  sortVal = null,
  setSortVal = () => { },
  sortType = null,
  setSortType = () => { },
  itemsTotal = 0,
  pageIndex = 1,
  setPageIndex = () => { },
  pageSizes = [20, 50],
  pageSize = 20,
  setPageSize = () => { },
  hiddenColumns = [],
  isRowClickable = false,
}: Props) => {
  const classes = useStyles();

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    toggleAllRowsSelected,
    state: {
      selectedRowIds,
    },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: _selectedRowIds,
        hiddenColumns: hiddenColumns
      },
    },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => {
        let newColumns = [];

        if (!(showCheckboxSelection && selectable && multiSelect)) {
          newColumns = columns;
        } else {
          newColumns = [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              columnStyle: {
                width: '3%',
                minWidth: '40px',
                maxWidth: '40px',
              },
            },
            ...columns,
          ];
        }

        return newColumns;
      });
    },
  );

  useEffect(() => {
    onSelectedRowIdsChange(selectedRowIds);
  }, [onSelectedRowIdsChange, selectedRowIds]);

  const sortClick = (property: string) => {
    setSortVal(property);

    if (!sortType || property !== sortVal) {
      setSortType('DESC');

      if (property !== sortVal) {
        setPageIndex(1);
      }
    } else if (sortType === 'DESC') {
      setSortType('ASC');
    } else if (sortType === 'ASC') {
      setSortType(null);
    }
  }

  // Render the UI for your table
  return (
    <>
      <div className={classes.tableOuter}>
        <table
          {...getTableProps()}
          className={classes.reactTable}
          style={{
            fontSize: fontSize,
          }}
        >
          <thead>
            {
              headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={column.columnStyle}
                      >
                        <div style={column.headerStyle}>
                          {
                            column.sortable && (
                              <TableSortingHeader
                                header={column.render('Header')}
                                property={column.id}
                                sortType={sortType}
                                sortVal={sortVal}
                                sortClick={sortClick}
                              />
                            )
                          }
                          {
                            !column.sortable && (
                              column.render('Header')
                            )
                          }
                        </div>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {
            !isLoading && data && data.length > 0 && (
              <tbody {...getTableBodyProps()}>
                {
                  rows.map((row, i) => {
                    prepareRow(row);

                    let rowClassName = classes.rowUnselectable;

                    if (selectable && isRowClickable) {
                      if (row.isSelected) {
                        rowClassName = classes.rowSelected;
                      } else {
                        rowClassName = '';
                      }
                    }

                    return (
                      <tr
                        {...row.getRowProps()}
                        className={rowClassName}
                        onClick={() => {
                          if (!multiSelect) {
                            toggleAllRowsSelected(false);
                          }
                          if (isRowClickable) {
                            row.toggleRowSelected(!row.isSelected);
                          }
                        }}
                      >
                        {
                          row.cells.map(cell => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={cell.column.columnStyle}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            )
          }
        </table>
        {
          isLoading && (
            <div className={classes.placeholderMsg}>
              <span>
                <CircularProgress
                  className={classes.progressGrey}
                  size={18}
                />
              </span>
              <span>Loading...</span>
            </div>
          )
        }
        {
          !isLoading && (!data || !data.length) && placeholder && (
            <div
              className={classes.placeholderMsg}
              style={placeholderStyle}
            >
              {placeholder}
            </div>
          )
        }
      </div>

      <div>
        <div
          className={classes.pagination}
          style={{
            fontSize: fontSize,
          }}
        >
          <div className={classes.paginItem}>
            <div>
              Row {(pageIndex - 1) * pageSize + 1}-{itemsTotal > pageIndex * pageSize ? pageIndex * pageSize : itemsTotal} of {itemsTotal}
            </div>
            <div>
              <IconButton
                disabled={pageIndex === 1}
                onClick={() => { setPageIndex(pageIndex - 1); }}
                className={classes.iconBtn}
                aria-label="previousPage"
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                disabled={pageIndex * pageSize >= itemsTotal}
                onClick={() => { setPageIndex(pageIndex + 1); }}
                className={classes.iconBtn}
                aria-label="nextPage"
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            <DropdownWrapper
              btnName={`Page ${pageIndex}`}
              style={{ padding: '0px 4px', background: 'white' }}
              color="default"
              variant="outlined"
              size="small"
              list={Array(Math.ceil(itemsTotal / pageSize)).fill('').map((e, i) => `Page ${i + 1}`)}
              setSelect={(e) => { setPageIndex(parseInt(e.toString().replace('Page ', ''))); }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              dynamicWidth
            />
          </div>
          <div className={classes.paginItem}>
            <DropdownWrapper
              btnName={pageSize.toString()}
              style={{ padding: '0px 4px', background: 'white' }}
              color="default"
              variant="outlined"
              size="small"
              list={pageSizes.map((e) => e.toString())}
              setSelect={(e) => {
                setPageSize(Number(e));
                setPageIndex(1);
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              dynamicWidth
            />
            <div
              className={classes.paginPerPage}
              style={{ fontSize: fontSize }}
            >
              per page
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.data, nextProps.data) &&
    prevProps.selectable === nextProps.selectable &&
    prevProps.multiSelect === nextProps.multiSelect &&
    prevProps.showCheckboxSelection === nextProps.showCheckboxSelection &&
    _.isEqual(prevProps.selectedRowIds, nextProps.selectedRowIds) &&
    prevProps.isLoading === nextProps.isLoading &&
    _.isEqual(prevProps.placeholder, nextProps.placeholder) && // this property causes lagging in rendering
    _.isEqual(prevProps.placeholderStyle, nextProps.placeholderStyle) &&
    prevProps.pageIndex === nextProps.pageIndex &&
    prevProps.pageSize === nextProps.pageSize &&
    _.isEqual(prevProps.pageSizes, nextProps.pageSizes) &&
    prevProps.fontSize === nextProps.fontSize &&
    _.isEqual(prevProps.paginInfo, nextProps.paginInfo) &&
    _.isEqual(prevProps.pagination, nextProps.pagination) &&
    _.isEqual(prevProps.paginChange, nextProps.paginChange) &&
    _.isEqual(prevProps.sortVal, nextProps.sortVal) &&
    _.isEqual(prevProps.sortType, nextProps.sortType) &&
    prevProps.itemsTotal === nextProps.itemsTotal &&
    prevProps.pageIndex === nextProps.pageIndex &&
    _.isEqual(prevProps.pageSizes, nextProps.pageSizes) &&
    prevProps.pageSize === nextProps.pageSize
  );
};

export default React.memo(ReusableControlledTable, areEqual);
