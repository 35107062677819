import { ShippingItem } from "../ShippingItem";
import { Address } from "./Address";

export type Order = {
  id: number;
  orderNo: string;
  orderDate: string;
  orderItems: OrderItem[];
  age: string;
  store: string;
  storeName: string;
  qty: number;
  orderTotal: OrderTotal;
  internalNotes: Notes;
  buyerNotes: string;
  soldTo: BuyerInfo;
  shipTo: Address;
  orderTags: any;
  status: string;
  lineItem?: lineItemType;
  orderActivities: OrderActivity[];
  requested?: string;
  fulfillmentInfo?: FulfillmentInfo;
  orderId?: number;
  serviceType?: string;
  shipFromId?: number;
  customDetails?: customDetails[];
  marketPlaceOrderId?: string;
  marketPlaceAccountId?: string;
};
export type OrderFilterData = {
  countries: [];
  stores: [];
  storeNames: [];
  tags: [];
};

export type customDetails = {
  countryOfOrigin: string;
  currency: string;
  description: string;
  id: number;
  numberOfUnits: number;
  orderId: number;
  reasonForExport: string;
  unitOfMeasurement: string;
  valuePerUnit: number;
  weight: number;
  harmonizationCode: string;
};

export type Notes = {
  id?: number;
  message: string;
};

type OrderTotal = {
  currency: string;
  amount: number;
};

export type FulfillmentInfo = {
  trackingNo: string;
  carrier: string;
  shipDate: string;
  labelLinks?: string;
  holdUntil: string;
  status?: string;
};

export type OrderActivity = {
  id: number;
  activityTime: string;
  person: string;
  activityNotes: string;
};

export type BuyerInfo = {
  email: string;
  name: string;
  phone: string;
};

export type ShippingInfoType = {
  isMetric: "METRIC" | "IMPERIAL";
  requested: string;
  shipFrom: Address;
  shippingItem: ShippingItem;
  service: Service;
  serviceOption: ServiceOption;
  // insuranceOption: ServiceOption;
  // CODOption: ServiceOption;
  prerequisiteOptions?: ServiceOption[];
  ifNonDeliveryOption?: ServiceOption;
  isSaturdayDelivery?: ServiceOption;
  keepCurrentRates?: boolean;
};

export type Service = {
  serviceCode: any;
  serviceName: string;
  carrierName?: string; // use for finding PO
  subservices?: {
    serviceCode: any;
    serviceName: string;
  }[];
};

export type ServiceOption = {
  optionCode: string;
  optionName?: string;
  amount?: string;
  qualifier?: string;
  postOfficeAddress?: Address;
};

export type CarrierServiceType = {
  name: string;
  code: string;
  carrier: string;
};

export type CustomsDeclarationsType = {
  packageContents: string;
  packageContentsDescription: string;
  ifNonDelivery?: string;
  currency?: string;
  customsDeclarationItems: CustomsDeclarationItem[];
  EEIFilingOption?: EEIFilingOption;
  ultimateConsigneeType?: string;
  partiesToTransaction?: string;
  modeOfTransport?: string;
  inBondCode?: string;
  entryNumber?: string;
  pointOfOriginInfo?: {
    pointOfOriginType: string;
    pointOfOriginValue: string;
  };
  shipFromTaxInfo?: {
    taxIndentificationNumber: string;
    taxIdType: string;
  };
};

export type EEIFilingOption = {
  type: string;
  POAType?: string;
  shipperFiled?: {
    type: string;
    ITNNumber?: string;
    referenceNumber?: string;
  };
};

export type CustomsDeclarationItem = {
  id: number;
  description: string;
  qty: number;
  value: string;
  total: number;
  weight: string;
  orderId: number;
  measurementUnit?: {
    name: string;
    code: string;
  };
  harmonizationCode: string;
  origin: string;
  EEIFormInformation?: {
    scheduleB: {
      scheduleBNumber: string;
      quantity: number;
      unitOfMeasurement: {
        code: string;
      };
    };
    exportType: string;
    isLicenseDetailsRequired: boolean;
    licenseDetails: {
      licenseCode: string;
      ECCNNumber: string;
      licenseNumber: string;
      licenseLineValue: number;
    };
    exportInformation: string;
  };
};

export type D2POAddressesOption = {
  // city: string,
  distance: number;
  // stateCode: string,
  // streetAddress: string
  id: string;
  postOfficeAddress: Address;
};

export type reqServiceOptionType = {
  code: string;
  amount?: number;
  qualifier?: string;
  postOfficeAddress?: Address;
};

export const orderStatusOptions = {
  unshipped: "UNSHIPPED",
  shipped: "SHIPPED",
  canceled: "CANCELED",
  partiallyShipped: "PARTIALLYSHIPPED",
  onHold: "ONHOLD",
  unpaid: "UNPAID",
  search: "",
};

export const orderActions = {
  unshipped: ["Mark as Shipped", "Hold Orders", "Cancel Orders"],
  canceled: ["Mark as Shipped", "Restore Orders"],
  shipped: ["Restore Orders", "Cancel Orders"],
  onHold: ["Mark as Shipped", "Restore Orders"],
  unpaid: ["Move to Awaiting Shipment"],
  fulfillment: ["Cancel Fulfillment"],
  search: [
    "Mark as Shipped",
    "Hold Orders",
    "Cancel Orders",
    "Restore Orders",
    "Move to Awaiting Shipment",
  ],
};

export type MarkAsShippedType = {
  id: number;
  orderNo: string;
  recipient: string;
  trackingNo: string;
  carrier: string;
  shipDate: string;
  notifyCus: boolean;
  notifyMar: boolean;
};

export type UpdateCustomDetailsRequest = {
  id: number;
  orderId: number;
  currency: string;
  reasonForExport: string;
  description: string;
  numberOfUnits: number;
  valuePerUnit: number;
  weight: number;
  countryOfOrigin: string;
  unitOfMeasurement: string;
};

export type OrderItem = {
  id: number;
  accountNo?: string;
  marketplaceOrderId?: string;
  itemId?: string;
  purchaseDate?: string;
  itemName: string;
  itemSKU: string;
  ASIN: string;
  buyerCustomizedInformation?: string;
  giftMessage: string;
  isGift?: boolean;
  quantity: number;
  numberOfItems?: number;
  price: number;
  tax?: number;
  shippingPrice?: number;
  shippingTax?: number;
  shippingDiscount?: number;
  promotionalDiscount?: string;
  condition?: string;
  status?: string;
  imageUrl?: string;
  weight?: number;
  dimensions?: Dimensions;
};

export type Dimensions = {
  length?: number;
  width?: number;
  height?: number;
};

type lineItemType = {
  declaredValue: declaredValueType;
  height: number;
  insuranceType: string;
  length: number;
  packagingType: string;
  unitOfMeasurement: string;
  weight: number;
  width: number;
};

type declaredValueType = {
  currency: string;
  amount: number;
};

export type DeleteCustomDetailsDTO = {
  OrderId: number;
  CustomDetailIds: number[];
}[];

export type SearchOrder = {
  name: string;
  checked: boolean;
  count: number;
};
