import React, { Component } from "react";

type StripeErrorBoundaryProps = {
  children: React.ReactNode;
};

type StripeErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

class StripeErrorBoundary extends Component<
  StripeErrorBoundaryProps,
  StripeErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <p className="p-4 text-danger">
          An error occurred while processing your payment. Please start over, if this issue persist please contact us.
        </p>
      );
    }

    return this.props.children;
  }
}

export default StripeErrorBoundary;
