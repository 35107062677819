import { useState, useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

import { PLAN, PLAN_LEVEL } from './common';
import SubscriptionContext from './SubscriptionContext';

import ConfirmPlanDialog from './ConfirmPlanDialog';

import Br from '../../../../utilities/Br';
import FixedDialogWrapper from '../../../../utilities/FixedDialogWrapper';
import InformationTooltip from '../../../../utilities/InformationTooltip';
import { MENU_MAP } from '../../../common';


type StyleProps = {
  topRef: React.MutableRefObject<HTMLElement>;
  bottomRef: React.MutableRefObject<HTMLElement>;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      padding: '10px 20px 6px 20px',
    },
    planTitle: {
      padding: 4,
      fontSize: '1.1rem',
      background: '#CFE6FF',
    },
    featureTextEmpty: {
      paddingRight: 10,
    },
    paidPlanBanner: {
      fontSize: "1.1rem",
      maxHeight: "40px",
    },
    emptyArea: {
      padding: "1px",
      backgroundColor: "#fff",
    },
    paidPlans: {
      padding: "10px",
      maxHeight: "40px",
      textAlign: "center",
      color: "#edf2f4",
      backgroundColor: "#1d3557",
      position: "relative",
      "&::after": {
        content: "' '",
        position: "absolute",
        width: "100%",
        height: ({ topRef, bottomRef }: StyleProps) => (topRef.current && bottomRef.current) ?
          ((bottomRef.current.offsetTop + bottomRef.current.clientHeight) - topRef.current.offsetTop) + "px" :
          "490px",
        display: "block",
        top: "0px",
        left: "0px",
        border: "1px solid #1d3557",
        userSelect: "none",
        pointerEvents: "none",
      },
    },
    featureText: {
      textAlign: 'right',
      paddingRight: 10,
      borderRight: '1px solid lightgray',
    },
    fireIcon: {
      width: 20,
    },
    freeTrialIcon: {
      position: 'absolute',
      top: -16,
      right: -20,
      width: 40,
    },
    notes: {
      margin: 30,
      padding: 10,
      border: '1px solid lightblue',
      color: '#0964C7',
    },
    gridCentered: {
      textAlign: 'center',
    },
    bold: {
      fontWeight: 600,
    },
    italic: {
      fontStyle: 'italic',
    },
    red: {
      color: 'red',
    },
    green: {
      color: '#03827A',
    },
    blue: {
      color: '#0964C7',
    },
    underlined: {
      textDecoration: 'underline',
    },
    pointer: {
      cursor: 'pointer',
    },
    smallerText: {
      fontSize: '0.9rem',
    },
    hidden: {
      visibility: 'hidden',
    },
    button: {
      textTransform: 'capitalize',
      fontSize: '1rem',
      padding: '6px 12px',
    },
    primaryButton: {
      background: '#03827A',
      color: 'white',
      '&:hover': {
        opacity: 0.9,
        background: '#03827A',
        color: 'white',
      },
      '&:disabled': {
        opacity: 0.5,
        background: '#03827A',
        color: 'white',
      },
    },
    strikeThrough: {
      textDecoration: 'line-through',
    },
    infoTooltip: {
      fontSize: '1rem',
      marginBottom: '1px !important',
      color: 'red'
    }
  }),
);

const ChangePlanDialog = ({ open, setOpen, setIsDowngradeToBeta }) => {
  const topRef = useRef<HTMLElement>(null);
  const bottomRef = useRef<HTMLElement>(null);
  const styleProps: StyleProps = {
    topRef,
    bottomRef,
  }

  const classes = useStyles(styleProps)

  const [targetPlanType, setTargetPlanType] = useState('');
  const [targetPrice, setTargetPrice] = useState(0);
  const [targetSpecialPrice, setTargetSpecialPrice] = useState(0);
  const [targetFreeTrial, setTargetFreeTrial] = useState(false);
  const [targetYearly, setTargetYearly] = useState(false);
  const [confirmPlanDialogOpen, setConfirmPlanDialogOpen] = useState(false);
  const [yearlyPrice, setYearlyPrice] = useState(0);

  const [planData, setPlanData] = useState([]);

  const {
    planCancelled,
    currencyFormatter,
    setAllFeaturesDialogOpen,
    setChangePlanSuccessDialogOpen,
    setCancelPlanDialogOpen,
    refetchUserSubriptionPlan,
    doUpdateUserSubscription,
    userSubscription,
    allSubscriptionPlans,
    setSeeAllFeaturePlanName,
    filledPromoCode,
  } = useContext(SubscriptionContext);

  useEffect(() => {
    const _planData = allSubscriptionPlans.data.map((e) => {
      const { title, price, subscriptionPromos, features, hasfreetrial, yearlyprice } = e;
      const { amount } = price;
      let specialPrice = null;

      const sp = subscriptionPromos.find((ee) => ee.promoName === 'On SALE Promo 1');
      if (sp && sp.description !== 'Free') {
        specialPrice = sp.description.replace('CAD $', '');
      }
      return {
        name: title,
        price: amount,
        specialPrice,
        shipments: features.find((ee) => ee.featureName === 'Shipments per Month')?.featureValue,
        accounts: features.find((ee) => ee.featureName === 'Link Your Own Courier Accounts')?.featureValue === 'Yes' ? 'Unlimited' : 'Shipvista Accounts',
        user: features.find((ee) => ee.featureName === 'Available Users')?.featureValue,
        features: [],
        yearly: false,
        freeTrial: hasfreetrial,
        yearlyPrice: yearlyprice
      };
    });

    _planData.push({ name: PLAN.CUSTOMIZE, price: 9999, shipments: 'Contact us', accounts: 'Unlimited', user: 'Contact us' });

    const staterPlanIndex = _planData.findIndex(e => e.name.toLowerCase() === PLAN.STARTER.toLowerCase());
    let sortedPlans = [..._planData];
    if (staterPlanIndex > -1) {
      sortedPlans = [..._planData.splice(staterPlanIndex, 1), ..._planData];
    }
    sortedPlans = sortedPlans.map((plan, index) => ({ ...plan, value: index }));
    setPlanData(sortedPlans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPlanButtonText = useCallback((targetPlanType: string) => {
    //const currentPlan = userSubscription?.data?.subscriptionPlan?.title?.toLowerCase();
    // const currentPlanIndex = planData.findIndex(p => p.name.toLowerCase() === currentPlan);
    // const planIndex = planData.findIndex(p => p.name.toLowerCase() === plan.toLowerCase());
    // if (!currentPlan || currentPlanIndex < 0 || planIndex < 0){
    //   return "Select Plan";
    // }else{
    //   const currentPlanValue = planData[currentPlanIndex].value;
    //   const planValue = planData[planIndex].value;
    //   if (currentPlanValue === planValue){
    //     return "Current";
    //   }else if(currentPlanValue > planValue){
    //     return "Downgrade";
    //   }else{
    //     return "Upgrade";
    //   }
    // }

    const currentPlanValue = PLAN_LEVEL[userSubscription?.data?.subscriptionPlan?.title];
    const targetPlanValue = PLAN_LEVEL[targetPlanType];
    if (currentPlanValue === targetPlanValue) {
      return "Current";
    } else if (currentPlanValue > targetPlanValue) {
      return "Downgrade";
    } else {
      return "Upgrade";
    }

  },
    [userSubscription
      //,planData
    ])

  const featureDefinitions = [
    { name: 'price', text: 'Price Per Month' },
    { name: 'shipments', text: 'Shipments Per Month' },
    { name: 'accounts', text: 'Carrier Accounts' },
    { name: 'user', text: 'User' },
    { name: 'features', text: '' },
    { name: 'yearly', text: '' },
  ];
  const seeAllFeaturesHandler = (plan) => {
    setSeeAllFeaturePlanName(plan)
    setAllFeaturesDialogOpen(true);
  };
  const isPlanAPaidPlan = (_price: number) => {
    return _price > 0
  }
  const confirmPlanDialogSuccessHandler = () => {
    setConfirmPlanDialogOpen(false);
    setOpen(false);
    refetchUserSubriptionPlan();
    setTimeout(() => {
      setChangePlanSuccessDialogOpen(true);

    }, 20);
  };

  const selectPlanHandler = async (plan, price = 0, specialPrice = 0, freeTrial = false, yearly = false, _yearlyPrice = 0) => {
    setTargetPlanType(plan);
    setTargetPrice(price);

    //////////////////////////////////////////////////////
    // enable this when promos are active
    // setTargetSpecialPrice(specialPrice);
    //////////////////////////////////////////////////////
    setTargetSpecialPrice(specialPrice);
    setTargetFreeTrial(freeTrial);
    setTargetYearly(yearly);
    setYearlyPrice(_yearlyPrice)

    let selectedPlanID = allSubscriptionPlans.data.filter((p) => p.title.toLowerCase() === plan.toLowerCase());
    if (plan !== PLAN.BETA && plan !== PLAN.STARTER) {
      setConfirmPlanDialogOpen(true);
    } else {
      const data = {
        subscriptionplanid: selectedPlanID[0].subscriptionplanid,
        billingDetails: {
          paymentMethodId: ""
        },
      };

      doUpdateUserSubscription(data).then((res) => {
        if (res) {
          confirmPlanDialogSuccessHandler();
        }
      })
    }
  };

  useEffect(() => {
    // refer to above function and use for reference
    if (filledPromoCode) {
      const planDetails = planData.find(p => p.name === filledPromoCode?.title);
      const code = filledPromoCode?.code;
      if (code) {
        if (planDetails) {

          const { name, price, specialPrice, freeTrial, yearlyPrice } = planDetails;

          setTargetPlanType(name);
          setTargetPrice(price);
          setTargetSpecialPrice(specialPrice);
          setTargetFreeTrial(freeTrial);
          setTargetYearly(filledPromoCode?.term === "yearly");
          setYearlyPrice(yearlyPrice);

          setConfirmPlanDialogOpen(true);

        }
      } else {
        filledPromoCode?.onFinish && filledPromoCode?.onFinish();
      }

    }
  }, [filledPromoCode, planData])
  const setProfessionalAndEnterprisePrice = (plan: string, price: number) => {
    if (isPlanAPaidPlan(price)) {
      if (plan === PLAN.PROFESSIONAL) {
        return 29.99
      }
      if (plan === PLAN.ENTERPRISE) {
        return 69.99
      }
    }
    return price
  }
  const contactUsHandler = (plan) => {
    window.open(window.location.origin + MENU_MAP.CONNECT_WITH_US.route, '_blank');
  };
  const formatToFixedDecimal = (_price: number) => {
    return (Math.round((_price + Number.EPSILON) * 100) / 100)
  }

  return (
    <FixedDialogWrapper
      dialogId="change-plan-dialog"
      title="Change your Plan"
      maxWidth="lg"
      open={open}
      setOpen={setOpen}
    >
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item className={classes.title}>
            <h5 className={classes.italic}>
              <span>Change your plan at any time. </span>
              {
                userSubscription?.data?.subscriptionPlan.title !== PLAN.STARTER && !planCancelled && (
                  <span
                    className={`${classes.blue} ${classes.pointer} ${classes.underlined}`}
                    onClick={() => {
                      // setCancelPlanType(PLAN.STARTER);
                      setCancelPlanDialogOpen(true);
                    }}
                  >
                    Change to Free Plan
                  </span>
                )
              }
            </h5>
          </Grid>
          <Grid innerRef={topRef} container item className={`${classes.paidPlanBanner} ${classes.bold}`}>
            <Grid item xs={4} className={classes.emptyArea} />
            <Grid item xs={8} className={classes.paidPlans}>
              <div>
                Paid Plans
              </div>
            </Grid>
          </Grid>
          <Grid container item className={`${classes.planTitle} ${classes.bold}`}>
            <Grid item xs className={classes.featureTextEmpty}></Grid>
            {
              planData.map((e, i) => (
                <Grid key={`plan-type-${i}`} item xs className={classes.gridCentered}>
                  <div>
                    <Br />
                    {e.name}
                    <Br />
                  </div>
                </Grid>
              ))
            }
          </Grid>
          {
            featureDefinitions.map((e, i) => (
              <Grid innerRef={bottomRef} key={`feature-row-${i}`} container item alignItems="center">
                <Grid item xs className={classes.featureText}>
                  <div>
                    <Br count={2} />
                    {e.text}&nbsp;
                    <Br count={2} />
                  </div>
                </Grid>
                {
                  planData.map((ee, ii) => {
                    const key = e.name;
                    const value = ee[key];

                    let content;

                    switch (key) {
                      case 'price': {
                        // const price = value === 0 ? 'Free' : value === 9999 ? 'Contact us' : `${currencyFormatter.format(value)} CAD`;
                        /////////////////////////////////////////////////////
                        // uncomment specialPrice when promos are active
                        // const specialPrice = ee.freeTrial && ee.specialPrice ? `Try ${currencyFormatter.format(ee.specialPrice)}*12 for a year!` : null;
                        ////////////////////////////////////////////////////
                        // const specialPrice = null
                        content = (
                          <div style={{ position: 'relative' }}>
                            <div className={`${classes.green} ${classes.bold}`}>
                              {
                                ee.price === 9999 ? (
                                  'Contact us'
                                ) : (
                                  ee.price === 0.00 ? ee.name.toLowerCase() === PLAN.STARTER.toLowerCase() ? "FREE" : currencyFormatter.format(0) + " CAD" : ee.name.toLowerCase() === PLAN.PROFESSIONAL.toLowerCase() || ee.name.toLowerCase() === PLAN.ENTERPRISE.toLowerCase() ? <span className={classes.strikeThrough}>{currencyFormatter.format(setProfessionalAndEnterprisePrice(ee.name, ee.price)) + ' CAD'}</span> : <span>{currencyFormatter.format(setProfessionalAndEnterprisePrice(ee.name, ee.price)) + ' CAD'}</span>
                                )
                              }


                            </div>
                            {
                              (ee.name.toLowerCase() === PLAN.PROFESSIONAL.toLowerCase() || ee.name.toLowerCase() === PLAN.ENTERPRISE.toLowerCase()) && (
                                <div
                                  className={`${classes.red} ${classes.bold} ${classes.smallerText} ${classes.italic}`}
                                  style={{
                                    position: 'absolute',
                                    width: '100%',
                                  }}
                                >
                                  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* <img
                                      className={classes.fireIcon}
                                      src="/images/subscription/fire.png"
                                      alt="fire icon"
                                    />
                                    &nbsp; */}

                                    <span style={{ color: 'red' }}>
                                      {(ee.name.toLowerCase() === PLAN.PROFESSIONAL.toLowerCase() ||
                                        ee.name.toLowerCase() === PLAN.ENTERPRISE.toLowerCase()) &&
                                        currencyFormatter.format(formatToFixedDecimal(ee.price))} CAD
                                      <InformationTooltip
                                        style={{ fontSize: '1rem', marginBottom: '1px !important', color: 'red' }}
                                        message={`You can save when you subscribe to our ${ee.name} yearly plan. With the limited time promotion, you only pay $${formatToFixedDecimal(ee.price * 12)} CAD per year ($${formatToFixedDecimal(ee.price)} CAD per month).`} />
                                    </span>
                                  </span>
                                </div>
                              )
                            }
                          </div>
                        );

                        break;
                      }
                      case 'features': {
                        if (value !== null && value !== undefined) {
                          content = (
                            <div
                              className={`${classes.blue} ${classes.underlined} ${classes.pointer}`}
                              onClick={() => { seeAllFeaturesHandler(ee.name); }}
                            >
                              See All Features
                            </div>
                          );
                        }

                        break;
                      }
                      case 'yearly': {
                        if (ee.name === userSubscription?.data?.subscriptionPlan.title) {
                          content = (
                            <div className={classes.bold} style={{ position: 'relative' }}>
                              {`Current${userSubscription?.data?.isYearly ? ' (Yearly)' : ''}`}
                              {/* {
                                (ee.name === PLAN.PROFESSIONAL || ee.name === PLAN.ENTERPRISE) && !userSubscription?.data?.isYearly && (
                                  <div
                                    className={`${classes.green} ${classes.underlined} ${classes.pointer} ${classes.smallerText} ${classes.bold} ${classes.italic}`}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      position: 'absolute',
                                      bottom: -32,
                                      width: '100%',
                                    }}
                                    onClick={() => {
                                      // window.alert("The feature is in progress.");
                                      selectPlanHandler(ee.name, ee.price, ee.specialPrice, ee.freeTrial, true, ee.yearlyPrice);
                                    }}
                                  >
                                    Select Yearly Plan
                                  </div>
                                )
                              } */}
                              {/* {
                                userSubscription?.data?.isYearly && (
                                  <div
                                    className={`${classes.green} ${classes.underlined} ${classes.pointer} ${classes.smallerText} ${classes.bold} ${classes.italic}`}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      position: 'absolute',
                                      bottom: -32,
                                      width: '100%',
                                    }}
                                    onClick={() => {
                                      // window.alert("The feature is in progress.");
                                      selectPlanHandler(ee.name, ee.price, ee.specialPrice, ee.freeTrial, false, ee.yearlyPrice);
                                    }}
                                  >
                                    Select Monthly Plan
                                  </div>
                                )
                              } */}
                            </div>
                          );
                        } else if (ee.name === PLAN.CUSTOMIZE) {
                          content = (
                            <Button
                              className={`${classes.button} ${classes.primaryButton}`}
                              onClick={() => { contactUsHandler(ee.name); }}
                            >
                              Contact Us
                            </Button>
                          );
                        } else if (userSubscription?.data?.subscriptionPlan?.title.toLowerCase() !== PLAN.BETA.toLowerCase() && userSubscription?.data?.subscriptionPlan?.title.toLowerCase() !== PLAN.STARTER.toLowerCase() && (ee.name === PLAN.BETA || ee.name === PLAN.STARTER)) {
                          content = (
                            <div>
                              <Button
                                className={`${classes.button} ${classes.primaryButton}`}
                                onClick={() => {
                                  if (ee.name === PLAN.BETA) {
                                    setIsDowngradeToBeta(true)
                                  } else {
                                    setIsDowngradeToBeta(false)
                                  }

                                  setCancelPlanDialogOpen(true);
                                }}
                              >
                                <span>{getPlanButtonText(ee.name)}</span>
                              </Button>
                            </div>
                          );
                          // }
                        } else {
                          content = (
                            <div style={{ position: 'relative' }}>
                              <Button
                                className={`${classes.button} ${classes.primaryButton}`}
                                style={{ position: 'relative' }}
                                onClick={() => { selectPlanHandler(ee.name, ee.price, ee.specialPrice, ee.freeTrial, false, ee.yearlyPrice); }}
                              >
                                <span>{getPlanButtonText(ee.name)}</span>
                              </Button>
                              {/* {

                                isPlanAPaidPlan(ee.price) && (
                                  <div
                                    className={`${classes.green} ${classes.underlined} ${classes.pointer} ${classes.smallerText} ${classes.bold} ${classes.italic}`}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      position: 'absolute',
                                      bottom: -24,
                                      width: '100%',
                                    }}
                                    onClick={() => {
                                      selectPlanHandler(ee.name, ee.price, ee.specialPrice, ee.freeTrial, true, ee.yearlyPrice);
                                    }}
                                  >
                                    Select Yearly Plan
                                  </div>
                                )
                              } */}
                            </div>
                          );
                        }

                        break;
                      }
                      default:
                        content = value;
                        if (value?.toLowerCase() === "Shipvista Accounts".toLowerCase()) {
                          content = "Shipvista Accounts Only";
                        }

                        break;
                    }

                    return (
                      <Grid key={`feature-column-${i}-${ii}`} item xs className={classes.gridCentered}>
                        {content}
                      </Grid>
                    );
                  })
                }
              </Grid>
            ))
          }
          <Grid item className={classes.notes}>
            <div className={classes.bold}>Please Note:</div>
            <div style={{ marginTop: 5, marginBottom: 5 }}>If you are a new registered user, you will have <span className={classes.bold}>30-days free trial</span>.</div>
            <div>If you decide to continue past your free trial period and select a paid plan, you will be charged a monthly or yearly subscription fee that reflects the plan you select. For your convinience, once you have activated your subscription, your Shipvista service will continue uninterrupted unless you decide to cancel. Your credit card may also be used to pay for postage and optional services. You can cancel online or reach out to sales@shipvista.com.</div>
          </Grid>
        </Grid>
      </div>
      {
        confirmPlanDialogOpen && (
          <ConfirmPlanDialog
            open={confirmPlanDialogOpen}
            setOpen={setConfirmPlanDialogOpen}
            targetPlanType={targetPlanType}
            targetPrice={targetPrice}
            targetSpecialPrice={targetSpecialPrice}
            targetFreeTrial={targetFreeTrial}
            targetYearly={targetYearly}
            yearlyPrice={yearlyPrice}
            onSubmit={() => { confirmPlanDialogSuccessHandler(); }}
          />
        )
      }
    </FixedDialogWrapper>
  );
};

export default ChangePlanDialog;
