import Button from '@material-ui/core/Button';

import './RoundedCard.scss';

const RoundedCard = ({
  title = '',
  showButton = false,
  buttonDefinitions = [],
  style = {},
  headerStyle = {},
  titleStyle = {},
  children,
}) => {
  const generateButtons = () => {
    const buttons = [];

    for (let i = 0, ii = buttonDefinitions.length; i < ii; i++) {
      const b = buttonDefinitions[i];

      buttons.push(
        <Button
          key={`rounded-card-title-button-${i}`}
          style={{
            marginLeft: '10px',
          }}
          variant="contained"
          color={b.color}
          disabled={b.disabled}
          onClick={b.onClick}
        >
          {b.label}
        </Button>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {buttons}
      </div>
    );
  };

  return (
    <div
      className="rounded-card"
      style={style}
    >
      <div className="rounded-card-header" style={headerStyle}>
        <span className="rounded-card-header-title" style={titleStyle}>{title}</span>
        {
          showButton && generateButtons()
        }
      </div>
      {children}
    </div>
  );
};

export default RoundedCard;