import { useMemo, createContext, useCallback } from "react";
import { useLocation, useHistory } from 'react-router-dom';

// Refer to AppNavQueryParamData for examples


// valid types of the data that can be sent though url query params:
// "string", "number", "bigint", "boolean"
// any other properties will be filtered out
export type ValidRouteParamPropertyType = string | number | bigint | boolean;

type FilterInvalidProperty<S> = {
    [key in keyof S]: S[key] extends ValidRouteParamPropertyType ? key : never;
}[keyof (S)];

type GetValueOfKeyProperty<S, T extends keyof S> = { [key in FilterInvalidProperty<S[T]>]: S[T][key] };

export type RouteParamBaseType<T> = { [key in keyof T]: { sample: Required<GetValueOfKeyProperty<T, key>> } };

export function activator<T>(val: RouteParamBaseType<T>) {
    return val;
}

export type QueryStringOptions = {
    full?: boolean;
    replaceAllParams?: boolean;
}

/**
* The purpose of the createNavQueryManager function is to create a typed
* hook and context that knows the types of query parameters that are passed
* to specific route throughout the app. The hook created by the function will
* allow someone to easily send and receive typed data between routes using
* url query parameters.
* @param {RouteParamBaseType<T>} routeMapping An object with keys that correspond to the different routes.
* The values are an object that has a sample property which is another object with sample data that should 
* be sent to the corresponding route key. The sample property is used for type conversion from and to the sting type.
*/
export const createNavQueryManager = <T>(routeMapping: RouteParamBaseType<T>) => {
    const NavQueryContext = createContext(routeMapping);
    /**
    * A hook that allow someone to easily send and receive typed data between routes using
    * url query parameters.
    * @param {K} key A url key
    */
    const useNavQueryParams = <K extends keyof T>(key: K) => {
        const history = useHistory();
        const location = useLocation();
        const { search } = useLocation();



        const query = useMemo(() => new URLSearchParams(search), [search]);


        const getQueryString = useCallback((newParams: Partial<GetValueOfKeyProperty<T, K>>, options: QueryStringOptions = {}) => {
            let result = options?.full ? "?" : "";

            const params = new URLSearchParams(options?.replaceAllParams ? {} : query);
            Object.entries(newParams).forEach(([key, value]) => {
                if (["string", "number", "bigint", "boolean"].includes(typeof value)) {
                    params.set(key, value.toString());
                }
            });
            result += params.toString();
            return result;
        }, [query]);

        const getQueryParams = useCallback((): Partial<GetValueOfKeyProperty<T, K>> => {
            let result: Partial<GetValueOfKeyProperty<T, K>> = {};
            const params = new URLSearchParams(query);
            Object.entries(routeMapping[key].sample).forEach(([key, value]) => {
                const stringRouteValue = params.get(key);
                if (!stringRouteValue) return;
                switch (typeof value) {
                    case "string":
                        result[key] = stringRouteValue;
                        break;
                    case "number":
                        const numberValue = Number(stringRouteValue);
                        if (numberValue !== null && numberValue !== undefined && !isNaN(numberValue)) {
                            result[key] = numberValue
                        }
                        break;
                    case "bigint":
                        try {
                            const bigIntValue = BigInt(stringRouteValue);
                            if (bigIntValue !== null && bigIntValue !== undefined && typeof bigIntValue === "bigint") {
                                result[key] = bigIntValue;
                            }
                        } catch (e) { }
                        break;
                    case "boolean":
                        result[key] = stringRouteValue === "true";
                        break;
                    default:
                }
            });
            return result;
        }, [key, query])

        const clearQueryParams = useCallback(() => {
            const params = new URLSearchParams(query);
            Object.keys(routeMapping[key].sample).forEach((key) => {
                params.delete(key);
            });
            history.replace({ pathname: location.pathname, search: params.toString() });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [key, query, history]);

        return {
            getQueryString,
            getQueryParams,
            clearQueryParams,
        };
    }

    return {
        NavQueryContext,
        useNavQueryParams,
    }

}





