import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      maxWidth: 200,
      fontSize: '0.9rem',
      lineHeight: '1.5',
      textAlign: 'center',
    },
    helpTooltip: {
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginBottom: '3px'
    },
  }),
);

const TooltipContent = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.tooltip}>
      {children}
    </div>
  );
};

interface Props {
  message: any,
  customIcon?: React.ReactElement,
  style?: any
}

const InformationTooltip = ({ message, customIcon, style }: Props) => {
  const classes = useStyles();
  return (
    <Tooltip
      placement="top"
      title={(
        <TooltipContent>
          {message}
        </TooltipContent>
      )}
    >
      {customIcon ? (customIcon) : (<InfoOutlinedIcon
        color="primary"
        className={classes.helpTooltip} style={style}
      />)}

    </Tooltip>
  )
}

export default InformationTooltip