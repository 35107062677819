import Button from "@material-ui/core/Button";
import LoopIcon from '@material-ui/icons/Loop';
import Paper from "@material-ui/core/Paper";
import "../../../styles/scss/refreshStores/commonStyles.scss";
import RefreshStoresListItem from "./RefreshStoresListItem";
import { StoreRefresh } from "../../../@type/StoreSetup/Store";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";

const useStyles = makeStyles(() =>
    createStyles({
        abtn: {
            color: "#069",
            textDecoration: "underline",
            cursor: "pointer",
            '&:hover': {
                color: "#0099E6",
            },
        }
    }),
);

interface Props {
    storesRefresh: StoreRefresh[];
    isLoadingAll: boolean;
    isZeroStore: boolean;
    refreshStore: (storeId: number) => void
}

export default function RefreshStoresPopperContent(props: Props) {
    const classes = useStyles();

    const { storesRefresh, isLoadingAll, isZeroStore, refreshStore } = props;

    const history = useHistory();

    function goToStoreSetupClick() {
        history.push("/settings/store-setup");
    }

    return (
        <Paper elevation={5} style={{ width: 380, height: 380, backgroundColor: "white" }}>
            <div style={{ width: 380, height: 380, overflow: "auto" }}>
                <Button
                    onClick={() => refreshStore(0)} // pass 0 if update all stores -- Vatsal
                    style={{ margin: 8, width: "95%", textTransform: "capitalize", fontWeight: 600 }}
                    variant="contained"
                    color="primary"
                    startIcon={<LoopIcon />}
                >
                    Update All Stores
            </Button>
                <hr className="refreshStoresHr" />
                {!isLoadingAll
                    ? !isZeroStore
                        ? storesRefresh.map((storeRefresh, i) =>
                            <RefreshStoresListItem
                                key={`storeRefresh-${i}`}
                                storeRefresh={storeRefresh}
                                refreshStore={refreshStore}
                            />)
                        : <div style={{ textAlign: "center", fontSize: 16, fontWeight: 600, margin: 5, marginTop: 80 }}>No active stores available<br />Please activate your store<br />Or Go to <span className={classes.abtn} onClick={goToStoreSetupClick}>Store Setup Page</span> to add a new store</div>
                    : <div style={{ textAlign: "center", fontSize: 16, fontWeight: 600, margin: 5, marginTop: 30 }}>Loading your stores...</div>
                }
            </div>
        </Paper>

    );
}