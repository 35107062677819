import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    breakline: {
      height: 10,
    },
  }),
);

const Br = ({ count = 1 }) => {
  const classes = useStyles();

  const generateBrs = () => {
    const brs = [];
    const key = (new Date()).toString();

    for (let i = 0; i < count; i += 1) {
      brs.push(
        <div key={`br-${key}-${i}`} className={classes.breakline} />
      );
    }

    return brs;
  };

  return (<>{generateBrs()}</>);
};

export default Br;
