import { OffersAction } from './../@types/offers/offersActions';
import { OffersState, OffersActions } from "../@types/offers";

const initialState: OffersState = {
    isOAOfferApplied: false
};

export function offersReducer(
    state = initialState,
    action: OffersAction
): OffersState {
    switch (action.type) {
        case OffersActions.SET_OA_OFFER_APPLIED:
            return { ...state, isOAOfferApplied: action.payload }
        default:
            return state;
    }
}