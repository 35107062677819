import { FeedsAction, FeedsState, FeedsActions } from "../@types/feeds";

const initialState: FeedsState = {
    feeds: [],
    feedDetailsFeed: null,
    isTableLoading: "Success" 
};

export function feedsReducer(
    state = initialState,
    action: FeedsAction
): FeedsState {
    switch (action.type) {
        case FeedsActions.LOAD_FEEDS_DATA:
            return { ...state, feeds: action.payload }
        case FeedsActions.SET_FEED_DETAILS_FEED:
            return { ...state, feedDetailsFeed: action.payload }
        case FeedsActions.SET_FEEDS_TABLE_LOADING:
            return { ...state, isTableLoading: action.payload } 
        default:
            return state;
    }
}