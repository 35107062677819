import { AvailableRate } from "../@type/AvailableRate";
import { getDays } from "../components/utilities/getDays";
// sort priority: transit day, arrival time, price
export const compareByTransitDay = (a: AvailableRate, b: AvailableRate) => {
    // 0001-01-01 is the date that shows when transit day is undefined
    if (a.transitDay === undefined && a.arrivalDate.substring(0, a.arrivalDate.indexOf('T')) === '0001-01-01') {
        return 1
    }
    if (b.transitDay === undefined && b.arrivalDate.substring(0, a.arrivalDate.indexOf('T')) === '0001-01-01') {
        return -1
    }
    // ================================================================================
    //          This section is for when there is no transit day returned
    // ================================================================================

    if (a.arrivalDate && a.transitDay === undefined && a.arrivalDate.substring(0, a.arrivalDate.indexOf('T')) !== '0001-01-01') {
        let first = getDays(a.arrivalDate)
        let second = getDays(b.arrivalDate)

        if (first === second) {
            if (a.arrivalTime === b.arrivalTime) {
                return a.price.totalCharge - b.price.totalCharge
            }

            return parseInt(a.arrivalTime) > parseInt(b.arrivalTime) ? 1 : -1;
        }
        return first > second ? 1 : -1;

    }
    // ================================================================================
    // ================================================================================
    if (a.transitDay === b.transitDay) {
        if (a.arrivalTime === b.arrivalTime) {
            return a.price.totalCharge - b.price.totalCharge;
        }

        return parseInt(a.arrivalTime) > parseInt(b.arrivalTime) ? 1 : -1;
    }

    return a.transitDay > b.transitDay ? 1 : -1;
}

// sort priority: price, transit day, arrival time
export const compareByPrice = (a: AvailableRate, b: AvailableRate) => {
    if (a.price.totalCharge === b.price.totalCharge) {
        if (a.transitDay === b.transitDay) {
            return parseInt(a.arrivalTime) - parseInt(b.arrivalTime);
        }

        return a.transitDay > b.transitDay ? 1 : -1;
    }

    return a.price.totalCharge > b.price.totalCharge ? 1 : -1;
}

export const getTop3Rates = (rates: AvailableRate[]) => {
    const top3Rates: AvailableRate[] = [];
    const ratesPre1 = [...rates];
    const ratesPre2 = [...rates];
    const ratesPre3 = [...rates];

    // sort each time will sort the rates arr itself and return the sorted arr
    top3Rates.push(ratesPre1.sort(compareByTransitDay)[0]);
    top3Rates.push(ratesPre2.find(rate => rate.service.serviceName.toLowerCase().includes("expedited"))
        ? ratesPre2.sort(compareByPrice).find(rate => rate.service.serviceName.toLowerCase().includes("expedited"))
        : ratesPre2.sort(compareByPrice)[0]);
    top3Rates.push(ratesPre3.sort(compareByPrice)[0]);

    return top3Rates;
}
