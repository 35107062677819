import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, List, ListItemText, ListItem, Divider } from '@material-ui/core';

import DropdownButton from '../DropdownButton/DropdownButton';

import { formatDateFilter } from '../../../functionUtilities/formatDateFilter';

import { APP_COLOR } from '../../../environment/env_dev';

const RANGE_KEY = {
  TODAY: 0,
  YESTERDAY: 1,
  LAST_7_DAYS: 2,
  LAST_30_DAYS: 3,
  THIS_MONTH: 4,
  LAST_MONTH: 5,
  CUSTOM_RANGE: 6,
};

const DATE_OBJECT = {
  TODAY: new Date(),
  YESTERDAY: moment().subtract(1, 'day').toDate(),
  LAST_7_DAYS: moment().subtract(6, 'day').toDate(),
  LAST_30_DAYS: moment().subtract(29, 'day').toDate(),
  THIS_MONTH_START: moment().startOf('month').toDate(),
  THIS_MONTH_END: moment().endOf('month').toDate(),
  LAST_MONTH_START: moment().subtract(1, 'month').startOf('month').toDate(),
  LAST_MONTH_END: moment().subtract(1, 'month').endOf('month').toDate(),
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: 10,
      display: 'flex',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      width: 220,
      marginRight: 10,
      '& .MuiListItem-root.Mui-selected': {
        background: APP_COLOR.appLightBlue,
        color: 'white',
      },
    },
    datePickerWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    calendar: {
      display: 'flex',
      '& .react-datepicker__day--disabled.react-datepicker__day--keyboard-selected': {
        background: 'unset',
        color: '#ccc',
        borderRadius: 'unset',
      },
      '& .react-datepicker__day--keyboard-selected': {
        background: 'unset',
        borderRadius: 'unset',
        fontWeight: 'unset',
        color: 'unset',
      },
      '& .react-datepicker__day--today': {
        fontWeight: 'unset',
      },
      '& .react-datepicker__day--highlighted-custom-selected': {
        background: '#2a87d0',
        color: '#fff',
        borderRadius: '0.3rem',
        fontWeight: 'bold',
      },
      '& .react-datepicker__day--highlighted-custom-unselected': {
        background: 'lightgray',
        color: '#fff',
        borderRadius: '0.3rem',
        fontWeight: 'unset',
      },
    },
    btn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    separator: {
      width: 10,
      height: '100%',
    },
  }),
);

interface DateRangeFilterProps {
  dateFilter?: string;
  setDateFilter?: (value: React.SetStateAction<string>) => void;
  setAnchorEl?: (value: React.SetStateAction<HTMLButtonElement>) => void;
  disableCancel?: boolean;
  maxDate?: Date;
  setDateRangeTitle?: (value: React.SetStateAction<string>) => void;
  isOnCustomerReport?: boolean
  getCustomerReportData?: (start: any, end: any) => void;

};

const DateRangeFilter = (props: DateRangeFilterProps) => {
  const classes = useStyles();
  const { dateFilter, setDateFilter, setAnchorEl, 
  disableCancel, maxDate, setDateRangeTitle, isOnCustomerReport, getCustomerReportData} = props;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedRange, setSelectedRange] = useState(null);

  useEffect(() => {
    if (!dateFilter) {
      return;
    }

    let _startDate = '';
    let _endDate = '';

    if (dateFilter.includes('-')) {
      const arrDates = dateFilter.split(' - ');

      _startDate = arrDates[0].replaceAll('/', '-');
      _endDate = arrDates[1].replaceAll('/', '-');
    } else {
      _startDate = _endDate = dateFilter.replaceAll('/', '-');
    }

    setStartDate(new Date(_startDate));
    setEndDate(new Date(_endDate));

    setSelectedRange(RANGE_KEY.CUSTOM_RANGE);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyBtnOnClick = () => {
    if(isOnCustomerReport)
    {
      getCustomerReportData(endDate, startDate);
    } else {
      if (formatDateFilter(startDate) === formatDateFilter(endDate)) {
        setDateFilter(formatDateFilter(startDate))
      } else {
        setDateFilter(formatDateFilter(startDate) + ' - ' + formatDateFilter(endDate))
      }
    }

    setAnchorEl(null);
  };

  const cancelBtnOnClick = () => {
    setSelectedRange(null);
    setDateFilter('');
    setAnchorEl(null);
  };

  const onRangeChange = (key) => {
    setSelectedRange(key);

    if (
      (key === RANGE_KEY.CUSTOM_RANGE && selectedRange !== RANGE_KEY.CUSTOM_RANGE) ||
      (key !== RANGE_KEY.CUSTOM_RANGE && selectedRange === RANGE_KEY.CUSTOM_RANGE)
    ) {
      window.dispatchEvent(new CustomEvent('resize')); // hack to readjust popover anchor position
    }

    switch (key) {
      case RANGE_KEY.TODAY:
        setStartDate(DATE_OBJECT.TODAY);
        setEndDate(DATE_OBJECT.TODAY);

        break;
      case RANGE_KEY.YESTERDAY:
        setStartDate(DATE_OBJECT.YESTERDAY);
        setEndDate(DATE_OBJECT.YESTERDAY);

        break;
      case RANGE_KEY.LAST_7_DAYS:
        setStartDate(DATE_OBJECT.LAST_7_DAYS);
        setEndDate(DATE_OBJECT.TODAY);

        break;
      case RANGE_KEY.LAST_30_DAYS:
        setStartDate(DATE_OBJECT.LAST_30_DAYS);
        setEndDate(DATE_OBJECT.TODAY);

        break;
      case RANGE_KEY.THIS_MONTH:
        setStartDate(DATE_OBJECT.THIS_MONTH_START);
        setEndDate(DATE_OBJECT.THIS_MONTH_END);

        break;
      case RANGE_KEY.LAST_MONTH:
        setStartDate(DATE_OBJECT.LAST_MONTH_START);
        setEndDate(DATE_OBJECT.LAST_MONTH_END);

        break;
      case RANGE_KEY.CUSTOM_RANGE:
        break;
      default:
        break;
    }
  };

  const getHighlightedDates = () => {
    const unselectedDates = [];
    const diff = moment(endDate).diff(moment(startDate), 'days');

    for (let i = 0; i < diff - 1; i += 1) {
      const nextDate = moment(i === 0 ? startDate : unselectedDates[i - 1]).add(1, 'day').toDate();

      unselectedDates.push(nextDate);
    }

    return [
      { 'react-datepicker__day--highlighted-custom-selected': [startDate, endDate] },
      { 'react-datepicker__day--highlighted-custom-unselected': unselectedDates },
    ];
  };

  const updateDateRangeTitle = (title: string) => {
    if (typeof setDateRangeTitle === "function") { 
      setDateRangeTitle(title);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <List dense>
          <ListItem button selected={selectedRange === RANGE_KEY.TODAY} 
              onClick={() => { 
                onRangeChange(RANGE_KEY.TODAY);
                updateDateRangeTitle("Today");
              }}>
            <ListItemText primary="Today" />
          </ListItem>
          <ListItem button selected={selectedRange === RANGE_KEY.YESTERDAY} 
              onClick={() => { 
                onRangeChange(RANGE_KEY.YESTERDAY); 
                updateDateRangeTitle("Yesterday");
               }}>
            <ListItemText primary="Yesterday" />
          </ListItem>
          <Divider />
          <ListItem button selected={selectedRange === RANGE_KEY.LAST_7_DAYS} 
            onClick={() => { 
              onRangeChange(RANGE_KEY.LAST_7_DAYS); 
              updateDateRangeTitle("Last 7 Days");
             }}>
            <ListItemText primary="Last 7 Days" />
          </ListItem>
          <ListItem button selected={selectedRange === RANGE_KEY.LAST_30_DAYS} 
            onClick={() => { 
              onRangeChange(RANGE_KEY.LAST_30_DAYS);
              updateDateRangeTitle("Last 30 Days");
              }}>
            <ListItemText primary="Last 30 Days" />
          </ListItem>
          <Divider />
          <ListItem button selected={selectedRange === RANGE_KEY.THIS_MONTH} onClick={() => { 
              onRangeChange(RANGE_KEY.THIS_MONTH);
              updateDateRangeTitle("This Month");
              }}>
            <ListItemText primary="This Month" />
          </ListItem>
          <ListItem button selected={selectedRange === RANGE_KEY.LAST_MONTH} onClick={() => { 
              onRangeChange(RANGE_KEY.LAST_MONTH); 
              updateDateRangeTitle("Last Month"); 
              }}>
            <ListItemText primary="Last Month" />
          </ListItem>
          <Divider />
          <ListItem button selected={selectedRange === RANGE_KEY.CUSTOM_RANGE} onClick={() => { 
              onRangeChange(RANGE_KEY.CUSTOM_RANGE); 
              updateDateRangeTitle("Custom Range");
              }}>
            <ListItemText primary="Custom Range" />
          </ListItem>
        </List>
        <div className={classes.btn}>
          {
            (!disableCancel && selectedRange !== null) && (
              <Button
                style={{ marginRight: 10 }}
                onClick={cancelBtnOnClick}
                size="small"
                variant="outlined"
                color="secondary"
              >
                Remove Filter
              </Button>
            )
          }
          <Button
            onClick={applyBtnOnClick}
            size="small"
            variant="contained"
            color="primary"
            disabled={selectedRange === null}
          >
            Apply
          </Button>
        </div>
      </div>
      {
        selectedRange === RANGE_KEY.CUSTOM_RANGE && (
          <div className={classes.datePickerWrapper}>
            <div className={classes.calendar}>
              <DatePicker
                selected={startDate}
                selectsStart
                onChange={(e) => {
                  const newDate = e as Date;
                  setStartDate(newDate);

                  const diff = moment(endDate).diff(moment(newDate), 'days');

                  if (diff >= 0) {
                    return;
                  }

                  setEndDate(newDate);
                }}
                inline
                highlightDates={getHighlightedDates()}
                startDate={startDate}
                endDate={endDate}
                maxDate={maxDate}
              />
              <div className={classes.separator}></div>
              <DatePicker
                selected={endDate}
                selectsEnd
                onChange={(e) => { setEndDate(e as Date); }}
                inline
                minDate={startDate}
                highlightDates={getHighlightedDates()}
                startDate={startDate}
                endDate={endDate}
                maxDate={maxDate}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

interface TableDateRangeFilterProps {
  label?: string;
  dateFilter?: string;
  setDateFilter?: (value: React.SetStateAction<string>) => void;
  disableCancel?: boolean;
  maxDate?: Date;
  setDateRangeTitle?: (value: React.SetStateAction<string>) => void;
  isOnCustomerReport?: boolean;
  getCustomerReportData?: (start: any, end: any) => void;
};

const TableDateRangeFilter = (props: TableDateRangeFilterProps) => {
  const { label, dateFilter, setDateFilter } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <DropdownButton
      btnName=""
      btnNameCustomEl={(
        <div style={{ color: '#0B57A8', fontWeight: 'bold' }}>
          {dateFilter ? dateFilter : label}
        </div>
      )}
      color="primary"
      variant={dateFilter ? 'outlined' : 'text'}
      anchorEl={anchorEl}
      size="medium"
      setAnchorEl={(e) => { setAnchorEl(e); }}
    >
      <DateRangeFilter
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        setAnchorEl={(e) => { setAnchorEl(e); }}
        disableCancel={props.disableCancel}
        maxDate={props.maxDate}
        setDateRangeTitle={props.setDateRangeTitle}
        isOnCustomerReport={props.isOnCustomerReport}
        getCustomerReportData={props.getCustomerReportData}
      />
    </DropdownButton>
  );
};

export default TableDateRangeFilter;
