 
import { Boxes } from "../../../@type/Boxes";

type LOAD_BOXES_DATA = "LOAD_BOXES_DATA";
type LOAD_VIEW_BOXES_DATA = "LOAD_VIEW_BOXES_DATA";
type SET_SELECTED_ROWS_BOX = "SET_SELECTED_ROWS_BOX";
type SET_BOXE_DETAILS_BOXE = "SET_BOXES_DETAILS_BOXE";
type SET_BOXES_TABLE_LOADING = "SET_BOXES_TABLE_LOADING"; 
type SET_BOXES_PAGE = "SET_BOXES_PAGE";
type SET_BOX_PAGE_INDEX = "SET_BOX_PAGE_INDEX";

type actionType = {
    LOAD_BOXES_DATA: LOAD_BOXES_DATA;
    LOAD_VIEW_BOXES_DATA: LOAD_VIEW_BOXES_DATA;
    SET_SELECTED_ROWS_BOX: SET_SELECTED_ROWS_BOX;
    SET_BOXE_DETAILS_BOXE: SET_BOXE_DETAILS_BOXE;
    SET_BOXES_TABLE_LOADING: SET_BOXES_TABLE_LOADING; 
    SET_BOXES_PAGE: SET_BOXES_PAGE;
    SET_BOX_PAGE_INDEX: SET_BOX_PAGE_INDEX;
};

export const BoxesActions: actionType = {
    LOAD_BOXES_DATA: "LOAD_BOXES_DATA",
    LOAD_VIEW_BOXES_DATA: "LOAD_VIEW_BOXES_DATA",
    SET_SELECTED_ROWS_BOX: "SET_SELECTED_ROWS_BOX",
    SET_BOXE_DETAILS_BOXE: "SET_BOXES_DETAILS_BOXE",
    SET_BOXES_TABLE_LOADING: "SET_BOXES_TABLE_LOADING", 
    SET_BOXES_PAGE: 'SET_BOXES_PAGE',
    SET_BOX_PAGE_INDEX: 'SET_BOX_PAGE_INDEX',
};

export type BoxesAction =
    | { type: LOAD_BOXES_DATA, payload: Boxes[] }
    | { type: LOAD_VIEW_BOXES_DATA, payload: Boxes[] }
    | { type: SET_SELECTED_ROWS_BOX, payload: Boxes[] }
    | { type: SET_BOXE_DETAILS_BOXE, payload: Boxes }
    | { type: SET_BOXES_TABLE_LOADING, payload: "Loading" | "Failed" | "Success" | "NoBox" }
    | { type: SET_BOXES_PAGE, payload: "awaitingShipment" | "shipped" | "canceled"|"onHold"|"awaitingPayment" }
    | { type: SET_BOX_PAGE_INDEX, payload: number }
