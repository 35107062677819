import { useEffect } from 'react';

export const useLoseFocus = (refContainer, onLoseFocus = null) => {
  useEffect(() => {
    const handleLoseFocus = (event) => {
      if (refContainer?.current && !refContainer.current.contains(event.target) && onLoseFocus) {
        onLoseFocus();
      }
    };

    document.addEventListener('mousedown', handleLoseFocus);

    return () => {
      document.removeEventListener('mousedown', handleLoseFocus);
    };
  }, [refContainer, onLoseFocus]);
};
