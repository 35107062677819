import moment from "moment";

const formatTwoDigits = (digit: any) => ('0' + digit).slice(-2);

export const formatDateFilter = (date: Date) => `${date.getFullYear()}/${formatTwoDigits(date.getMonth() + 1)}/${formatTwoDigits(date.getDate())}`;

// date filter format for open shipments (in end of day)
export const DATE_FILTER_FORMAT = 'YYYY/MM/DD';

// .Net Core Date Format
export const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * A function that takes a date filter in one format and converts it to two dates in another format
*/
export const convertAndExtractDates = (dates: string, oldFormat: string, newFormat: string) => {
    const now = moment().format(newFormat);
    if (dates) {
        const [start, end] = convertToMomentDates(dates, oldFormat);
        return [start.format(newFormat), end.format(newFormat)] as const;
    }
    return [now, now] as const;
}

/**
 * A function that takes a date filter in one format and converts it to two dates represented as moments
*/
export const convertToMomentDates = (dates: string, oldFormat: string) => {
    const now = moment();

    let startDate = now;
    let endDate = now;

    if (dates.includes('-')) {
        const arrDates = dates.split(' - ');

        startDate = moment(arrDates[0], oldFormat);
        endDate = moment(arrDates[1], oldFormat);
    } else {
        startDate = endDate = moment(dates, oldFormat);
    }

    return [startDate, endDate] as const;
}