export type Store = {
    id: number;
    storeName: string;
    marketplace: string;
    accountStatus: string;
    lastUpdatedOn: string;
    storeUpdateStatus: string;
}

export type editMarketplace = {
    id: number;
    accountStatus: string;
    svMarketPlaceId: string;
}

export const storeStatusOptions = {
    active: "Active",
    inactive: "InActive",
    deleted: "Deleted"
}

export const storeUpdateStatusOptions = {
    noEntry: "NoEntry",
    processing: "Processing",
    completed: "Completed",
    failed: "Failed"
}

export type StoreRefresh = {
    store: Store;
    updateStatus: "loading" | "success" | "failed"
}
