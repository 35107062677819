import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import { CircularProgress, Tooltip, Popover } from "@material-ui/core";

import RefreshStoresPopperContent from "./RefreshStoresPopperContent";
import { storeUpdateStatusOptions } from "../../../@type/StoreSetup/Store";
import { getAllStoresService } from "../../../apis/storeSetup/getAllStoresService";
import { getAllStoresResMapper } from "../../../mappers/storeSetup/getAllStoresResMapper";
import { updateOrdersService } from "../../../apis/RefreshStores/updateOrdersService";
import { useStoreSetupActions } from "../../../redux/actions/storeSetupActions";
import { RootState } from "../../../redux/reducers";

const objectMapper = require("object-mapper");

export default function RefreshStores() {
  const { setStoresRefresh } = useStoreSetupActions();

  const { storesRefresh } = useSelector((state: RootState) => state.storeSetup);

  // popovers
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // loading when fetching all stores
  const [isLoadingAll, setLoadingAll] = useState<boolean>(false);
  const [isZeroStore, setZeroStore] = useState<boolean>(false);
  const [isUpdatingStores, setIsUpdatingStores] = useState<boolean>(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    if (!isUpdatingStores) {
      getAllStoresService()
        .then((res) => {
          setLoadingAll(false);
          if (res.data.accounts.length !== 0) {
            setZeroStore(false);
            const storesData: any[] = objectMapper(
              res.data.accounts,
              getAllStoresResMapper
            );
            setStoresRefresh(
              storesData
                .filter(
                  (store) =>
                    store.accountStatus === "Active" &&
                    store.marketplace !== "Shipvista"
                )
                .reverse()
                .map((store) => {
                  return {
                    store: store,
                    updateStatus:
                      store.storeUpdateStatus ===
                      storeUpdateStatusOptions.failed
                        ? "failed"
                        : "success",
                  };
                })
            );
          } else {
            setZeroStore(true);
          }
        })
        .catch((error) => {
          setLoadingAll(false);
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshStore = (storeId: number) => {
    const setUpdateStatus = (val: "loading" | "success" | "failed") => {
      setStoresRefresh(
        storesRefresh.map((storeRefresh) =>
          storeRefresh.store.id === storeId
            ? { ...storeRefresh, updateStatus: val }
            : storeRefresh
        )
      );
    };
    const setUpdateStatusAll = (val: "loading" | "success" | "failed") => {
      setStoresRefresh(
        storesRefresh.map((storeRefresh) => {
          return { ...storeRefresh, updateStatus: val };
        })
      );
    };

    storeId !== 0 ? setUpdateStatus("loading") : setUpdateStatusAll("loading");
    updateOrdersService(storeId)
      .then((res) => {
        if (res.data.status) {
          // do something if success
        } else {
          setUpdateStatus("failed");
        }
      })
      .catch((error) => {
        storeId !== 0
          ? setUpdateStatus("failed")
          : setUpdateStatusAll("failed");
      });
  };

  useEffect(() => {
    // get all stores when the page first loaded
    setLoadingAll(true);
    getAllStoresService()
      .then((res) => {
        setLoadingAll(false);
        if (res.data.accounts.length !== 0) {
          setZeroStore(false);
          const storesData: any[] = objectMapper(
            res.data.accounts,
            getAllStoresResMapper
          );
          setStoresRefresh(
            storesData
              .filter((store) => store.accountStatus === "Active")
              .reverse()
              .map((store) => {
                return {
                  store: store,
                  updateStatus:
                    store.storeUpdateStatus === storeUpdateStatusOptions.failed
                      ? "failed"
                      : "success",
                };
              })
          );
        } else {
          setZeroStore(true);
        }
      })
      .catch((error) => {
        setLoadingAll(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if any of the store is loading, set the button to loading as well
  useEffect(() => {
    setIsUpdatingStores(
      storesRefresh.find((store) => store.updateStatus === "loading")
        ? true
        : false
    );
  }, [storesRefresh]);

  return (
    <>
      <Tooltip title="Sync All Orders" arrow>
        <div
          style={{
            color: "white",
            paddingTop: 13,
            paddingBottom: 13,
            padding: 0,
          }}
          onClick={handleClick}
        >
          {isUpdatingStores ? (
            <CircularProgress
              style={{ marginTop: 4, width: 18, height: 18, color: "white" }}
            />
          ) : (
            <RefreshIcon />
          )}
        </div>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <RefreshStoresPopperContent
          storesRefresh={storesRefresh}
          isLoadingAll={isLoadingAll}
          isZeroStore={isZeroStore}
          refreshStore={refreshStore}
        />
      </Popover>
    </>
  );
}
