import { BatchesHistory } from "../../../@type/BatchesHistory";

type LOAD_BATCHESHISTORY_DATA = "LOAD_BATCHESHISTORY_DATA";
type SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY = "SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY";
type SET_BATCHESHISTORY_TABLE_LOADING = "SET_BATCHESHISTORY_TABLE_LOADING";

type actionType = {
    LOAD_BATCHESHISTORY_DATA: LOAD_BATCHESHISTORY_DATA;
    SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY: SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY;
    SET_BATCHESHISTORY_TABLE_LOADING: SET_BATCHESHISTORY_TABLE_LOADING; 
};

export const BatchesHistoryActions: actionType = {
    LOAD_BATCHESHISTORY_DATA: "LOAD_BATCHESHISTORY_DATA",
    SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY: "SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY",
    SET_BATCHESHISTORY_TABLE_LOADING: "SET_BATCHESHISTORY_TABLE_LOADING" 
};

export type BatchesHistoryAction =
    | { type: LOAD_BATCHESHISTORY_DATA, payload: BatchesHistory[] }
    | { type: SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY, payload: BatchesHistory }
    | { type: SET_BATCHESHISTORY_TABLE_LOADING, payload: "Loading" | "Failed" | "Success" | "NoBatchesHistory"   } 
