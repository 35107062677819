import IconButton from "@material-ui/core/IconButton";
import "../../../styles/scss/refreshStores/commonStyles.scss";
import "../../../styles/scss/refreshStores/listItemStyles.scss";
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckIcon from '@material-ui/icons/Check';
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningIcon from '@material-ui/icons/Warning';
import { StoreRefresh } from "../../../@type/StoreSetup/Store";
import { MarketplaceName, newEggStores } from "../../../@type/StoreSetup/MarketplaceNames";
import { marketplaceImages } from "../../../images/imagesTS/marketPlaceImages";
import { storeUpdateStatusOptions } from "../../../@type/StoreSetup/Store";

interface Props {
    storeRefresh: StoreRefresh,
    refreshStore?: (storeId: number) => void
}

export default function RefreshStoresListItem(props: Props) {
    const { store, updateStatus } = props.storeRefresh;

    return (
        <div>
            <div className="listItemDiv">
                <div className="bar"></div>
                <div className="content">
                    <div className="contentHead">
                        {store.marketplace === newEggStores.newEggCA || store.marketplace === newEggStores.newEggUSA || store.marketplace === newEggStores.newEggBusiness
                            ? <img alt="" className="contentImg" src={marketplaceImages.find(image => image.name === MarketplaceName.newegg)?.iconUrl}></img>
                            : marketplaceImages.find(image => image.name === store.marketplace)
                                ? <img alt="" className="contentImg" src={marketplaceImages.find(image => image.name === store.marketplace)?.iconUrl}></img>
                                : <img alt="" className="contentImg" ></img>}
                        <div className="headText">{store.storeName}</div>
                    </div>
                    <div className="contentHead">
                        {updateStatus === "loading"
                            ? <GetAppIcon style={{ width: 15, height: 15, marginRight: 5, color: "#3f51b5" }} />
                            : updateStatus === "success"
                                ? <CheckIcon style={{ width: 15, height: 15, marginRight: 5, color: "#3f51b5" }} />
                                : <WarningIcon style={{ width: 15, height: 15, marginRight: 5, color: "red" }} />
                        }

                        <div className="timeText">
                            {updateStatus === "loading"
                                ? `Fetching Orders...`
                                : updateStatus === "success"
                                    ? store.storeUpdateStatus === storeUpdateStatusOptions.noEntry
                                        ? `Click to refresh the store`
                                        : `Last updated ${moment(store.lastUpdatedOn).format("MM/DD hh:mm A")}`
                                    : <div style={{ color: "red" }}>Fail to update</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="contentHead">
                    <IconButton disabled={updateStatus === "loading"} onClick={() => props.refreshStore(store.id)} style={{ padding: 5 }}>
                        {updateStatus === "loading"
                            ? <CircularProgress style={{ marginRight: 2, width: 18, height: 18, color: "slategrey" }} />
                            : <RefreshIcon />

                        }
                    </IconButton>
                </div>
            </div>
            <hr className="refreshStoresHr" />
        </div>

    );
}