/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import DatePicker from "react-datepicker";
import {
  ExpandMore,
  ChevronLeft,
  CloseOutlined,
  CheckOutlined,
  SearchOutlined,
} from "@material-ui/icons";
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";

import { getSearchResult } from "../../apis/getOrders";
import { useAlertActions } from "../../redux/actions";
import { MasterPageContext } from "./masterPageContext";
import { MENU_MAP } from "./common";
import { orderStatusOptions, SearchOrder } from "../../@type/Orders/Order";
import { formatDateFilter } from "../../functionUtilities/formatDateFilter";
import { getAllStoresService } from "../../apis/storeSetup/getAllStoresService";
import { getAllStoresResMapper } from "../../mappers/storeSetup/getAllStoresResMapper";

var objectMapper = require("object-mapper");

const Accordion = withStyles({
  root: {
    margin: "0px",
    boxShadow: "none",
    borderBottom: "1px solid gray",
    "&$expanded": {
      margin: "0px",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#444",
    color: "white",
    minHeight: 50,
    "&$expanded": {
      minHeight: 50,
      "&:hover": {
        backgroundColor: "#222",
      },
    },
    "&:hover": {
      backgroundColor: "#666",
    },
  },
  content: {
    "&$expanded": {
      margin: "0px",
    },
  },
  expanded: {
    backgroundColor: "#222",
  },
})(MuiAccordionSummary);

const useStyles = makeStyles({
  checkbox: {
    padding: 0,
    marginRight: 8,
  },

  searchDiv: {
    backgroundColor: "#444",
    borderBottom: "1px solid gray",
  },

  inputField: {
    backgroundColor: "white",
    width: "90%",
    display: "flex",
    margin: "12px auto",
    borderRadius: "8px",
  },
  totalHeader: {
    backgroundColor: "#3f51b5",
    padding: "10px",
    textAlign: "center",
    margin: 0,
  },
  totalText: {
    marginLeft: "8px",
    backgroundColor: "rgba(0,0,0,0.3)",
    padding: "0 5px 0px 5px",
    borderRadius: "4px",
  },

  checkBoxList: {
    backgroundColor: "white",
  },

  applyButton: {
    display: "inline-flex",
    margin: "10px",
  },

  loadingDiv: {
    marginTop: "10px",
    textAlign: "center",
  },
  buttonContent: {
    textAlign: "center",
    marginTop: "5px",
  },
  listItem: {
    fontWeight: 500,
  },
  advancedSearchContent: {
    padding: "10px",
    backgroundColor: "#444",
    borderBottom: "1px solid gray",
    maxWidth: "fit-content",
  },
  advancedSearchLabel: {
    color: "white",
    fontWeight: "bold",
  },
  advancedSearchInput: {
    backgroundColor: "white",
    width: "100%",
    height: "30px",
    borderRadius: "4px",
  },
  advancedSearchDiv: {
    marginBottom: "16px",
  },
  datePicker: {
    width: "100%",
  },
  selectField: {
    paddingLeft: "8px",
  },
  advancedSearchOutLine: {
    height: "100%",
    paddingLeft: "8px",
  },
});

// data = [{ key, label, route, children }]
const MasterPageSidebar = ({
  data = [],
  selectedKey = "",
  onChange = (key, route) => {},
  style = {},
  id = "",
}) => {
  const [expandedKeyMap, setExpandedKeyMap] = useState({});
  const [ready, setReady] = useState(false);
  const [text, setText] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const { openAlertBar } = useAlertActions();
  const [checkedItems, setCheckedItems] = useState({});
  const status = ["Unpaid", "Onhold", "Unshipped", "Shipped", "Canceled"];
  const [stores] = useState<string[]>([
    "Amazon.ca",
    "Newegg.com",
    "Amazon.com",
    "Shopify",
    "Ebay",
    "ETSY",
    "Manual",
  ]);
  const [isLoad, setIsLoad] = useState(false);
  const [timer, setTimer] = useState(null);
  const [total, setTotal] = useState(0);
  const [isOnOrderPage, setIsOnOrderPage] = useState(false);
  const { resolveSelectedMenuKey, resolveHeaderMenuKey } =
    useContext(MasterPageContext);
  const [searchOrderList] = useState<SearchOrder[]>([]);
  const [isOnAdvancedSearch, setIsOnAdvancedSearch] = useState(false);
  const [statusNames, setStatusNames] = useState<string[]>(status);
  const [selectedStores, setSelectedStores] = useState<string[]>(stores);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [destination, setDestination] = useState("Domestic");
  const [itemName, setItemName] = useState("");
  const [sku, setSku] = useState("");
  const [recipient, setRecipient] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (
      selectedKey === "awaiting-payment" ||
      selectedKey === "on-hold" ||
      selectedKey === "awaiting-shipment" ||
      selectedKey === "canceled" ||
      selectedKey === "search" ||
      selectedKey === "shipped"
    ) {
      setIsOnOrderPage(true);
    } else {
      setIsOnOrderPage(false);
    }
    for (let i = 0, ii = data.length; i < ii; i++) {
      const d = data[i];

      if (!d.children?.length) {
        continue;
      }

      for (let j = 0, jj = d.children.length; j < jj; j++) {
        const c = d.children[j];

        if (selectedKey !== c.key) {
          continue;
        }

        setExpandedKeyMap({ [d.key]: true });
        break;
      }
    }

    setReady(true);
  }, [selectedKey]);

  useEffect(() => {
    for (let i = 0; i < status.length; i++) {
      let newSearchOrder: SearchOrder = {
        name: status[i],
        checked: true,
        count: 0,
      };
      searchOrderList.push(newSearchOrder);
    }

    getAllStoresService()
      .then((res) => {
        if (res.data.accounts.length !== 0) {
          const storesData: any[] = objectMapper(
            res.data.accounts,
            getAllStoresResMapper
          );
          storesData.forEach((e) => {
            if (!stores.includes(e.marketplace)) {
              stores.push(e.marketplace);
            }
          });
          setSelectedStores(stores);
        }
      })
      .catch((error) => {});
  }, []);

  const onMenuClick = (key, route) => {
    onChange(key, route);
  };

  const renderMenu = () => {
    let items = [];
    for (let i = 0, ii = data.length; i < ii; i++) {
      const d = data[i];
      const children = [];

      if (d.children?.length) {
        for (let j = 0, jj = d.children.length; j < jj; j++) {
          const c = d.children[j];
          children.push(
            <div
              key={`sidebar-menu-item-${i}-${j}`}
              className={`master-page-sidebar-menu-item child${
                selectedKey === c.key ? " selected" : ""
              }`}
              onClick={() => onMenuClick(c.key, c.route)}
            >
              {c.label}
            </div>
          );
        }
      }

      if (children.length) {
        items.push(
          <Accordion
            key={`${id}-sidebar-menu-item-${i}`}
            expanded={expandedKeyMap[d.key] ? true : false}
            onChange={(e, value) => {
              setExpandedKeyMap({ [d.key]: value });
            }}
          >
            <AccordionSummary
              expandIcon={
                expandedKeyMap[d.key] ? (
                  <ExpandMore style={{ color: "white" }} />
                ) : (
                  <ChevronLeft style={{ color: "white" }} />
                )
              }
            >
              {d.label}
            </AccordionSummary>
            {children}
          </Accordion>
        );
        continue;
      }

      items.push(
        <div
          key={`sidebar-menu-item-${i}`}
          className={`master-page-sidebar-menu-item${
            selectedKey === d.key ? " selected" : ""
          }`}
          onClick={() => onMenuClick(d.key, d.route)}
        >
          <div>{d.label}</div>
        </div>
      );
    }
    return items;
  };

  const handleSearchTextOnChange = (value) => {
    setIsSearched(false);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        handleSearch(value);
      }, 700)
    );
  };

  const handleSearch = async (para) => {
    try {
      if (para) {
        setIsLoad(true);
        const res = await getSearchResult(para);
        if (res.data.status) {
          setTotal(res.data.data.total);
          searchOrderList.forEach((e) => {
            switch (e.name) {
              case "Shipped":
                e.count = res.data.data.shipped;
                e.checked = res.data.data.shipped > 0 ?? false;
                break;
              case "Canceled":
                e.count = res.data.data.canceled;
                e.checked = res.data.data.canceled > 0 ?? false;
                break;
              case "Unshipped":
                e.count = res.data.data.unShipped;
                e.checked = res.data.data.unShipped > 0 ?? false;
                break;
              case "Onhold":
                e.count = res.data.data.onHold;
                e.checked = res.data.data.onHold > 0 ?? false;
                break;
              case "Unpaid":
                e.count = res.data.data.unPaid;
                e.checked = res.data.data.unPaid > 0 ?? false;
                break;
            }
          });
          setIsLoad(false);
          setIsSearched(true);
        }
      }
    } catch (error) {
      openAlertBar("No data found!", false);
      setIsLoad(false);
    }
  };

  const textBoxOnChange = (e) => {
    searchOrderList.forEach((element) => {
      if (element.name === e.target.value) {
        setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked });
        if (element.checked) {
          element.checked = false;
        } else {
          element.checked = true;
        }
      }
    });
  };

  const handleCheckBoxChange = (event, field: string) => {
    const {
      target: { value },
    } = event;
    switch (field) {
      case "status":
        setStatusNames(
          // On autofill we get a stringified value.
          typeof value === "string" ? value.split(",") : value
        );
        break;
      case "stores":
        setSelectedStores(
          // On autofill we get a stringified value.
          typeof value === "string" ? value.split(",") : value
        );
    }
  };

  const CheckboxWrapper = ({ orderFilter }) => {
    return (
      <div>
        <ListItem
          className={classes.listItem}
          style={
            orderFilter.count < 1
              ? { opacity: 0.5, color: "black" }
              : { color: "black" }
          }
        >
          <Checkbox
            className={classes.checkbox}
            size="small"
            checked={orderFilter.checked}
            value={orderFilter.name}
            onChange={(e) => textBoxOnChange(e)}
            name={orderFilter.name}
            disabled={orderFilter.count < 1 ?? false}
            color="primary"
          />
          <span style={{ width: "65%" }}>{orderFilter.name}</span>{" "}
          <span>
            {orderFilter.count > 0 ? "(" + orderFilter.count + ")" : ""}
          </span>
        </ListItem>
      </div>
    );
  };

  const handleApply = () => {
    let param = "";
    let counter = 0;
    searchOrderList.forEach((e) => {
      if (e.checked) {
        counter++;
      }
    });

    for (let i = 0; i < searchOrderList.length; i++) {
      let value;
      if (searchOrderList[i].checked) {
        if (counter > 1) {
          value = "&Status=";
        } else {
          value = "";
        }
        param += searchOrderList[i].name + value;
        counter--;
      }
    }
    orderStatusOptions.search = param + "&SearchText=" + text;
    resolveSelectedMenuKey(MENU_MAP.ORDERS_SEARCH.key);
    resolveHeaderMenuKey(MENU_MAP.ORDERS_SEARCH.route);
    onMenuClick("search", MENU_MAP.ORDERS_SEARCH.route);
  };

  const handleClear = () => {
    setSelectedStores(stores);
    setStatusNames(status);
    setStartDate(null);
    setEndDate(null);
    setSku("");
    setRecipient("");
    setItemName("");
    setMinPrice("");
    setMaxPrice("");
    setText("");
    resolveSelectedMenuKey(MENU_MAP.ORDERS_AWAITING_SHIPMENT.key);
    resolveHeaderMenuKey(MENU_MAP.ORDERS_SEARCH.route);
    onMenuClick("awaiting-shipment", MENU_MAP.ORDERS_AWAITING_SHIPMENT.route);
  };

  const handleAdvancedSearchSection = () => {
    setIsOnAdvancedSearch(true);
    setText("");
  };

  const isNumeric = (value) => {
    return /^(0|[0-9][0-9]*)$/.test(value);
  };

  const handleAdvancedSearch = () => {
    let param = "";
    if (statusNames.length > 0) {
      let counter = statusNames.length;
      for (let i = 0; i < statusNames.length; i++) {
        let value;
        if (counter > 1) {
          value = "&Status=";
        } else {
          value = "";
        }
        param += statusNames[i] + value;
        counter--;
      }
    }

    if (selectedStores.length > 0) {
      for (let i = 0; i < selectedStores.length; i++) {
        param += "&Store=" + selectedStores[i];
      }
    }

    if (endDate && startDate) {
      let start = "";
      let end = "";
      if (formatDateFilter(startDate) === formatDateFilter(endDate)) {
        start = end = formatDateFilter(startDate).replaceAll("/", "-");
      } else {
        start = formatDateFilter(startDate).replaceAll("/", "-");
        end = formatDateFilter(endDate).replaceAll("/", "-");
      }

      param += "&MinDate=" + start + "&MaxDate=" + end;
    }

    if (sku !== "") {
      param += "&Sku=" + sku;
    }

    if (itemName !== "") {
      param += "&ItemName=" + itemName;
    }

    if (recipient !== "") {
      param += "&Recipient=" + recipient;
    }

    if (minPrice && maxPrice !== "") {
      param += "&MinPrice=" + minPrice + "&MaxPrice=" + maxPrice;
    }

    orderStatusOptions.search = param + "&Destination=" + destination;
    resolveSelectedMenuKey(MENU_MAP.ORDERS_SEARCH.key);
    onMenuClick("search", MENU_MAP.ORDERS_SEARCH.route);
  };

  return (
    <div className="master-page-sidebar" style={style}>
      <div className="master-page-sidebar-menu">
        <div className={classes.searchDiv}>
          {isOnOrderPage && !isOnAdvancedSearch && (
            <div>
              <OutlinedInput
                className={classes.inputField}
                id="outlined-basic"
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                  handleSearchTextOnChange(e.target.value);
                }}
                placeholder="Search for #, name, sku..."
                inputProps={{
                  style: {
                    padding: "10px",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClear}
                      edge="end"
                    >
                      {text.length > 0 ? <CloseOutlined /> : <SearchOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div style={{ width: "100%", textAlign: "right" }}>
                <Button
                  size="small"
                  variant="text"
                  style={{
                    color: "white",
                    textTransform: "inherit",
                    padding: "0 5px 5px 0",
                  }}
                  onClick={handleAdvancedSearchSection}
                >
                  Advanced Search...
                </Button>
              </div>
            </div>
          )}
          {isLoad && (
            <div className={classes.loadingDiv}>
              <span>
                <CircularProgress style={{ color: "white" }} size={18} />
              </span>
            </div>
          )}
          {isSearched && text.length > 0 && (
            <div>
              <h6 className={classes.totalHeader}>
                Quick Search Result(s):
                <span className={classes.totalText}>{total}</span>
              </h6>
              <List className={classes.checkBoxList}>
                {" "}
                {searchOrderList.map((item, i) => {
                  return <CheckboxWrapper orderFilter={item} />;
                })}
                <div className={classes.buttonContent}>
                  <Button
                    startIcon={<CloseOutlined />}
                    variant="contained"
                    size="small"
                    className={classes.applyButton}
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  <Button
                    startIcon={<CheckOutlined />}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={total < 1}
                    className={classes.applyButton}
                    onClick={handleApply}
                  >
                    Apply
                  </Button>
                </div>
              </List>
            </div>
          )}
        </div>
        {isOnAdvancedSearch && (
          <div className={classes.advancedSearchContent}>
            <div className={classes.advancedSearchDiv}>
              <InputLabel
                className={classes.advancedSearchLabel}
                id="multiple-checkbox-label"
              >
                Status
              </InputLabel>
              <Select
                label="status"
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                style={{ paddingLeft: "8px" }}
                multiple
                value={statusNames}
                renderValue={(selected: string[]) =>
                  selected.length < 3
                    ? selected.join(", ")
                    : selected.length + " of 5 selected"
                }
                fullWidth
                onChange={(e) => handleCheckBoxChange(e, "status")}
                className={classes.advancedSearchInput}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {status.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      color="primary"
                      name={name}
                      value={name}
                      checked={statusNames.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.advancedSearchDiv}>
              <InputLabel
                className={classes.advancedSearchLabel}
                id="multiple-checkbox-label"
              >
                Marketplace
              </InputLabel>
              <Select
                label="Marketplace"
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                style={{ paddingLeft: "8px" }}
                multiple
                value={selectedStores}
                placeholder="Any"
                renderValue={(selected: string[]) =>
                  selected.length < 3
                    ? selected.join(", ")
                    : selected.length + " of " + stores.length + " selected"
                }
                fullWidth
                onChange={(e) => handleCheckBoxChange(e, "stores")}
                className={classes.advancedSearchInput}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {stores.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      color="primary"
                      name={name}
                      value={name}
                      checked={selectedStores.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.advancedSearchDiv}>
              <InputLabel className={classes.advancedSearchLabel}>
                Destination
              </InputLabel>
              <Select
                label="Destination"
                id="select destination"
                style={{ paddingLeft: "8px" }}
                value={destination}
                fullWidth
                onChange={(e) => setDestination(e.target.value.toString())}
                className={classes.advancedSearchInput}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="Domestic">
                  <ListItemText primary="Domestic" />
                </MenuItem>
                <MenuItem value="International">
                  <ListItemText primary="International" />
                </MenuItem>
              </Select>
            </div>
            <div className={classes.advancedSearchDiv}>
              <InputLabel className={classes.advancedSearchLabel}>
                Item's name
              </InputLabel>
              <TextField
                id="item name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                variant="outlined"
                fullWidth
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "6px",
                  },
                }}
              />
            </div>
            <div className={classes.advancedSearchDiv}>
              <InputLabel className={classes.advancedSearchLabel}>
                Recipient's Name
              </InputLabel>
              <TextField
                id="recipient name"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                variant="outlined"
                fullWidth
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "6px",
                  },
                }}
              />
            </div>
            <div className={classes.advancedSearchDiv}>
              <InputLabel className={classes.advancedSearchLabel}>
                SKU
              </InputLabel>
              <TextField
                id="SKU"
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                variant="outlined"
                fullWidth
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "6px",
                  },
                }}
              />
            </div>
            <div className={classes.advancedSearchDiv}>
              <InputLabel className={classes.advancedSearchLabel}>
                Price
              </InputLabel>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="Min Price"
                    value={minPrice}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setMinPrice(e.target.value);
                        return;
                      }
                      if (!isNumeric(e.target.value)) {
                        return;
                      }
                      setMinPrice(e.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Min Price"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "6px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="Max Price"
                    value={maxPrice}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setMaxPrice(e.target.value);
                        return;
                      }
                      if (!isNumeric(e.target.value)) {
                        return;
                      }
                      setMaxPrice(e.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Max Price"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "6px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.advancedSearchDiv}>
              <InputLabel className={classes.advancedSearchLabel}>
                Date
              </InputLabel>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    className={classes.datePicker}
                    selected={startDate}
                    maxDate={new Date()}
                    onChange={(d) => {
                      const newDate = d as Date;
                      setStartDate(newDate);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    className={classes.datePicker}
                    selected={endDate}
                    maxDate={new Date()}
                    onChange={(d) => {
                      const newDate = d as Date;
                      setEndDate(newDate);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.buttonContent}>
              <Button
                variant="contained"
                size="small"
                className={classes.applyButton}
                onClick={() => {
                  setIsOnAdvancedSearch(false);
                  handleClear();
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.applyButton}
                onClick={handleAdvancedSearch}
              >
                Search
              </Button>
            </div>
          </div>
        )}
        {ready && renderMenu()}
      </div>
      {/* {ready && (id === "orders" || id === "shipments") && (
        <BannerSection isVerticalLayout lockResponsive />
      )} */}
    </div>
  );
};

export default MasterPageSidebar;
