import { GET_ALL_SUBSCRIPTION_PLANS, LOAD_USER_SUBSCRIPTION, SET_DEFAULT_USER_SUBSCRIPTION } from "../@types/user/userSubscriptionAction"

export const loadUserSubscription = (subscription) => ({
    type: LOAD_USER_SUBSCRIPTION,
    payload: subscription
})

export const setDefaultUserSubscription = (data) => ({
    type: SET_DEFAULT_USER_SUBSCRIPTION,
    payload: { data }
})

export const getAllSubscriptionPlans = (allSubscriptionPlans) => ({
    type: GET_ALL_SUBSCRIPTION_PLANS,
    payload: allSubscriptionPlans
})