import moment, { Moment } from 'moment'

//general use get days between date sent and current date
export const getDays = (_date: string) => {
    // for now only accepts date format that begins with or is YYYY-MM-DD
    let modifiedDate: Moment;
    let currentDate = moment().format('YYYY-MM-DD')

    if (_date.includes('T')) {
        modifiedDate = moment(_date.substring(0, _date.indexOf('T')), 'YYYY-MM-DD');
        return modifiedDate.diff(currentDate, 'days')
    } else {
        modifiedDate = moment(_date, 'YYYY-MM-DD');
        return modifiedDate.diff(currentDate, 'days')
    }
}

//specific use for getting transit days in shipment and orders
export const getTransitDay = (_rate: any) => {
    if (_rate.shippingService.isSaturdayDelivery) {
        return getDays(_rate.arrivalDate)
    }

    if (_rate.transitDay) {
        return parseInt(_rate.transitDay)
    } else if (!_rate.transitDay && _rate.arrivalDate && _rate.arrivalDate !== '0001-01-01T00:00:00') {
        return getDays(_rate.arrivalDate)
    } else {
        return 'Time Not Guarantee'
    }
}