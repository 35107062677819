
type SET_RATES = "SET_RATES";
type FILTER_RATES_BY_PRICE = "FILTER_RATES_BY_PRICE";
type FILTER_RATES_BY_TRANSIT_DAY = "FILTER_RATES_BY_TRANSIT_DAY";
type GET_TOP3_RATES = "GET_TOP3_RATES";
type EXPAND_RATE = "EXPAND_RATE";
type TOP3_JUMP_TO_RATE = "TOP3_JUMP_TO_RATE";
type SET_PAGE_INDEX = "SET_PAGE_INDEX";

type actionType = {
    SET_RATES: SET_RATES;
    FILTER_RATES_BY_PRICE: FILTER_RATES_BY_PRICE;
    FILTER_RATES_BY_TRANSIT_DAY: FILTER_RATES_BY_TRANSIT_DAY;
    GET_TOP3_RATES: GET_TOP3_RATES;
    EXPAND_RATE: EXPAND_RATE;
    TOP3_JUMP_TO_RATE: TOP3_JUMP_TO_RATE;
    SET_PAGE_INDEX: SET_PAGE_INDEX;
};

export const CarrierServiceActions: actionType = {
    SET_RATES: "SET_RATES",
    FILTER_RATES_BY_PRICE: "FILTER_RATES_BY_PRICE",
    FILTER_RATES_BY_TRANSIT_DAY: "FILTER_RATES_BY_TRANSIT_DAY",
    GET_TOP3_RATES: "GET_TOP3_RATES",
    EXPAND_RATE: "EXPAND_RATE",
    TOP3_JUMP_TO_RATE: "TOP3_JUMP_TO_RATE",
    SET_PAGE_INDEX: "SET_PAGE_INDEX",
};

export type CarrierServiceAction =
    | { type: SET_RATES, payload: any[] }
    | { type: FILTER_RATES_BY_PRICE }
    | { type: FILTER_RATES_BY_TRANSIT_DAY }
    | { type: GET_TOP3_RATES }
    | { type: EXPAND_RATE, payload: string | false }
    | { type: TOP3_JUMP_TO_RATE, payload: string }
    | { type: SET_PAGE_INDEX, payload: number };