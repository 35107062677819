import {GET_REFERRAL_CODE, SET_REFERRAL_CODE} from '../actions/referralCodeActions'

const initialState={
    value:''
}

export const referralCodeReducer=(state=initialState,action)=>{
    switch(action.type){
        case GET_REFERRAL_CODE:
            // const referralCode=action.payload   
            // return referralCode
            return state

        case  SET_REFERRAL_CODE:
            const setRef=action.payload   
            return setRef
        default:
            return state;
    }
}