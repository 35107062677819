// the library has bug when taking parameter to transform in array item
var objectMapper = require('object-mapper');

export const createManualOrderReqMapper = (data: any) => {
    let req = objectMapper(data, map);
    req.shippedItems = 0;
    req.unshippedItems = req.numberOfItems;
    req.fulfillmentInfo = {
        status: "UNSHIPPED"
    };
    return req;
}

const map = {
    "orderNo": {
        key: "marketPlaceOrderId",
        transform: function (value: string) {
            return value !== "" ? value : null;
        }
    },
    "address.name": [
        {
            key: "shippingAddress.name",
        },
        {
            key: "buyer.buyerName",
        },
    ],
    "address.companyName": "shippingAddress.companyName",
    "address.streetAddress": "shippingAddress.streetAddress",
    "address.streetAddress2": "shippingAddress.streetAddress2",
    "address.city": "shippingAddress.city",
    "address.country": "shippingAddress.country",
    "address.countryCode": "shippingAddress.countryCode",
    "address.state": "shippingAddress.state",
    "address.stateCode": "shippingAddress.stateCode",
    "address.postalCode": "shippingAddress.postalCode",
    "address.email": [
        {
            key: "shippingAddress.email",
            transform: function (value: string) {
                return value !== "" ? value : null;
            }
        },
        {
            key: "buyer.email",
            transform: function (value: string) {
                return value !== "" ? value : "";
            }
        },
    ],
    "address.phone": [
        {
            key: "shippingAddress.phone",
        },
        {
            key: "buyer.phone",
        },
    ],
    "marketPlaceAccountId":"marketPlaceAccountId",
    "salesChannel":"salesChannel",
    "orderDate": "purchaseDate",
    "amountPaid": [
        {
            key: "amount.currency",
            transform: function (value: string) {
                return "CAD";
            }
        },
        {
            key: "amount.amount",
            transform: function (value: string) {
                return value != null ? parseFloat(value) : 0;
            }
        },
    ],
    "orderItems": [
        {
            key: "numberOfItems",
            transform: function (value: {
                id: string;
                SKU: string;
                name: string;
                qty: number;
                price: string;
            }[]) {
                let numberOfItems = 0;
                value.forEach(item => {
                    numberOfItems += item.qty;
                });
                return numberOfItems;
            }
        },
    ],
    "orderItems[].id": {
        key: "orderItems[].id",
        transform: function (value: any) {
            if(!isNaN(value) && value > 0)
            {
                return value;
            } else return 0;
        }
    },
    "orderItems[].SKU": "orderItems[].sellerSKU",
    "orderItems[].name": "orderItems[].title",
    "orderItems[].qty": "orderItems[].quantity",
    "orderItems[].price": {
        key: "orderItems[].price",
        transform: function (value: string) {
            return parseFloat(value);
        }
    },
}