import { useState, useContext, useEffect } from 'react'
import {
    Button, CircularProgress,
    createStyles, Grid, Link,
    makeStyles, TextField
} from '@material-ui/core'

import Br from '../../../../utilities/Br'
import { useAlertActions } from '../../../../../redux/actions';
import { redeemPromoCode } from '../../../../../apis/promoCodesServices';
import { formatToFixedDecimal } from '../../../../../utils/commonFunctions';
import SubscriptionContext from './SubscriptionContext';

const useStyles = makeStyles(() =>
    createStyles({
        green: {
            color: '#03827A',
        }
    })
);

const ApplyPromoCode = ({
    discountApplied,
    setDiscountApplied,
    setTotalAmount,
    totalAmount,
    setDiscountedAmount,
    promoCode,
    setPromoCode,
    setPlanType
}) => {
    const classes = useStyles();
    const { filledPromoCode } = useContext(SubscriptionContext);

    const [showPromo, setShowPromo] = useState(false);
    const [loading, setLoading] = useState(false);
    const { openAlertBar } = useAlertActions();

    const applyPromoCode = async () => {
        setLoading(true);
        try {
            const { data } = await redeemPromoCode({ promoCode: promoCode.trim() });

            if (data.status) {
                openAlertBar(data.message, data.status)

                if (data.data.promoType === 'Y') {
                    setPlanType('annually');
                    setTotalAmount(totalAmount * 12)
                }

                if (data.data.disTyp === 1) {
                    let discountedAmount = (data.data.rate / 100) * totalAmount;

                    // checking again because discount amount has to be updated
                    if (data.data.promoType === 'Y') {
                        discountedAmount *= 12
                    }

                    discountedAmount = formatToFixedDecimal(discountedAmount)

                    setDiscountedAmount(discountedAmount)
                    setTotalAmount(t => t - discountedAmount);
                } else {

                    setTotalAmount(t => t - data.data.amount);
                    setDiscountedAmount(data.data.amount);
                }

                setDiscountApplied(true);
            } else {
                openAlertBar(data.message, data.status)
            }
        } catch (error) {
            console.log(error);

            openAlertBar("Unexpected error occurred", false);
        }
        setLoading(false);
        setShowPromo(false);
    }

    useEffect(() => {
        const trigger = async () => {

            if (filledPromoCode && filledPromoCode?.code) {
                setShowPromo(true);
                await applyPromoCode();
                filledPromoCode?.onFinish && filledPromoCode?.onFinish();
            }
        }
        trigger();
    }, [filledPromoCode]);

    return (
        <>
            <Grid container>
                <Grid item sm={6}>
                    <Link onClick={() => setShowPromo(s => !s)}>
                        Have a Promo Code?
                    </Link>
                </Grid>
                <Grid item sm={6} style={{ textAlign: "right" }}>
                    {discountApplied && <p className={classes.green}>{promoCode} APPLIED</p>}
                </Grid>
            </Grid>
            {showPromo &&
                <>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item sm={7}>
                            <TextField
                                variant='outlined'
                                size="small"
                                fullWidth
                                placeholder='Paste your code here'
                                value={promoCode}
                                onChange={({ target }) => setPromoCode(target.value)}
                            />
                        </Grid>
                        <Grid item sm={5}>
                            <Button disabled={discountApplied || loading || !promoCode} color="primary" variant='contained' onClick={applyPromoCode}>
                                Apply {loading && <CircularProgress style={{ marginLeft: 10 }} size={20} color="primary" />}
                            </Button>
                            <Button className='ml-2' variant='contained' onClick={() => setShowPromo(s => !s)}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    <Br count={2} />
                </>
            }
        </>
    )
}

export default ApplyPromoCode