import { useState, useContext, useRef, useEffect } from 'react';
import moment from 'moment';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Divider, FormControlLabel,
  Grid, Radio, RadioGroup,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { PLAN_ICON, PLAN_LEVEL } from './common';
import SubscriptionContext from './SubscriptionContext';
import { MasterPageContext } from '../../../masterPageContext';
import SelectCardDropdown from './SelectCardDropdown';
import Br from '../../../../utilities/Br';
import FixedDialogWrapper from '../../../../utilities/FixedDialogWrapper';
import { gettingAllSubscriptionPlans } from '../../../../../redux/thunk/userSubscriptionThunk';
import { RootState } from '../../../../../redux/reducers';
import ApplyPromoCode from './ApplyPromoCode';
import { formatToFixedDecimal } from '../../../../../utils/commonFunctions';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '24px 30px',
    },
    icon: {
      height: 80,
      marginRight: 20,
    },
    bold: {
      fontWeight: 600,
    },
    italic: {
      fontStyle: 'italic',
    },
    red: {
      color: 'red',
    },
    green: {
      color: '#03827A',
    },
    blue: {
      color: '#0964C7',
    },
    underlined: {
      textDecoration: 'underline',
    },
    pointer: {
      cursor: 'pointer',
    },
    smallerText: {
      fontSize: '0.9rem',
    },
    button: {
      textTransform: 'capitalize',
      fontSize: '1rem',
    },
    primaryButton: {
      background: '#03827A',
      color: 'white',
      '&:hover': {
        opacity: 0.9,
        background: '#03827A',
        color: 'white',
      },
      '&:disabled': {
        opacity: 0.5,
        background: '#03827A',
        color: 'white',
      },
    },
  }),
);
// const testYearlyPrice = 200.99
// const DATE_FORMAT = 'MM/DD/YYYY';

const ConfirmPlanDialog = ({
  open,
  setOpen,
  targetPlanType = '',
  targetPrice = 0,
  targetSpecialPrice = 0,
  targetFreeTrial = true,
  targetYearly = false,
  yearlyPrice = 0,
  onSubmit = () => { },
  // billingInformationDetails
}) => {
  const classes = useStyles();

  const startLoadingAllSubscriptionPlans = useDispatch();

  const [defaultCard, setDefaultCard] = useState(null);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [isCardChanged, setIsCardChanged] = useState(false);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [planType, setPlanType] = useState('monthly');
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [promoCode, setPromoCode] = useState('')

  const mountedRef = useRef(false);

  const allSubscriptionPlans = useSelector((state: RootState) => state.allSubscriptionPlans)
  const userAccountNo = useSelector((state: RootState) => state.user.user?.account_no);

  const selectedPlan = allSubscriptionPlans?.data?.find((e) => e.title === targetPlanType);
  const [totalAmount, setTotalAmount] = useState(selectedPlan?.price.amount)

  const {
    // planType,
    // planPrice,
    // planIsTrial,
    // planYearly,

    // cardData,
    // currencyFormatter,

    // allPlans,
    doUpdateUserSubscription,
    fetchCreditCardData,
    userSubscription,
    filledPromoCode,
    // allSubScriptionPlans
  } = useContext(SubscriptionContext);

  const {
    sendCustomEvent,
    cardFunctions: {
      setAddCardDialogVisible,
      creditCardData
    }
  } = useContext(MasterPageContext);
  const [shouldSendAnalytics, setShouldSendAnalytics] = useState(false);
  useEffect(() => {
    mountedRef.current = true;
    fetchCreditCardData()

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filledPromoCode && filledPromoCode?.code) {
      setPromoCode(filledPromoCode?.code);
      setShouldSendAnalytics(!!filledPromoCode?.sendAnalytics);
    }
  }, [filledPromoCode]);

  useEffect(() => {

    mountedRef.current = true;
    startLoadingAllSubscriptionPlans(gettingAllSubscriptionPlans())
    if (!isCardChanged && userSubscription?.data?.billingDetails) {
      setDefaultCard(creditCardData.filter(card => card.id === userSubscription?.data?.billingDetails?.id)[0])
    } else {
      setDefaultCard(creditCardData[0])
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardData]);

  const isUpgrade = () => {
    return PLAN_LEVEL[targetPlanType] >= PLAN_LEVEL[userSubscription?.data?.subscriptionPlan.title];
  };

  const submitHandler = async () => {
    setSubmitIsLoading(true);
    const selectedPlan = allSubscriptionPlans?.data?.find((e) => e.title === targetPlanType);

    let data = {
      subscriptionplanid: selectedPlan.subscriptionplanid,
      billingDetails: {
        paymentMethodId: defaultCard.paymentMethodId
      },
      ClientCallBack: window.location.href
    }

    if (discountApplied) {
      data["promoCode"] = promoCode.trim();
      if (shouldSendAnalytics) {
        sendCustomEvent("bannerPromoCodeToChangePlan", {
          url: document.location.origin + document.location.pathname + document.location.search,
          params: {
            accountNo: userAccountNo,
            promoCode: promoCode.trim(),
            targetPlan: targetPlanType,
            totalAmount,
            planType,
          },
        });
      }
    }

    if (planType === 'annually') {
      data["isYearly"] = true;
    }

    const result = await doUpdateUserSubscription(data);

    setSubmitIsLoading(false);

    if (!result) {
      return;
    }

    sendCustomEvent("updatePlan", {
      url: document.location.origin + document.location.pathname + document.location.search,
      params: {
        isUpgrade: isUpgrade(),
        accountNo: userAccountNo,
        targetPlan: targetPlanType,
        totalAmount,
        planType,
      },
    });


    onSubmit();
  };

  // const fromPaid = () => {
  //   return userSubscription?.data?.subscriptionPlan?.price?.amount > 0;
  // };

  // const isPaid = () => {
  //   return targetPrice > 0;
  // };

  // const isUpdatePaidPlan = () => {
  //   return fromPaid() && isPaid() && !(userSubscription?.data?.isYearly && !targetYearly);
  // };

  // const isUpdatePaidPlanFromYearlyToMonthly = () => {
  //   return fromPaid() && isPaid() && userSubscription?.data?.isYearly && !targetYearly;
  // };
  // const isUpgradeProfessionalYearlyToEnterpriseMonthly = () => {
  //   return userSubscription?.data?.isYearly && (targetPlanType.toLowerCase() === PLAN.ENTERPRISE.toLowerCase() && !targetYearly)
  // }

  const createDialogTitle = () => {
    return isUpgrade() ?
      `Upgrade from ${userSubscription?.data?.subscriptionPlan.title} to ${targetPlanType} Plan` :
      `Downgrade from ${userSubscription?.data?.subscriptionPlan.title} to ${targetPlanType} Plan`
  }

  const handlePlanTypeChange = ({ target }) => {
    setPlanType(target.value)
    setDiscountApplied(false);

    if (target.value === 'annually') {

      setTotalAmount(targetPrice * 12);
    } else {
      setTotalAmount(targetPrice);
    }
  }

  const getFormattedDate = () => {
    let date = userSubscription?.data?.unsubscribedate ?? new Date();

    return planType === 'monthly' ?
      moment(date).add(1, "month").format("LL") :
      moment(date).add(1, 'year').format("LL")
  }

  return (
    <FixedDialogWrapper
      dialogId="confirm-plan-dialog"
      title={createDialogTitle()}
      maxWidth="sm"
      open={open}
      setOpen={setOpen}
      buttonDefinitions={[
        {
          label: 'Add New Card',
          color: 'secondary',
          onClick: () => setAddCardDialogVisible(true)
        },
        {
          label: 'Change Plan',
          color: 'primary',
          disabled: !defaultCard || submitIsLoading,
          isLoading: submitIsLoading,
          onClick: submitHandler
        },
      ]}
      closeDisabled={submitIsLoading}
    >
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={3}>
            <img
              className={classes.icon}
              src={PLAN_ICON[targetPlanType]}
              alt="plan icon"
            />
          </Grid>
          <Grid item sm={9}>
            <h3 className={classes.green}>{targetPlanType}</h3>
            {isUpgrade() ?
              <p>This change will be processed immediately and will
                start a new billing cycle.</p>
              :
              <p>This change will be processed at the end of your current billing cycle,
                so your next bill will be at the new plan's rate.</p>
            }
          </Grid>
        </Grid>
        <Br count={3} />
        <Grid container>
          <Grid item sm={6} alignItems="center">
            <RadioGroup
              aria-label="plan"
              name="plan"
              value={planType}
              onChange={handlePlanTypeChange}>
              <Grid container item sm={12}>
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label={
                    <>
                      <span>Monthly Plan</span>
                      <p style={{ margin: 0 }}>{selectedPlan?.price?.currency}$ {selectedPlan?.price?.amount}/month</p>
                    </>
                  } />
              </Grid>
              <Grid container item sm={12}>
                <FormControlLabel
                  value="annually"
                  control={<Radio />}
                  label={
                    <>
                      <span>Annual Plan</span>
                      <p style={{ margin: 0 }}>{selectedPlan?.price?.currency}$ {selectedPlan?.price?.amount * 12}/year</p>
                    </>
                  } />
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid item sm={6}>
            <Grid container>
              <Grid item sm={9}>
                <span className={`${classes.green}`}> {targetPlanType} </span> till{" "}
                <b>{getFormattedDate()}</b>
              </Grid>
              <Grid style={{ textAlign: "right" }} item sm={3}>
                ${planType === 'monthly' ? selectedPlan?.price?.amount : selectedPlan?.price?.amount * 12}
              </Grid>
            </Grid>
            {discountApplied &&
              <>
                <Br />
                <Grid container>
                  <Grid item sm={9}>
                    Discount
                  </Grid>
                  <Grid style={{ textAlign: "right" }} item sm={3}>
                    -${discountedAmount}
                  </Grid>
                </Grid>
              </>
            }
            <Br count={2} />
            <Divider />
            <Grid container alignItems="flex-end">
              <Grid item sm={6}>
                <span>Total</span>
              </Grid>
              <Grid style={{ textAlign: "right" }} item sm={6}>
                CAD {" "}
                <b style={{ fontSize: "22px" }}>
                  ${formatToFixedDecimal(totalAmount)}
                </b>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Br />
        <Divider />
        <Br />
        <ApplyPromoCode
          discountApplied={discountApplied}
          setDiscountApplied={setDiscountApplied}
          setTotalAmount={setTotalAmount}
          totalAmount={totalAmount}
          setDiscountedAmount={setDiscountedAmount}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          setPlanType={setPlanType}
        />
        <SelectCardDropdown
          selectedCard={defaultCard}
          setSelectedCard={setDefaultCard}
          cardData={creditCardData}
          onSubmit={(e) => {
            setDefaultCard(e);
            setIsCardChanged(true)
            fetchCreditCardData()
          }}
        />
        {/* <img
          className={classes.icon}
          src={PLAN_ICON[targetPlanType]}
          alt="plan icon"
        />
        <div>
          <h5>
            <span>You are changing from {userSubscription?.data?.subscriptionPlan.title} {`${userSubscription?.data?.isYearly ? '(Yearly)' : ''}`} Plan to </span>
            <span className={`${classes.green}`}> {targetPlanType} {`${targetYearly ? '(Yearly)' : ''}`} </span>
            <span>Plan.</span>
          </h5>
          {
            userSubscription?.data?.subscriptionPlan.title.toLowerCase() === PLAN.STARTER.toLowerCase() && targetPlanType === PLAN.BETA ? (
              <></>
            ) :
              isUpgradeProfessionalYearlyToEnterpriseMonthly() ? (
                <div>You cannot change from from Professional (Yearly) Plan to Enterprise Plan.</div>
              ) :
                (
                  <>
                    <div>
                      {
                        isUpgrade() ? (
                          <div>This change will happen immediately.</div>
                        ) : (
                          <div>This change will be processed at the end of your current billing cycle,<br />so your next bill will be at the new plan's rate.</div>
                        )
                      }
                      {
                        isUpgrade() && isUpdatePaidPlan() && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>Cost difference is pro-rated based on the days remaining in your current billing cycle.&nbsp;<HelpTooltip message="For example, if you are subscribed to a plan on January 1 that costs $30/month and on January 15, you decide to upgrade your plan, we will take $30/remaining days on your subscription plan (in this case it is $15) and reduce the price of your new plan" /></div>
                        )
                      }
                      {
                        isUpdatePaidPlanFromYearlyToMonthly() && (
                          <div>You won't get the pro-rate since yearly plans are the final sale if you want to change to monthly plans.</div>
                        )
                      }
                      {
                        !userSubscription?.data?.subscriptionPlan.hastrialperiod && targetFreeTrial && !targetYearly && (
                          <div>You will have a 30-day free trial for subscribing for the first time.</div>
                        )
                      }
                      {
                        targetYearly && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Yearly plan is the final sale.</span>&nbsp;<HelpTooltip message="Once you buy the yearly plan, you won't get the pro-rate if you want to change to a monthly plan." />

                          </div>
                        )
                      }
                      {
                        targetYearly && userSubscription?.data?.subscriptionPlan.hastrialperiod && (
                          <div><span style={{ display: 'flex', fontWeight: 500, alignItems: 'center' }}>If you are on free trial and change to yearly plan, you will lose the free trial.</span></div>
                        )
                      }
                    </div>
                    <div className={classes.bold}>
                      {
                        isPaid() && (
                          <div>
                            <Br count={2} />
                            {
                              targetYearly ? (

                                <div>
                                  {yearlyPrice < 1 ?
                                    (
                                      `You will be charged ${currencyFormatter.format(targetSpecialPrice * 12)} CAD (${currencyFormatter.format(targetSpecialPrice)}*12) per year.`
                                    ) : (
                                      <>
                                        {
                                          targetSpecialPrice ? (
                                            <>
                                              <div>You will be charged <span style={{ textDecoration: 'line-through', color: 'red' }}>{currencyFormatter.format(yearlyPrice)} CAD ({currencyFormatter.format(yearlyPrice / 12)}*12) per year.</span></div>
                                              <span>{currencyFormatter.format(targetSpecialPrice * 12)} CAD ({currencyFormatter.format(targetSpecialPrice)}*12) per year.</span>
                                            </>
                                          ) : (
                                            <div>You will be charged <span>{currencyFormatter.format(yearlyPrice)} CAD (${formatToFixedDecimal(yearlyPrice / 12)}*12) per year.</span></div>
                                          )
                                        }
                                      </>
                                    )}
                                </div>
                              ) : (
                                <div>{`You will be charged ${currencyFormatter.format(targetPrice)} CAD per month.`}</div>
                              )
                            }
                            {
                              targetFreeTrial && !userSubscription?.data?.subscriptionPlan.price.amount && isPaid() && !targetYearly ? (
                                <div>Your billing cycle will start on {moment().add(1, 'month').format(DATE_FORMAT)}</div>
                              ) : (
                                <div>Your billing cycle will start on {moment().format(DATE_FORMAT)}</div>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  </>
                )
          }
          <Br count={2} />
          <SelectCardDropdown
            selectedCard={defaultCard}
            setSelectedCard={setDefaultCard}
            cardData={cardData}
            onSubmit={(e) => {
              setDefaultCard(e);
              setIsCardChanged(true)
              fetchCreditCardData()
            }}
          />
        </div> */}
      </div>
    </FixedDialogWrapper>
  );
};

export default ConfirmPlanDialog;
