import shipvistaAPI from "./shipvistaAPI";

export const getCustomerCards = () => {
  return shipvistaAPI.get(`/card/GetCustomerCards`);
};

export const getPaymentMethods = () => {
  return shipvistaAPI.get(`/card/GetPaymentMethods`);
};

export const addCustomerCard = (card) => {
  return shipvistaAPI.post(`/card/AddCard`, card);
};

export const updateCustomerCard = (card) => {
  return shipvistaAPI.put(`/card/UpdateCard`, card);
};

export const deleteCustomerCards = (cardIds) => {
  return shipvistaAPI.delete(`/card`, {
    data: cardIds,
  });
};

export const getEFSToken = (cardId) => {
  return shipvistaAPI.get(`/card/getefstoken?id=${cardId}`);
};

export const purchaseWith3D = (requestBody) => {
  return shipvistaAPI.post(`/card/Purchasewith3DSecure`, requestBody);
};
