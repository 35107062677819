import * as React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 220,
    },
    input: {
      flex: 1,
      '& .MuiInputBase-input': {
        fontSize: 'small',
      },
    },
    iconButton: {
      padding: 3,
    },
  }),
);

interface Props {
  globalSearch: string;
  setGlobalSearch: (value: React.SetStateAction<string>) => void;
};

export default function TableSearchFilter(props: Props) {
  const { globalSearch, setGlobalSearch } = props;

  const classes = useStyles();

  const clearBtnOnclick = () => {
    setGlobalSearch('');
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.root}>
      <InputBase
        value={globalSearch}
        onChange={(e) => { setGlobalSearch(e.target.value); }}
        className={classes.input}
        placeholder="Search by..."
        inputProps={{ 'aria-label': 'search by' }}
      />
      <IconButton
        onClick={clearBtnOnclick}
        type="button"
        className={classes.iconButton}
        aria-label="search"
      >
        {
          globalSearch ?
            <ClearIcon style={{ color: "red" }} /> :
            <SearchIcon />
        }
      </IconButton>
    </Paper>
  );
}
