import { useDispatch } from "react-redux";
import { ShippingAccount } from "../../@type/ShippingAccount";
import { ShipvistaAccountsInfo } from "../../@type/ShipvistaAccountsInfo";
import { CarrierSetupActions } from "../@types/carrierSetup";

export function useCarrierSetupActions() {
    const dispatch = useDispatch();

    function updateUserSvAccountsData(account: ShippingAccount) {
        return dispatch({ type: CarrierSetupActions.UPDATE_SHIPVISTA_ACCOUNTS_DATA, payload: account });
    }

    function updateYourAccountsData(account: ShippingAccount) {
        return dispatch({ type: CarrierSetupActions.UPDATE_YOUR_ACCOUNTS_DATA, payload: account });
    }

    function goToYourAccounts() {
        return dispatch({ type: CarrierSetupActions.GO_TO_YOUR_ACCOUNTS });
    }

    function setTabPanelValue(value: Number) {
        return dispatch({ type: CarrierSetupActions.SET_TAB_PANEL_VALUE, payload: value });
    }

    function loadSvAccounts(svAccounts: ShippingAccount[]) {
        return dispatch({ type: CarrierSetupActions.LOAD_SV_ACCOUNTS, payload: svAccounts });
    }

    function loadYourAccounts(yourAccounts: ShippingAccount[]) {
        return dispatch({ type: CarrierSetupActions.LOAD_YOUR_ACCOUNTS, payload: yourAccounts });
    }

    function fetchCurrentYourAccount(yourAccount: ShippingAccount) {
        return dispatch({ type: CarrierSetupActions.FETCH_CURRENT_YOUR_ACCOUNT, payload: yourAccount });
    }

    function fetchCurrentShipVistaAccount(svAccount: ShippingAccount) {
        return dispatch({ type: CarrierSetupActions.FETCH_CURRENT_SHIPVISTA_ACCOUNT, payload: svAccount });
    }

    function fetchCurrentYourAccountImage(yourAccountImage: string) {
        return dispatch({ type: CarrierSetupActions.FETCH_CURRENT_YOUR_ACCOUNT_IMAGE, payload: yourAccountImage });
    }

    function passSvCarrierInfo(svCarrierInfo: ShipvistaAccountsInfo) {
        return dispatch({ type: CarrierSetupActions.PASS_SHIPVISTA_INFO, payload: svCarrierInfo });
    }

    return {
        updateUserSvAccountsData,
        updateYourAccountsData,
        goToYourAccounts,
        setTabPanelValue,
        loadSvAccounts,
        loadYourAccounts,
        fetchCurrentYourAccount,
        fetchCurrentShipVistaAccount,
        fetchCurrentYourAccountImage,
        passSvCarrierInfo
    }
}