import React from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import moment from "moment";
import { Order } from "../../../../../../@type/Orders/Order";
import DraggableDialog from "../../../../../utilities/DraggableDialog";

const useStylesMaterialUI = makeStyles(() =>
    createStyles({
        PDFRoot: {
            width: "100%",
            height: "80vh"
        }
    }),
);

const stylesPDF = StyleSheet.create({
    page: {
        paddingVertical: 35,
        paddingHorizontal: 35,
    },
    timeHeader: {
        fontSize: 9,
        marginVertical: 5,
        textAlign: 'right',
        color: 'grey',
    },
    headerTxt: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        color: "white",
        backgroundColor: "grey",
        padding: 2
    },
    table: {
        display: "table",
        width: "100%",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol1: {
        width: "20%",
    },
    tableCol2: {
        width: "55%",
    },
    tableCol3: {
        width: "20%",
    },
    tableCol4: {
        width: "5%",
    },
    tableHeaderCell: {
        paddingHorizontal: 3,
        paddingVertical: 7,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 9,
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    centerTableHeaderCell: {
        paddingHorizontal: 3,
        paddingVertical: 7,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 9,
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "center"
    },
    tableCell: {
        paddingHorizontal: 3,
        paddingVertical: 7,
        fontFamily: 'Open Sans',
        fontSize: 9,
    },
    centerTableCell: {
        paddingHorizontal: 3,
        paddingVertical: 7,
        fontFamily: 'Open Sans',
        fontSize: 9,
        textAlign: "center"
    },
    header: {
        fontFamily: 'Open Sans',
        fontSize: 8,
        marginBottom: 5,
        textAlign: 'right',
    },
    totalText: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        marginTop: 5,
        textAlign: 'right',
    }
});

// register font
Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
});

// break words callback function
Font.registerHyphenationCallback(word => {
    const middle = Math.floor(word.length / 2);
    const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
    return parts;
});

// const userInfo = {
//     userName: "myuser",
//     address: "9-9 Royalcrest Rd",
//     city: "Etobicoke",
//     state: "ON",
//     postalCode: "M9V 2L6"
// }

interface Props {
    open: boolean;
    setOpen: (value: React.SetStateAction<boolean>) => void;
    orders: Order[];
}

export default function PrintPickList(props: Props) {
    const classes = useStylesMaterialUI();

    const { open, setOpen, orders } = props;

    let totalNum = 0;

    orders.forEach(order => order.orderItems.forEach(item => {
        totalNum += item.quantity;
    }))

    return (
        <DraggableDialog
            maxWidth={"lg"}
            title={"Print Product Pick List"}
            open={open}
            setOpen={setOpen}
            dialogId="printPickList">
            <PDFViewer className={classes.PDFRoot}>
                <Document title={`Product Pick List`}>
                    <Page size="A4" style={stylesPDF.page}>
                        <Text style={stylesPDF.header} fixed>
                            {moment().format("dddd, LL, h:mm a")}
                        </Text>
                        <Text style={stylesPDF.headerTxt}>Product Pick List</Text>
                        <View style={stylesPDF.table}>
                            {/* TableHeader */}
                            <View style={stylesPDF.tableRow}>
                                <View style={stylesPDF.tableCol1}>
                                    <Text style={stylesPDF.tableHeaderCell}>Item SKU</Text>
                                </View>
                                <View style={stylesPDF.tableCol2}>
                                    <Text style={stylesPDF.tableHeaderCell}>Description</Text>
                                </View>
                                <View style={stylesPDF.tableCol3}>
                                    <Text style={stylesPDF.centerTableHeaderCell}>Warehouse Location</Text>
                                </View>
                                <View style={stylesPDF.tableCol4}>
                                    <Text style={stylesPDF.centerTableHeaderCell}>Qty</Text>
                                </View>
                            </View>
                            {orders.map((order) =>
                                order.orderItems.map((item, i) =>
                                    <View key={`orderSummary-${order.orderNo}-${i}`} style={stylesPDF.tableRow}>
                                        <View style={stylesPDF.tableCol1}>
                                            <Text style={stylesPDF.tableCell}>{item.itemSKU}</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol2}>
                                            <Text style={stylesPDF.tableCell}>{item.itemName}</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol3}>
                                            <Text style={stylesPDF.centerTableCell}>{/* Warehouse location property? */}</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol4}>
                                            <Text style={stylesPDF.centerTableCell}>{item.quantity}</Text>
                                        </View>
                                    </View>
                                )
                            )}
                            <Text style={stylesPDF.totalText}>Total Items: {totalNum}</Text>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
            {/* <Divider />
            <DialogActions>
                <Button autoFocus color="primary">
                    Save as PDF
                </Button>
                <Button autoFocus color="primary">
                    Print
                </Button>
            </DialogActions> */}
        </DraggableDialog>
    );
}