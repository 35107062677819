import {
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useReducer, useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";

import "./AddCardDialog.scss";
import countriesData from "../../datajsons/countriesData.json";
import FixedDialogWrapper from "../utilities/FixedDialogWrapper";
import FloatingTextField from "../utilities/FloatingTextField";
import { Address, CountryState } from "../../@type/Orders/Address";
import { useGoogleAddressSearch } from "../utilities/useGoogleAddressSearch";
import { useAlertActions } from "../../redux/actions";
import { getAddressesService } from "../../apis/addressBookServices";
import { getAllAddressesMapper } from "../../mappers/addressBook/getAllAddressesMapper";
import { getStateByCountryService } from "../../apis/getAllCountries";

import { addCustomerCard } from "../../apis/creditCard";
import { RootState } from "../../redux/reducers";
import StripeCheckout from "../stripe-checkout/StripeCheckout";
import { MENU_MAP } from "../masterPage/common";
import StripeErrorBoundary from "../utilities/StripeErrorBoundary";
import CurrenciesDropdown from "../currencies-dropdown/CurrenciesDropdown";
import { getStripeToken } from "../../environment/env_dev";

const { token, usToken } = getStripeToken();

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    padding: "0px 0px 0px 0px",
    width: "100%",
  },
  autoTextbox: {
    "& .MuiFormLabel-root": {
      fontSize: "small",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "x-small",
    },
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: 0,
    },
  },
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stripeForm: {
    padding: theme.spacing(3),
  },
}));

const initialAddress: Address = {
  name: "",
  companyName: "",
  streetAddress: "",
  streetAddress2: "",
  city: "",
  phone: "",
  postalCode: "",
  country: "Canada",
  countryCode: "CA",
  state: "Ontario",
  stateCode: "ON",
};

const ON_CHANGE = "ON_CHANGE";
const SET_ADDRESS = "SET_ADDRESS";
const RESET_ADDRESS = "RESET_ADDRESS";

const addCardReducer = (
  state: Address,
  { type, inputName = "", value }: any
) => {
  switch (type) {
    case ON_CHANGE:
      return { ...state, [inputName]: value };
    case SET_ADDRESS:
      return { ...state, ...value };
    case RESET_ADDRESS:
      return { ...initialAddress };
    default:
      return { ...state };
  }
};

const AddCardDialog = ({
  isOpen = false,
  initialMakeDefault = true,
  hideMakeDefault = false,
  setIsOpen = (value) => {},
  onSubmit = (value) => {},
  setAddFundsDialogVisible = (value) => {},
  fetchCreditCardData,
}) => {
  const id = "addCardAddressSearchInput";

  const classes = useStyles();
  const history = useHistory();
  const [address, dispatch] = useReducer(addCardReducer, initialAddress);

  const [currency, setCurrency] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(true);
  const [isAgree, setIsAgree] = useState(false);

  const [myAddresses, setMyAddresses] = useState<Address[]>([]);
  const [statesMap, setStatesMap] = useState({});
  const [states, setStates] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [clientSecret, setClientSecret] = useState("");

  const { user } = useSelector((state: RootState) => state.user);

  const handleGoogleInputChange = (addressData) => {
    dispatch({ type: "SET_ADDRESS", value: addressData });
  };

  const { handleSearch } = useGoogleAddressSearch(
    address,
    handleGoogleInputChange,
    id,
    initialAddress
  );
  const { openAlertBar } = useAlertActions();

  const getMyAddresses = useCallback(async () => {
    try {
      const { data } = await getAddressesService();

      if (data.addresses) {
        if (data.addresses.length !== 0) {
          const addressesData = getAllAddressesMapper(data.addresses);
          let addressList: Address[] = addressesData;

          let newAddress: Address = initialAddress;

          addressList.push(newAddress);
          setMyAddresses(addressList);
        }
      } else {
        openAlertBar(data.message, false);
      }
    } catch (error) {
      openAlertBar(JSON.stringify(error), false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearHandler = () => {
    dispatch({ type: RESET_ADDRESS });
  };

  const submitButtonDisabled = () => {
    const requiredFields = [
      currency,
      address.streetAddress,
      address.state,
      address.country,
      address.postalCode,
    ];

    return requiredFields.filter((e) => !e).length > 0;
  };

  const onSubmitHandler = async () => {
    try {
      setIsLoading(true);

      try {
        const card = {
          status: "Active",
          currency,
          billingAddress: {
            ...address,
            isResidence: true,
            status: "Active",
            email: address.email ? address.email : user?.email,
            phone: address.phone ? address.phone : user?.phone,
          },
        };

        const { data } = await addCustomerCard(card);

        setIsLoading(false);

        // setShowStripeDialog(true);
        setClientSecret(data.data.clientSecret);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        if (!data) {
          openAlertBar(
            "Adding a Card ran into some errors. Please try again later",
            false
          );

          return;
        } else if (!data.status) {
          openAlertBar(data.message, false);
          return;
        }
      } catch {
        openAlertBar(
          "Adding a Card ran into some errors. Please try again later",
          false
        );
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handlePaymentSuccess = () => {
    openAlertBar("Card added successfully", true);
    setIsOpen(false);
    fetchCreditCardData();
  };

  const handlePaymentError = (message: string) => {
    openAlertBar(message, false);
  };

  const fetchStatesByCountry = async (countryData, countryName) => {
    try {
      if (countryName in statesMap) {
        setStates(statesMap[countryName]);
        dispatch({
          type: ON_CHANGE,
          value: {
            state: statesMap[countryName][0].name,
            stateCode: statesMap[countryName][0].code,
          },
        });
        return;
      }

      const selectedCountry = countryData.find((e) => e.name === countryName);
      const { data } = await getStateByCountryService(selectedCountry?.id);

      if (!data?.data) {
        setStates([]);
        dispatch({ type: SET_ADDRESS, value: { state: "", stateCode: "" } });

        return;
      }

      setStates(data.data);

      const newStatesMap = { ...statesMap };
      newStatesMap[countryName] = data.data;

      setStatesMap(newStatesMap);

      dispatch({
        type: SET_ADDRESS,
        value: {
          state: data.data[0].name,
          stateCode: data.data[0].name,
        },
      });
    } catch (err) {
      // do something with error
      console.log(err);
    }
  };

  const steps = ["Currency & Billing Address", "Payment Details"];

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const childRef = useRef<any>();

  const finishHandler = (event) => {
    childRef?.current?.triggerSubmit(event);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="add-card-dialog">
            <div className="add-card-dialog-content">
              <Grid container spacing={2} justify="center">
                <Grid container item direction="column" spacing={2}>
                  <Grid item>
                    <Autocomplete
                      className={classes.autoComplete}
                      size="small"
                      style={{ width: "100%" }}
                      id="shipFrom-search"
                      disableClearable
                      autoHighlight
                      value={address}
                      onChange={(event, newValue: Address) => {
                        if (newValue.name === "xxx") {
                          setIsOpen(false);
                          setAddFundsDialogVisible(false);
                          history.push(
                            MENU_MAP.SETTINGS_SHIPPING_ADDRESS_BOOK.route
                          );
                        }

                        dispatch({ type: SET_ADDRESS, value: newValue });

                        if (newValue.name === "Enter New Address") {
                          setIsNewAddress(true);
                        } else {
                          setIsNewAddress(false);
                        }
                      }}
                      options={[...myAddresses, { name: "xxx" }]}
                      getOptionLabel={(option) =>
                        option.name === "xxx"
                          ? "Add New Address"
                          : option.isDefault
                          ? option.name + " (Default Address)"
                          : option.name
                      }
                      getOptionSelected={(option, value) =>
                        option.name === "xxx"
                          ? false
                          : option.name === value.name
                      }
                      defaultValue={myAddresses[0]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          className={classes.autoTextbox}
                          variant="outlined"
                          InputProps={{ ...params.InputProps }}
                          id="address-select"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <input
                      style={
                        isNewAddress ? { marginTop: "10px" } : { padding: 0 }
                      }
                      placeholder="Search for a Location"
                      className="form-control"
                      id={id}
                      onChange={handleSearch}
                      hidden={!isNewAddress}
                    />
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item sm={6}>
                      <FloatingTextField
                        name="companyName"
                        label="Name"
                        value={address.companyName}
                        onChange={({ target }) =>
                          dispatch({
                            type: ON_CHANGE,
                            inputName: target.name,
                            value: target.value,
                          })
                        }
                        disabled={isLoading}
                        style={{ margin: "1.2em 0 0 0" }}
                      />
                    </Grid>
                    <Grid item sm={6} className="mt-3">
                      <CurrenciesDropdown
                        placeholder="Please select currency of this card"
                        currency={currency}
                        handleSelect={(value) => {
                          setCurrency(value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FloatingTextField
                      name="streetAddress"
                      label="Address Line 1"
                      value={address.streetAddress}
                      onChange={({ target }) =>
                        dispatch({
                          type: ON_CHANGE,
                          inputName: target.name,
                          value: target.value,
                        })
                      }
                      disabled={isLoading}
                      style={{ margin: "1.2em 0 0 0" }}
                    />
                  </Grid>
                  <Grid item>
                    <FloatingTextField
                      name="streetAddress2"
                      label="Address Line 2 (Optional)"
                      value={address.streetAddress2}
                      onChange={({ target }) =>
                        dispatch({
                          type: ON_CHANGE,
                          inputName: target.name,
                          value: target.value,
                        })
                      }
                      disabled={isLoading}
                      style={{ margin: "1.2em 0 0 0" }}
                    />
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item sm={6}>
                      <FloatingTextField
                        name="city"
                        label="City (Optional)"
                        value={address.city}
                        onChange={({ target }) =>
                          dispatch({
                            type: ON_CHANGE,
                            inputName: target.name,
                            value: target.value,
                          })
                        }
                        disabled={isLoading}
                        style={{ margin: "1.2em 0 0 0" }}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <FloatingTextField
                        name="postalCode"
                        label="Postal Code"
                        value={address.postalCode}
                        onChange={({ target }) =>
                          dispatch({
                            type: ON_CHANGE,
                            inputName: target.name,
                            value: target.value,
                          })
                        }
                        disabled={isLoading}
                        style={{ margin: "1.2em 0 0 0" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2} style={{ paddingBottom: 0 }}>
                    <Grid item sm={6}>
                      <Autocomplete
                        disableClearable
                        size="small"
                        autoHighlight
                        value={{
                          name: address?.country,
                          code: address?.countryCode,
                        }}
                        onChange={(event: any, newValue: CountryState) => {
                          dispatch({
                            type: SET_ADDRESS,
                            value: {
                              country: newValue.name,
                              countryCode: newValue.code,
                            },
                          });

                          fetchStatesByCountry(countriesData, newValue.name);
                        }}
                        options={countriesData}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.code === value.code
                        }
                        freeSolo
                        renderInput={(params) => {
                          const inputProps = {
                            ...params.inputProps,
                            className: `form-control ${params.inputProps}`,
                          };

                          return (
                            <div
                              className="form-group"
                              ref={params.InputProps.ref}
                              style={{}}
                            >
                              <input
                                type="text"
                                id={`${id}-input`}
                                name="country"
                                className="form-control"
                                required
                                disabled={isLoading}
                                {...inputProps}
                              />
                              <label
                                className="form-control-placeholder"
                                htmlFor="auto-complete-text-field"
                              >
                                Country
                              </label>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Autocomplete
                        disableClearable
                        size="small"
                        autoHighlight
                        value={address.state}
                        onChange={(event: any, newValue: CountryState) => {
                          if (
                            !states
                              .map((e) => e.name)
                              .find((e) => e === newValue)
                          ) {
                            return;
                          }
                        }}
                        options={states.map((e) => e.name)}
                        getOptionLabel={(option) => option}
                        getOptionSelected={(option, value) =>
                          option.code === value.code
                        }
                        freeSolo
                        renderInput={(params) => {
                          const inputProps = {
                            ...params.inputProps,
                            className: `form-control ${params.inputProps}`,
                          };

                          return (
                            <div
                              className="form-group"
                              ref={params.InputProps.ref}
                              style={{ margin: "1.2em 0 0 0" }}
                            >
                              <input
                                type="text"
                                id={`${id}-input`}
                                className="form-control"
                                name="state"
                                required
                                disabled={isLoading}
                                {...inputProps}
                              />
                              <label
                                className="form-control-placeholder"
                                htmlFor="auto-complete-text-field"
                              >
                                Province/State
                              </label>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      case 1:
        return (
          <StripeErrorBoundary>
            <div className={classes.stripeForm}>
              {currency === "CAD" ? (
                <StripeCheckout
                  clientSecret={clientSecret}
                  ref={childRef}
                  setIsLoading={setIsLoading}
                  handlePaymentSuccess={handlePaymentSuccess}
                  handlePaymentError={handlePaymentError}
                  agreeTerms={isAgree}
                  setAgreeTerms={setIsAgree}
                  stripeToken={token}
                />
              ) : (
                <StripeCheckout
                  clientSecret={clientSecret}
                  ref={childRef}
                  setIsLoading={setIsLoading}
                  handlePaymentSuccess={handlePaymentSuccess}
                  handlePaymentError={handlePaymentError}
                  agreeTerms={isAgree}
                  setAgreeTerms={setIsAgree}
                  stripeToken={usToken}
                />
              )}
            </div>
          </StripeErrorBoundary>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  useEffect(() => {
    getMyAddresses();
  }, [getMyAddresses]);

  return (
    <FixedDialogWrapper
      maxWidth="sm"
      setOpen={setIsOpen}
      dialogId="add-card-dialog"
      open={isOpen}
      title="Link a New Card to your Account"
      buttonDefinitions={[
        activeStep > 0
          ? {
              label: "Back",
              color: "default",
              disabled: isLoading,
              onClick: handleBack,
            }
          : {
              label: "Clear",
              color: "default",
              disabled: isLoading,
              onClick: onClearHandler,
            },
        activeStep > 0
          ? {
              label: "Finish",
              color: "primary",
              disabled: submitButtonDisabled() || isLoading || !isAgree,
              onClick: finishHandler,
              isLoading: isLoading,
            }
          : {
              label: "Next",
              color: "primary",
              disabled: submitButtonDisabled() || isLoading,
              onClick: onSubmitHandler,
              isLoading: isLoading,
            },
      ]}
      closeDisabled={isLoading}
      overFlowOption="initial"
      marginOption="5.5%"
      disableEnforceFocus={true}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography className={classes.instructions}>
        {getStepContent(activeStep)}
      </Typography>
    </FixedDialogWrapper>
  );
};

export default AddCardDialog;
