import { WarningTypesMap, WarningTypesDataMap } from "./warningActions";


export type WarningState = {
    warningList: (keyof WarningTypesMap)[];
    globalData: WarningTypesDataMap;
};

export const initialWs: WarningState = {
    warningList: [],
    globalData: {
        SHIPMENT_LIMIT: { date: "", remainder: "" }
    }
}