import { DialogActions, DialogOption } from "../@types/dialog";
import { useDispatch } from "react-redux";

export function useDialogActions() {
    const dispatch = useDispatch();

    function closeDialog() {
        return dispatch({ type: DialogActions.CLOSE_DIALOG });
    }

    function openContentPage(dialogOption: DialogOption) {
        return dispatch({ type: DialogActions.OPEN_CONTENT_PAGE, payload: dialogOption });
    }

    return {
        closeDialog,
        openContentPage
    }
}