import './FloatingTextField.scss';

const FloatingTextField = ({
  id = '',
  label = '',
  name = '',
  value = '',
  type = 'text',
  noFloating = false,
  disabled = false,
  placeHolder = '',
  style = {},
  onChange = (e) => { },
}) => {
  if (!noFloating) {
    return (
      <div className="form-group" style={style}>
        <input
          type={type}
          id={id}
          className="form-control"
          required
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <label className="form-control-placeholder" htmlFor={id}>{label}</label>
      </div>
    );
  }

  return (
    <div className="form-group" style={style}>
      <input
        type={type}
        id={id}
        className="form-control"
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeHolder}
      />
      <label className="form-control-placeholder" htmlFor={id}>{label}</label>
    </div>
  );
};

export default FloatingTextField;
