import {loadReferralCode, setReferralCode} from '../actions/referralCodeActions'

export const getReferralCode = ()=>async(dispatch)=>{
    try{
        dispatch(loadReferralCode())
    }catch(err){

    }
}

export const settingReferralCode=(_referralCode:string)=>async(dispatch, getState)=>{
    try{
        dispatch(setReferralCode(_referralCode))
    }catch(err){

    }
}