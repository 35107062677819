import { useDispatch } from "react-redux";

import { OffersActions } from './../@types/offers/offersActions';

export function useOffersActions() {
    const dispatch = useDispatch();

    function setOAOfferApplied(isApplied: boolean) {
        return dispatch({ type: OffersActions.SET_OA_OFFER_APPLIED, payload: isApplied });
    }

    return {
        setOAOfferApplied
    }
}