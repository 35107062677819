import { AddressDetails } from "../../../../@type/AddressDetails";
import { ShippingItem } from "../../../../@type/ShippingItem";

type SET_MEASUREMENT_UNIT = "SET_MEASUREMENT_UNIT";
type SET_NEW_SHIPPING_ITEMS = "SET_NEW_SHIPPING_ITEMS";
type UPDATE_SHIPPING_ITEMS = "UPDATE_SHIPPING_ITEMS";
type COPY_SHIPPING_ITEM = "COPY_SHIPPING_ITEM";
type DELETE_SHIPPING_ITEM = "DELETE_SHIPPING_ITEM";
type UPDATE_SHIP_TO_ADDRESS_DETAILS = "UPDATE_SHIP_TO_ADDRESS_DETAILS";
type UPDATE_SHIP_FROM_ADDRESS_DETAILS = "UPDATE_SHIP_FROM_ADDRESS_DETAILS";
type SWAP_ADDRESS_DETAILS = "SWAP_ADDRESS_DETAILS";

type actionType = {
    SET_MEASUREMENT_UNIT: SET_MEASUREMENT_UNIT;
    SET_NEW_SHIPPING_ITEMS: SET_NEW_SHIPPING_ITEMS;
    UPDATE_SHIPPING_ITEMS: UPDATE_SHIPPING_ITEMS;
    COPY_SHIPPING_ITEM: COPY_SHIPPING_ITEM;
    DELETE_SHIPPING_ITEM: DELETE_SHIPPING_ITEM;
    UPDATE_SHIP_TO_ADDRESS_DETAILS: UPDATE_SHIP_TO_ADDRESS_DETAILS;
    UPDATE_SHIP_FROM_ADDRESS_DETAILS: UPDATE_SHIP_FROM_ADDRESS_DETAILS;
    SWAP_ADDRESS_DETAILS: SWAP_ADDRESS_DETAILS;
};

export const GetQuoteActions: actionType = {
    SET_MEASUREMENT_UNIT: "SET_MEASUREMENT_UNIT",
    SET_NEW_SHIPPING_ITEMS: "SET_NEW_SHIPPING_ITEMS",
    UPDATE_SHIPPING_ITEMS: "UPDATE_SHIPPING_ITEMS",
    COPY_SHIPPING_ITEM: "COPY_SHIPPING_ITEM",
    DELETE_SHIPPING_ITEM: "DELETE_SHIPPING_ITEM",
    UPDATE_SHIP_TO_ADDRESS_DETAILS: "UPDATE_SHIP_TO_ADDRESS_DETAILS",
    UPDATE_SHIP_FROM_ADDRESS_DETAILS: "UPDATE_SHIP_FROM_ADDRESS_DETAILS",
    SWAP_ADDRESS_DETAILS: "SWAP_ADDRESS_DETAILS"
};

export type GetQuoteAction =
    | { type: SET_MEASUREMENT_UNIT, payload: boolean }
    | { type: SET_NEW_SHIPPING_ITEMS, payload: number }
    | { type: UPDATE_SHIPPING_ITEMS, payload: ShippingItem }
    | { type: COPY_SHIPPING_ITEM, payload: ShippingItem }
    | { type: DELETE_SHIPPING_ITEM, payload: ShippingItem }
    | { type: UPDATE_SHIP_TO_ADDRESS_DETAILS, payload: AddressDetails }
    | { type: UPDATE_SHIP_FROM_ADDRESS_DETAILS, payload: AddressDetails }
    | { type: SWAP_ADDRESS_DETAILS };