import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { APP_COLOR } from '../../environment/env_dev';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            backgroundColor: APP_COLOR.appLightBlue
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "white",
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            style={{ cursor: 'move' }}
            disableTypography
            className={classes.root}
            {...other}>
            <Typography style={{ color: "white" }} variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 0,
    },
}))(MuiDialogContent);

interface PaperComponentProps extends PaperProps { 
    dialogId?: string;
}

function PaperComponent(pProps: PaperComponentProps) {
    const { dialogId , ...props } = pProps;
    return (
        <Draggable bounds="parent" handle={`#${dialogId}`} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

interface Props {
    open: boolean;
    children: any;
    title: string;
    maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl";
    setOpen: (value: boolean) => void,
    dialogId: string
}

// This component may occur bugs when using global state in the children component because of the third-party draggable dialog library. 
// If you want to use this component, be sure the states of the children component are in the local
// Or you could choose to use Fixed dialog Component
export default function DraggableDialog(props: Props) {
    const { open, setOpen, maxWidth, title, children, dialogId } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const MemoPaperComponent = React.useMemo(() => {
        return (props: PaperProps) => PaperComponent({...props, dialogId});
    }, [dialogId])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleClose}
            PaperComponent={MemoPaperComponent}
            aria-labelledby="customized-dialog-title"
            open={open}>
            <DialogTitle
                id={dialogId}
                onClose={handleClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
}