import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import DropdownWrapper from '../DropdownButton/DropdownWrapper';

interface Props {
  emptyBtnName: string;
  btnName: string;
  list: string[];
  list2?:string[];
  selectedIndexMap: object;
  setSelect: (value: React.SetStateAction<object>) => void;
  disableAutoHide?: boolean;
  btnSize?: "small" | "medium" | "large";
  lazyLoad?: boolean;
};

const TableMultiSelectFilter = (props: Props) => {
  const { btnName, emptyBtnName, list,list2, selectedIndexMap, setSelect, disableAutoHide, btnSize, lazyLoad } = props;

  const CheckboxWrapper = ({ label, checked, color=null }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          style={{ padding: 0, marginRight: 8 }}
          size="small"
          checked={checked}
        />

        { // Order Table -> Order Tags Filter 
        color &&
          <div style={{display:"inline-block", width:"10px", height:"14px", marginRight: '10px', backgroundColor:color}}></div>
        }

        {label}
        
      </div>
    );
  };

  const [indexList, setIndexList] = useState([]);

  useEffect(() => {
    setIndexList(list.map((e, i) => i));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const hasSelection = () => {
    return Object.values(selectedIndexMap).find((e) => e);
  };

  return (
    <DropdownWrapper
      btnName={btnName}
      btnNameCustomEl={(
        <div style={{ color: '#0B57A8', fontWeight: 'bold' }}>
          {btnName ? btnName.toUpperCase() : emptyBtnName}
        </div>
      )}
      color="primary"
      variant={btnName ? 'outlined' : 'text'}
      size={btnSize ? btnSize : "medium"}
      list={['Remove Filter', ...indexList]}
      listFormatter={(i) => {
        if (i === 0) {
          return (
            <div
              style={{
                display: 'flex',
                color: hasSelection() ? '#f50057' : 'lightgray',
                cursor: hasSelection() ? 'pointer' : 'not-allowed',
              }}
            >
              <NotInterestedIcon />
              &nbsp;
              Remove Filter
            </div>
          );
        }

        return (
          <CheckboxWrapper
            label={list[i - 1]}
            checked={selectedIndexMap[(i - 1).toString()]}
            color={ list2?list2[i-1]:null } // color for ordertag filter in order table
          />
        );
      }}
      dynamicWidth
      setSelect={(e) => {
        if (e === 'Remove Filter') {
          setSelect({});

          return;
        }

        const index = e.toString();

        if (selectedIndexMap[index]) {
          setSelect({ ...selectedIndexMap, [index]: false });
        } else {
          setSelect({ ...selectedIndexMap, [index]: true });
        }
      }}
      disableAutoHide={disableAutoHide}
      lazyLoad={lazyLoad}
      lazyLoadContainerStyle={{ width: '300px', height: '300px' }}
    />
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.emptyBtnName, nextProps.emptyBtnName) &&
    _.isEqual(prevProps.btnName, nextProps.btnName) &&
    _.isEqual(prevProps.list, nextProps.list) &&
    _.isEqual(prevProps.selectedIndexMap, nextProps.selectedIndexMap)
  );
};

export default React.memo(TableMultiSelectFilter, areEqual);
