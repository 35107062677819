import { Address } from "../@type/Orders/Address";
import {
  MarkAsShippedType,
  Order,
  OrderItem,
  orderStatusOptions,
} from "../@type/Orders/Order";

import { createManualOrderReqMapper } from "../mappers/getOrders/createManualOrderReqMapper";
import { postOfficeAddressMapper } from "../mappers/findPostOfficeAddress/postOfficeAddressMapper";
import shipvistaAPI from "./shipvistaAPI";

export const getOrdersService = (
  status?: string[],
  pageIndex?: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  stores?: string[],
  storeNames?: string[],
  countries?: string[],
  destination?: string,
  dateFilter?: string,
  orderTags?: number[],
  pageSize: number = 20,
  source?: any
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });

  for (let i = 0, ii = status.length; i < ii; i += 1) {
    params.push({ key: "Status", value: status[i] });
  }

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  stores?.map((value) => params.push({ key: "Store", value: value }));

  storeNames?.map((value) => params.push({ key: "StoreName", value: value }));

  countries?.map((value) => params.push({ key: "Country", value: value }));

  orderTags?.map((value) => params.push({ key: "OrderTags", value: value }));

  if (destination) {
    params.push({ key: "Destination", value: destination });
  }

  if (dateFilter) {
    let startDate = "";
    let endDate = "";

    if (dateFilter.includes("-")) {
      const arrDates = dateFilter.split(" - ");

      startDate = arrDates[0].replaceAll("/", "-");
      endDate = arrDates[1].replaceAll("/", "-");
    } else {
      startDate = endDate = dateFilter.replaceAll("/", "-");
    }

    params.push({ key: "MinDate", value: startDate });
    params.push({ key: "MaxDate", value: endDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/marketplaces/GetOrders${paramString}`, {
    cancelToken: source.token,
  });
};

const getParamString = (
  status?: string[],
  pageIndex?: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  stores?: string[],
  storeNames?: string[],
  countries?: string[],
  destination?: string,
  dateFilter?: string,
  orderTags?: number[],
  pageSize: number = 20
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });

  for (let i = 0, ii = status.length; i < ii; i += 1) {
    params.push({ key: "Status", value: status[i] });
  }

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  stores?.map((value) => params.push({ key: "Store", value: value }));

  storeNames?.map((value) => params.push({ key: "StoreName", value: value }));

  countries?.map((value) => params.push({ key: "Country", value: value }));

  orderTags?.map((value) => params.push({ key: "OrderTags", value: value }));

  if (destination) {
    params.push({ key: "Destination", value: destination });
  }

  if (dateFilter) {
    let startDate = "";
    let endDate = "";

    if (dateFilter.includes("-")) {
      const arrDates = dateFilter.split(" - ");

      startDate = arrDates[0].replaceAll("/", "-");
      endDate = arrDates[1].replaceAll("/", "-");
    } else {
      startDate = endDate = dateFilter.replaceAll("/", "-");
    }

    params.push({ key: "MinDate", value: startDate });
    params.push({ key: "MaxDate", value: endDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;
  return paramString;
};

export const getOrdersServiceAllOrders = (
  status?: string[],
  pageIndex?: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  stores?: string[],
  storeNames?: string[],
  countries?: string[],
  destination?: string,
  dateFilter?: string,
  orderTags?: number[],
  pageSize: number = 20,
  source?: any
) => {
  const paramString = getParamString(
    status,
    pageIndex,
    sortVal,
    sortType,
    searchText,
    stores,
    storeNames,
    countries,
    destination,
    dateFilter,
    orderTags,
    pageSize
  );
  return shipvistaAPI.get(`/marketplaces/GetAllOrders${paramString}`, {
    cancelToken: source.token,
  });
};

export const exportAllOrders = (
  status?: string[],
  pageIndex?: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  stores?: string[],
  storeNames?: string[],
  countries?: string[],
  destination?: string,
  dateFilter?: string,
  orderTags?: number[],
  pageSize: number = 20
) => {
  const paramString = getParamString(
    status,
    pageIndex,
    sortVal,
    sortType,
    searchText,
    stores,
    storeNames,
    countries,
    destination,
    dateFilter,
    orderTags,
    pageSize
  );

  return shipvistaAPI.get(`/marketplaces/ExportAllOrders${paramString}`);
};

export const exportOrders = (
  status?: string[],
  pageIndex?: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  stores?: string[],
  storeNames?: string[],
  countries?: string[],
  destination?: string,
  dateFilter?: string,
  orderTags?: number[],
  pageSize: number = 20,
  orderIds: number[] = []
) => {
  const paramString = getParamString(
    status,
    pageIndex,
    sortVal,
    sortType,
    searchText,
    stores,
    storeNames,
    countries,
    destination,
    dateFilter,
    orderTags,
    pageSize
  );

  return shipvistaAPI.post(`/marketplaces/ExportChosenOrders${paramString}`, {
    orderIds,
  });
};

export const getSearchResult = (value) => {
  return shipvistaAPI.get(`/marketplaces/Search?searchText=${value}`);
};

export const getRecipientAddressResult = (value) => {
  return shipvistaAPI.get(`/marketplaces/SearchRecipient?searchText=${value}`);
};

export const updateShipToAddressService = (addressData: any, orderId: number) =>
  shipvistaAPI.put(
    `/MarketplaceOrder/UpdateShippingInfo?orderId=${orderId}`,
    addressData
  );

export const getDiscoverService = (getServicePre: any) =>
  shipvistaAPI.post(`/ShippingServices/GetDiscoverService`, getServicePre);

export const getServiceOptionsService = (serviceRequest: any) =>
  shipvistaAPI.post(`/ShippingServices/GetServiceDetail`, serviceRequest);

export const getShippingAccountsService = () =>
  shipvistaAPI.get(`/ShippingCarrierConnect/getshippingaccounts`);

export const getAllPostOfficeService = (
  postalCode: string,
  shippingCarrier: string
) => shipvistaAPI.get(`/post-office/${postalCode}/${shippingCarrier}`);

export const getPostOfficesService = (
  _address: Address,
  shippingCarrier: string
) => {
  let address = postOfficeAddressMapper(_address);
  let req = {
    address: address,
    shippingCarrier: shippingCarrier,
  };
  return shipvistaAPI.post(`/post-office`, req);
};

export const getFromAddressesService = () => shipvistaAPI.get(`/addresses`);

export const addNewInternalNotesService = (orderId: number, message: string) =>
  shipvistaAPI.post(
    `/MarketplaceOrder/AddOrderNote?order_id=${orderId}&message=${message}`,
    null
  );

export const editInternalNotesService = (
  orderId: number,
  id: number,
  message: string
) =>
  shipvistaAPI.put(`/MarketplaceOrder/EditNotes`, {
    id: id,
    orderId: orderId,
    message: message,
  });

export const getOrderByIdService = (orderId: number) => {
  return shipvistaAPI.get(`/MarketplaceOrder/GetOrderById?orderId=${orderId}`);
};

export const updateOrdersStatusService = (
  orderIdList: number[],
  orderStatus: string
) => {
  const req = orderIdList.map((id) => {
    return {
      id: id,
      fulfillmentInfo: {
        status: orderStatus,
      },
    };
  });
  return shipvistaAPI.post(`/MarketplaceOrder/UpdateOrderStatus`, req);
};

export const addManualOrderService = (order: any) => {
  const createManualOrderRequest = createManualOrderReqMapper(order);
  return shipvistaAPI.post(`/MarketplaceOrder`, createManualOrderRequest);
};

export const markOrdersAsShippedService = (
  markAsShippedData: MarkAsShippedType[]
) => {
  const req = markAsShippedData.map((item) => {
    return {
      id: item.id,
      fulfillmentInfo: {
        status: orderStatusOptions.shipped,
        carrier: item.carrier,
        trackingNumber: item.trackingNo,
        shipDate: item.shipDate,
        serviceName: "DOM_EP",
      },
    };
  });
  return shipvistaAPI.post(`/MarketplaceOrder/UpdateOrderStatus`, req);
};

export const holdOrdersService = (ordersHold: Order[]) => {
  const req = ordersHold.map((orderHold) => {
    return {
      id: orderHold.id,
      fulfillmentInfo: {
        status: orderStatusOptions.onHold,
        holdUntil: orderHold.fulfillmentInfo.holdUntil,
      },
    };
  });
  return shipvistaAPI.post(`/MarketplaceOrder/UpdateOrderStatus`, req);
};

//Feeds
export const getAllFeeds = (
  status: string[],
  type: string[],
  pageIndex: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  dateFilter?: string,
  pageSize: number = 20
) => {
  const params = [];
  params.push({ key: "Limit", value: pageSize });

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }

  for (let i = 0, ii = status.length; i < ii; i += 1) {
    params.push({ key: "Status", value: status[i] });
  }

  for (let i = 0, ii = type?.length ?? 0; i < ii; i += 1) {
    params.push({ key: "Type", value: type[i] });
  }

  if (dateFilter) {
    let startDate = "";
    let endDate = "";

    if (dateFilter.includes("-")) {
      const arrDates = dateFilter.split(" - ");

      startDate = arrDates[0].replaceAll("/", "-");
      endDate = arrDates[1].replaceAll("/", "-");
    } else {
      startDate = endDate = dateFilter.replaceAll("/", "-");
    }

    params.push({ key: "MinDate", value: startDate });
    params.push({ key: "MaxDate", value: endDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/feeds${paramString}`);
};

export const getErrorFileService = (fileName: string, feedType: string) =>
  shipvistaAPI.get(
    `/feeds/GetErrorFile?fileName=${fileName}&feedType=${feedType}`
  );

// LTLService
export const getLTLServiceOptionsService = (carriersList: string[]) => {
  return shipvistaAPI.post(
    `/ShippingServices/GetFreightServiceOptions`,
    carriersList
  );
};

//Order Tags
export const getOrderTagsService = () => shipvistaAPI.get(`/OrderTags`);

export const addOrderTagService = (orderTag: any) => {
  return shipvistaAPI.post(`/OrderTags`, orderTag);
};

export const updateOrderTagService = (data: any) => {
  return shipvistaAPI.put(`/OrderTags`, data);
};

export const removeOrderTagService = (id: any) => {
  return shipvistaAPI.delete(`/OrderTags?Id=` + [parseInt(id)]);
};

export const updateOrdersTagsService = (model: any) => {
  return shipvistaAPI.post(`/MarketplaceOrder/UpdateOrdersTags`, model);
};

export const deleteOrderTagService = (id: string) => {
  return shipvistaAPI.delete(`/OrderTags`, {
    data: [parseInt(id)],
  });
};

//Order Tags
export const getOrdersBatchHistoryMapService = (id: any) =>
  shipvistaAPI.get(`/orderbatchhistory/GetOrdersByOrdersBatchId?id=${id}`);

export const getAllOrdersByOrderIdsService = (id: any) =>
  shipvistaAPI.post(`/marketplaceOrder/GetOrdersByOrderIds`, id);

export const getBatchById = (id: any) =>
  shipvistaAPI.get(`/orderbatchhistory/GetBatchById?id=${id}`);

export const getAllBatchesHistory = (
  status: string[],
  shipFromId: string,
  pageIndex: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  shipDateFilter?: string,
  processedDateFilter?: string,
  pageSize: number = 20
) => {
  const params = [];
  params.push({ key: "Limit", value: pageSize });

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }

  if (shipFromId) {
    params.push({ key: "ShipFromId", value: shipFromId.trim() });
  }

  for (let i = 0, ii = status.length; i < ii; i += 1) {
    params.push({ key: "Status", value: status[i] });
  }

  if (shipDateFilter) {
    let shipStartDate = "";
    let shipEndDate = "";
    if (shipDateFilter.includes("-")) {
      const arrDates = shipDateFilter.split(" - ");
      shipStartDate = arrDates[0].replaceAll("/", "-");
      shipEndDate = arrDates[1].replaceAll("/", "-");
    } else {
      shipStartDate = shipEndDate = shipDateFilter.replaceAll("/", "-");
    }
    params.push({ key: "shipMinDate", value: shipStartDate });
    params.push({ key: "shipMaxDate", value: shipEndDate });
  }

  if (processedDateFilter) {
    let processedStartDate = "";
    let processedEndDate = "";
    if (processedDateFilter.includes("-")) {
      const arrDates = processedDateFilter.split(" - ");
      processedStartDate = arrDates[0].replaceAll("/", "-");
      processedEndDate = arrDates[1].replaceAll("/", "-");
    } else {
      processedStartDate = processedEndDate = processedDateFilter.replaceAll(
        "/",
        "-"
      );
    }
    params.push({ key: "processedMinDate", value: processedStartDate });
    params.push({ key: "processedMaxDate", value: processedEndDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/orderbatchhistory${paramString}`);
};

export const combineOrdersService = (Ids: number[]) => {
  return shipvistaAPI.post(`/marketplaceOrder/CombineOrders`, Ids);
};

export const splitOrderService = (orders: Order[], id: any) => {
  return shipvistaAPI.post(
    `/marketplaceOrder/SplitOrder?mainOrderId=${id}`,
    orders
  );
};

export const addOrUpdateOrderItem = (items: OrderItem[]) => {
  return shipvistaAPI.post(`/marketplaceOrder/AddOrUpdateOrderItem`, items);
};

export const getOrderItemSearchResult = (searchText: string) => {
  return shipvistaAPI.get(
    `/marketplaceOrder/GetOrderItemSearch?searchText=` + searchText
  );
};

export const getCustomerReport = (max: string, min: string) => {
  return shipvistaAPI.post(
    `/marketplaceOrder/GetReport?max=${max}&min=${min}`,
    null
  );
};

export const getOperationReport = (max: string, min: string) => {
  return shipvistaAPI.post(
    `/marketplaceOrder/GetOperationReport?max=${max}&min=${min}`,
    null
  );
};

export const getSalesReport = (max: string, min: string) => {
  return shipvistaAPI.post(
    `/marketplaceOrder/GetSalesReport?max=${max}&min=${min}`,
    null
  );
};

export const getCustomerOverviewReport = (max: string, min: string) => {
  return shipvistaAPI.post(
    `/marketplaceOrder/GetCustomerReport?max=${max}&min=${min}`,
    null
  );
};

export const getProductReport = (max: string, min: string) => {
  return shipvistaAPI.post(
    `/marketplaceOrder/GetProductReport?max=${max}&min=${min}`,
    null
  );
};
