export const formatToFixedDecimal = (_price: number) => {
  let calcDec = Math.pow(10, 2);

  return Math.trunc(_price * calcDec) / calcDec;
};

export const roundAfterDecimalPoint = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const convertCurrencyToSymbol = (currency, locale = "en-CA") =>
  new Intl.NumberFormat(locale, { style: "currency", currency })
    .formatToParts(1)
    .find((x) => x.type === "currency").value;

export const formatPhoneNumber = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

// Gets Ship Date with conditions
// sets date to next day 8 AM if time is 5PM or more
// set date to monday 8AM if day is friday and time is 5PM or more
export const getShipDate = (): Date => {
  // Get current date and time
  const now = new Date();

  // Get current hour in local timezone
  const currentHour = now.getHours();
  const currentDate = now.getDate();
  const currentDay = now.getDay();

  // Check if current hour is greater than or equal to 5 PM
  if (currentHour >= 17) {
    // Increment the date by one day
    now.setDate(currentDate + 1);

    if (currentDay === 5 && currentHour >= 17) {
      // Increment the date to the next Monday
      now.setDate(currentDate + (8 - currentDay));
    }

    now.setHours(8);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
  }

  return now;
};
