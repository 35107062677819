import React, { forwardRef } from "react";
import { PropTypes, Button, Popover, PopoverOrigin, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
    style?: object;
    btnName: string;
    btnNameCustomEl?: any;
    color: PropTypes.Color;
    variant: "text" | "outlined" | "contained";
    anchorEl: HTMLButtonElement | null;
    disabled?: boolean;
    children: any;
    size?: "small" | "medium" | "large";
    setAnchorEl: (value: React.SetStateAction<HTMLButtonElement>) => void;
    hideEndIcon?: boolean;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    isLoading?: boolean;
    onClickLoadData?:()=>void;
};

function DropdownButton(props: Props, ref) {
    const { setAnchorEl, anchorOrigin, transformOrigin, isLoading,onClickLoadData } = props;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(onClickLoadData){
            onClickLoadData();
        }
       
        if (isLoading) {
            return;
        }

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(props.anchorEl);
    const id = open ? 'dropdown-popover' : undefined;

    const _anchorOrigin: PopoverOrigin = anchorOrigin ? anchorOrigin : {
        vertical: 'bottom',
        horizontal: 'left',
    };
    const _transformOrigin: PopoverOrigin = transformOrigin ? transformOrigin : {
        vertical: 'top',
        horizontal: 'left',
    };

    return (
        <div ref={ref}>
            <div>
                <Button
                    style={props.style}
                    disabled={props.disabled}
                    color={props.color}
                    variant={props.variant}
                    endIcon={props.hideEndIcon ? '' : <ExpandMoreIcon />}
                    onClick={handleClick}
                    size={props.size ? props.size : 'small'}
                >
                    {
                        isLoading && (
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <CircularProgress size={21} />
                            </div>
                        )
                    }
                    {
                        !isLoading && props.btnNameCustomEl && (
                            props.btnNameCustomEl
                        )
                    }
                    {
                        !isLoading && !props.btnNameCustomEl && (
                            props.btnName
                        )
                    }
                </Button>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={props.anchorEl}
                onClose={handleClose}
                anchorOrigin={_anchorOrigin}
                transformOrigin={_transformOrigin}
            >
                {props.children}
            </Popover>
        </div>
    );
}

export default forwardRef(DropdownButton);
