import { useRef, useEffect, useState } from 'react';
import { PropTypes, PopoverOrigin } from '@material-ui/core';

import DropdownBtnPopoverList from './DropdownBtnPopoverList';
import DropdownButton from './DropdownButton';

const DROPDOWN_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  textTransform: 'none',
};

interface Props {
  btnName: string;
  btnNameCustomEl?: any;
  color: PropTypes.Color;
  variant: "text" | "outlined" | "contained";
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  list: any[];
  dynamicWidth?: boolean;
  listFormatter?: (i) => {};
  setSelect?: (value: React.SetStateAction<any>) => void;
  style?: any;
  hideEndIcon?: boolean;
  disableAutoHide?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  lazyLoad?: boolean;
  lazyLoadContainerStyle?: object;
  isLoading?: boolean;
  onClickLoadData?:()=>void;
  optionsLabel?:string[]|number[]
};

const DropdownWrapper = (props: Props) => {
  const {
    btnName, btnNameCustomEl, color, variant, dynamicWidth,
    size, list, listFormatter, setSelect, disabled, style, hideEndIcon,
    disableAutoHide, anchorOrigin, transformOrigin, lazyLoad,
    lazyLoadContainerStyle, isLoading,onClickLoadData,optionsLabel
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownWidth, setDropdownWidth] = useState(100);
  
  const dropdownButtonRef = useRef(null);

  const handleResize = () => {
    const dropdownButtonEl = dropdownButtonRef.current;

    if (!dropdownButtonEl) {
      return;
    }

    const dropdownButtonElRect = dropdownButtonEl.getBoundingClientRect();

    setDropdownWidth(dropdownButtonElRect.width);
  };

  useEffect(() => {
    if (dynamicWidth) {
      return;
    }

    setTimeout(() => {
      handleResize();
    });

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dynamicWidth) {
      return;
    }

    setTimeout(() => {
      handleResize();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const defaultStyle = {...DROPDOWN_STYLE};

  if (style) {
    Object.keys(style).forEach((e) => {
      defaultStyle[e] = style[e];
    });
  }

  return (
    <DropdownButton
      ref={dropdownButtonRef}
      style={{
        ...defaultStyle,
        width: dynamicWidth ? 'auto' : DROPDOWN_STYLE.width,
      }}
      btnName={btnName}
      btnNameCustomEl={btnNameCustomEl}
      color={color}
      variant={variant}
      anchorEl={anchorEl}
      size={size}
      setAnchorEl={setAnchorEl}
      disabled={disabled}
      hideEndIcon={hideEndIcon}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      isLoading={isLoading}
      onClickLoadData={onClickLoadData}
    >
      <DropdownBtnPopoverList
        style={
          dynamicWidth ? {} : {
          width: `${dropdownWidth}px`,
          overflowY: 'overlay',
        }}
        list={optionsLabel ? optionsLabel: list }
        listFormatter={listFormatter}
        setAnchorEl={setAnchorEl}
        setSelect={setSelect}
        disableAutoHide={disableAutoHide}
        lazyLoad={lazyLoad}
        lazyLoadContainerStyle={lazyLoadContainerStyle}
      />
    </DropdownButton>
  );
};

export default DropdownWrapper;
