import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { cyan } from "@material-ui/core/colors";
import { Mail as MailIcon, Phone as PhoneIcon } from "@material-ui/icons";

import FixedDialogWrapper from "../utilities/FixedDialogWrapper";
import { markFreightIntroComplete } from "../../apis/userService";

import * as shipvistaAccountsInfo from "../../datajsons/shipvistaAccountsInfo.json";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/reducers";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      textAlign: "center",
    },
    textCenter: {
      textAlign: "center",
    },
    primaryColor: {
      color: theme.palette.primary.dark,
    },
    cyanColor: {
      color: cyan[500],
    },
    pt1: {
      paddingTop: theme.spacing(1),
    },
    progress: {
      color: "white",
      marginRight: 10,
    },
    warnings: {
      display: "grid",
      gridAutoColumns: "auto",
      gap: "5px",
    },
  })
);

const tForce = shipvistaAccountsInfo.shipvistaAccounts.find((s) => s.id === 7);

const FreightIntroDialog = () => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.user);
  const [freightIntro, setFreightIntro] = useState(false);
  const markComplete = async () => {
    await markFreightIntroComplete();
  };

  useEffect(() => {
    setFreightIntro(user?.freightIntroActive);
  }, [user?.freightIntroActive]);

  return (
    <FixedDialogWrapper
      dialogId="payment-info"
      title="Introducing LTL shipping on ShipVista"
      maxWidth="md"
      disableFullWidth
      open={freightIntro}
      setOpen={() => {
        markComplete();
        setFreightIntro((s) => !s);
      }}
    >
      <div className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Introducing T Force(LTL) Shipping Exclusively on ShipVista.com
        </Typography>
        <div className="text-center">
          <img
            src={tForce.image}
            alt="TForce Logo"
            className="img-fluid w-50"
          />
        </div>
        <br />
        <Typography variant="h6" gutterBottom>
          We are thrilled to announce the introduction of LTL (Less Than
          Truckload) shipping services on ShipVista.com, our all-in-one shipping
          platform. Now, users can conveniently ship their LTL shipments
          directly from ShipVista's user-friendly dashboard. With this new
          feature, we aim to provide our customers with greater flexibility and
          convenience in managing their logistics needs.
        </Typography>

        <br />
        <Typography>
          By opting to ship with ShipVista, you stand to benefit significantly
          from cost savings, potentially reducing LTL shipment expenses by up to
          an impressive 75%. We understand the importance of efficient and
          affordable shipping solutions for businesses and individuals alike.
          With our streamlined LTL shipping services, you can expect not only
          significant cost savings but also a seamless and reliable shipping
          experience.
        </Typography>
        <br />
        <Typography>
          At ShipVista, we continually strive to enhance our offerings and
          provide cutting-edge solutions to meet the ever-evolving needs of our
          valued customers. Our commitment to excellence and customer
          satisfaction remains unwavering as we aim to make shipping processes
          smoother, more cost-effective, and hassle-free for everyone.{" "}
        </Typography>
        <br />
        <Typography>
          Experience the convenience and savings of LTL shipping on
          ShipVista.com today and take your logistics to the next level. Trust
          us to be your reliable partner in navigating the world of shipping
          with ease and efficiency. Let us handle the logistics while you focus
          on growing your business and achieving your goals. ShipVista is here
          to support you every step of the way.
        </Typography>
        <br />
        <Typography>
          <b>
            Incase you any questions contact our Customer Care Team for any
            assistance.
          </b>
        </Typography>
        <br />
        <Typography>
          <Grid container justify="space-around" spacing={2}>
            <Grid item>
              <MailIcon className={classes.cyanColor} />{" "}
              <a className={classes.pt1} href="mailto:">
                customercare@shipvista.com
              </a>
            </Grid>
            <Grid item>
              <PhoneIcon className={classes.cyanColor} />{" "}
              <a className={classes.pt1} href="tel:9053468044">
                (905) 346-8044
              </a>{" "}
              - Ext #2.
            </Grid>
            <Grid item>
              <PhoneIcon className={classes.cyanColor} />{" "}
              <a className={classes.pt1} href="tel:8554343477">
                855-434-3477
              </a>{" "}
              - Ext #2.
            </Grid>
          </Grid>
        </Typography>
        <br />
        <Typography>
          Business Hours: <br />
          <b className={classes.primaryColor}>
            Monday to Friday: 10 am to 7:30 pm EST
          </b>
          .
        </Typography>
      </div>
    </FixedDialogWrapper>
  );
};

export default FreightIntroDialog;
