import React, { useCallback, useEffect } from 'react';

import { getAllOrdersMapper } from "../../../../../mappers/getOrders/getAllOrdersMapper";
import { useAlertActions } from '../../../../../redux/actions/alertActions';

import { useDataTableUseStyles } from '../../../../../styles/materialUI/dataTableUseStyles';
import FixedDialog from '../../../../utilities/FixedDialog'
import Checkbox from '@material-ui/core/Checkbox';
import DropdownButton from '../../../../utilities/DropdownButton/DropdownButton';
import DropdownBtnPopoverList from "../../../../utilities/DropdownButton/DropdownBtnPopoverList";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import PrintPackingSlip from "../../../orders/OrdersCommon/OrdersFunctions/Print/PrintPackingSlip";
import PrintOrderSummary from "../../../orders/OrdersCommon/OrdersFunctions/Print/PrintOrderSummary";
import PrintPickList from "../../../orders/OrdersCommon/OrdersFunctions/Print/PrintPickList";
import { Order } from '../../../../../@type/Orders/Order'
import { getMergedPDF } from '../../../../../apis/shipmentsService'
import CircularProgress from '@material-ui/core/CircularProgress';
import { mergePDFType } from '../../../../../@type/PDFTypes';
import _ from 'lodash';

interface Props {
    orderDetailsDialogOpen: boolean;
    setOrderDetailsDialogOpen: (value: boolean) => void;
    orderBatchesHistory: any;
}

const LabelSummaryDialog = (props: Props) => {
    const printList = ["Label", "Packing Slip", "Order Summary", "Product Pick List"];
    const { orderDetailsDialogOpen, setOrderDetailsDialogOpen, orderBatchesHistory } = props
    const { table } = useDataTableUseStyles();

    const { openAlertBar } = useAlertActions();
    const [isSuccess, setIsSuccess] = React.useState<boolean>(true);
    const [batchOrderstatus, setbatchOrderstatus] = React.useState<string>("SUCCESS");
    const [selectedOrderIdsArr, setSelectedOrderIdsArr] = React.useState<number[]>([]);
    const [checked, setChecked] = React.useState<number[]>([]);
    const [anchorElPrint, setAnchorElPrint] = React.useState<HTMLButtonElement | null>(null);
    const [checkAll, setCheckAll] = React.useState<boolean>(false);
    const [packingSlipDialogOpen, setPackingSlipDialogOpen] = React.useState<boolean>(false);
    const [orderSummaryDialogOpen, setOrderSummaryDialogOpen] = React.useState<boolean>(false);
    const [pickListDialogOpen, setPickListDialogOpen] = React.useState<boolean>(false);
    const [selectPrint, setSelectPrint] = React.useState<string>("");
    const [filteredOrderBatchesHistory, setFilteredOrderBatchesHistory] = React.useState<Order[]>([]);
    const [selectedOrders, setSelectedOrders] = React.useState<any[]>([]);
    const [openLabel, setOpenLabel] = React.useState<boolean>(false);
    const [labelLoading, setLabelLoading] = React.useState<boolean>(false);
    const [pdfBaseString, setPdfBaseString] = React.useState('');
    const [pdfFileContentType, setFileContentType] = React.useState('');
    const [pdfResponseMsg, setPdfResponseMsg] = React.useState('');

    // on open and close
    React.useEffect(() => {
        if (orderDetailsDialogOpen) {
            setFilteredOrderBatchesHistory(orderBatchesHistory.filter(batch => batch.fulfillmentInfo.labelLinks))
            // handleSearch("SUCCESS")
            setbatchOrderstatus("SUCCESS") // set here, will set the status to success as soon as opened
        } else {

            setSelectedOrderIdsArr([]);
            setChecked([])
            setCheckAll(false)
            // setbatchOrderstatus("SUCCESS") // don't set here, causes visual bug
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderDetailsDialogOpen])

    const getAllOrdersByOrderId = useCallback((value: number[]) => {
        let selectedOrdersFiltered = orderBatchesHistory.filter((order) => value.includes(order.id));
        const ordersDataPre = selectedOrdersFiltered;
        if (ordersDataPre.length !== 0) {
            const ordersData: any[] = getAllOrdersMapper(ordersDataPre);
            setSelectedOrders(ordersData);
        } else {
            setSelectedOrders([]);
        }
    }, [orderBatchesHistory])

    useEffect(() => {
        if (selectedOrderIdsArr.length > 0) {
            getAllOrdersByOrderId(selectedOrderIdsArr);
        }
    }, [selectedOrderIdsArr, getAllOrdersByOrderId]);

    React.useEffect(() => {

        if (selectPrint === "Packing Slip") {
            setPackingSlipDialogOpen(true);
            setSelectPrint("");
        } else if (selectPrint === "Order Summary") {
            setOrderSummaryDialogOpen(true);
            setSelectPrint("");
        } else if (selectPrint === "Product Pick List") {
            setPickListDialogOpen(true);
            setSelectPrint("");
        } else if (selectPrint === "Label") {
            setLabelLoading(true)
            let pdfLinks = selectedOrders.map((order) => { return order.fulfillmentInfo.labelLinks })
            getMergedPDF(pdfLinks, mergePDFType.LABEL)
                .then(res => {
                    if (res.data.status) {
                        const { fileContents, contentType } = res.data.data;

                        setPdfBaseString(fileContents);
                        setFileContentType(contentType);
                        setPdfResponseMsg('');
                        setOpenLabel(true)
                        setLabelLoading(false)
                    } else {
                        openAlertBar(res.data.message, false);
                    }

                }).catch(err => {
                    openAlertBar(err, false);
                })

            setSelectPrint("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPrint]);


    const handleToggle = (value: number) => () => {
        updateChecked(value);
    };

    const handleOnSelectAll = (e) => {
        const { checked } = e.target;
        if (checked) {
            setCheckAll(true);
            var newChecked = [];
            orderBatchesHistory.forEach(function (items) {
                newChecked.push(items.id);
            });
            setChecked(newChecked);
            setSelectedOrderIdsArr(newChecked)
        } else {
            setCheckAll(false);
            setSelectedOrderIdsArr([]);
            setChecked([]);
        }
    };

    const OrderDetailsStatus = ({ status = '' }) => {
        let backgroundColor = 'green';
        if (status === 'FAILED') {
            backgroundColor = 'red';
        }
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'left', width: '83px' }}>
                    <span style={{ height: '14px', width: '14px', float: 'left', margin: '4px', borderRadius: '50%', display: 'inline-block', backgroundColor }}>
                    </span><div style={{ display: 'flex' }}>{status}</div>
                </div>
            </div>
        );
    };

    const ErrorMessage = ({ row }) => {
        let backgroundColor = 'green';
        if (row === 'FAILED') {
            backgroundColor = 'red';
            return (
                <div style={{ color: backgroundColor }}>
                    {"There was an error creating label"}
                </div>
            );
        } else {
            return (
                <div style={{ color: backgroundColor }}>
                    {"Label Created Successfully"}
                </div>
            );
        }


    };
    const updateChecked = (value: number) => {
        var totalChecked = [];
        const currentIndex = checked.indexOf(value);
        let newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
            setSelectedOrderIdsArr([...selectedOrderIdsArr, value]);
            if (newChecked.length === orderBatchesHistory.length) {
                setCheckAll(true);
            }
        }
        else {
            setCheckAll(false);
            setChecked(_.remove(newChecked, (n) => { return n === value }));
            setSelectedOrderIdsArr(selectedOrderIdsArr.filter(v => v !== value));
        }
        setChecked(newChecked);
        if (totalChecked.length > 0) {
            getAllOrdersByOrderId(totalChecked);
        }
    };

    const handleSearch = (value) => {
        if (value.target.innerText === "SUCCESS") {
            setIsSuccess(true);
            setFilteredOrderBatchesHistory(orderBatchesHistory.filter(batchByStatus => batchByStatus.fulfillmentInfo.labelLinks))
        } else {
            setIsSuccess(false);
            setFilteredOrderBatchesHistory(orderBatchesHistory.filter(batchByStatus => !batchByStatus.fulfillmentInfo.labelLinks))
        }
        setbatchOrderstatus(value.target.innerText);
    }

    return (
        <>
            <FixedDialog
                maxWidth="sm"
                title="Batches history details"
                open={orderDetailsDialogOpen}
                setOpen={setOrderDetailsDialogOpen}
                dialogId="BatchHistoryDetailId">

                <table className={table} style={{ marginLeft: "10px", width: "96.5%", marginBottom: '30px' }}>
                    <tbody>

                        <tr>

                            <td style={{ width: '50px', padding: '0px !important', paddingLeft: '20px' }}>
                                {
                                    isSuccess ? <input type="checkbox" checked={checkAll} onChange={e => handleOnSelectAll(e)} />
                                        : <></>
                                }
                            </td>

                            <td style={{ width: '100px', padding: '0px !important', paddingLeft: '20px' }}>
                                <b> Order #</b>
                            </td>

                            <td colSpan={2} style={{ width: '100px', padding: '0px !important', paddingLeft: '20px' }}>
                                <ToggleButtonGroup
                                    className="toggleBtn paddingRight25"
                                    exclusive
                                    aria-label="status"
                                    size="small" onChange={(e) => handleSearch(e)} value={batchOrderstatus}>
                                    <ToggleButton value="SUCCESS" aria-label="SUCCESS"  >
                                        SUCCESS
                                    </ToggleButton>
                                    <ToggleButton value="FAILED" aria-label="FAILED">
                                        FAILED
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </td>

                        </tr>

                        {
                            filteredOrderBatchesHistory.map((batches, index) =>
                                <tr key={index}>
                                    <td style={{ borderLeft: '1px solid #e9ecef', width: '50px', padding: '0px !important', paddingLeft: '20px' }}>
                                        {
                                            isSuccess ? <Checkbox
                                                edge="start"
                                                size="small"
                                                checked={checked.indexOf(batches.id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': `checkbox-list-label-${batches.orderId}` }}
                                                onClick={handleToggle(batches.id)} />
                                                : <></>
                                        }
                                    </td>
                                    <td style={{ borderLeft: '1px solid #e9ecef', width: '100px', padding: '0px !important', paddingLeft: '20px' }}>
                                        <div>{batches.marketPlaceOrderId}</div>
                                    </td>
                                    <td style={{ borderLeft: '1px solid #e9ecef', width: '100px', padding: '0px !important', paddingLeft: '20px' }}>
                                        <OrderDetailsStatus status={batchOrderstatus} />
                                    </td>
                                    <td style={{ borderLeft: '1px solid #e9ecef', padding: '0px !important', paddingLeft: '20px' }}>
                                        <ErrorMessage row={batchOrderstatus} />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                {
                    isSuccess && (
                        <>
                            <hr />

                            <div style={{ float: 'right', paddingRight: '11px', paddingBottom: '11px' }}>
                                <DropdownButton
                                    disabled={selectedOrderIdsArr.length === 0 ? true : false}
                                    anchorEl={anchorElPrint}
                                    setAnchorEl={setAnchorElPrint}
                                    btnName={"Print"}
                                    color={"default"}
                                    variant={"outlined"} >
                                    <DropdownBtnPopoverList setSelect={setSelectPrint} setAnchorEl={setAnchorElPrint} list={printList} />
                                </DropdownButton>
                            </div>
                        </>
                    )
                }


            </FixedDialog>
            <FixedDialog
                maxWidth="md"
                title="Shipping Label"
                open={openLabel}
                setOpen={setOpenLabel}
                dialogId="shippingLabel"
                closeDisabled={labelLoading}
            >
                <div style={{ minHeight: '80vh', position: 'relative' }}>
                    {
                        labelLoading && (
                            <CircularProgress
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-20px',
                                    marginLeft: '-20px'
                                }}
                            />
                        )
                    }
                    {
                        !labelLoading ?

                            <>
                                <object
                                    aria-labelledby="label1"
                                    style={{ width: '100%', height: '80vh' }}
                                    type={pdfFileContentType}
                                    data={"data:application/pdf;base64," + pdfBaseString}>
                                </object>
                            </>
                            :
                            <div>
                                <span>{pdfResponseMsg}</span>
                            </div>
                    }
                </div>
            </FixedDialog>

            <PrintPackingSlip
                open={packingSlipDialogOpen}
                setOpen={setPackingSlipDialogOpen}
                orders={selectedOrders}
            />
            <PrintOrderSummary
                open={orderSummaryDialogOpen}
                setOpen={setOrderSummaryDialogOpen}
                orders={selectedOrders}
            />
            <PrintPickList
                open={pickListDialogOpen}
                setOpen={setPickListDialogOpen}
                orders={selectedOrders}
            />
        </>
    )
}


export default LabelSummaryDialog