type TOGGLE_WARNING = "TOGGLE_WARNING";
type CLOSE_ALL_WARNINGS = "CLOSE_ALL_WARNINGS";


// WARNINGS

type SHIPMENT_LIMIT = "SHIPMENT_LIMIT";
type USER_LIMIT = "USER_LIMIT";

export type WarningTypesMap = {
    SHIPMENT_LIMIT: SHIPMENT_LIMIT;
    USER_LIMIT: USER_LIMIT;
}

export const warningTypesMapObject: WarningTypesMap = {
    SHIPMENT_LIMIT: "SHIPMENT_LIMIT",
    USER_LIMIT: "USER_LIMIT"
};

export type WarningTypesDataMap = {
    SHIPMENT_LIMIT: { date: string | null, remainder: "" };
};


export type WARNING_TYPES =
    ( { warning: SHIPMENT_LIMIT, data?: WarningTypesDataMap[SHIPMENT_LIMIT] }
    | { warning: USER_LIMIT } ) &
    { warning: keyof WarningTypesMap, data?: any; };




type actionType = {
    TOGGLE_WARNING: TOGGLE_WARNING;
    CLOSE_ALL_WARNINGS: CLOSE_ALL_WARNINGS;
};

export const WarningActions: actionType = {
    TOGGLE_WARNING: "TOGGLE_WARNING",
    CLOSE_ALL_WARNINGS: "CLOSE_ALL_WARNINGS"
};

export type WarningAction =
    | { type: TOGGLE_WARNING, payload: { isOpen: boolean, warning: WARNING_TYPES  } }
    | { type: CLOSE_ALL_WARNINGS };