import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import PrintSubscriptionInvoiceDialog from './PrintSubscriptionInvoiceDialog';
import ReusableControlledTable from '../../../../utilities/ReusableControlledTable';
import {
  TableWrapper, TableHeaderWrapper, TableFilterWrapper, TableSearchFilterWrapper,
  TableSelectFilterWrapper, TableDateRangeFilterWrapper, ClearFilter,
} from '../../../../utilities/TableComponent';
import useDebounce from '../../../../utilities/useDebounce';
import usePrevious from '../../../../utilities/usePrevious';
import { getInvoices } from '../../../../../apis/subscriptionService';
import { useAlertActions } from '../../../../../redux/actions';
import { getCountry } from '../../../../../functionUtilities/getCountryCode';
import { RootState } from '../../../../../redux/reducers';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      padding: '20px 20px 0px 20px',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 40,
    },
    titleItemContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    backButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: '100%',
      marginRight: 10,
      borderRadius: '50%',
      cursor: 'pointer',
      background: 'lightgray',
    },
    tableSection: {
      display: 'flex',
      width: '100%',
      flex: 1,
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 231px)',
    },
    blue: {
      color: '#0964C7',
    },
    bold: {
      fontWeight: 600,
    },
    underlined: {
      textDecoration: 'underline',
    },
    pointer: {
      cursor: 'pointer',
    },
  })
);

const CENTERED_HEADER_STYLE = {
  display: 'flex',
  justifyContent: 'center',
};

const DATE_FORMAT = 'YYYY/MM/DD h:mm A';

const NOW = () => (new Date()).toString();

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const TYPE = {
  INVOICE: 'Invoice',
  REFUND: 'Refund',
};

const LeftAlignedCellWrapper = ({ children }) => (<div style={{ textAlign: 'left' }}>{children}</div>);
const CenteredCellWrapper = ({ children }) => (<div style={{ textAlign: 'center' }}>{children}</div>);

const SubscriptionPastInvoices = ({ onClose }) => {
  const classes = useStyles();

  const { openAlertBar } = useAlertActions();

  const [printSubcriptionInvoiceData, setPintSubcriptionInvoiceData] = useState({});
  const [printSubcriptionInvoiceDialogOpen, setPintSubcriptionInvoiceDialogOpen] = useState(false);

  const [fetcher, setFetcher] = useState(NOW());
  const [pastInvoicesData, setPastInvoicesData] = useState([]);
  const [dataIsLoading, setDataIsLoading] = useState(false);

  const [searchFilter, setSearchFilter] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedType, setSelectedType] = useState('');

  const [sortVal, setSortVal] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const searchFilterDebounce = useDebounce(searchFilter, 500);
  const fetcherPrev = usePrevious(fetcher);

  const { user } = useSelector((state: RootState) => state.user)

  const mountedRef = useRef(false);

  const ViewInvoice = ({ id, data }) => {
    // const {
    //   name, billingaddressline1, billingcity, billingprovince, billingcountry, billiingpostalcode,
    //   billingdate, duedate,
    //   invoiceId, refundInvoiceReference, creditReference,
    //   invoiceType, invoiceStatus,
    //   subtotal, total, paid, credit,
    //   planName, number, cardType,
    //   subscriptionStartDate, subscripitionEndDate,
    // } = data;


    // if (!data.customerCard) {
    //   return null;
    // }
    const {
      billingdate, duedate,
      invoiceId, refundInvoiceReference, creditReference,
      invoiceType, invoiceStatus,
      subtotal, total, paid, credit,
      planName, discountedPrice,
      subscriptionStartDate, subscripitionEndDate,
      typeOfCard, lastFourDigits
    } = data;
    // const { cardType, cardNumber } = customerCard;
    const { name, email, companyName, streetAddress, streetAddress2, city, stateCode, countryCode, postalCode } = user.address;
    // const { streetAddress, streetAddress2, city, stateCode, countryCode, postalCode } = billingAddress;
    let country = getCountry(countryCode)

    return (
      <div
        className={`${classes.blue} ${classes.bold} ${classes.underlined} ${classes.pointer}`}
        onClick={() => {
          const paymentsDetails = [];

          if (credit) {
            paymentsDetails.push({
              date: billingdate,
              description: `${currencyFormatter.format(Math.abs(credit))} Credit Applied from Invoice #${creditReference}`,
            });
          }

          if (paid) {
            if (invoiceType === 'REFUND') {
              paymentsDetails.push({
                date: billingdate,
                description: `${currencyFormatter.format(Math.abs(paid))} Credit Redeemed on Invoice #${refundInvoiceReference}`,
              });
            } else {
              paymentsDetails.push({
                date: billingdate,
                description: `${currencyFormatter.format(Math.abs(paid))} Payment from ${typeOfCard} ... ${lastFourDigits ? lastFourDigits : ''}`,
              });
            }
          }

          setPintSubcriptionInvoiceData({
            addressDetails: [
              name,
              email,
              companyName,
              streetAddress,
              streetAddress2,
              [city, stateCode, postalCode].join(' '),
              country,
            ],
            discountedPrice: discountedPrice,
            invoiceNo: invoiceId ?? ' ',
            billedOn: billingdate,
            dueOn: duedate,
            issuedOn: billingdate,
            refundedInvoiceNo: refundInvoiceReference,
            type: invoiceType,
            status: invoiceStatus,
            subtotal: invoiceType === 'REFUND' ? -subtotal : subtotal,
            total: invoiceType === 'REFUND' ? -total : total,
            dateDetails: {
              startDate: subscriptionStartDate,
              endDate: subscripitionEndDate,
              description: planName,
              type: 'Payment',
            },
            paymentsDetails,
            creditApplied: -credit,
            paid: invoiceType === 'REFUND' ? paid : -paid,
          });
          setPintSubcriptionInvoiceDialogOpen(true);
        }}
      >
        View Invoice
      </div>
    );
  };

  const doLoadData = async () => {
    try {
      let sortValParam = sortVal;

      switch (sortVal) {
        case 'invoiceNo': sortValParam = 'invoiceId'; break;
        case 'date': sortValParam = 'billingdate'; break;
        case 'description': sortValParam = 'planName'; break;
        case 'type': sortValParam = 'invoiceType'; break;
        default:
          break;
      }

      const result = await getInvoices(
        pageIndex, pageSize, sortValParam, sortType, searchFilterDebounce, selectedDate, selectedType,
      );

      const { message, status } = result.data;

      if (!mountedRef.current) {
        return;
      }

      if (!status) {
        openAlertBar(message, false);

        return null;
      }

      return result.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;

      openAlertBar(JSON.stringify(errors) ?? 'Something happened', false);

      return null;
    }
  };

  const loadData = async () => {
    setDataIsLoading(true);

    const result = await doLoadData();

    setDataIsLoading(false);

    if (!result) {
      setPastInvoicesData([]);
      setItemsTotal(0);

      return;
    }

    setPastInvoicesData(result.records.map((e) => ({
      invoiceNo: `2014${e.invoiceId}`,
      date: e.billingdate,
      description: e.planName,
      type: e.invoiceType,
      total: e.total,
      original: e,
      isYearly: e.isYearly
    })));
    setItemsTotal(result.total_records);
  };

  const triggerRefetch = (index = null) => {
    if (!index) {
      setPageIndex(1);
    }

    setTimeout(() => {
      setFetcher(NOW());
    }, 1);
  };

  useEffect(() => {
    const init = async () => {
      await loadData();
    };

    mountedRef.current = true;

    init();

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    triggerRefetch();
  }, [searchFilterDebounce]);

  useEffect(() => {
    if (!fetcherPrev) {
      return;
    }

    if (fetcher === fetcherPrev) {
      return;
    }

    const update = async () => {
      await loadData();
    };

    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher]);

  const clearFilter = () => {
    let refetch = false;

    if (!searchFilter) {
      refetch = true;
    }

    setSearchFilter('');
    setSelectedDate(null);
    setSelectedType('');

    if (!refetch) {
      return;
    }

    triggerRefetch();
  };

  const generateColumnStyle = (width) => {
    const w = `${width}%`;

    return {
      width: w,
      minWidth: w,
      maxWidth: w,
    };
  };

  const columnStyle15 = generateColumnStyle(15);

  const tableColumns = [
    {
      Header: 'Invoice No',
      accessor: 'invoiceNo',
      sortable: true,
      columnStyle: columnStyle15,
      Cell: (props) => (<LeftAlignedCellWrapper>{props.value}</LeftAlignedCellWrapper>),
    },
    {
      Header: 'Date',
      accessor: 'date',
      sortable: true,
      columnStyle: columnStyle15,
      Cell: (props) => (<LeftAlignedCellWrapper>{props.value ? moment(props.value).format(DATE_FORMAT) : ''}</LeftAlignedCellWrapper>),
    },
    {
      Header: 'Description',
      accessor: 'description',
      sortable: true,
      columnStyle: columnStyle15,
      // Cell: (props) => (<LeftAlignedCellWrapper>{`${props.value} ${props.isYearly ? ' (Yearly)':''}`}</LeftAlignedCellWrapper>),
      Cell: (row) => (<LeftAlignedCellWrapper>{`${row.row.original.description} ${row.row.original.isYearly ? ' (Yearly)' : ''}`}</LeftAlignedCellWrapper>),
    },
    {
      Header: 'Type',
      accessor: 'type',
      sortable: true,
      columnStyle: columnStyle15,
      Cell: (props) => (<LeftAlignedCellWrapper>{props.value}</LeftAlignedCellWrapper>),
    },
    {
      Header: 'Total (CAD)',
      accessor: 'total',
      sortable: true,
      headerStyle: CENTERED_HEADER_STYLE,
      columnStyle: columnStyle15,
      Cell: (props) => (<CenteredCellWrapper>{currencyFormatter.format(props.value)}</CenteredCellWrapper>),
    },
    {
      Header: '',
      accessor: 'action',
      columnStyle: columnStyle15,
      Cell: (props) => (
        <CenteredCellWrapper>
          <ViewInvoice id={props.value} data={props.row.original.original} />
        </CenteredCellWrapper>
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.titleItemContainer}>
          <div className={classes.backButton} onClick={onClose}>
            <ChevronLeft />
          </div>
          <h4>Past Invoices</h4>
        </div>
      </div>
      <div className={classes.tableSection}>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableFilterWrapper>
              <TableSearchFilterWrapper
                globalSearch={searchFilter}
                setGlobalSearch={(e) => { setSearchFilter(e); }}
              />
              <TableDateRangeFilterWrapper
                label="DATE"
                dateFilter={selectedDate}
                setDateFilter={(e) => {
                  setSelectedDate(e);
                  triggerRefetch();
                }}
              />
              <TableSelectFilterWrapper
                emptyBtnName="TYPE"
                btnName={selectedType}
                list={Object.values(TYPE)}
                setSelect={(e) => {
                  setSelectedType(e);
                  triggerRefetch();
                }}
              />
              {
                (searchFilter || selectedDate || selectedType) && (
                  <ClearFilter onClick={() => { clearFilter(); }} />
                )
              }
            </TableFilterWrapper>
          </TableHeaderWrapper>
          <div className={classes.tableContainer}>
            <ReusableControlledTable
              data={pastInvoicesData}
              columns={tableColumns}
              isLoading={dataIsLoading}
              sortVal={sortVal}
              setSortVal={(e) => {
                setSortVal(e);
                triggerRefetch();
              }}
              sortType={sortType}
              setSortType={(e) => {
                setSortType(e);
                triggerRefetch();
              }}
              itemsTotal={itemsTotal}
              pageIndex={pageIndex}
              setPageIndex={(e) => {
                setPageIndex(e);
                triggerRefetch(e);
              }}
              pageSizes={[20, 50]}
              pageSize={pageSize}
              setPageSize={(e) => {
                setPageSize(e);
                triggerRefetch();
              }}
            />
          </div>
        </TableWrapper>
      </div>
      {
        printSubcriptionInvoiceDialogOpen && (
          <PrintSubscriptionInvoiceDialog
            open={printSubcriptionInvoiceDialogOpen}
            setOpen={setPintSubcriptionInvoiceDialogOpen}
            data={printSubcriptionInvoiceData}
          />
        )
      }
    </div>
  );
};

export default SubscriptionPastInvoices;
