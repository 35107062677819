// the library has bug when taking parameter to transform in array item
var objectMapper = require('object-mapper');

export const getAllAddressesMapper = (data: any) => objectMapper(data, map);

const map = {
    "[].id": "[].id",
    "[].nickname": "[].nickname",
    "[].name": "[].name",
    "[].companyName": "[].companyName",
    "[].streetAddress": "[].streetAddress",
    "[].streetAddress2": "[].streetAddress2",
    "[].city": "[].city",
    "[].country": "[].country",
    "[].countryCode": "[].countryCode",
    "[].state": "[].state",
    "[].stateCode": "[].stateCode",
    "[].postalCode": "[].postalCode",
    "[].email": "[].email",
    "[].phone": "[].phone",
    "[].isDefault": "[].isDefault",
    "[].isResidence": "[].isResidence",
    "[].isSave":"[].isSave"
}