export enum MarketplaceName {
    amazonca = "Amazon.ca",
    amazoncom = "Amazon.com",
    amazonmx = "Amazon.com.mx",
    ebay = "Ebay",
    newegg = "Newegg",
    neweggcanada = "Newegg.ca",
    neweggcom = "Newegg.com",
    neweggbusiness = "Neweggbusiness.com",
    shopify = "Shopify",
    etsy = "ETSY",
    woocommerce = "WOOCOMMERCE",
    wix = "Wix.com",
    stripe = "Stripe",
    paypal = "PayPal",
    bigcommerce = "Bigcommerce",
    square = "Square",
    wish = "Wish",
    magento = "Magento",
    bonanza = "Bonanza",
    ecwid = "Ecwid",
    walmart = "Walmart",
    default = "",
    shipvista = "Shipvista"
}

export const storeNames = [
    MarketplaceName.amazonca,
    MarketplaceName.amazoncom,
    MarketplaceName.amazonmx,
    MarketplaceName.ebay,
    MarketplaceName.newegg,
    MarketplaceName.neweggcanada,
    MarketplaceName.neweggcom,
    MarketplaceName.neweggbusiness,
    MarketplaceName.shopify,
    MarketplaceName.etsy,
    MarketplaceName.woocommerce,
    MarketplaceName.wix,
    MarketplaceName.stripe,
    MarketplaceName.paypal,
    MarketplaceName.bigcommerce,
    MarketplaceName.square,
    MarketplaceName.wish,
    MarketplaceName.magento,
    MarketplaceName.bonanza,
    MarketplaceName.ecwid,
    MarketplaceName.default,
    MarketplaceName.walmart,
    MarketplaceName.shipvista
]

export const newEggStores = {
    newEggCA: "Newegg.ca",
    newEggUSA: "Newegg.com",
    newEggBusiness: "Neweggbusiness.com"
}