import moment from "moment";

import {
  getAccessToken,
  getExpireAt,
} from "../functionUtilities/getTokenAndExpireTime";
import { tokenCookieName } from "../environment/env_dev";

export const isAccessTokenExpired = () => {
  const accessToken = getAccessToken(tokenCookieName);
  const accessTokenExpiryDate = getExpireAt(tokenCookieName);

  if (!accessToken || !accessTokenExpiryDate) {
    return true;
  }

  const now = moment();
  const expiredDate = moment(accessTokenExpiryDate);

  return expiredDate < now;
};

export default isAccessTokenExpired;
