import { ReferralProgramInfoActions, ReferralProgramInfoState } from '../@types/referralProgramInfo';

export const fetchingReferralInfoData = () => dispatch => {
    return  dispatch({ type: ReferralProgramInfoActions.FETCH_REFERRAL_INFO_DATA });
}

export const loadReferralInfoData = (data: Partial<ReferralProgramInfoState>) => dispatch => {
    return  dispatch({ type: ReferralProgramInfoActions.LOAD_REFERRAL_INFO_DATA, payload: data });
}

export const referralInfoDataLoading = (isLoading: boolean) => dispatch => {
    return  dispatch({ type: ReferralProgramInfoActions.REFERRAL_INFO_DATA_LOADING, payload: isLoading });
}

export const clearReferralInfoData = () => dispatch => {
    return  dispatch({ type: ReferralProgramInfoActions.CLEAR_REFERRAL_INFO_DATA });
}

export const notifyRefreshReferralAlerts = () => dispatch => {
    return  dispatch({ type: ReferralProgramInfoActions.NOTIFY_REFRESH_REFERRAL_ALERTS });
}