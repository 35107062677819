import { AlertAction, AlertState, AlertActions } from "../@types/alert";

const initialState: AlertState = {
    isAlertBarOpen: false,
    alertMsg: "",
    isSuccess: false,
    custom: ''
};

export function alertReducer(
    state = initialState,
    action: AlertAction
): AlertState {
    switch (action.type) {
        case AlertActions.OPEN_ALERT_BAR:
            return { ...state, isAlertBarOpen: true, alertMsg: action.payload, isSuccess: action.payload2, custom: action.payload3 }
        case AlertActions.CLOSE_ALERT_BAR:
            return { ...state, isAlertBarOpen: false }
        default:
            return state;
    }
}