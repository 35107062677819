import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      maxWidth: 200,
      fontSize: '0.9rem',
      lineHeight: '1.5',
      textAlign: 'center',
    },
    helpTooltip: {
      fontSize: '1rem',
      cursor: 'pointer',
    },
  }),
);

const TooltipContent = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.tooltip}>
      {children}
    </div>
  );
};

const HelpTooltip = ({ message }) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      title={(
        <TooltipContent>
          {message}
        </TooltipContent>
      )}
    >
      <HelpOutline
        color="primary"
        className={classes.helpTooltip}
      />
    </Tooltip>
  );
};

export default HelpTooltip;
