import { OPEN_LOGIN_DIALOG } from "../actions/openLoginAction";

export const openLoginDialogStateReducer=(state=false,action)=>{
    switch(action.type){
        case OPEN_LOGIN_DIALOG:{
            return action.payload
        }
        default:
            return state
    } 
}