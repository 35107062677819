import React, { forwardRef } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { APP_COLOR } from "../../environment/env_dev";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: APP_COLOR.appLightBlue,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: any;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ color: "white" }} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: "0px !important",
  },
}))(MuiDialogContent);

interface Props {
  open: boolean;
  children: any;
  title?: string;
  maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl";
  disableFullWidth?: boolean;
  disableEnforceFocus?: boolean;
  setOpen: (value: boolean) => void;
  dialogId: string;
  closeDisabled?: boolean;
  overFlowOption?: any;
  marginOption?: any;
  disableBackdropAndEsc?: boolean;
}

const FixedDialog = (
  {
    open,
    setOpen,
    maxWidth,
    disableFullWidth,
    title,
    children,
    disableEnforceFocus,
    dialogId,
    closeDisabled,
    overFlowOption,
    marginOption,
    disableBackdropAndEsc,
  }: Props,
  ref
) => {
  const handleClose = (event: any, reason: any) => {
    if (
      disableBackdropAndEsc &&
      reason &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    )
      return;

    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={!disableFullWidth}
      maxWidth={maxWidth}
      onClose={closeDisabled ? null : handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableEnforceFocus={disableEnforceFocus}
      PaperProps={{
        style: {
          overflowY: overFlowOption,
          marginTop: marginOption,
        },
      }}
    >
      {title !== "" && (
        <DialogTitle id={dialogId} onClose={closeDisabled ? null : handleClose}>
          {title}
        </DialogTitle>
      )}

      <DialogContent dividers ref={ref}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(FixedDialog);
