import { PdfParameters } from "./../@type/PDFTypes";
import { mergePDFType } from "../@type/PDFTypes";
import { createShipmentReqMapper } from "../mappers/CreateShipment/createShipmentReqMapper";
import {
  convertAndExtractDates,
  DATE_FILTER_FORMAT,
  DATE_FORMAT,
} from "../functionUtilities/formatDateFilter";
import shipvistaAPI from "./shipvistaAPI";

export const getShipmentService = (
  pageIndex: number = 1,
  pageSize: number = 20,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  dateFilter?: string,
  carriers?: Array<string>,
  carrierAccount?: string,
  shipmentType?: string,
  paymentMethods?: Array<string>,
  statuses?: Array<string>,
  insureType?: Array<string>
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });
  params.push({ key: "Limit", value: pageSize });

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }
  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }
  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }
  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }

  if (dateFilter) {
    let startDate = "";
    let endDate = "";
    if (dateFilter) {
      let startDate = "";
      let endDate = "";

      if (dateFilter.includes("-")) {
        const arrDates = dateFilter.split(" - ");
        if (dateFilter.includes("-")) {
          const arrDates = dateFilter.split(" - ");

          startDate = arrDates[0].replaceAll("/", "-");
          endDate = arrDates[1].replaceAll("/", "-");
        } else {
          startDate = endDate = dateFilter.replaceAll("/", "-");
        }
        startDate = arrDates[0].replaceAll("/", "-");
        endDate = arrDates[1].replaceAll("/", "-");
      } else {
        startDate = endDate = dateFilter.replaceAll("/", "-");
      }

      params.push({ key: "MinDate", value: startDate });
      params.push({ key: "MaxDate", value: endDate });
    }
    params.push({ key: "MinDate", value: startDate });
    params.push({ key: "MaxDate", value: endDate });
  }

  if (carriers) {
    for (let i = 0, ii = carriers.length; i < ii; i += 1) {
      params.push({ key: "Carrier", value: carriers[i] });
    }
  }
  if (insureType) {
    for (let i = 0, ii = insureType.length; i < ii; i += 1) {
      params.push({ key: "InsuranceType", value: insureType[i] });
    }
  }
  if (carrierAccount) {
    params.push({ key: "CarrierAccount", value: carrierAccount });
  }
  if (carriers) {
    for (let i = 0, ii = carriers.length; i < ii; i += 1) {
      params.push({ key: "Carrier", value: carriers[i] });
    }
  }
  if (insureType) {
    for (let i = 0, ii = insureType.length; i < ii; i += 1) {
      params.push({ key: "InsuranceType", value: insureType[i] });
    }
  }
  if (carrierAccount) {
    params.push({ key: "CarrierAccount", value: carrierAccount });
  }

  if (shipmentType) {
    params.push({ key: "ShipmentType", value: shipmentType });
  }
  if (shipmentType) {
    params.push({ key: "ShipmentType", value: shipmentType });
  }

  if (paymentMethods) {
    for (let i = 0, ii = paymentMethods.length; i < ii; i += 1) {
      params.push({ key: "PaymentMethod", value: paymentMethods[i] });
    }
  }
  if (paymentMethods) {
    for (let i = 0, ii = paymentMethods.length; i < ii; i += 1) {
      params.push({ key: "PaymentMethod", value: paymentMethods[i] });
    }
  }

  if (statuses) {
    for (let i = 0, ii = statuses.length; i < ii; i += 1) {
      params.push({ key: "Status", value: statuses[i] });
    }
  }
  if (statuses) {
    for (let i = 0, ii = statuses.length; i < ii; i += 1) {
      params.push({ key: "Status", value: statuses[i] });
    }
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;
  return shipvistaAPI.get(`/Shipments${paramString}`);
};

export const getShippingAccounts = () =>
  shipvistaAPI.get(`/ShippingCarrierConnect/getshippingaccounts`);

export const refundService = (shipmentHId: string, emailid: string) =>
  shipvistaAPI.post(
    `/Shipments/refund?shipmentHistoryId=${parseInt(
      shipmentHId
    )}&email=${emailid}`,
    null
  );

export const getUserDetails = () => shipvistaAPI.get(`/user`);

export const createShipmentService = (shipment: any, isLTL: boolean) => {
  const createShipmentRequest = createShipmentReqMapper(shipment, isLTL);

  if (isLTL) {
    return shipvistaAPI.post(
      `/Shipments/CreateFreightShipment`,
      createShipmentRequest
    );
  }

  return shipvistaAPI.post(`/Shipments`, createShipmentRequest);
  // const createShipmentRequest = createShipmentReqMapper(shipment, isLTL);
  // return shipvistaAPI.post(`/Shipments`, createShipmentRequest);
};

export const getFulfillments = () => shipvistaAPI.get(`/fulfillment`);

export const getTrackingDetails = (data: any) =>
  shipvistaAPI.post(`/Shipments/GetTrackingDetails`, data);

export const cancelFulfillment = (data: any) => {
  const request = data.map((d) => {
    return {
      id: d.id,
      status: "canceled",
    };
  });

  return shipvistaAPI.put(`/fulfillment`, request);
};

export const getFulfillmentService = (
  status: string[],
  pageIndex: number,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  countries?: string[],
  dateFilter?: string,
  pageSize: number = 20
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });
  params.push({ key: "Limit", value: pageSize });

  for (let i = 0, ii = status.length; i < ii; i += 1) {
    params.push({ key: "Status", value: status[i] });
  }
  for (let i = 0, ii = status.length; i < ii; i += 1) {
    params.push({ key: "Status", value: status[i] });
  }

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }
  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }
  if (searchText) {
    params.push({ key: "SearchText", value: searchText.trim() });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }
  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  for (let i = 0, ii = countries?.length ?? 0; i < ii; i += 1) {
    params.push({ key: "Country", value: countries[i] });
  }
  for (let i = 0, ii = countries?.length ?? 0; i < ii; i += 1) {
    params.push({ key: "Country", value: countries[i] });
  }

  if (dateFilter) {
    let startDate = "";
    let endDate = "";
    if (dateFilter) {
      let startDate = "";
      let endDate = "";

      if (dateFilter.includes("-")) {
        const arrDates = dateFilter.split(" - ");
        if (dateFilter.includes("-")) {
          const arrDates = dateFilter.split(" - ");

          startDate = arrDates[0].replaceAll("/", "-");
          endDate = arrDates[1].replaceAll("/", "-");
        } else {
          startDate = endDate = dateFilter.replaceAll("/", "-");
        }
        startDate = arrDates[0].replaceAll("/", "-");
        endDate = arrDates[1].replaceAll("/", "-");
      } else {
        startDate = endDate = dateFilter.replaceAll("/", "-");
      }

      params.push({ key: "MinDate", value: startDate });
      params.push({ key: "MaxDate", value: endDate });
    }
    params.push({ key: "MinDate", value: startDate });
    params.push({ key: "MaxDate", value: endDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/fulfillment${paramString}`);
};

export const getOpenShipments = (
  pageIndex: number = 1,
  pageSize: number = 20,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  carrier?: "CanadaPost" | "FedEx" | "Canpar" | null,
  dateFilter?: string
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });
  params.push({ key: "Limit", value: pageSize });

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }
  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  if (carrier) {
    params.push({ key: "Carrier", value: carrier });
  }
  if (carrier) {
    params.push({ key: "Carrier", value: carrier });
  }

  if (dateFilter) {
    let startDate = "";
    if (dateFilter) {
      let startDate = "";

      if (dateFilter.includes("-")) {
        const arrDates = dateFilter.split(" - ");
        if (dateFilter.includes("-")) {
          const arrDates = dateFilter.split(" - ");

          startDate = arrDates[0].replaceAll("/", "-");
        } else {
          startDate = dateFilter.replaceAll("/", "-");
        }
        startDate = arrDates[0].replaceAll("/", "-");
      } else {
        startDate = dateFilter.replaceAll("/", "-");
      }

      params.push({ key: "Date", value: startDate });
    }
    params.push({ key: "Date", value: startDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/Shipments/GetOpenShipments${paramString}`);
};

export const getClosedShipments = (
  pageIndex: number = 1,
  pageSize: number = 20,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  carrier?: "CanadaPost" | "FedEx" | "Canpar" | null,
  shipDateFilter?: string,
  closeDateFilter?: string
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });
  params.push({ key: "Limit", value: pageSize });

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }
  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }
  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  if (carrier) {
    params.push({ key: "Carrier", value: carrier });
  }
  if (carrier) {
    params.push({ key: "Carrier", value: carrier });
  }

  if (shipDateFilter) {
    let startDate = "";
    let endDate = "";
    if (shipDateFilter) {
      let startDate = "";
      let endDate = "";

      if (shipDateFilter.includes("-")) {
        const arrDates = shipDateFilter.split(" - ");
        if (shipDateFilter.includes("-")) {
          const arrDates = shipDateFilter.split(" - ");

          startDate = arrDates[0].replaceAll("/", "-");
          endDate = arrDates[1].replaceAll("/", "-");
        } else {
          startDate = endDate = shipDateFilter.replaceAll("/", "-");
        }
        startDate = arrDates[0].replaceAll("/", "-");
        endDate = arrDates[1].replaceAll("/", "-");
      } else {
        startDate = endDate = shipDateFilter.replaceAll("/", "-");
      }

      params.push({ key: "ShipMinDate", value: startDate });
      params.push({ key: "ShipMaxDate", value: endDate });
    }
    params.push({ key: "ShipMinDate", value: startDate });
    params.push({ key: "ShipMaxDate", value: endDate });
  }

  if (closeDateFilter) {
    let startDate = "";
    let endDate = "";
    if (closeDateFilter) {
      let startDate = "";
      let endDate = "";

      if (closeDateFilter.includes("-")) {
        const arrDates = closeDateFilter.split(" - ");
        if (closeDateFilter.includes("-")) {
          const arrDates = closeDateFilter.split(" - ");

          startDate = arrDates[0].replaceAll("/", "-");
          endDate = arrDates[1].replaceAll("/", "-");
        } else {
          startDate = endDate = closeDateFilter.replaceAll("/", "-");
        }
        startDate = arrDates[0].replaceAll("/", "-");
        endDate = arrDates[1].replaceAll("/", "-");
      } else {
        startDate = endDate = closeDateFilter.replaceAll("/", "-");
      }

      params.push({ key: "ClosedMinDate", value: startDate });
      params.push({ key: "ClosedMaxDate", value: endDate });
    }
    params.push({ key: "ClosedMinDate", value: startDate });
    params.push({ key: "ClosedMaxDate", value: endDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/Shipments/GetCloseShipments${paramString}`);
};

export const getOpenShipmentsCount = (dateFilter: string) => {
  const params = [];

  if (dateFilter) {
    let startDate = "";
    if (dateFilter) {
      let startDate = "";

      if (dateFilter.includes("-")) {
        const arrDates = dateFilter.split(" - ");
        if (dateFilter.includes("-")) {
          const arrDates = dateFilter.split(" - ");

          startDate = arrDates[0].replaceAll("/", "-");
        } else {
          startDate = dateFilter.replaceAll("/", "-");
        }
        startDate = arrDates[0].replaceAll("/", "-");
      } else {
        startDate = dateFilter.replaceAll("/", "-");
      }

      params.push({ key: "Date", value: startDate });
    }
    params.push({ key: "Date", value: startDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/Shipments/GetOpenShipmentsCount${paramString}`);
};

/**
 * Accepts a dateFiler in the form of 'DATE_FILTER_FORMAT' or 'DATE_FILTER_FORMAT - DATE_FILTER_FORMAT' (one or two dates)
 * and converts them to two dates (startDate and endDate) in the format of DATE_FORMAT (.Net date format) before sending a
 * request to get the count of open shipments grouped by carrier and ship date.
 * and converts them to two dates (startDate and endDate) in the format of DATE_FORMAT (.Net date format) before sending a
 * request to get the count of open shipments grouped by carrier and ship date.
 * The ship date will be in the range of the two dates provided or on the singular date.
 */

export const getOpenShipmentsCountDateRange = (dateFilter: string) => {
  const params = [];

  if (dateFilter) {
    const [startDate, endDate] = convertAndExtractDates(
      dateFilter,
      DATE_FILTER_FORMAT,
      DATE_FORMAT
    );
    if (dateFilter) {
      const [startDate, endDate] = convertAndExtractDates(
        dateFilter,
        DATE_FILTER_FORMAT,
        DATE_FORMAT
      );

      params.push({ key: "StartDate", value: startDate });
      params.push({ key: "EndDate", value: endDate });
    }
    params.push({ key: "StartDate", value: startDate });
    params.push({ key: "EndDate", value: endDate });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(
    `/Shipments/GetOpenShipmentsCountGroupedByDate${paramString}`
  );
};

export const closeShipments = (
  carrier?: string,
  dateFilter?: string,
  isCloseAll: boolean = false,
  shipmentIds: Array<string> = []
) => {
  const params = [];

  if (carrier) {
    params.push({ key: "Carrier", value: carrier });
  }

  if (dateFilter) {
    let startDate = "";

    if (dateFilter.includes("-")) {
      const arrDates = dateFilter.split(" - ");

      startDate = arrDates[0].replaceAll("/", "-");
    } else {
      startDate = dateFilter.replaceAll("/", "-");
    }

    params.push({ key: "Date", value: startDate });
  }

  if (isCloseAll) {
    params.push({ key: "IsCloseAll", value: isCloseAll });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.put(
    `/Shipments/CloseShipments${paramString}`,
    isCloseAll ? [] : shipmentIds
  );
};

export const getMergedPDF = (ids, pdftype: mergePDFType) => {
  var PDFObject: PdfParameters[] = [];
  ids.forEach((element) => {
    if (element) {
      if (typeof element === "string") {
        PDFObject.push({
          filename: element,
          isCancelled: false,
        });
      } else {
        PDFObject.push(element);
      }
    }
  });
  return shipvistaAPI.post(
    `/Shipments/GetMergedPDF?type=${pdftype}`,
    PDFObject
  );
};

export const checkIfShipmentsWillPassLimit = (numberOfShipments: number) => {
  return shipvistaAPI.get(
    `/Shipments/CheckIfShipmentsWillPassLimit?numberOfShipments=${numberOfShipments}`
  );
};
