import React from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(() =>
    createStyles({
        checkboxDiv: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        checkboxTxt: {

        },
        optCheckBox: {
            padding: 5,
            paddingRight: 5
        },
        optCheckBoxBold: {
            padding: 0,
            paddingRight: 5
        },
        optCheckBoxTxtBold: {
            fontWeight: 600
        }
    }),
);

interface Props {
    text: string;
    isChecked: boolean;
    disabled?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isBold?: boolean;
    textLink?: string;
    hrefLink?: any;
    optionalStyle?: any;
}

export default function AppCheckboxRight(props: Props) {
    const { optCheckBox, checkboxTxt, checkboxDiv, optCheckBoxBold, optCheckBoxTxtBold } = useStyles();
    const { text, isChecked, handleChange, disabled, isBold, textLink, hrefLink, optionalStyle } = props;

    return (
        <div className={checkboxDiv}>
            <Checkbox
                className={isBold ? optCheckBoxBold : optCheckBox}
                checked={isChecked}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'appCheckbox' }}
                disabled={disabled}
            />
            {
                textLink ?
                    <div className={isBold ? optCheckBoxTxtBold : checkboxTxt} style={optionalStyle}>
                        {text}
                        <a href={hrefLink} target="_blank" rel="noreferrer">
                            {textLink}
                        </a>
                    </div> :
                    <div className={isBold ? optCheckBoxTxtBold : checkboxTxt}>{text}</div>
            }
        </div>
    );
}