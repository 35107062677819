import { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";

import FixedDialogWrapper from "../../utilities/FixedDialogWrapper";
import { useAlertActions, useUserActions } from "../../../redux/actions";
import { claimReferralBonus } from "../../../apis/loginService";

interface Props {
  open: boolean;
  setOpen: (newOpen: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 10,
      maxWidth: "97%",
      margin: "5px 10px",
      position: "relative",
    },
    container: {
      maxWidth: "98%",
      margin: "0px auto",
    },
    image: {
      width: "60px",
      marginRight: "5px",
      height: "60px",
      objectFit: "contain",
      position: "absolute",
      top: "10px",
      left: "10px",
    },
    dialogInfo: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
    },
    dialogDesc: {
      fontSize: "0.9rem",
      color: "#444",
      fontWeight: 500,
      marginBottom: "0px",
    },
    dialogTitleWrapper: {
      margin: 0,
      padding: theme.spacing(2),
    },
    dialogTitle: {
      fontSize: "1.7rem",
      color: "#222",
      fontWeight: 700,
      textAlign: "center",
      marginTop: "15px",
      marginBottom: "35px",
    },
    divider: {
      color: "#444",
    },
    dialogBody: {
      marginTop: "10px",
    },
    buttonSection: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      margin: "5px 5px 10px 5px",
    },
    button: {
      color: "#fff",
      backgroundColor: "#1d3557", //"#03827A",
      fontSize: "1rem",
      fontWeight: 500,

      "&:hover": {
        color: "#fff",
        backgroundColor: "#1d3557f2", //"#03827Af2",
      },
      "&:disabled": {
        opacity: "0.7",
      },
    },
    loader: {
      color: "#14213d",
      margin: "0px 5px",
    },
    loaderSection: {
      display: "flex",
      justifyContent: "center",
      margin: "10px 5px",
      minHeight: "30px",
      minWidth: "10px",
    },
    buttonText: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const ReferralBonusClaimDialog = ({ open, setOpen }: Props) => {
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);
  const { openAlertBar } = useAlertActions();
  const { fetchUser } = useUserActions();

  const handleChooseReferralCurrency = async (
    currency: "CAD" | "USD",
    status: number
  ) => {
    if (isSubmitting) {
      return;
    }
    let isSuccess = false;
    setSubmitting(true);
    try {
      const { data } = await claimReferralBonus({
        currency,
        claimType: status,
      });

      if (data?.status) {
        openAlertBar("Successfully deposited referral bonus!", true);
        isSuccess = true;
      } else {
        openAlertBar(data.message ?? "", false);
        setOpen(false);
      }
    } catch (err) {
      openAlertBar("Error making selection for referral bonus:" + err, false);
    }

    try {
      if (isSuccess) {
        await fetchUser();
        setOpen(false);
      }
    } catch (err) {
      setOpen(false);
    }
    setSubmitting(false);
  };

  const handleDialogClose = () => {
    handleChooseReferralCurrency("CAD", 3);
    setOpen(false);
  };

  return (
    <FixedDialogWrapper
      open={open}
      setOpen={handleDialogClose}
      title="Choose Referral Bonus Deposit Type"
      maxWidth="sm"
      dialogId="referral-bonus-claim-dialog"
      disableBackdropAndEsc
    >
      <div className={classes.root}>
        <div>
          <img
            className={classes.image}
            src="/images/referral/givemoney.png"
            alt="give money"
          />
          <h2 className={classes.dialogTitle}>
            Select a Currency for your Deposit
          </h2>
          <div className={classes.dialogInfo}>
            <p className={classes.dialogDesc}>
              You have registered using a referral code. This means that you are
              eligible for a $5 SVCash reward. Please select the type of
              currency you wish to receive this bonus as:
            </p>
          </div>
        </div>

        <div className={classes.dialogBody}>
          <div className={classes.buttonSection}>
            <Button
              className={classes.button}
              onClick={() => handleChooseReferralCurrency("CAD", 2)}
              color="inherit"
              variant="contained"
              disabled={isSubmitting}
            >
              <div className={classes.buttonText}>
                <span>Canadian Dollar (CAD)</span>
                {isSubmitting && (
                  <CircularProgress
                    size={20}
                    color="inherit"
                    className={classes.loader}
                  />
                )}
              </div>
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleChooseReferralCurrency("USD", 2)}
              color="inherit"
              variant="contained"
              disabled={isSubmitting}
            >
              <div className={classes.buttonText}>
                <span>US Dollar (USD)</span>
                {isSubmitting && (
                  <CircularProgress
                    size={20}
                    color="inherit"
                    className={classes.loader}
                  />
                )}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </FixedDialogWrapper>
  );
};

export default ReferralBonusClaimDialog;
