import React from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import moment from "moment";
import { Order } from "../../../../../../@type/Orders/Order";
import DraggableDialog from "../../../../../utilities/DraggableDialog";

const useStylesMaterialUI = makeStyles(() =>
    createStyles({
        PDFRoot: {
            width: "100%",
            height: "80vh"
        }
    }),
);

const stylesPDF = StyleSheet.create({
    page: {
        paddingVertical: 35,
        paddingHorizontal: 35,
    },
    headerTxt: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        color: "white",
        backgroundColor: "grey",
        padding: 2
    },
    senderInfo: {
        marginVertical: 10
    },
    senderName: {
        fontSize: 12,
        fontFamily: 'Open Sans',
        fontWeight: 600
    },
    text: {
        fontSize: 9,
        fontFamily: 'Open Sans',
    },
    textBold: {
        fontSize: 9,
        fontFamily: 'Open Sans',
        fontWeight: 600
    },
    viewOuterDark: {
        paddingTop: 5,
        paddingBottom: 10,
        borderColor: "black",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#F8F8F8"
    },
    viewOuter: {
        paddingTop: 5,
        paddingBottom: 10,
        borderColor: "black",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    shipToOrderViewOuter: {
        flexDirection: "row",
        marginBottom: 10
    },
    shipToView: {
        flexGrow: 1
    },
    orderViewOuter: {
        flexGrow: 1
    },
    notesView: {
        flexGrow: .5
    },
    orderView: {
        display: "flex",
        flexDirection: "row"
    },
    table: {
        display: "table",
        width: "100%",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol1: {
        width: "20%",
    },
    tableCol2: {
        width: "55%",
    },
    tableCol3: {
        width: "10%",
    },
    tableCol4: {
        width: "5%",
    },
    tableCol5: {
        width: "10%",
    },
    tableHeaderCell: {
        padding: 3,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 9,
        borderColor: "darkgrey",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    centerTableHeaderCell: {
        padding: 3,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 9,
        borderColor: "darkgrey",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "center"
    },
    tableCell: {
        paddingHorizontal: 3,
        paddingVertical: 5,
        fontFamily: 'Open Sans',
        fontSize: 9,
    },
    centerTableCell: {
        paddingHorizontal: 3,
        paddingVertical: 5,
        fontFamily: 'Open Sans',
        fontSize: 9,
        textAlign: "center"
    },
    textCommentBold: {
        fontFamily: 'Open Sans',
        fontSize: 7,
        fontWeight: 600,
        textDecoration: "underline"
    },
    textComment: {
        fontFamily: 'Open Sans',
        fontSize: 7,
    },
    commentView: {
        flexDirection: "row",
        paddingTop: 5
    }
});

// register font
Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
});

// break words callback function
Font.registerHyphenationCallback(word => {
    const middle = Math.floor(word.length / 2);
    const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

    return parts;
});
interface Props {
    open: boolean;
    setOpen: (value: React.SetStateAction<boolean>) => void;
    orders: Order[];
}

export default function PrintOrderSummary(props: Props) {
    const classes = useStylesMaterialUI();

    const { open, setOpen, orders } = props;

    return (
        <DraggableDialog
            maxWidth={"lg"}
            title={"Print Order Summary"}
            open={open}
            setOpen={setOpen}
            dialogId="printOrderSummary">
            <PDFViewer className={classes.PDFRoot}>
                <Document title={`Order Summary`}>
                    <Page size="A4" style={stylesPDF.page}>
                        <Text style={stylesPDF.headerTxt}>Order Summary Report</Text>
                        {orders.map((order, i) =>
                            <View style={i % 2 === 0 ? stylesPDF.viewOuterDark : stylesPDF.viewOuter} key={`orderSummaryReport${i}`}>
                                <View style={stylesPDF.shipToOrderViewOuter}>
                                    <View style={stylesPDF.shipToView}>
                                        <Text style={stylesPDF.textBold}>Ship To</Text>
                                        <Text style={stylesPDF.text}>{order?.shipTo?.name}</Text>
                                        <Text style={stylesPDF.text}>{order?.shipTo?.streetAddress}</Text>
                                        <Text style={stylesPDF.text}>{`${order?.shipTo?.city}, ${order?.shipTo?.stateCode} ${order?.shipTo?.postalCode}, ${order?.shipTo?.countryCode}`}</Text>
                                    </View>
                                    <View style={stylesPDF.orderViewOuter}>
                                        <View style={stylesPDF.orderView}>
                                            <Text style={stylesPDF.textBold}>Order#: </Text>
                                            <Text style={stylesPDF.text}>{order.orderNo}</Text>
                                        </View>
                                        <View style={stylesPDF.orderView}>
                                            <Text style={stylesPDF.textBold}>Store: </Text>
                                            <Text style={stylesPDF.text}>{order.store}</Text>
                                        </View>
                                        <View style={stylesPDF.orderView}>
                                            <Text style={stylesPDF.textBold}>Date Paid: </Text>
                                            <Text style={stylesPDF.text}>{moment(order.orderDate).format("YYYY-MM-DD")}</Text>
                                        </View>
                                        <View style={stylesPDF.orderView}>
                                            <Text style={stylesPDF.textBold}>Amount Paid: </Text>
                                            <Text style={stylesPDF.text}>{`${order.orderTotal.currency}$ ${order.orderTotal.amount}`}</Text>
                                        </View>
                                    </View>
                                    <View style={stylesPDF.notesView}>
                                        <Text style={stylesPDF.textBold}>Internal Notes: </Text>
                                        <Text style={stylesPDF.text}>{order.internalNotes.message ? order.internalNotes.message : "None"}</Text>
                                    </View>
                                    <View style={stylesPDF.notesView}>
                                        <Text style={stylesPDF.textBold}>Buyer Comments: </Text>
                                        <Text style={stylesPDF.text}>{order.buyerNotes}</Text>
                                    </View>
                                </View>
                                <View style={stylesPDF.table}>
                                    {/* TableHeader */}
                                    <View style={stylesPDF.tableRow}>
                                        <View style={stylesPDF.tableCol1}>
                                            <Text style={stylesPDF.tableHeaderCell}>Item SKU</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol2}>
                                            <Text style={stylesPDF.tableHeaderCell}>Title</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol3}>
                                            <Text style={stylesPDF.centerTableHeaderCell}>Unit Price</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol4}>
                                            <Text style={stylesPDF.centerTableHeaderCell}>Qty</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol5}>
                                            <Text style={stylesPDF.centerTableHeaderCell}>Total</Text>
                                        </View>
                                    </View>
                                    {/* TableContent */}
                                    {order.orderItems.map((item, i) =>
                                        <View key={`orderSummary-${order.orderNo}-${i}`} style={stylesPDF.tableRow}>
                                            <View style={stylesPDF.tableCol1}>
                                                <Text style={stylesPDF.tableCell}>{item.itemSKU}</Text>
                                            </View>
                                            <View style={stylesPDF.tableCol2}>
                                                <View style={stylesPDF.tableCell}>
                                                    <Text style={stylesPDF.text}>{item.itemName}</Text>
                                                </View>
                                            </View>
                                            <View style={stylesPDF.tableCol3}>
                                                <Text style={stylesPDF.centerTableCell}>{item.price}</Text>
                                            </View>
                                            <View style={stylesPDF.tableCol4}>
                                                <Text style={stylesPDF.centerTableCell}>{item.quantity}</Text>
                                            </View>
                                            <View style={stylesPDF.tableCol5}>
                                                <Text style={stylesPDF.centerTableCell}>{item.price * item.quantity}</Text>
                                            </View>
                                        </View>
                                    )}
                                </View>
                            </View>
                        )}
                    </Page>
                </Document>
            </PDFViewer>
            {/* <Divider />
            <DialogActions>
                <Button autoFocus color="primary">
                    Save as PDF
                </Button>
                <Button autoFocus color="primary">
                    Print
                </Button>
            </DialogActions> */}
        </DraggableDialog>
    );
}