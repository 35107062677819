import countriesData from "../datajsons/countriesData.json";

export const getCountryCode = (countryName: string) =>
  countriesData.find((country) => country.name === countryName)
    ? countriesData.find((country) => country.name === countryName).code
    : "";

export const getCountry = (countryCode: string) =>
  countriesData.find((country) => country.code === countryCode)
    ? countriesData.find((country) => country.code === countryCode).name
    : "";
