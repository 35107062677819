import { Address } from "../../../@type/Orders/Address";
import { Order, OrderFilterData } from "../../../@type/Orders/Order";

type LOAD_ORDERS_DATA = "LOAD_ORDERS_DATA";
type SET_ORDER_DETAILS_ORDER = "SET_ORDER_DETAILS_ORDER";
type SET_ORDERS_TABLE_LOADING = "SET_ORDERS_TABLE_LOADING";
type SET_SHIP_FROM_ADDRESSES = "SET_SHIP_FROM_ADDRESSES";
type SET_ORDERS_PAGE = "SET_ORDERS_PAGE";
type SET_ORDERS_FILTER = "SET_ORDERS_FILTER";
type REMOVE_ORDER = "REMOVE_ORDER";

type actionType = {
    LOAD_ORDERS_DATA: LOAD_ORDERS_DATA;
    SET_ORDER_DETAILS_ORDER: SET_ORDER_DETAILS_ORDER;
    SET_ORDERS_TABLE_LOADING: SET_ORDERS_TABLE_LOADING;
    SET_SHIP_FROM_ADDRESSES: SET_SHIP_FROM_ADDRESSES;
    SET_ORDERS_PAGE: SET_ORDERS_PAGE;
    SET_ORDERS_FILTER: SET_ORDERS_FILTER;
    REMOVE_ORDER:REMOVE_ORDER;
};

export const OrdersActions: actionType = {
    LOAD_ORDERS_DATA: "LOAD_ORDERS_DATA",
    SET_ORDER_DETAILS_ORDER: "SET_ORDER_DETAILS_ORDER",
    SET_ORDERS_TABLE_LOADING: "SET_ORDERS_TABLE_LOADING",
    SET_SHIP_FROM_ADDRESSES: "SET_SHIP_FROM_ADDRESSES",
    SET_ORDERS_PAGE: 'SET_ORDERS_PAGE',
    SET_ORDERS_FILTER: 'SET_ORDERS_FILTER',
    REMOVE_ORDER:'REMOVE_ORDER'
};

export type OrdersAction =
    | { type: LOAD_ORDERS_DATA, payload: Order[] }
    | { type: SET_ORDER_DETAILS_ORDER, payload: Order }
    | { type: SET_ORDERS_TABLE_LOADING, payload: "Loading" | "Failed" | "Success" | "NoOrder" | "NoOrderShipped" | "NoOrderCanceled" }
    | { type: SET_SHIP_FROM_ADDRESSES, payload: Address[] }
    | { type: SET_ORDERS_PAGE, payload: "awaitingShipment" | "shipped" | "canceled"|"onHold"|"awaitingPayment" }
    | { type: SET_ORDERS_FILTER, payload:OrderFilterData }
    | { type: REMOVE_ORDER, payload:number }
