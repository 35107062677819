import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useDataTableUseStyles = makeStyles(() => createStyles({
    table: {
        width: "100%",
        "& th": {
            padding: 10,
            color: "#565656",
            fontWeight:600,
            "&:last-child": {
                textAlign: "center"
            }
        },
        "& td": {
            padding: 10,
            borderLeft: "none",
            borderRight: "none",
            border: "1px solid #e9ecef"
        },
        border: "1px solid #e9ecef",
        borderCollapse: "collapse"
    },
    head: {
        backgroundColor: "#e9ecef"
    },
    startHead: {
        textAlign: "start"
    }
}));