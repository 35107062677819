import { loadUserSubscription, getAllSubscriptionPlans } from "../actions/userSubscriptionActions";
import { getUserSubscription } from "../../apis/subscriptionService";
import { getAllUserSubscription } from "../../apis/guestSubscriptionServices";

export const gettingUserSubscription = () => async (dispatch) => {

    try {
        const { data } = await getUserSubscription();

        if (data.status) {
            dispatch(loadUserSubscription(data))
        }

    } catch (err) {
        console.log(err);
    }
}

export const gettingAllSubscriptionPlans = () => async (dispatch) => {

    try {
        const { data } = await getAllUserSubscription();

        if (data.status) {
            dispatch(getAllSubscriptionPlans(data))
        }

    } catch (err) {
        console.log(err);
    }
}
