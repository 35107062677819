import { ReferralProgramInfoState, ReferralProgramInfoAction, ReferralProgramInfoActions  } from "../@types/referralProgramInfo";

const initialState: ReferralProgramInfoState = {
    isLoadingInfo: false,
    info: null,
    infoError: null,
    infoIsSuccess: true,
    referralAlertsRefresh: 0,
};

export function referralProgramInfoReducer(
    state = initialState,
    action: ReferralProgramInfoAction
): ReferralProgramInfoState {
    switch (action.type) {
        case ReferralProgramInfoActions.FETCH_REFERRAL_INFO_DATA: {
            return { ...state, isLoadingInfo: true }
        }
        case ReferralProgramInfoActions.LOAD_REFERRAL_INFO_DATA: {
            const { info, infoError, infoIsSuccess } = action.payload;
            return { ...state, info, isLoadingInfo: false, infoError, infoIsSuccess }
        }
        case ReferralProgramInfoActions.REFERRAL_INFO_DATA_LOADING: {
            return { ...state, isLoadingInfo: action.payload }
        }
        case ReferralProgramInfoActions.CLEAR_REFERRAL_INFO_DATA: {
            return initialState;
        }
        case ReferralProgramInfoActions.NOTIFY_REFRESH_REFERRAL_ALERTS: {
            return { ...state, referralAlertsRefresh: state.referralAlertsRefresh + 1 }
        }
        default:
            return state;
    }
}