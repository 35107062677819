import { SET_NOTIFICATIONS, ADD_NOTIFICATION, UPDATE_NOTIFICATION, MARK_ALL_AS_READ } from '../@types/notification/notificationAction';

const initialState = {
  notifications: [],
};

export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case ADD_NOTIFICATION:
      return { ...state, notifications: [action.payload, ...state.notifications] };
    case UPDATE_NOTIFICATION:
      return { ...state, notifications: state.notifications.map((e) => { return e.id === action.payload.id ? action.payload.data : e }) };
    case MARK_ALL_AS_READ:
      return { ...state, notifications: state.notifications.map((e) => ({ ...e, isRead: true })) };
    default:
      return state;
  }
}
