export const MENU_MAP = { // remember to update the sitemap.xml file in the public folder
  LANDING_PAGE: {key: 'landing-page', route: '/', label: 'Landing Page', isProtected: false, isDynamic: false},
  LOGIN_SUCCESS: {key: 'login-success', route: '/login-success', label: 'Customer Login', isProtected: false, isDynamic: false},
  LOGIN: {key: 'login', route: '/login', label: 'Login', isProtected: false, isDynamic: false},
  REGISTER: {key: 'register', route: '/register', label: 'Register', isProtected: false, isDynamic: false},
  GUEST_SUBSCRIPTION_PAGE: {key: 'guest-subscription', route: '/pricing', label: 'Guest Subscription', isProtected: false, isDynamic: false},
  GUEST_SUBSCRIPTION_PLAN_PAGE: {key: 'guest-subscription-plan', route: '/pricing/:plan', label: 'Guest Subscription Plan', isProtected: false, isDynamic: true},
  AIR_MILES_GUEST: {key: 'air-miles-guest', route: '/airmiles', label: 'Air Miles Guest', isProtected: false, isDynamic: false},
  INSURESHIELD: {key: 'insure-shield', route: '/insureshield', label: 'InsureShield', isProtected: false, isDynamic: false},
  INSURESHIELD_TERMS: {key: 'insure-shield-terms', route: '/insureshield/terms', label: 'InsureShield Terms', isProtected: false, isDynamic: false},
  CONNECT_WITH_US: {key: 'connect-with-us', route: '/connect', label: 'Connect With Us', isProtected: false, isDynamic: false},
  VERIFY_REGISTRATION_EMAIL: {key: 'verify-email', route: '/verifyemailaddress', label: 'Verify Register Email', isProtected: false, isDynamic: false},
  ONBOARD: {key: 'quick-setup', route: '/quick-setup', label: 'Quick Setup', isProtected: true, isDynamic: false},
  DASHBOARD: {key: 'dashboard', route: '/dashboard', label: 'DashBoard', isProtected: true, isDynamic: false},
  ORDERS: {key: 'orders', route: '/orders', label: 'Orders', isProtected: true, isDynamic: false},
  ORDERS_AWAITING_PAYMENT: {key: 'awaiting-payment', route: '/orders/awaiting-payment', label: 'Awaiting Payment', isProtected: true, isDynamic: false},
  ORDERS_ON_HOLD: {key: 'on-hold', route: '/orders/on-hold', label: 'On Hold', isProtected: true, isDynamic: false},
  ORDERS_AWAITING_SHIPMENT: {key: 'awaiting-shipment', route: '/orders/awaiting-shipment', label: 'Awaiting Shipment', isProtected: true, isDynamic: false},
  ORDERS_SHIPPED: {key: 'shipped', route: '/orders/shipped', label: 'Shipped', isProtected: true, isDynamic: false},
  ORDERS_CANCELED: {key: 'canceled', route: '/orders/canceled', label: 'Canceled', isProtected: true, isDynamic: false},
  ORDERS_SEARCH: {key: 'search', route: '/orders/search', label: 'Search', isProtected: true, isDynamic: false},
  SHIPMENTS: {key: 'shipments', route: '/shipments', label: 'Shipments', isProtected: true, isDynamic: false},
  SHIPMENTS_QUICK_QUOTE: {key: 'quick-quote', route: '/shipments/quick-quote', label: 'Quick Quote', isProtected: true, isDynamic: false},
  SHIPMENTS_CREATE_SHIPMENT: {key: 'create-shipment', route: '/shipments/create-shipment', label: 'Create Shipment', isProtected: true, isDynamic: false},
  SHIPMENTS_SHIPMENT_HISTORY: {key: 'shipment-history', route: '/shipments/shipment-history', label: 'Shipment History', isProtected: true, isDynamic: false},
  SHIPMENTS_MY_PICKUPS: {key: 'my-pickups', route: '/shipments/my-pickups', label: 'My Pickups', isProtected: true, isDynamic: false},
  SHIPMENTS_FULFILLMENTS: {key: 'fulfillments', route: '/shipments/fulfillments', label: 'Fulfillments', isProtected: true, isDynamic: false},
  SHIPMENTS_END_OF_DAY: {key: 'end-of-day', route: '/shipments/end-of-day', label: 'End of Day', isProtected: true, isDynamic: false},
  SHIPMENTS_BATCH_HISTORY: {key: 'batch-history', route: '/shipments/batch-history', label: 'Batch History', isProtected: true, isDynamic: false},
  WAREHOUSING: {key: 'warehousing', route: '/warehousing', label: 'Warehousing', isProtected: true, isDynamic: false},
  WAREHOUSING_MY_PRODUCTS: {key: 'my-products', route: '/warehousing/my-products', label: 'My Products', isProtected: true, isDynamic: false},
  WAREHOUSING_WAREHOUSING_SHIPMENTS: {key: 'warehousing-shipments', route: '/warehousing/warehousing-shipments', label: 'Warehousing Shipments', isProtected: true, isDynamic: false},
  WAREHOUSING_MY_INVENTORY: {key: 'my-inventory', route: '/warehousing/my-inventory', label: 'My Inventory', isProtected: true, isDynamic: false},
  REFERRAL_PROGRAM: {key: 'referral-program', route: '/referral-program', label: 'Referral Program', isProtected: true, isDynamic: false},
  SETTINGS: {key: 'settings', route: '/settings', label: '', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT: {key: 'account', route: '', label: 'Account', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT_MY_PROFILE: {key: 'my-profile', route: '/settings/my-profile', label: 'My Profile', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT_VERIFY_EMAIL: {key: 'my-profile', route: '/settings/verify-email', label: 'Verify Email', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT_PAYMENTS: {key: 'payments', route: '/settings/payments', label: 'Payments', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT_OFFERS_AVAILABLE: {key: 'offers-available', route: '/settings/offers-available', label: 'Offers Available', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT_TRANSACTION_HISTORY: {key: 'transaction-history', route: '/settings/transaction-history', label: 'Transaction History', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT_SUBSCRIPTION: {key: 'subscription', route: '/settings/account/subscription', label: 'Subscription', isProtected: true, isDynamic: false},
  SETTINGS_ACCOUNT_AIRMILES: {key: 'airmiles', route: '/settings/account/airmiles', label: 'Airmiles Rewards', isProtected: true, isDynamic: false},
  SETTINGS_INTEGRATIONS: {key: 'integrations', route: '', label: 'Integrations', isProtected: true, isDynamic: false},
  SETTINGS_INTEGRATIONS_STORE_SETUP: {key: 'store-setup', route: '/settings/store-setup', label: 'Store Setup', isProtected: true, isDynamic: false},
  SETTINGS_INTEGRATIONS_CARRIER_SETUP: {key: 'carrier-setup', route: '/settings/carrier-setup', label: 'Carrier Setup', isProtected: true, isDynamic: false},
  SETTINGS_SHIPPING: {key: 'shipping', route: '', label: 'Shipping', isProtected: true, isDynamic: false},
  SETTINGS_SHIPPING_ADDRESS_BOOK: {key: 'address-book', route: '/settings/address-book', label: 'Address Book', isProtected: true, isDynamic: false},
  SETTINGS_SHIPPING_BOX_LIBRARY: {key: 'box-library', route: '/settings/box-library', label: 'Box Library', isProtected: true, isDynamic: false},
  SETTINGS_FEED: {key: 'feed', route: '', label: 'Feed', isProtected: true, isDynamic: false},
  SETTINGS_FEED_BULK_ORDERS_FEED: {key: 'bulk-orders-feed', route: '/settings/bulk-orders-feed', label: 'Bulk Orders Feed', isProtected: true, isDynamic: false},
  SETTINGS_FEED_BULK_AFFILIATE_USER_FEED: {key: 'bulk-affiliate-user-feed', route: '/settings/bulk-affiliate-user-feed', label: 'Bulk Affiliate User Feed', isProtected: true, isDynamic: false},
  SETTINGS_CREDIT_CARD_CONSENT: {key: 'credit-card-consent-agreement', route: '/credit-card-consent-agreement', label: 'Credit Card Consent Agreement', isProtected: false, isDynamic: false},
  SETTINGS_COMMISSION_PROGRAM_AGREEMENT: {key: 'commission-program-agreement', route: '/commission-program-agreement', label: 'Commission Program Agreement', isProtected: false, isDynamic: false},
  CUSTOMER_SUPPORT: {key: 'customer-support', route: '/customer-support', label: '', isProtected: true, isDynamic: false},
  CUSTOMER_SUPPORT_MY_TICKETS: {key: 'my-tickets', route: '/customer-support/my-tickets', label: 'My Tickets', isProtected: true, isDynamic: false},
  CUSTOMER_SUPPORT_LEARNING_CENTER: {key: 'learning-center', route: '/customer-support/learning-center', label: 'Learning Center', isProtected: true, isDynamic: false},
  PARTNERS: {key: 'partners', route: '/partners', label: 'Partners', isProtected: false, isDynamic: false},
  PARTNER: {key: 'partner', route: '/partners/:partnerId', label: 'Partner', isProtected: false, isDynamic: true},
  CARRIERS: {key: 'carriers', route: '/carriers', label: 'Carriers', isProtected: false, isDynamic: false},
  CARRIER: {key: 'carrier', route: '/carriers/:carrierName', label: 'Carrier', isProtected: false, isDynamic: true},
  PARTNER_TYPE: {key: 'partner-type', route: '/partner-type', label: 'Partner Type', isProtected: false, isDynamic: false},
  PARTNER_TYPE_V2: {key: 'partner-type-v2', route: '/partner', label: 'Partner Type', isProtected: false, isDynamic: false},
  PARTNER_TYPE_TECHNOLOGY: {key: 'partner-type-technology', route: '/partner-type/technology', label: 'Partner Type Technology', isProtected: false, isDynamic: false},
  PARTNER_TYPE_FULFILLMENT: {key: 'partner-type-fulfillment', route: '/partner/fulfillment', label: 'Partner Type Fulfillment', isProtected: false, isDynamic: false},
  BECOME_A_PARTNER: {key: 'become-a-partner', route: '/become-a-partner', label: 'Become A Partner', isProtected: false, isDynamic: false},
  BECOME_AN_AFFILIATE: {key: 'become-an-affiliate', route: '/become-an-affiliate', label: 'Become An Affiliate', isProtected: false, isDynamic: false},
  PRIVACY_POLICY: {key: 'privacy-policy', route: '/privacy-policy', label: 'Privacy Policy', isProtected: false, isDynamic: false},
  TERMS_OF_USE: {key: 'terms-of-use', route: '/terms-of-use', label: 'Terms And Conditions Of Use', isProtected: false, isDynamic: false},
  ABOUT_US: {key: 'about', route: '/about', label: 'About Us', isProtected: false, isDynamic: false},
  OPEN_ACCOUNT_OFFER: {key: 'open-account-offer', route: '/open-account-offer', label: 'What We Offer', isProtected: false, isDynamic: false},
  CONTACT_US: {key: 'contact-us', route: '/contact', label: 'Contact Us', isProtected: false, isDynamic: false},
  CONTACT_SALES: {key: 'contact-sales', route: '/contact/sales', label: 'Contact Sales', isProtected: false, isDynamic: false},
  CONTACT_SUPPORT: {key: 'contact-support', route: '/contact/support', label: 'Contact Support', isProtected: false, isDynamic: false},
  HELP_CENTER: {key: 'help-center', route: '/help', label: 'Help Center', isProtected: false, isDynamic: false},
  HELP_CENTER_PAGE: {key: 'help-center-page', route: '/help/:collectionId/:categoryId/:articleId', label: 'Help Center Page', isProtected: false, isDynamic: true},
  DEV_CENTER: {key: 'developer-center', route: '/developers', label: 'Developers Center', isProtected: false, isDynamic: false},
  DEV_OVERVIEW: {key: 'developer-overview', route: '/developers/overview', label: 'Developers Overview', isProtected: false, isDynamic: false},
  DEV_API_REF: {key: 'developer-api-references', route: '/developers/api/references', label: 'Developers API References', isProtected: false, isDynamic: false},
  DEV_API_DOCS: {key: 'developer-api-docs', route: '/developers/api/docs', label: 'Developers API Documentation', isProtected: false, isDynamic: false},
  DEV_API_STATUS: {key: 'developer-api-status', route: '/developers/api/status', label: 'Developers API Status', isProtected: false, isDynamic: false},
  DEV_BECOME_A_DEVELOPER: {key: 'developer-join', route: '/developers/become-a-developer', label: 'Become A Developer', isProtected: false, isDynamic: false},
  TRACK_MY_SHIPMENT: {key: 'track-shipment', route: '/track-shipment', label: 'Track My Shipment', isProtected: false, isDynamic: false},
  GET_INSTANT_QUOTE: {key: 'get-quote', route: '/get-quote', label: 'Get Instant Quote', isProtected: false, isDynamic: false},
  HOW_IT_WORKS: {key: 'how-it-works', route: '/how-it-works', label: 'How It Works', isProtected: false, isDynamic: false},
  DOCUMENT_TOOLKIT: {key: 'document-toolkit', route: '/document-toolkit', label: 'Document Toolkit', isProtected: false, isDynamic: false},
  SITEMAP: {key: 'sitemap', route: '/sitemap', label: 'Sitemap', isProtected: false, isDynamic: false},
  PERSONAL_INFO_FORM: {key: 'personal-info-form', route: '/personal-info-form', label: 'Personal Information Form', isProtected: false, isDynamic: false},
  CAREERS: {key: 'careers', route: '/careers', label: 'Careers', isProtected: false, isDynamic: false},
  CAREERS_JOBS: {key: 'careers-jobs', route: '/careers/jobs', label: 'Career Jobs', isProtected: false, isDynamic: false},
  CAREERS_JOBS_WITH_ID: {key: 'careers-jobs-with-id', route: '/careers/jobs/:jobId', label: 'Career Jobs With ID', isProtected: false, isDynamic: true},
  CAREERS_JOBS_APPLY: {key: 'careers-jobs-apply', route: '/careers/jobs/:jobId/apply', label: 'Career Jobs Apply', isProtected: false, isDynamic: true},
  NEWSROOM: {key: 'newsroom', route: '/media/newsroom', label: 'Newsroom', isProtected: false, isDynamic: false},
  NEWSROOM_ARTICLE: {key: 'newsroom-article', route: '/media/newsroom/:newsId', label: 'Newsroom Article', isProtected: false, isDynamic: true},
  BLOG: {key: 'blog', route: '/blog', label: 'Blog', isProtected: false, isDynamic: false},
  BLOG_ARTICLE: {key: 'blog-article', route: '/blog/:articleId', label: 'Blog Article', isProtected: false, isDynamic: true},
  INFO: {key: 'info', route: '/info', label: 'Info', isProtected: false, isDynamic: false},
  INFO_CREATE_LABEL: {key: 'info-create-label', route: '/info/create-label', label: 'Create Shipping Labels', isProtected: false, isDynamic: false},
  INFO_CONNECT_TOOLS: {key: 'info-connect-tools', route: '/info/connect-tools', label: 'Connect Your Tools And Plugins', isProtected: false, isDynamic: false},
  INFO_CONNECT_TOOLS_PLUGIN: {key: 'info-connect-tools-plugin', route: '/info/connect-tools/:partnerId', label: 'Connect Your Tools And Plugins Partner Page', isProtected: false, isDynamic: true},
  INFO_MANAGE_EXPERIENCE: {key: 'info-manage-experience', route: '/info/manage-experience', label: 'Manage The Experience', isProtected: false, isDynamic: false},
  INFO_GROW_YOUR_BUSINESS: {key: 'info-grow-business', route: '/info/grow-business', label: 'Grow Your Business', isProtected: false, isDynamic: false},
  SHOPIFY_OAUTH: {key: 'shopify-oauth', route: '/initshopify', label: 'Setup Shopify', isProtected: true, isDynamic: false}, // important
  ANALYTIC: {key: 'analytic', route: '/analytic', label: 'Analytics', isProtected: true, isDynamic: false},
  ANALYTIC_OVERVIEW: {key: 'overview', route: '/analytic/overview', label: 'Overview', isProtected: true, isDynamic: false},
  ANALYTIC_OPERATION: {key: 'operation', route: '/analytic/operation', label: 'Operation', isProtected: true, isDynamic: false},
  ANALYTIC_SALE: {key: 'sale', route: '/analytic/sale', label: 'Sales Trend', isProtected: true, isDynamic: false},
  ANALYTIC_CUSTOMER: {key: 'customer', route: '/analytic/customer', label: 'Customer Overview', isProtected: true, isDynamic: false},
  ANALYTIC_PRODUCT: {key: 'product', route: '/analytic/product', label: 'Product HighLights', isProtected: true, isDynamic: false},
  LINK_YOUR_UPSACCOUNT: {key: 'link-your-ups-account', route: '/partners/:partnerId/link-your-ups-account', label: 'UPS Account', isProtected: false, isDynamic: false},
  // UPS_DIGITAL_ACCESS_PROGRAM: { key: 'ups-dap', route: '/partners/ups/digital-access-program', label: 'UPS Digital Access Program', isProtected: false, isDynamic: false },
  UPS_READY_PROGRAM: {key: 'ups-ready', route: '/partners/ups/ready-program', label: 'UPS Ready Program', isProtected: false, isDynamic: false},
  UPS_CANCEL_SHIPMENT: {key: 'ups-cancel-shipment', route: '/partners/ups/cancel-shipment', label: 'UPS Cancel or Void Shipment', isProtected: false, isDynamic: false},
  UPS_CA_GROUND: {key: 'ups-ca-ground', route: '/partners/ups-canada/ca-ups-ground', label: 'UPS Ground', isProtected: false, isDynamic: false},
  UPS_EXPRESS: {key: 'ups-ca-express', route: '/partners/ups-canada/ups-express', label: 'UPS Express', isProtected: false, isDynamic: false},
  UPS_EXPRESS_EARLY: {key: 'ups-express-early', route: '/partners/ups-canada/ups-express-early', label: 'UPS Express', isProtected: false, isDynamic: false},
  UPS_EXPRESS_SAVER: {key: 'ups-express-saver', route: '/partners/ups-canada/ups-express-saver', label: 'UPS Express', isProtected: false, isDynamic: false},
  UPS_EXPEDITED: {key: 'ups-expedited', route: '/partners/ups-canada/ups-expedited', label: 'UPS Express', isProtected: false, isDynamic: false},
  UPS_STANDARD: {key: 'ups-standard', route: '/partners/ups-usa/ups-standard', label: 'UPS Standard', isProtected: false, isDynamic: false},
  UPS_WORLDWIDE_EXPEDITED: {key: 'ups-worldwide-expedited', route: '/partners/ups-usa/ups-worldwide-expedited', label: 'UPS Worldwide Expedited', isProtected: false, isDynamic: false},
  UPS_WORLDWIDE_SAVER: {key: 'ups-Worldwide-Saver', route: '/partners/ups-usa/ups-worldwide-saver', label: 'UPS Worldwide Saver', isProtected: false, isDynamic: false},
  UPS_NEXT_DAY_AIR_SAVER: {key: 'ups-next-day-air-Saver', route: '/partners/ups-usa/ups-next-day-air-Saver', label: 'UPS Next Day Air Saver', isProtected: false, isDynamic: false},
  UPS_WORLDWIDE_EXPRESS: {key: 'ups-Worldwide-Express', route: '/partners/ups-usa/ups-worldwide-express', label: 'UPS Worldwide Express', isProtected: false, isDynamic: false},
  UPS_WORLDWIDE_EXPRESS_PLUS: {key: 'ups-worldwide-express-plus', route: '/partners/ups-usa/ups-worldwide-express-plus', label: 'UPS Worldwide Express Plus ', isProtected: false, isDynamic: false},
  UPS_3_DAY_SELECT: {key: 'ups-3-Day-Select', route: '/partners/ups-usa/ups-3-day-select', label: 'UPS 3-Day Select', isProtected: false, isDynamic: false},
  UPS_2ND_DAY_AIR: {key: 'ups-2nd Day Air', route: '/partners/ups-usa/ups-2nd-Day-Air', label: 'UPS 2nd Day Air', isProtected: false, isDynamic: false},
  UPS_NEXT_DAY_AIR: {key: 'ups-Next-Day-Air', route: '/partners/ups-usa/next-day-air', label: 'UPS Next Day Air', isProtected: false, isDynamic: false},
  CANPAR_OVERNIGHT: {key: 'canpar-overnight', route: '/partners/canpar/canpar-overnight', label: 'Canpar-Overnight', isProtected: false, isDynamic: false},
  CANPAR_GROUND: {key: 'canpar-ground', route: '/partners/canpar/canpar-ground', label: 'Canpar-Select', isProtected: false, isDynamic: false},
  CANPAR_SELECT: {key: 'canpar-select', route: '/partners/canpar/canpar-select', label: 'Canpar-Ground', isProtected: false, isDynamic: false},
  CANPAR_INTERNATIONAL: {key: 'canpar-international', route: '/partners/canpar/canpar-international', label: 'Canpar International', isProtected: false, isDynamic: false},
  CANADA_POST_XPRESSPOST: {key: 'canada-post-xpresspost', route: '/partners/canada-post/canada-post-xpresspost', label: 'Canada Post Xpresspost', isProtected: false, isDynamic: false},
  CANADA_POST_XPRESSPOST_USA: {key: 'canada-post-xpresspost-usa', route: '/partners/canada-post/canada-post-xpresspost-usa', label: 'Canada Post Xpresspost USA', isProtected: false, isDynamic: false},
  CANADA_POST_DOMESTIC_EXPEDITED_PARCEL: {key: 'canada-post-domestic-expedited-parcel', route: '/partners/canada-post/canada-post-domestic-expedited-parcel', label: 'Canada Post Domestic Expedited Parcel', isProtected: false, isDynamic: false},
  CANADA_POST_EXPEDITED_PARCEL: {key: 'canada-post-expedited-parcel', route: '/partners/canada-post/canada-post-expedited-parcel', label: 'Canada Post Expedited Parcel', isProtected: false, isDynamic: false},
  CANADA_POST_EXPEDITED_PARCEL_USA: {key: 'canada-post-expedited-parcel-usa', route: '/partners/canada-post/canada-post-expedited-parcel-usa', label: 'Canada Post Expedited Parcel USA', isProtected: false, isDynamic: false},
  CANADA_POST_INTERNATIONAL_SMALL_PACKET_AIR: {key: 'canada-post-international-small-packet-air', route: '/partners/canada-post/canada-post-international-small-packet-air', label: 'Canada Post International Small Packet Air', isProtected: false, isDynamic: false},
  CANADA_POST_PRIORITY_SHIPPING: {key: 'canada-post-priority-shipping', route: '/partners/canada-post/canada-post-priority-shipping', label: 'Canada Post Priority Shipping', isProtected: false, isDynamic: false},
  CANADA_POST_SMALL_PACKET_USA: {key: 'canada-post-small-packet-usa', route: '/partners/canada-post/canada-post-small-packet-usa', label: 'Canada Post Small Packet USA', isProtected: false, isDynamic: false},
  CANADA_POST_TRACKED_PACKET: {key: 'canada-post-tracked-packet', route: '/partners/canada-post/canada-post-tracked-packet', label: 'Canada Post Tracked Packet', isProtected: false, isDynamic: false},
  USPS_FIRST_CLASS_MAIL: {key: 'usps-first-class-mail', route: '/partners/usps/first-class-mail', label: 'USPS First Class Mail', isProtected: false, isDynamic: false},
  USPS_PRIORITY_MAIL: {key: 'usps-priority-mail', route: '/partners/usps/priority-mail', label: 'USPS Priority Mail', isProtected: false, isDynamic: false},
  USPS_PRIORITY_MAIL_EXPRESS: {key: 'usps-priority-mail-express', route: '/partners/usps/priority-mail-express', label: 'USPS Priority Mail Express', isProtected: false, isDynamic: false},
  USPS_PARCEL_SELECT: {key: 'usps-parcel-select', route: '/partners/usps/parcel-select', label: 'USPS Parcel Select', isProtected: false, isDynamic: false},
  USPS_INTERNATIONAL_SHIPPING: {key: 'usps-international-shipping', route: '/partners/usps/international-shipping', label: 'USPS International Shipping', isProtected: false, isDynamic: false},
  USPS_FLAT_RATE_SHIPPING: {key: 'usps-flat-rate-shipping', route: '/partners/usps/flat-rate-shipping', label: 'USPS Flat Rate Shipping', isProtected: false, isDynamic: false},
  USPS_CLICK_N_SHIP: {key: 'usps-click-n-ship', route: '/partners/usps/click-n-ship', label: 'USPS Click-N-Ship', isProtected: false, isDynamic: false},
  USPS_INFORMED_DELIVERY: {key: 'usps-informed-delivery', route: '/partners/usps/informed-delivery', label: 'USPS Informed Delivery', isProtected: false, isDynamic: false},

  // these will disable the dashboard header for all dynamic carrier pages
  CANADA_POST_DYNAMIC_SERVICE_PAGE: {key: 'canada-post-dynamic-service', route: '/partners/canada-post/:serviceId', label: 'Canada Post Dynamic Service', isProtected: false, isDynamic: true},
  USPS_DYNAMIC_SERVICE_PAGE: {key: 'usps-dynamic-service', route: '/partners/usps/:serviceId', label: 'USPS Dynamic Service', isProtected: false, isDynamic: true},
  UPS_US_DYNAMIC_SERVICE_PAGE: {key: 'ups-us-dynamic-service', route: '/partners/ups-usa/:serviceId', label: 'UPS US Dynamic Service', isProtected: false, isDynamic: true},
  UPS_CANADA_DYNAMIC_SERVICE_PAGE: {key: 'ups-canada-dynamic-service', route: '/partners/ups-canada/:serviceId', label: 'UPS Canada Dynamic Service', isProtected: false, isDynamic: true},
  UPS_OLD_DYNAMIC_SERVICE_PAGE: {key: 'ups-us-old-dynamic-service', route: '/partners/ups/:serviceId', label: 'UPS Old Dynamic Service', isProtected: false, isDynamic: true},


  USPS_FIRST_CLASS_LETTERS_AND_FLATS: {key: 'usps-first-class-letters-and-flats', route: '/partners/usps/first-class-letters-and-flats', label: 'USPS First Class (Letters and Flats)', isProtected: false, isDynamic: false},
  USPS_FIRST_CLASS_PACKAGE_SERVICE: {key: 'usps-first-class-package-service', route: '/partners/usps/first-class-package-service', label: 'USPS First Class Package Service', isProtected: false, isDynamic: false},
  USPS_PRIORITY: {key: 'usps-priority', route: '/partners/usps/priority', label: 'USPS Priority', isProtected: false, isDynamic: false},
  USPS_EXPRESS: {key: 'usps-express', route: '/partners/usps/express', label: 'USPS Express', isProtected: false, isDynamic: false},
  // USPS_PARCEL_SELECT: { key: 'usps-parcel-select', route: '/partners/usps/parcel-select', label: 'USPS Parcel Select', isProtected: false, isDynamic: false },
  USPS_LIBRARY_MAIL: {key: 'usps-library-mail', route: '/partners/usps/library-mail', label: 'USPS Library Mail', isProtected: false, isDynamic: false},
  USPS_MEDIA_MAIL: {key: 'usps-media-mail', route: '/partners/usps/media-mail', label: 'USPS Media Mail', isProtected: false, isDynamic: false},
  USPS_FIRST_CLASS_PACKAGE_INTERNATIONAL: {key: 'usps-first-class-package-international', route: '/partners/usps/first-class-package-international', label: 'USPS First Class Package International', isProtected: false, isDynamic: false},
  USPS_PRIORITY_MAIL_INTERNATIONAL: {key: 'usps-priority-mail-international', route: '/partners/usps/priority-mail-international', label: 'USPS Priority Mail International', isProtected: false, isDynamic: false},
  USPS_EXPRESS_MAIL_INTERNATIONAL: {key: 'usps-express-mail-international', route: '/partners/usps/express-mail-international', label: 'USPS Express Mail International', isProtected: false, isDynamic: false},

  USPS_PRIORITY_MAIL_EXPRESS_FLAT_RATE_ENVELOPE: {key: 'usps-priority-mail-express-flat-rate-envelope', route: '/partners/usps/priority-mail-express-flat-rate-envelope', label: 'USPS Priority Mail Express Flat Rate® Envelope', isProtected: false, isDynamic: false},
  USPS_PRIORITY_MAIL_EXPRESS_OPEN_DISTRIBUTE_SERVICE: {key: 'usps-priority-mail-express-open-distribute-service', route: '/partners/usps/priority-mail-express-open-distribute-service', label: 'Priority Mail Express Open & Distribute® Service', isProtected: false, isDynamic: false},
  USPS_PRIORITY_MAIL_FLAT_RATE_ENVELOPE: {key: 'usps-priority-mail-flat-rate-envelope', route: '/partners/usps/priority-mail-flat-rate-envelope', label: 'Priority Mail Flat Rate® Envelope', isProtected: false, isDynamic: false},
  USPS_PREPAID_FOREVER_PRIORITY_MAIL_FLAT_RATE: {key: 'usps-prepaid-forever-priority-mail-flat-rate', route: '/partners/usps/prepaid-forever-priority-mail-flat-rate', label: 'Prepaid Forever Priority Mail Flat Rate', isProtected: false, isDynamic: false},

  QUICK_VIDEOS: {key: 'quick-videos', route: '/quick-videos', label: 'Quick Videos', isProtected: false, isDynamic: false},
  UPDATE_PAGE: {key: 'update-page', route: '/blog/update-notes', label: 'Update Page', isProtected: false, isDynamic: false},
  UPDATE_NOTES: {key: 'update-notes', route: '/blog/update-notes/:notesId', label: 'Update Notes', isProtected: false, isDynamic: true},
  FEATURES_PAGE: {key: 'features', route: '/features', label: 'Features', isProtected: false, isDynamic: false},
  CONTESTS: {key: 'contests', route: '/contests', label: 'Contests', isProtected: false, isDynamic: false},
  AIR_MILES_GIVEAWAY: {key: 'air-miles-giveaway', route: '/contests/airmiles-giveaway', label: 'Air Miles Giveaway', isProtected: false, isDynamic: false},
  INVITATION: {key: 'invitation', route: '/an-invitation-to-try-shipvista-com-for-free', label: 'Invitation Page', isProtected: false, isDynamic: false},

};

export const HEADER_LEFT_MENU_KEY = {
  [MENU_MAP.DASHBOARD.key]: MENU_MAP.DASHBOARD.key,
  [MENU_MAP.ORDERS.key]: MENU_MAP.ORDERS.key,
  [MENU_MAP.SHIPMENTS.key]: MENU_MAP.SHIPMENTS.key,
  [MENU_MAP.WAREHOUSING.key]: MENU_MAP.WAREHOUSING.key,
  [MENU_MAP.ANALYTIC.key]: MENU_MAP.ANALYTIC.key,
};

export const HEADER_RIGHT_MENU_KEY = {
  [MENU_MAP.SETTINGS.key]: MENU_MAP.SETTINGS.key,
  [MENU_MAP.CUSTOMER_SUPPORT.key]: MENU_MAP.CUSTOMER_SUPPORT.key,
};

export const SIDEBAR_MENU_MAP = {
  ORDERS: [
    MENU_MAP.ORDERS_AWAITING_PAYMENT,
    MENU_MAP.ORDERS_ON_HOLD,
    MENU_MAP.ORDERS_AWAITING_SHIPMENT,
    MENU_MAP.ORDERS_SHIPPED,
    MENU_MAP.ORDERS_CANCELED,
  ],
  SHIPMENTS: [
    // MENU_MAP.SHIPMENTS_QUICK_QUOTE,
    MENU_MAP.SHIPMENTS_CREATE_SHIPMENT,
    MENU_MAP.SHIPMENTS_SHIPMENT_HISTORY,
    MENU_MAP.SHIPMENTS_MY_PICKUPS,
    MENU_MAP.SHIPMENTS_FULFILLMENTS,
    MENU_MAP.SHIPMENTS_END_OF_DAY,
    MENU_MAP.SHIPMENTS_BATCH_HISTORY,
  ],
  WAREHOUSING: [
    MENU_MAP.WAREHOUSING_MY_PRODUCTS,
    MENU_MAP.WAREHOUSING_WAREHOUSING_SHIPMENTS,
    MENU_MAP.WAREHOUSING_MY_INVENTORY,
  ],
  ANALYTIC: [
    MENU_MAP.ANALYTIC_OVERVIEW,
    MENU_MAP.ANALYTIC_OPERATION,
    MENU_MAP.ANALYTIC_SALE,
    MENU_MAP.ANALYTIC_CUSTOMER,
    MENU_MAP.ANALYTIC_PRODUCT
  ],
  SETTINGS: [
    {
      ...MENU_MAP.SETTINGS_ACCOUNT,
      children: [
        MENU_MAP.SETTINGS_ACCOUNT_MY_PROFILE,
        MENU_MAP.SETTINGS_ACCOUNT_PAYMENTS,
        MENU_MAP.SETTINGS_ACCOUNT_OFFERS_AVAILABLE,
        MENU_MAP.SETTINGS_ACCOUNT_TRANSACTION_HISTORY,
        MENU_MAP.SETTINGS_ACCOUNT_SUBSCRIPTION,
        MENU_MAP.SETTINGS_ACCOUNT_AIRMILES,
      ],
    },
    {
      ...MENU_MAP.SETTINGS_INTEGRATIONS,
      children: [
        MENU_MAP.SETTINGS_INTEGRATIONS_STORE_SETUP,
        MENU_MAP.SETTINGS_INTEGRATIONS_CARRIER_SETUP,
      ],
    },
    {
      ...MENU_MAP.SETTINGS_SHIPPING,
      children: [
        MENU_MAP.SETTINGS_SHIPPING_ADDRESS_BOOK,
        MENU_MAP.SETTINGS_SHIPPING_BOX_LIBRARY,
      ],
    },
    {
      ...MENU_MAP.SETTINGS_FEED,
      children: [
        MENU_MAP.SETTINGS_FEED_BULK_ORDERS_FEED,
        MENU_MAP.SETTINGS_FEED_BULK_AFFILIATE_USER_FEED,
      ],
    },
  ],
  CUSTOMER_SUPPORT: [
    MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS,
    MENU_MAP.CUSTOMER_SUPPORT_LEARNING_CENTER,
  ],
  ALL: [
    MENU_MAP.DASHBOARD,
    {
      ...MENU_MAP.ORDERS,
      children: [
        MENU_MAP.ORDERS_AWAITING_PAYMENT,
        MENU_MAP.ORDERS_ON_HOLD,
        MENU_MAP.ORDERS_AWAITING_SHIPMENT,
        MENU_MAP.ORDERS_SHIPPED,
        MENU_MAP.ORDERS_CANCELED,
        MENU_MAP.ORDERS_SEARCH
      ],
    },
    {
      ...MENU_MAP.SHIPMENTS,
      children: [
        // MENU_MAP.SHIPMENTS_QUICK_QUOTE,
        MENU_MAP.SHIPMENTS_CREATE_SHIPMENT,
        MENU_MAP.SHIPMENTS_SHIPMENT_HISTORY,
        MENU_MAP.SHIPMENTS_MY_PICKUPS,
        MENU_MAP.SHIPMENTS_FULFILLMENTS,
        MENU_MAP.SHIPMENTS_END_OF_DAY,
        MENU_MAP.SHIPMENTS_BATCH_HISTORY,
      ],
    },
    {
      ...MENU_MAP.WAREHOUSING,
      children: [
        MENU_MAP.WAREHOUSING_MY_PRODUCTS,
        MENU_MAP.WAREHOUSING_WAREHOUSING_SHIPMENTS,
        MENU_MAP.WAREHOUSING_MY_INVENTORY,
      ],
    },
    {
      ...MENU_MAP.ANALYTIC,
      children: [
        MENU_MAP.ANALYTIC_OVERVIEW,
        MENU_MAP.ANALYTIC_OPERATION,
        MENU_MAP.ANALYTIC_SALE,
        MENU_MAP.ANALYTIC_CUSTOMER,
        MENU_MAP.ANALYTIC_PRODUCT
      ]
    },
  ],
};
