import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";

import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "material-react-toastify/dist/ReactToastify.css";

import MasterPage from "./masterPage/MasterPage";
import CustomerLogin from "./customer-login/CustomerLogin";
import { MENU_MAP } from "./masterPage/common";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

interface Props {}

export default function App(props: Props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Switch>
          <Route exact path={MENU_MAP.LOGIN_SUCCESS.route}>
            <CustomerLogin />
          </Route>
          <MasterPage />
        </Switch>
      </div>
    </>
  );
}
