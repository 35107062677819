import { SET_TOKEN_STRING, SET_TOKEN_EXPIRE_AT, SET_TOKEN_ACCOUNT_NO } from '../@types/token/tokenAction';

const initialState = {
  tokenString: '',
  expireAt: '',
  accountNo: '',
};

export function tokenReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN_STRING:
      return { ...state, tokenString: action.data };
    case SET_TOKEN_EXPIRE_AT:
      return { ...state, expireAt: action.data };
    case SET_TOKEN_ACCOUNT_NO:
      return { ...state, accountNo: action.data };
    default:
      return state;
  }
}
