import { getAccessToken } from "../functionUtilities/getTokenAndExpireTime";

// if you want to connect to backend https://localhost:44368 - change your port in the console
// example set PORT=3001
// npm start will always run on port 3001. To change it back, set PORT=3000
// const getServerURL = () => {
//   if (
//     window.location.origin.includes("sandboxspa") ||
//     window.location.origin.includes("sandboxapp")
//   ) {
//     return "https://sandboxapi.shipvista.com";
//   } else if (
//     window.location.origin.includes(
//       "https://witty-meadow-0fd734b0f.5.azurestaticapps.net"
//     )
//   ) {
//     return "https://sv-spa-api.azurewebsites.net";
//   } else if (window.location.origin.includes("beta")) {
//     return "https://betaapi.shipvista.com";
//   } else if (
//     window.location.origin.includes(
//       "https://salmon-meadow-0884adf10.5.azurestaticapps.net"
//     ) ||
//     window.location.origin.includes("localhost:3001")
//   ) {
//     // return "https://localhost:5001";
//     return "https://sv-test-api.azurewebsites.net";
//   } else {
//     return "https://api.shipvista.com";
//   }
// };

export const getLandingPageURL = () => {
  return process.env.REACT_APP_LANDING_PAGE_URL;
  // if (
  //   window.location.origin.includes("sandboxspa") ||
  //   window.location.origin.includes("sandboxapp")
  // ) {
  //   return "https://sandbox.shipvista.com";
  // } else if (window.location.origin.includes("spa")) {
  //   return "https://spa.shipvista.com";
  // } else if (window.location.origin.includes("beta")) {
  //   return "https://beta.shipvista.com";
  // } else if (
  //   window.location.origin.includes("testapp") ||
  //   window.location.origin.includes("localhost:3001")
  // ) {
  //   return "https://test.shipvista.com";
  //   // return "http://localhost:3000";
  //   // return "https://localhost:44368"
  // } else {
  //   return "https://www.shipvista.com";
  // }
};

export const getDataDogConfig = () => {
  return {
    dataDogAppId: "ee52b1df-a2cf-45f2-979f-27255e3faea0",
    dataDogClientToken: "pubee5b4983696abd25b63912d7f8ad2cd7",
    dataDogSiteName: "datadoghq.com",
    dataDogServiceName: "shipvista-client",
    dataDogEnv:
      getLandingPageURL() === "https://www.shipvista.com" ? "prod" : "dev",
    dataDogVersion: "1.0.0",
  };
};

export const getBrandAssetsURL = () => {
  // if (window.location.origin.includes("sandboxspa") || window.location.origin.includes("sandboxapp")) {
  //     return "https://sandboxapi.shipvista.com";
  // } else if (window.location.origin.includes("spa")) {
  //     return "https://spaapi.shipvista.com";
  // } else if (window.location.origin.includes("beta")) {
  //     return "https://betaapi.shipvista.com";
  // } else
  // if (
  //   window.location.origin.includes("testapp") ||
  //   window.location.origin.includes("localhost:3000")
  // ) {
  //   return {
  //     creatives:
  //       "https://svstaticfiles.blob.core.windows.net/brand-central-assets/creative-assets-test.json",
  //     links:
  //       "https://svstaticfiles.blob.core.windows.net/brand-central-assets/link-assets-test.json",
  //   };
  // } else {
  //   return {
  //     creatives:
  //       "https://svstaticfiles.blob.core.windows.net/brand-central-assets/creative-assets.json",
  //     links:
  //       "https://svstaticfiles.blob.core.windows.net/brand-central-assets/link-assets.json",
  //   };
  // }

  return {
    creatives: process.env.REACT_APP_BRAND_ASSET_CREATIVE_URL,
    links: process.env.REACT_APP_BRAND_ASSET_LINKS_URL,
  };
};
export const SERVER_URL = process.env.REACT_APP_API_URL; //getServerURL();

export const REDIRECT_URL = window.location.href.includes("?")
  ? window.location.href.split("?")[0]
  : window.location.href;

export const getStoreSetupURL = () => {
  const domainArr = window.location.href.split("/");
  return `${domainArr[0]}//${domainArr[2]}/settings/store-setup`;
};

export const tokenCookieName = "sv_tkn";
export const refreshTokenKey = "refreshToken";
// Note: the below values of the variables might hardcoded in the MasterPage.tsx logout function
// and in the loginHandlerService.tsx
export const accessTokenSessionKey = "accesstoken";
export const accessTokenSessionExpiryKey = "expireAt";

export const getToken = () => {
  try {
    const token = getAccessToken(tokenCookieName);

    return token;
  } catch (err) {}

  return null;
};

export const config = { headers: { Authorization: `Bearer ${getToken()}` } };
// Use getAxiosConfig instead of config object as it will give you a stale config if the token in cookie/session storage changes
export const getAxiosConfig = () => ({
  headers: { Authorization: `Bearer ${getToken()}` },
});

export const APP_COLOR = {
  appLightBlue: "#3c8dbc",
  appHrGrey: "#e9ecef",
  deleteRed: "#D30101",
};

export const getIncomingShipmentsURL = () => {
  const domainArr = window.location.href.split("/");
  return `${domainArr[0]}//${domainArr[2]}/SW_WarehouseIncomingShipments/`;
};

export const getStripeToken = () => {
  return {
    token: process.env.REACT_APP_STRIPE_CA_TOKEN,
    usToken: process.env.REACT_APP_STRIPE_US_TOKEN,
    redirectUrl: process.env.REACT_APP_STRIPE_REDIRECT_URL,
  };
};
