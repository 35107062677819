import { DialogState, DialogAction, DialogActions, DialogOption } from "../@types/dialog";

const initialState: DialogState = {
  isDialogOpened: false,
  dialogPage: DialogOption.BlankPage,
};

export function dialogReducer(
  state = initialState,
  action: DialogAction
): DialogState {
  switch (action.type) {
    case DialogActions.CLOSE_DIALOG:
      return { ...state, isDialogOpened: false, dialogPage: DialogOption.BlankPage };
    case DialogActions.OPEN_CONTENT_PAGE:
      return {
        ...state,
        isDialogOpened: true,
        dialogPage: action.payload,
      };
    default:
      return state;
  }
}