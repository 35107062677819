import {
  forwardRef,
  SetStateAction,
  Dispatch,
  useImperativeHandle,
} from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getLandingPageURL, getStripeToken } from "../../environment/env_dev";
import AppCheckboxRight from "../utilities/AppCheckboxRight";
import { MENU_MAP } from "../masterPage/common";

const { redirectUrl } = getStripeToken();

type StripeCheckoutProps = {
  clientSecret: string;
  stripeToken: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handlePaymentSuccess: () => void;
  handlePaymentError: (message: string) => void;
  agreeTerms: boolean;
  setAgreeTerms: Dispatch<SetStateAction<boolean>>;
};

const StripeCheckout = forwardRef(
  (
    {
      clientSecret,
      setIsLoading,
      handlePaymentSuccess,
      handlePaymentError,
      agreeTerms,
      setAgreeTerms,
      stripeToken,
    }: StripeCheckoutProps,
    ref
  ) => {
    // const stripeToken = currency === "CAD" ? token : usToken;
    const stripePromise = loadStripe(stripeToken);

    const options = {
      clientSecret,
      appearance: {},
    };

    return (
      <>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentDialog
              setIsLoading={setIsLoading}
              ref={ref}
              handlePaymentSuccess={handlePaymentSuccess}
              handlePaymentError={handlePaymentError}
              agreeTerms={agreeTerms}
              setAgreeTerms={setAgreeTerms}
            />
          </Elements>
        )}
      </>
    );
  }
);

type PaymentDialogProps = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handlePaymentSuccess: () => void;
  handlePaymentError: (message: string) => void;
  agreeTerms: boolean;
  setAgreeTerms: Dispatch<SetStateAction<boolean>>;
};

const PaymentDialog = forwardRef(
  (
    {
      setIsLoading,
      handlePaymentSuccess,
      handlePaymentError,
      agreeTerms,
      setAgreeTerms,
    }: PaymentDialogProps,
    ref
  ) => {
    const elements = useElements();
    const stripe = useStripe();

    useImperativeHandle(ref, () => ({
      triggerSubmit(event) {
        handleSubmit(event);
      },
    }));

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) return;

      setIsLoading(true);
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: { return_url: redirectUrl },
        redirect: "if_required",
      });

      if (error) {
        setIsLoading(false);
        handlePaymentError(error?.message);
        // TODO onPaymentError(error.message);
        return;
      } else {
        handlePaymentSuccess();
      }
    };

    return (
      <>
        <PaymentElement />
        <AppCheckboxRight
          isChecked={agreeTerms}
          text=" I have read and agree to the terms of the ShipVista's "
          handleChange={({ target }) => setAgreeTerms(target.checked)}
          hrefLink={
            getLandingPageURL() + MENU_MAP.SETTINGS_CREDIT_CARD_CONSENT.route
          }
          textLink="Consent Agreement"
          optionalStyle={{ fontSize: ".9em" }}
        />
      </>
    );
  }
);

export default StripeCheckout;
