import shipvistaAPI from "./shipvistaAPI";

export const getAllCountriesService = () =>
  shipvistaAPI.get(`/addresses/countries`);

export const getStateByCountryService = (countryId: number) =>
  shipvistaAPI.get(`/addresses/${countryId}/states`);

export const getStateByCountryServiceForGuest = (countryId: number) =>
  shipvistaAPI.get(`/addresses/${countryId}/states/guest`);
