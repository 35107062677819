import { GetQuoteAction, GetQuoteState, GetQuoteActions } from "../../@types/quickQuote/getQuote";
import { v4 as uuidv4 } from 'uuid';
import { ShippingItem } from "../../../@type/ShippingItem";

const newShippingItem = () => {
    return { id: uuidv4(), quantity: 1, length: "", width: "", height: "", weight: "" };
}

const initialState: GetQuoteState = {
    isMetric: true,
    rowOfItems: 1,
    shippingItems: [{ id: uuidv4(), quantity: 1, length: "10", width: "10", height: "10", weight: "10" }],
    shipFrom: { address: "", country: "Canada", postCode: "M9V1A2" },
    shipTo: { address: "", country: "Canada", postCode: "M9V1A2" }
};

export function getQuoteReducer(
    state = initialState,
    action: GetQuoteAction
): GetQuoteState {
    switch (action.type) {
        case GetQuoteActions.SET_MEASUREMENT_UNIT:
            return { ...state, isMetric: action.payload }
        case GetQuoteActions.SET_NEW_SHIPPING_ITEMS:
            return {
                ...state,
                rowOfItems: action.payload,
                shippingItems: getNewShippingItems(action.payload, state.shippingItems)
            }
        case GetQuoteActions.UPDATE_SHIPPING_ITEMS:
            const shippingItemsUpdate = state.shippingItems.map(item =>
                item.id === action.payload.id ? action.payload : item
            );
            return { ...state, shippingItems: shippingItemsUpdate };
        case GetQuoteActions.COPY_SHIPPING_ITEM:
            return {
                ...state,
                rowOfItems: state.rowOfItems + 1,
                shippingItems: [...state.shippingItems,
                {
                    id: uuidv4(),
                    quantity: action.payload.quantity,
                    length: action.payload.length,
                    width: action.payload.width,
                    height: action.payload.height,
                    weight: action.payload.weight
                }]
            };
        case GetQuoteActions.DELETE_SHIPPING_ITEM:
            return {
                ...state,
                rowOfItems: state.rowOfItems !== 1 ? state.rowOfItems - 1 : state.rowOfItems,
                shippingItems: state.rowOfItems !== 1
                    ? state.shippingItems.filter(item => item.id !== action.payload.id)
                    : state.shippingItems.map(item =>
                        item.id === action.payload.id
                            ? { ...action.payload, ...newShippingItem() }
                            : item
                    )
            };
        case GetQuoteActions.UPDATE_SHIP_FROM_ADDRESS_DETAILS:
            return { ...state, shipFrom: action.payload };
        case GetQuoteActions.UPDATE_SHIP_TO_ADDRESS_DETAILS:
            return { ...state, shipTo: action.payload };
        case GetQuoteActions.SWAP_ADDRESS_DETAILS:
            const tempShipFrom = state.shipFrom;
            return { ...state, shipFrom: state.shipTo, shipTo: tempShipFrom };
        default:
            return state;
    }
}

const getNewShippingItems = (rowOfItems: number, oldShippingItems: ShippingItem[]) => {
    let newShippingItems = [...oldShippingItems];
    if (rowOfItems > oldShippingItems.length) {
        for (let i = 0; i < rowOfItems - oldShippingItems.length; i++) {
            newShippingItems.push(
                newShippingItem()
            )
        }
    } else if (rowOfItems < oldShippingItems.length) {
        newShippingItems = [...oldShippingItems.slice(0, rowOfItems)];
    }

    return newShippingItems;
}