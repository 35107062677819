import axios, { AxiosRequestConfig } from "axios";
import { AffiliateRegistrationInfo } from "../@type/ReferralProgram/AffiliateRegistrationInfo";
import { ReferralProgramInfo } from "../@type/ReferralProgram/ReferralProgramInfo";
import { ResponseInfo } from "../@type/ResponseInfo";
import { getBrandAssetsURL } from "../environment/env_dev";
import shipvistaAPI from "./shipvistaAPI";

export const getBalanceSummary = () => {
  return shipvistaAPI.get(`/affiliate/GetBalanceSummary`);
};

export const getAllPayoutMethod = () => {
  return shipvistaAPI.get(`/PayoutMethod`);
};

export const sendInvitesViaEmail = (emails) => {
  const data = emails.map((e) => ({ email: e, method: "EMAIL" }));

  return shipvistaAPI.post(`/affiliate/invite`, {
    inviteUserDTO: data,
    signUpUrl: window.location.origin,
  });
};

export const socialMediaAddContacts = (source, redirectUrl, state) => {
  return shipvistaAPI.get(
    `/affiliate/SocialPlatformsLoginUrl?source=${source}&redirectUrl=${redirectUrl}&state=${state}`
  );
};

// Assets are located on azure portal svstaticfiles brand-central-assets container
// In order for the below two requests to work the svstaticfiles must have CORS policy allowed for the domain the frontend is deployed on
// which is done through the portal
export const getReferralLinks = (
  config: AxiosRequestConfig = {
    responseType: "json",
    headers: {
      ["Allow-Origin"]: window.location.origin,
      ["Access-Control-Allow-Origin"]: window.location.origin,
    },
  }
) => {
  return axios.get(getBrandAssetsURL().links, config);
};

export const getReferralCreatives = (
  config: AxiosRequestConfig = {
    responseType: "json",
    headers: {
      ["Allow-Origin"]: window.location.origin,
      ["Access-Control-Allow-Origin"]: window.location.origin,
    },
  }
) => {
  return axios.get(getBrandAssetsURL().creatives, config);
};

export const getAllUserContacts = (
  pageIndex: number = 1,
  pageSize: number = 20,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  inviteStatus?: string,
  sources?: Array<string>
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText });
  }

  if (inviteStatus) {
    params.push({ key: "InviteStatus", value: inviteStatus });
  }

  if (sources) {
    for (let i = 0, ii = sources.length; i < ii; i += 1) {
      params.push({ key: "Source", value: sources[i] });
    }
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/affiliate/GetAllUserContacts${paramString}`);
};

export const sendInvites = (data) => {
  return shipvistaAPI.post(`/affiliate/invite`, {
    inviteUserDTO: data,
    signUpUrl: window.location.origin,
  });
};

export const checkReferralCodeUsage = (referralCode: string) => {
  return shipvistaAPI.get<ResponseInfo<boolean>>(
    `/affiliate/checkReferralCodeUsage?referralCode=${referralCode}`
  );
};

export const getReferralProgramInfo = () => {
  return shipvistaAPI.get<ResponseInfo<ReferralProgramInfo>>(
    `/affiliate/getReferralProgramInfo`
  );
};

export const updateReferralCode = (referralCode: string) => {
  return shipvistaAPI.put<ResponseInfo<boolean>>(
    `/affiliate/updateReferralCode?referralCode=${referralCode}`,
    null
  );
};

export const registerReferralUser = (
  registrationInfo: Partial<AffiliateRegistrationInfo>
) => {
  return shipvistaAPI.post<ResponseInfo<boolean>>(
    `/affiliate/registerReferralUser`,
    registrationInfo
  );
};
