var objectMapper = require('object-mapper');

export const manageUserSubscriptionMapper = (data: any) => {
    let req = objectMapper(data, map)

    return req;
}

// let map={
//     "subscriptionplanid" : "subscriptionplanid",
//     "billingDetails.cardHolderName": "billingDetails.cardHolderName",
//     "billingDetails.cardNumber": "billingDetails.cardNumber",
//     "billingDetails.expiryDate": "billingDetails.expiryDate",
//     "billingDetails.securityNumber":"billingDetails.securityNumber",
//     "billingDetails.cardType":"billingDetails.cardType",
//     "billingDetails.status":"billingDetails.status",
//     "billingDetails.isDefault":"billingDetails.isDefault",
//     "billingDetails.billingAddress":"billingDetails.billingAddress",
//     "promoId":"promoId",
//     "reason":"additionalComments",

// }

let map = {
    "subscriptionplanid": "subscriptionplanid",
    "billingDetails": "billingDetails",
    "promoId": "promoId",
    "reason": "reason",
    "additionalComments": "additionalComments",
    "isYearly": "isYearly",
    "ClientCallBack": "ClientCallBack",
    "promoCode": "promoCode"
}