import NotInterestedIcon from '@material-ui/icons/NotInterested';

import DropdownWrapper from '../DropdownButton/DropdownWrapper';

interface Props {
  emptyBtnName: string;
  btnName: string;
  list: string[];
  setSelect: (value: React.SetStateAction<string>) => void;
}

const TableSelectFilter = (props: Props) => {
  const { btnName, emptyBtnName, list, setSelect } = props;

  return (
    <DropdownWrapper
      btnName={btnName}
      btnNameCustomEl={(
        <div style={{ color: '#0B57A8', fontWeight: 'bold' }}>
          {btnName ? btnName.toUpperCase() : emptyBtnName}
        </div>
      )}
      color="primary"
      variant={btnName ? 'outlined' : 'text'}
      size="medium"
      list={
        btnName
          ? ['Remove Filter', ...list]
          : list
      }
      listFormatter={(i) => {
        if (!btnName) {
          return list[i];
        }

        if (i === 0) {
          return (
            <div style={{ display: 'flex', color: '#f50057' }}>
              <NotInterestedIcon />
              &nbsp;
              Remove Filter
            </div>
          );
        }

        return list[i - 1];
      }}
      dynamicWidth
      setSelect={(e) => {
        const newValue = e === 'Remove Filter' ? '' : e;

        setSelect(newValue);
      }}
    />
  );
};

export default TableSelectFilter;
