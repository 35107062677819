export const PLAN = {
  BETA: 'Beta Associate',
  STARTER: 'Starter',
  PROFESSIONAL: 'Professional',
  ENTERPRISE: 'Enterprise',
  CUSTOMIZE: 'Customize',
};

export const PLAN_ICON = {
  [PLAN.BETA]: '/images/subscription/beta.png',
  [PLAN.STARTER]: '/images/subscription/shop.png',
  [PLAN.PROFESSIONAL]: '/images/subscription/company.png',
  [PLAN.ENTERPRISE]: '/images/subscription/buildings.png',
  [PLAN.CUSTOMIZE]: '/images/subscription/business.png',
};

export const PLAN_LEVEL = {
  [PLAN.STARTER]: 0,
  [PLAN.BETA]: 1,
  [PLAN.PROFESSIONAL]: 2,
  [PLAN.ENTERPRISE]: 3,
  [PLAN.CUSTOMIZE]: 4,
};

export const PLAN_ID={
  BETA: 1,
  STARTER: 2,
  PROFESSIONAL: 3,
  ENTERPRISE: 4,
  CUSTOMIZE: 5,
}
