import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Link, TextField, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { PLAN, PLAN_ICON } from './common';
import SubscriptionContext from './SubscriptionContext';
import SmallBr from './SmallBr';
import AddBillingInformationDialog from './AddBillingInformationDialog';
import ChangeBillingInformationDialog from './ChangeBillingInformationDialog';
import ChangePlanDialog from './ChangePlanDialog';
import SubscriptionPastInvoices from './SubscriptionPastInvoices';

import { RootState } from '../../../../../redux/reducers';
import { MasterPageContext } from '../../../masterPageContext';
import { MENU_MAP } from '../../../common';
import Br from '../../../../utilities/Br';
import DropdownWrapper from '../../../../utilities/DropdownButton/DropdownWrapper';
import FixedDialogWrapper from '../../../../utilities/FixedDialogWrapper';
import HelpTooltip from '../../../../utilities/HelpTooltip';
import RoundedCard from '../../../../utilities/RoundedCard';
import { getPaymentMethods } from '../../../../../apis/creditCard';
import { useAlertActions } from '../../../../../redux/actions';
import { gettingUserSubscription } from '../../../../../redux/thunk/userSubscriptionThunk';
import {
  updateUserBillingDetails,
  manageUserSubscription,
} from '../../../../../apis/subscriptionService';
import { useNavQueryParams } from '../../../../../hooks/AppNavQueryParamData';
// import Challenge from '../../../../utilities/Challenge'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 20,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      fontSize: '1.4rem',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 40px)',
      overflowX: 'hidden',
    },
    planCancelledInfo: {
      backgroundColor: '#CFE6FF',
      marginBottom: 10,
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 600,
    },
    cardContent: {
      padding: 20,
      minHeight: 250,
      position: 'relative',
    },
    cardIcon: {
      width: 60,
    },
    yourPlanTitle: {
      fontSize: '1.6rem',
      color: '#03827A',
    },
    yourPlanPrice: {
      fontSize: '1.4rem',
    },
    billingInformationSubtitle: {
      fontSize: '1.3rem',
    },
    actionButtonContainer: {
      position: 'absolute',
      bottom: 20,
      right: 20,
    },
    allFeaturesDialogContainer: {
      padding: 30,
    },
    allFeaturesDialogCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    allFeaturesDialogCardIcon: {
      width: 40,
    },
    featureItem: {
      minHeight: 60,
      padding: '10px 40px',
      fontSize: '1.1rem',
      fontWeight: 500,
      color: 'gray',
      '&:nth-child(even)': {
        background: '#f4f4f4',
      },
    },
    featureItemLeft: {
      width: '70%',
    },
    featureItemRight: {
      display: 'flex',
      justifyContent: 'center',
      width: '25%',
      textAlign: 'center',
    },
    featureItemCheckIcon: {
      width: 30,
    },
    changePlanSuccessContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 280,
      padding: '50px 0px',
      color: 'dodgerblue',
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    checkIcon: {
      width: 100,
    },
    cancelPlanContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 30,
    },
    centered: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '100%',
    },
    blue: {
      color: '#0964C7',
    },
    bold: {
      fontWeight: 600,
    },
    underlined: {
      textDecoration: 'underline',
    },
    pointer: {
      cursor: 'pointer',
    },
    button: {
      textTransform: 'capitalize',
      fontSize: '1rem',
      padding: '6px 12px',
    },
    primaryButton: {
      background: '#03827A',
      color: 'white',
      border: '1px solid #03827A',
      '&:hover': {
        opacity: 0.9,
        background: '#03827A',
        color: 'white',
      },
      '&:disabled': {
        opacity: 0.5,
        background: '#03827A',
        color: 'white',
      },
    },
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    xIcon: {
      width: 20,
      height: 20,
      textAlign: 'center',
    },
  }),
);

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const Card = ({ title, children }) => {
  const classes = useStyles();

  return (
    <RoundedCard title={title}>
      <div className={classes.cardContent}>
        {children}
      </div>
    </RoundedCard>
  );
};

// your plan content
const YourPlanContent = ({
  features = [],
}) => {
  const classes = useStyles();

  const {
    // planYearly,
    userSubscription,
    setAllFeaturesDialogOpen,
    setPastInvoicesOpen,
    setSeeAllFeaturePlanName,
    // allSubscriptionPlans
  } = useContext(SubscriptionContext);


  return (
    <Grid container>
      <Grid item xs={2}>
        <img
          className={classes.cardIcon}
          src={PLAN_ICON[userSubscription?.data?.subscriptionPlan?.title]}
          alt="your plan icon"
        />
      </Grid>
      <Grid container item xs={5} direction="column">
        <div className={`${classes.yourPlanTitle} ${classes.bold}`}>
          <span>{userSubscription?.data?.subscriptionPlan?.title}</span>
          <SmallBr />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 100 }}>
          {
            // show you are on free trial notification
            userSubscription?.data?.subscriptionPlan.hastrialperiod && (
              <div id="freeTrialText">
                <Grid container alignItems="center" className={classes.blue}>
                  <i>You are in <span className={classes.bold}>Free Trial</span></i>
                  &nbsp;
                  <HelpTooltip message="If you change to another plan in free trial period, you will get remaining days in next plan." />
                </Grid>
                <SmallBr />
              </div>
            )
          }
          {
            // shows date of end of billing cycle
            // checks if user is on free trial, if trialenddate and subscriptionenddate is not null and if user subscription plan is not a free plan

            (userSubscription?.data?.subscriptionPlan?.title !== PLAN.BETA && userSubscription?.data?.subscriptionPlan?.title !== PLAN.STARTER) && (
              <div id="nextInvoiceText">
                <div className={classes.bold}>Next Invoice</div>
                <div>{moment(
                  userSubscription?.data?.subscriptionPlan?.hastrialperiod ?
                    userSubscription?.data?.subscriptionPlan?.trialenddate :
                    userSubscription?.data?.subscriptionPlan?.subscripitionenddate).format('MMM DD, YYYY')} Automatically paid</div>
                <SmallBr />
              </div>
            )
          }
          <div id="viewPastInvoiceText"
            className={`${classes.bold} ${classes.blue} ${classes.underlined} ${classes.pointer}`}
            onClick={() => { setPastInvoicesOpen(true); }}
          >
            View past invoice
          </div>
        </div>
      </Grid>
      <Grid container item xs direction="column">
        <div className={`${classes.yourPlanPrice} ${classes.bold}`}>
          {
            userSubscription?.data?.subscriptionPlan?.price?.amount > 0 ?
              userSubscription?.data?.isYearly ?
                `${currencyFormatter.format(userSubscription?.data.subscriptionPlan?.yearlyprice)} CAD / ye.` :
                `${currencyFormatter.format(userSubscription?.data.subscriptionPlan?.price?.amount)} CAD / mo.` :
              'Free'
          }
        </div>
        <Br />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 100 }}>
          {
            features.map((e, i) => (
              <div key={`plan-feature-${i}`}>• {e}</div>
            ))
          }
          <span>
            •&nbsp;
            <span
              className={`${classes.bold} ${classes.underlined} ${classes.pointer}`}
              onClick={() => {
                setAllFeaturesDialogOpen(true);
                setSeeAllFeaturePlanName(userSubscription?.data?.subscriptionPlan?.title)

              }}
            >
              See All Features
            </span>
          </span>
        </div>
      </Grid>
    </Grid>
  );
};

const BillingInformationContent = ({ userSubscription }) => {
  const classes = useStyles();
  const { data } = userSubscription
  const { billingDetails } = data
  const { billingAddress, cardType, cardNumber, expiryDate, cardHolderName } = billingDetails
  const { name, streetAddress, streetAddress2, city, stateCode, postalCode, countryCode } = billingAddress;

  return (
    <Grid container>
      <Grid item xs={2}>
        <img
          className={classes.cardIcon}
          src="/images/subscription/credit-card.png"
          alt="your plan icon"
        />
      </Grid>
      <Grid container item xs={5} direction="column">
        <div className={`${classes.billingInformationSubtitle} ${classes.bold}`}>Billing Address</div>
        <Br />
        <div>{name}</div>
        <div>{streetAddress}</div>
        <div>{streetAddress2}</div>
        <div>{city}</div>
        <div>{stateCode} {postalCode}, {countryCode}</div>
      </Grid>
      <Grid container item xs direction="column">
        <div className={`${classes.billingInformationSubtitle} ${classes.bold}`}>Billing Method</div>
        <Br />
        <div>{cardHolderName}</div>
        <div>{cardType} {cardNumber}</div>
        <div>Expiration Date {expiryDate}</div>
      </Grid>
    </Grid>
  );
};

const FeaturesCard = ({ imgSrc, title, features }) => {
  const classes = useStyles();

  return (
    <div className={classes.allFeaturesDialogCard}>
      <img
        className={classes.allFeaturesDialogCardIcon}
        src={imgSrc}
        alt={title}
      />
      <Br />
      <h4>{title}</h4>
      <Br />
      <RoundedCard
        title="Features"
        style={{ width: '100%' }}
        headerStyle={{ padding: '0px 40px' }}
      >
        <div>
          {
            features.map((e, i) => (
              <Grid container alignItems="center" justify="space-between" key={`feature-${i}`} className={classes.featureItem}>
                <div className={classes.featureItemLeft}>{e.name}</div>
                <div className={classes.featureItemRight}>
                  {
                    typeof e.value === 'boolean' ? (
                      e.value ?
                        <img
                          className={classes.featureItemCheckIcon}
                          src="/images/subscription/yes.svg"
                          alt="checkmark icon"
                        /> : <img
                          className={classes.xIcon}
                          src="/images/subscription/x-mark.png"
                          alt="x mark"
                        />
                    ) : (
                      e.value
                    )
                  }
                </div>
              </Grid>
            ))
          }
        </div>
      </RoundedCard>
    </div>
  );
};

const AllFeaturesDialog = ({ open, setOpen, coreFeatures = [], advanceFeatures = [], planTitle = 'Unknown' }) => {
  const classes = useStyles();

  return (
    <FixedDialogWrapper
      dialogId="all-features-dialog"
      title={`All Features for ${planTitle} Plan`}
      maxWidth="sm"
      open={open}
      setOpen={setOpen}
    >
      <div className={classes.allFeaturesDialogContainer}>
        <FeaturesCard
          imgSrc="/images/subscription/star.png"
          title="Core Features"
          features={coreFeatures}
        />
        <Br count={4} />
        <FeaturesCard
          imgSrc="/images/subscription/rocket.png"
          title="Advance Features"
          features={advanceFeatures}
        />
      </div>
    </FixedDialogWrapper>
  );
};

const ChangePlanSuccessDialog = ({ open, setOpen }) => {
  const classes = useStyles();

  const history = useHistory();

  const {
    resolveSelectedMenuKey,
    resolveHeaderMenuKey,
    setSettingsPageKey,
  } = useContext(MasterPageContext);

  const redirectToCarrierSetupHandler = () => {
    setOpen(false);

    resolveSelectedMenuKey(MENU_MAP.SETTINGS_INTEGRATIONS_CARRIER_SETUP.key);
    resolveHeaderMenuKey(MENU_MAP.SETTINGS.route);
    history.push(MENU_MAP.SETTINGS_INTEGRATIONS_CARRIER_SETUP.route);
    setSettingsPageKey((new Date()).toString());
  };

  const redirectToStoreSetupHandler = () => {
    setOpen(false);

    resolveSelectedMenuKey(MENU_MAP.SETTINGS_INTEGRATIONS_STORE_SETUP.key);
    resolveHeaderMenuKey(MENU_MAP.SETTINGS.route);
    history.push(MENU_MAP.SETTINGS_INTEGRATIONS_STORE_SETUP.route);
    setSettingsPageKey((new Date()).toString());
  };

  return (
    <FixedDialogWrapper
      dialogId="change-your-plan-success-dialog"
      title="Change your Plan"
      maxWidth="sm"
      setOpen={setOpen}
      open={open}
    >
      <div className={classes.changePlanSuccessContainer}>
        <img
          className={classes.checkIcon}
          src="/images/common/yes.png"
          alt="upload success"
        />
        <div>You have changed your plan successfully.</div>
        <div>You can go to <Link className={`${classes.blue} ${classes.underlined} ${classes.pointer}`} onClick={redirectToCarrierSetupHandler}>Carrier Setup</Link> to set up the carriers or</div>
        <div>go to <Link className={`${classes.blue} ${classes.underlined} ${classes.pointer}`} onClick={redirectToStoreSetupHandler}>Store Setup</Link> to set up the stores.</div>
      </div>
    </FixedDialogWrapper>
  );
};

export const CancelPlanDialog = ({ open, setOpen, onSubmit = () => { }, isDowngradeToBeta = false }) => {

  const classes = useStyles();
  const { openAlertBar } = useAlertActions();
  const reasons = [
    "Couldn't connect to my store or shopping cart",
    "Couldn't connect to my shipping provider",
    "Missing Features",
    "No longer need shipping",
    "Too Expensive",
    "Printer Issues",
    "Less Shipments needed",
    "Other",
  ];

  const [selectedReason, setSelectedReason] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [cancelIsLoading, setCancelIsLoading] = useState(false);
  // cancel plan is special case.
  // needs its own allsubscriptionplan and user subscription because it is called outside of subscription
  // it is also called in master page
  // context will not work here
  const allSubscriptionPlans = useSelector(
    (state: RootState) => state.allSubscriptionPlans
  )
  const userSubscription = useSelector(
    (state: RootState) => state.userSubscription
  )

  const cancelHandler = async () => {

    setCancelIsLoading(true);
    let selectedPlanID = allSubscriptionPlans.data.filter((plan) => plan.title.toLowerCase() === PLAN.STARTER.toLowerCase());

    const body = {
      subscriptionplanid: selectedPlanID[0].subscriptionplanid,
      billingDetails: userSubscription?.data?.billingDetails && userSubscription?.data?.billingDetails?.paymentMethodId ?
        {
          paymentMethodId: userSubscription?.data?.billingDetails?.paymentMethodId
        } :
        {
          paymentMethodId: ""
        },
      promoId: 0,
      reason: selectedReason,
      additionalComments: additionalDetails === "" ? null : additionalDetails,
    };
    try {
      const { data } = await manageUserSubscription(body);

      if (data.status) {
        setCancelIsLoading(false);
        onSubmit();
        openAlertBar("You have successfully changed your subscription and are now in starter plan", true)
      } else {
        setCancelIsLoading(false);
        openAlertBar("Something went wrong with server trying to return data", false)
      }

    } catch (err) {
      openAlertBar("Something went wrong with cancelling user subscription", false)
    }
  };

  return (
    <FixedDialogWrapper
      dialogId="cancel-plan-dialog"
      title={"Change your Plan"}
      maxWidth="md"
      setOpen={setOpen}
      open={open}
      buttonDefinitions={[
        { label: 'Change Plan', color: 'secondary', disabled: !selectedReason || cancelIsLoading, isLoading: cancelIsLoading, onClick: cancelHandler },
      ]}
      closeDisabled={cancelIsLoading}
    >
      <div className={classes.cancelPlanContainer}>
        {userSubscription?.data?.subscriptionPlan?.subscripitionenddate ? (
          <div>
            <div>If you would like to change your subscription, you may do so below. You can continue to use your plan until {moment(userSubscription?.data?.subscriptionPlan?.subscripitionenddate).format('dddd MMMM DD, YYYY')} You can continue to use Shipvista through the end of your current plan, but your plan will not renew (Change to {isDowngradeToBeta ? PLAN.BETA : PLAN.STARTER} Plan) and you will not be charged any additional subscription fees.</div>
            <Br />
            <div>Primary Reason</div>
            <Br />
          </div>
        ) : (
          <div>
            <div>You can continue to use Shipvista and your current plan's feature until the end of your <span style={{ fontWeight: 600 }}>free trial</span>, but your plan will not renew (Change to {isDowngradeToBeta ? 'Beta' : 'Starter'} Plan) and you will not be charged any additional subscription fees.</div>
            <Br />
            <div>Primary Reason</div>
            <Br />
          </div>
        )}

        <DropdownWrapper
          btnName={selectedReason ? selectedReason : 'Choose Reason'}
          color="default"
          variant="outlined"
          size="medium"
          list={reasons}
          setSelect={setSelectedReason}
        />
        <Br />
        <div>Any additional details you're willing to share?</div>
        <Br />
        <TextField
          id="cancel-plan-additional-details"
          multiline
          rows={10}
          value={additionalDetails}
          onChange={(e) => { setAdditionalDetails(e.target.value); }}
          required={selectedReason === "Other"}
          variant="outlined"
        />
      </div>
    </FixedDialogWrapper>
  );
};

const Subscription = () => {
  const classes = useStyles();
  const getReduxUserSubscription = useDispatch();
  const { openAlertBar } = useAlertActions();
  let { userSubscription, allSubscriptionPlans } = useSelector((state: RootState) => state)

  // const [futureSubscriptionPlan,setFutureSubscriptionPlan]= useState(allSubscriptionPlans.data?.find((e) => e.subscriptionplanid === userSubscription?.data?.futuresubscriptionplanid))
  const [isLoading, setIsLoading] = useState(false);
  // const [planYearly, setPlanYearly] = useState(userSubscription?.data?.isYearly);
  const [allFeaturesDialogOpen, setAllFeaturesDialogOpen] = useState(false);
  const [seeAllFeaturePlanName, setSeeAllFeaturePlanName] = useState('')
  const [pastInvoicesOpen, setPastInvoicesOpen] = useState(false);

  const [cardData, setCardData] = useState([]);

  const [addBillingInformationDialogOpen, setAddBillingInformationDialogOpen] = useState(false);
  const [changePlanDialogOpen, setChangePlanDialogOpen] = useState(false);
  const [changePlanSuccessDialogOpen, setChangePlanSuccessDialogOpen] = useState(false);
  const [cancelPlanDialogOpen, setCancelPlanDialogOpen] = useState(false);
  const [changeBillingInformationDialogOpen, setChangeBillingInformationDialogOpen] = useState(false);
  const [isDowngradeToBeta, setIsDowngradeToBeta] = useState(false);
  const [issueChallenge, setIssueChallenge] = useState(false);
  const [formStringFromBackend, setFormStringFromBackend] = useState('')
  const mountedRef = useRef(false);

  const loc = useLocation();
  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [filledPromoCode, setFilledPromoCode] = useState<{
    title?: string;
    code?: string;
    term?: string;
    sendAnalytics?: boolean;
    onFinish?: () => void;
  } | null>(null);

  const { getQueryParams, clearQueryParams } = useNavQueryParams("SETTINGS_ACCOUNT_SUBSCRIPTION");

  useEffect(() => { 
    const { view } = getQueryParams();
    if (view){
      setChangePlanDialogOpen(true);
      clearQueryParams();
    }
  }, [getQueryParams, clearQueryParams]);


  useEffect(() => {
    const code = query.get("promoCode");
    const title = query.get("title");
    const term = query.get("term");
    if (title && term) {

      const foundTitle = Object.values(PLAN).find(p => p.toLowerCase() === title.toLowerCase());

      if (foundTitle && (term === "yearly" || term === "monthly")) {
        setChangePlanDialogOpen(true);
        setFilledPromoCode({ sendAnalytics: !!code, code, title: foundTitle, term, onFinish: () => setFilledPromoCode(null) });
        const params = new URLSearchParams(query);
        params.delete("promoCode");
        params.delete("title");
        params.delete("term");
        history.replace({ pathname: loc.pathname, search: params.toString() });

      }
    }
  }, [query])

  const doFetchCreditCardData = async () => {
    try {
      // const result = await getCustomerCards();
      // replacing customer cards with stripe payment methods 
      const { data } = await getPaymentMethods();

      if (!mountedRef.current) {
        return;
      }

      if (!data.status) {
        openAlertBar(data.message, false);
        return null;
      }
      return data.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;

      openAlertBar(JSON.stringify(errors) ?? 'Something happened', false);

      return null;
    }
  };


  const fetchCreditCardData = async () => {
    const result = await doFetchCreditCardData();
    setCardData(!result ? [] : result);
  }

  // fetch user subscription is used to call and update store
  const fetchUserSubscription = async () => {
    // fetching user subscription from redux store
    getReduxUserSubscription(gettingUserSubscription());
    // setPlanYearly(userSubscription?.data?.isYearly)

  };

  // refetch is used to set loading and call fetch user
  const refetchUserSubriptionPlan = async () => {
    setIsLoading(true);
    await fetchUserSubscription();
    // setPlanYearly(userSubscription?.data?.isYearly)
    setIsLoading(false);
  };

  useEffect(() => {
    mountedRef.current = true;

    const init = async () => {
      setIsLoading(true);
      await fetchUserSubscription()

      fetchCreditCardData();

      setIsLoading(false);
    };
    let getRef = new URLSearchParams(window.location.search);
    let parseURL = getRef.get('status');
    if (parseURL && parseURL.toLowerCase() === "succeeded".toLowerCase()) {
      setChangePlanSuccessDialogOpen(true)
      window.history.replaceState(null, null, window.location.pathname);
    }
    if (parseURL && parseURL.toLowerCase() === "failed".toLowerCase()) {
      window.history.replaceState(null, null, window.location.pathname);
      openAlertBar("Error with processing your credit card. Please try again later", false);
    }
    init();
    // set future subscription plan if user downgrades plan before end of billing cycle
    // setFutureSubscriptionPlan(allSubscriptionPlans.data?.find((e) => e.subscriptionplanid === userSubscription?.data?.futuresubscriptionplanid))
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // update user subscription is used to call manage user subscription service
  const doUpdateUserSubscription = async (_data) => {
    try {
      const result = await manageUserSubscription(_data);
      const { message, status } = result.data;
      if (result.data.code && result.data.code.toLowerCase() === "C".toLowerCase()) {
        setFormStringFromBackend(result?.data?.data)
        setIssueChallenge(true);
      }


      if (!mountedRef.current) {
        return;
      }

      if (!status) {
        openAlertBar(message, false);

        return null;
      }

      return status;
    } catch (err) {
      const errors = err?.response?.data?.errors;

      openAlertBar(JSON.stringify(errors) ?? 'Something happened', false);

      return null;
    }
  };

  const context = {
    filledPromoCode,
    isLoading,
    // planYearly,
    // setPlanYearly,
    // cardData,
    currencyFormatter,
    setAllFeaturesDialogOpen,
    setPastInvoicesOpen,
    setChangePlanSuccessDialogOpen,
    setCancelPlanDialogOpen,
    setChangeBillingInformationDialogOpen,
    refetchUserSubriptionPlan,
    fetchUserSubscription,
    fetchCreditCardData,
    openAlertBar,
    doUpdateUserSubscription,
    userSubscription,
    allSubscriptionPlans,
    seeAllFeaturePlanName,
    setSeeAllFeaturePlanName
  };
  // add / change billing information
  // if id (e.id) is not empty then user is updating, else user is adding
  const handleAddBillingInformationSubmit = async (e) => {
    try {
      const res = await doFetchCreditCardData();

      if (res) {
        const { data } = await updateUserBillingDetails(e.paymentMethodId ? e.paymentMethodId : res[0].paymentMethodId)

        if (data.status) {
          refetchUserSubriptionPlan()
          openAlertBar(data.message, true)
        }
      }

    } catch (err) {
      openAlertBar("Failed to connect to server error", false)
    }
  };

  const checkFutureSubscriptionPlan = () => {
    return allSubscriptionPlans.data?.find((e) => e.subscriptionplanid === userSubscription?.data?.futuresubscriptionplanid)
  }

  if (pastInvoicesOpen) {
    return (
      <SubscriptionPastInvoices onClose={() => { setPastInvoicesOpen(false); }} />
    );
  }

  return (
    <SubscriptionContext.Provider value={context}>
      <div className={classes.root}>
        <div className={classes.header}>
          <h4>Subscription</h4>
        </div>
        <div className={classes.content}>
          <div className={classes.cardContainer}>
            {
              checkFutureSubscriptionPlan() && (checkFutureSubscriptionPlan()?.title === PLAN.BETA || checkFutureSubscriptionPlan()?.title === PLAN.STARTER) && (
                <Alert icon={false} severity="info" className={classes.planCancelledInfo}>
                  <div className={classes.blue}>
                    You decided to cancel <span className={classes.bold}>{userSubscription?.data?.subscriptionPlan?.title}</span> Plan. You can continue to use your current plan until <span className={classes.bold}>{moment(userSubscription?.data?.unsubscribedate).format('MM/DD/YYYY')}</span>.
                  </div>
                </Alert>
              )
            }
            {
              checkFutureSubscriptionPlan() && checkFutureSubscriptionPlan()?.title !== PLAN.BETA && checkFutureSubscriptionPlan()?.title !== PLAN.STARTER && !userSubscription?.data?.isYearly && (
                <Alert icon={false} severity="info" className={classes.planCancelledInfo}>
                  <div className={classes.blue}>
                    You decided to change your plan from {userSubscription?.data?.subscriptionPlan?.title} Plan to
                    <span className={classes.bold}>&nbsp;{checkFutureSubscriptionPlan()?.title} {userSubscription?.data?.futureIsYearly ? '(Yearly) ' : ''} Plan</span>.
                    On <span className={classes.bold}> {moment(userSubscription?.data?.subscriptionPlan?.hastrialperiod ? userSubscription?.data?.subscriptionPlan?.trialenddate : userSubscription?.data?.subscriptionPlan?.subscripitionenddate).format('MM/DD/YYYY')}</span>,
                    you will be charged {userSubscription?.data?.futureIsYearly ? (
                      <span className={classes.bold}>
                        {`${currencyFormatter.format(allSubscriptionPlans.data?.find((e) => e.subscriptionplanid === userSubscription?.data?.futuresubscriptionplanid)?.yearlyprice)} CAD per year`}
                      </span>
                    ) :
                      (
                        <span className={classes.bold}>
                          {`${currencyFormatter.format(allSubscriptionPlans.data?.find((e) => e.subscriptionplanid === userSubscription?.data?.futuresubscriptionplanid)?.price?.amount)} CAD per month`}
                        </span>
                      )}
                  </div>
                </Alert>
              )
            }
            {
              checkFutureSubscriptionPlan() && checkFutureSubscriptionPlan()?.title !== PLAN.BETA && checkFutureSubscriptionPlan()?.title !== PLAN.STARTER && userSubscription?.data?.isYearly && (
                <Alert icon={false} severity="info" className={classes.planCancelledInfo}>
                  <div className={classes.blue}>
                    You decided to change your plan from Yearly {userSubscription?.data?.subscriptionPlan?.title} Plan to
                    <span className={classes.bold}>{userSubscription?.data?.futureIsYearly ? ' Yearly' : ' Monthly'}  {checkFutureSubscriptionPlan()?.title} Plan</span>. On <span className={classes.bold}> {moment(userSubscription?.data?.subscriptionPlan?.hastrialperiod ? userSubscription?.data?.subscriptionPlan?.trialenddate : userSubscription?.data?.subscriptionPlan?.subscripitionenddate).format('MM/DD/YYYY')}</span>, you will be charged <span className={classes.bold}>{`${currencyFormatter.format(allSubscriptionPlans.data?.find((e) => e.subscriptionplanid === userSubscription?.data?.futuresubscriptionplanid)?.price?.amount)} CAD per month`}</span>.
                  </div>
                </Alert>
              )
            }
            {
              checkFutureSubscriptionPlan()?.futuresubscriptionplanid && (
                <Br count={2} />
              )
            }
            {
              isLoading && (
                <>
                  <Card title="Your Plan">
                    <div className={classes.loadingWrapper}>
                      <CircularProgress />
                    </div>
                  </Card>
                  <Br count={2} />
                  <Card title="Billing Information">
                    <div className={classes.loadingWrapper}>
                      <CircularProgress />
                    </div>
                  </Card>
                </>
              )
            }
            {
              !isLoading && (
                <>
                  <Card title="Your Plan">
                    <YourPlanContent
                      features={userSubscription?.data?.subscriptionPlan?.features.slice(0, 3).map((e) => e.featureName)}
                    // userSubscription={userSubscription}
                    />
                    <div className={classes.actionButtonContainer}>
                      {
                        checkFutureSubscriptionPlan()?.title.toLowerCase() !== PLAN.STARTER.toLowerCase() && userSubscription?.data?.subscriptionPlan?.title.toLowerCase() !== PLAN.BETA.toLowerCase() && userSubscription?.data?.subscriptionPlan?.title.toLowerCase() !== PLAN.STARTER.toLowerCase() && (checkFutureSubscriptionPlan()?.title !== PLAN.STARTER || checkFutureSubscriptionPlan()?.title !== PLAN.BETA) && (
                          <Button
                            className={classes.button}
                            variant="outlined"
                            color="secondary"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              setCancelPlanDialogOpen(true);
                            }}
                          >
                            Cancel Plan
                          </Button>
                        )
                      }
                      <Button
                        className={`${classes.button} ${classes.primaryButton}`}
                        onClick={() => { setChangePlanDialogOpen(true); }}
                      >
                        Change Plan
                      </Button>
                    </div>
                  </Card>
                  <Br count={2} />
                  {
                    userSubscription?.data?.billingDetails ? (
                      <Card title="Billing Information">
                        <BillingInformationContent
                          // billingInformation={billingInformationDetails}
                          userSubscription={userSubscription}
                        />
                        <div className={classes.actionButtonContainer}>
                          <Button
                            className={`${classes.button} ${classes.primaryButton}`}
                            style={{ marginRight: 10 }}
                            onClick={() => { setChangeBillingInformationDialogOpen(true); }}
                          >
                            Change Billing Information
                          </Button>
                        </div>
                      </Card>
                    ) : (
                      <Card title="Billing Information">
                        <div className={classes.centered}>
                          <div>
                            <div>You don't have any billing information yet.</div>
                            <div
                              className={`${classes.blue} ${classes.underlined} ${classes.pointer}`}
                              onClick={() => setAddBillingInformationDialogOpen(true)}
                            >
                              Add Billing Information
                            </div>
                          </div>
                        </div>
                      </Card>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
        {
          allFeaturesDialogOpen && (
            <AllFeaturesDialog
              open={allFeaturesDialogOpen}
              setOpen={setAllFeaturesDialogOpen}
              planTitle={seeAllFeaturePlanName}
              coreFeatures={allSubscriptionPlans.data
                .filter((plan) => plan.title.toLowerCase() === seeAllFeaturePlanName.toLowerCase())[0].features
                .filter((e) => e.featureCategory === 'CORE')
                .map((e) => ({
                  name: e.featureName,
                  value: e.featureType === 'BOOL' ?
                    e.featureValue.toLowerCase() === 'YES'.toLowerCase() :
                    e.featureValue
                }))}
              advanceFeatures={allSubscriptionPlans.data
                .filter((plan) => plan.title.toLowerCase() === seeAllFeaturePlanName.toLowerCase())[0].features
                .filter((e) => e.featureCategory === 'ADVANCED')
                .map((e) => ({
                  name: e.featureName,
                  value: e.featureType === 'BOOL' ?
                    e.featureValue.toLowerCase() === 'Yes'.toLowerCase() :
                    e.featureValue
                }))}
            />
          )
        }
        {
          addBillingInformationDialogOpen && (
            <AddBillingInformationDialog
              open={addBillingInformationDialogOpen}
              setOpen={setAddBillingInformationDialogOpen}
              onSubmit={(e) => {
                setAddBillingInformationDialogOpen(false);
                handleAddBillingInformationSubmit(e);
                // refetchUserSubriptionPlan()
              }}

            />
          )
        }
        {
          changeBillingInformationDialogOpen && (
            <ChangeBillingInformationDialog
              open={changeBillingInformationDialogOpen}
              setOpen={setChangeBillingInformationDialogOpen}
              onSubmit={(e) => {
                setChangeBillingInformationDialogOpen(false);
                handleAddBillingInformationSubmit(e)
                // refetchUserSubriptionPlan()
              }}
            // billingInformationDetails={billingInformationDetails}
            />
          )
        }

        {
          changePlanDialogOpen && (
            <ChangePlanDialog
              open={changePlanDialogOpen}
              setIsDowngradeToBeta={setIsDowngradeToBeta}
              // billingInformationDetails={billingInformationDetails}
              setOpen={(e) => {
                // if (!e) {
                //   updateFeatures(userSubscription?.data?.subscriptionPlan?.title);
                // }
                setChangePlanDialogOpen(e);


              }}
            />
          )
        }
        {
          changePlanSuccessDialogOpen && (
            <ChangePlanSuccessDialog
              open={changePlanSuccessDialogOpen}
              setOpen={setChangePlanSuccessDialogOpen}
            />
          )
        }
        {
          cancelPlanDialogOpen && (
            <CancelPlanDialog
              open={cancelPlanDialogOpen}
              isDowngradeToBeta={isDowngradeToBeta}
              setOpen={setCancelPlanDialogOpen}
              onSubmit={() => {
                refetchUserSubriptionPlan();
                setCancelPlanDialogOpen(false);
                setChangePlanDialogOpen(false);

              }}
            // billingInformationDetails={billingInformationDetails}
            />
          )
        }
        {/* {issueChallenge &&
          <Challenge formStringFromBackend={formStringFromBackend} />
        } */}
      </div>
    </SubscriptionContext.Provider>
  );
};

export default Subscription;
