import { BatchesHistoryAction, BatchesHistoryState,BatchesHistoryActions } from "../@types/batchesHistory";

const initialState: BatchesHistoryState = {
    batchesHistory: [],
    batchesHistoryDetailsBatchesHistory: null,
    isTableLoading: "Success" 
};

export function batchesHistoryReducer(
    state = initialState,
    action: BatchesHistoryAction
): BatchesHistoryState {
    switch (action.type) {
        case BatchesHistoryActions.LOAD_BATCHESHISTORY_DATA:
            return { ...state, batchesHistory: action.payload }
        case BatchesHistoryActions.SET_BATCHESHISTORY_DETAILS_BATCHESHISTORY:
            return { ...state, batchesHistoryDetailsBatchesHistory: action.payload }
        case BatchesHistoryActions.SET_BATCHESHISTORY_TABLE_LOADING:
            return { ...state, isTableLoading: action.payload } 
        default:
            return state;
    }
}