import { Address } from "../@type/Orders/Address";
import { addressMapper } from "../mappers/addressBook/addressMapper";
import shipvistaAPI from "./shipvistaAPI";

export const getAddressesService = () => {
  return shipvistaAPI.get(`/addresses`);
};

export const addNewAddressService = (address: Address) => {
  const newAddressReq = addressMapper(address);
  return shipvistaAPI.post(`/addresses/addaddress`, newAddressReq);
};

export const updateAddressService = (address: Address) => {
  const updateAddressReq = addressMapper(address);
  return shipvistaAPI.put(`/addresses`, updateAddressReq);
};

export const deleteAddressesService = (ids: number[]) => {
  return shipvistaAPI.delete(`/addresses`, {
    data: ids,
  });
};
