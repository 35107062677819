import React from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import moment from "moment";
import { Order } from "../../../../../../@type/Orders/Order";
import DraggableDialog from "../../../../../utilities/DraggableDialog";

const useStylesMaterialUI = makeStyles(() =>
    createStyles({
        PDFRoot: {
            width: "100%",
            height: "80vh"
        }
    }),
);

const stylesPDF = StyleSheet.create({
    page: {
        paddingVertical: 35,
        paddingHorizontal: 35,
    },
    headerTxt: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        color: "white",
        backgroundColor: "grey",
        padding: 2
    },
    senderInfo: {
        marginVertical: 10
    },
    senderName: {
        fontSize: 12,
        fontFamily: 'Open Sans',
        fontWeight: 600
    },
    text: {
        fontSize: 9,
        fontFamily: 'Open Sans',
    },
    textBold: {
        fontSize: 9,
        fontFamily: 'Open Sans',
        fontWeight: 600
    },
    shipToOrderViewOuter: {
        flexDirection: "row",
        marginBottom: 10
    },
    shipToView: {
        flexGrow: 2
    },
    orderViewOuter: {
        flexGrow: 1
    },
    orderView: {
        display: "flex",
        flexDirection: "row"
    },
    table: {
        display: "table",
        width: "100%",
        borderColor: "darkgrey",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol1: {
        width: "20%",
    },
    tableCol2: {
        width: "75%",
    },
    tableCol3: {
        width: "5%",
    },
    tableHeaderCell: {
        padding: 3,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 9,
        backgroundColor: "grey",
        color: "white"
    },
    centerTableHeaderCell: {
        padding: 3,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 9,
        backgroundColor: "grey",
        color: "white",
        textAlign: "center"
    },
    tableCell: {
        paddingHorizontal: 3,
        paddingVertical: 5,
        fontFamily: 'Open Sans',
        fontSize: 9,
    },
    centerTableCell: {
        paddingHorizontal: 3,
        paddingVertical: 5,
        fontFamily: 'Open Sans',
        fontSize: 9,
        textAlign: "center"
    }
});

// register font
Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
});

// break words callback function
Font.registerHyphenationCallback(word => {
    const middle = Math.floor(word.length / 2);
    const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

    return parts;
});

const userInfo = {
    userName: "myuser",
    address: "9-9 Royalcrest Rd",
    city: "Etobicoke",
    state: "ON",
    postalCode: "M9V 2L6"
}

interface Props {
    open: boolean;
    setOpen: (value: React.SetStateAction<boolean>) => void;
    orders: Order[];
}

export default function PrintPackingSlip(props: Props) {
    const classes = useStylesMaterialUI();

    const { open, setOpen, orders } = props;

    return (
        <DraggableDialog
            maxWidth={"lg"}
            title={"Print Packing Slip"}
            open={open}
            setOpen={setOpen}
            dialogId="printPackingSlip">
            <PDFViewer className={classes.PDFRoot}>
                <Document title={`Packing Slips`}>
                    {orders.map(order =>
                        <Page key={`packingSlip${order.orderNo}`} size="A4" style={stylesPDF.page}>
                            <Text style={stylesPDF.headerTxt}>Packing Slip</Text>
                            <View style={stylesPDF.senderInfo}>
                                <Text style={stylesPDF.senderName}>{userInfo.userName}</Text>
                                <Text style={stylesPDF.text}>{userInfo.address}</Text>
                                <Text style={stylesPDF.text}>{`${userInfo.city}, ${userInfo.state} ${userInfo.postalCode}`}</Text>
                            </View>
                            <View style={stylesPDF.shipToOrderViewOuter}>
                                <View style={stylesPDF.shipToView}>
                                    <Text style={stylesPDF.textBold}>Ship To</Text>
                                    <Text style={stylesPDF.text}>{order?.shipTo?.name}</Text>
                                    <Text style={stylesPDF.text}>{order?.shipTo?.streetAddress}</Text>
                                    <Text style={stylesPDF.text}>{`${order?.shipTo?.city}, ${order?.shipTo?.stateCode} ${order?.shipTo?.postalCode}, ${order?.shipTo?.countryCode}`}</Text>
                                    <Text style={stylesPDF.text}>{order?.shipTo?.phone}</Text>
                                </View>
                                <View style={stylesPDF.orderViewOuter}>
                                    <View style={stylesPDF.orderView}>
                                        <Text style={stylesPDF.textBold}>Order#: </Text>
                                        <Text style={stylesPDF.text}>{order.orderNo}</Text>
                                    </View>
                                    <View style={stylesPDF.orderView}>
                                        <Text style={stylesPDF.textBold}>Order Date: </Text>
                                        <Text style={stylesPDF.text}>{moment(order.orderDate).format("YYYY-MM-DD")}</Text>
                                    </View>
                                    <View style={stylesPDF.orderView}>
                                        <Text style={stylesPDF.textBold}>Store: </Text>
                                        <Text style={stylesPDF.text}>{order.store}</Text>
                                    </View>
                                    <View style={stylesPDF.orderView}>
                                        <Text style={stylesPDF.textBold}>User Email: </Text>
                                        <Text style={stylesPDF.text}>{order.soldTo ? order.soldTo.email : ""}</Text>
                                    </View>
                                    <View style={stylesPDF.orderView}>
                                        <Text style={stylesPDF.textBold}>Ship Date: </Text>
                                        <Text style={stylesPDF.text}>{order.fulfillmentInfo ? moment(order.fulfillmentInfo.shipDate).format("YYYY-MM-DD") : ""}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={stylesPDF.table}>
                                {/* TableHeader */}
                                <View style={stylesPDF.tableRow}>
                                    <View style={stylesPDF.tableCol1}>
                                        <Text style={stylesPDF.tableHeaderCell}>Item</Text>
                                    </View>
                                    <View style={stylesPDF.tableCol2}>
                                        <Text style={stylesPDF.tableHeaderCell}>Description</Text>
                                    </View>
                                    <View style={stylesPDF.tableCol3}>
                                        <Text style={stylesPDF.centerTableHeaderCell}>Qty</Text>
                                    </View>
                                </View>
                                {/* TableContent */}
                                {order.orderItems.map((item, i) =>
                                    <View key={`packingSlip-${order.orderNo}-${i}`} style={stylesPDF.tableRow}>
                                        <View style={stylesPDF.tableCol1}>
                                            <Text style={stylesPDF.tableCell}>{item.itemSKU}</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol2}>
                                            <Text style={stylesPDF.tableCell}>{item.itemName}</Text>
                                        </View>
                                        <View style={stylesPDF.tableCol3}>
                                            <Text style={stylesPDF.centerTableCell}>{item.quantity}</Text>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </Page>
                    )}
                </Document>
            </PDFViewer>
            {/* <Divider />
            <DialogActions>
                <Button autoFocus color="primary">
                    Save as PDF
                </Button>
                <Button autoFocus color="primary">
                    Print
                </Button>
            </DialogActions> */}
        </DraggableDialog>
    );
}