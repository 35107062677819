import { refreshTokenKey, tokenCookieName } from "../environment/env_dev";

const getCookie = (tokenCookieName, key) => {
    const cookie =
        document.cookie.split('; ')
            .find(row => row.startsWith(tokenCookieName));

    if (!cookie) {
        const sessionCookie = window.localStorage.getItem(key);

        return sessionCookie ? sessionCookie : '';
    }

    const obj =
        cookie
            .slice(tokenCookieName.length + 1).split("&")
            .find(row => row.startsWith(key));

    if (!obj) {
        const sessionCookie = window.localStorage.getItem(key);

        return sessionCookie ? sessionCookie : '';
    }

    return obj.split('=')[1];
};

export const getAccessToken = (tokenCookieName) => {
    try {
        return getCookie(tokenCookieName, 'accesstoken');
    } catch (err) {

        return '';
    }
};

export const getExpireAt = (tokenCookieName) => {
    try {
        return getCookie(tokenCookieName, 'expireAt');
    } catch (err) {

        return '';
    }
};

export type RefreshResult = { refreshToken: string; expiredAt: string };
const refreshExpiryPartialKey = "Expiry";

export const getRefreshToken = (refreshTokenKey: string): RefreshResult => {
    return { refreshToken: localStorage.getItem(refreshTokenKey) || '', expiredAt: localStorage.getItem(refreshTokenKey + refreshExpiryPartialKey) || '' };
}

export const setRefreshToken = (refreshTokenKey: string, refreshToken: string, expiry: string): RefreshResult => {
    const oldValue = localStorage.getItem(refreshTokenKey);
    const oldValueExpiry = localStorage.getItem(refreshTokenKey + refreshExpiryPartialKey);
    localStorage.setItem(refreshTokenKey, refreshToken);
    localStorage.setItem(refreshTokenKey + refreshExpiryPartialKey, expiry);
    if (oldValue) {
        return { refreshToken: oldValue, expiredAt: oldValueExpiry || '' };
    } else {
        return { refreshToken: '', expiredAt: oldValueExpiry || '' };
    }
}

export const clearTokens = (accessTokenCookieName: string, accessTokenSessionKey: string, accessTokenSessionExpiryKey: string, refreshTokenKey: string) => {
    // Should be the same code as logout function in MasterPage but modified to provide the keys

    document.cookie = `${accessTokenCookieName}=`
    window.localStorage.setItem(accessTokenSessionKey, '');
    window.localStorage.setItem(accessTokenSessionExpiryKey, '');

    setRefreshToken(refreshTokenKey, '', '');

}

// Note: Refactor this function later so that all keys are parameters
export const saveTokens = (loginResponse, rememberMe: boolean) => {
    // Same code as in the loginHandlerService except rememberMe is a parameter
    if (loginResponse.data.access_token) {
        const { tokenString, expireAt } = loginResponse.data.access_token;

        if (!rememberMe) {
            window.localStorage.setItem('accesstoken', tokenString);
            window.localStorage.setItem('expireAt', expireAt);
        } else {
            document.cookie = `${tokenCookieName}=accesstoken=${tokenString}&expireAt=${expireAt}`
        }

        if (loginResponse.data.refresh_token) {
            const { tokenString: refreshString, expireAt: refreshExpireAt } = loginResponse.data.refresh_token;
            setRefreshToken(refreshTokenKey, refreshString, refreshExpireAt);
        }
    }


} 
