import { activator, createNavQueryManager } from "./useGenericNavQueryParams";

import { MENU_MAP } from "../components/masterPage/common";

export type ValidRouteKeys = keyof typeof MENU_MAP;

// properties must be keys in the MENU_MAP
// must provide sample values for each property below in routeMapping
// values must be object with properties of valid types in useGenericNavQueryParams

export interface PageMappingDefinition {
    SETTINGS_INTEGRATIONS_CARRIER_SETUP: {
        type: "sv" | "customer";
    },
    SETTINGS_ACCOUNT_SUBSCRIPTION: {
        view: boolean;
    },
}

export const routeMapping = activator<PageMappingDefinition>({
    SETTINGS_INTEGRATIONS_CARRIER_SETUP: {
        sample: {
            type: "sv",
        }
    },
    SETTINGS_ACCOUNT_SUBSCRIPTION: {
        sample: {
            view: false,
        }
    },
});


export const { useNavQueryParams, NavQueryContext } = createNavQueryManager<PageMappingDefinition>(routeMapping);