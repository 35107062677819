import shipvistaAPI from "./shipvistaAPI";

export const addDeposit = (cardId, amount, purchaseBody) => {
  return shipvistaAPI.post(`/deposit/AddDeposit`, {
    amount: amount,
    cardInfo: { id: cardId },
    purchaseBody,
    clientCallBack: window.location.href,
  });
};

export const initializePayment = (paymentInfo: any) => {
  return shipvistaAPI.post(`/deposit/InitPaymentRequest`, paymentInfo);
};

export const completePayment = (paymentData) => {
  return shipvistaAPI.post(`/deposit/CompletePayment`, paymentData);
};
