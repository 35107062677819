import { useDispatch } from "react-redux";
import { toast } from "material-react-toastify";

import { AlertActions } from "../@types/alert/alertActions";

export function useAlertActions() {
  const dispatch = useDispatch();

  function openAlertBar(
    alertMsg: string | JSX.Element,
    isSuccess: boolean,
    custom: JSX.Element = null
  ) {
    if (!alertMsg) return;

    if (isSuccess) {
      toast.success(alertMsg, { autoClose: 3000 });
    } else {
      toast.error(alertMsg);
    }
    // return dispatch({ type: AlertActions.OPEN_ALERT_BAR, payload: alertMsg, payload2: isSuccess, payload3: custom });
  }

  function closeAlertBar() {
    return dispatch({ type: AlertActions.CLOSE_ALERT_BAR });
  }

  return {
    openAlertBar,
    closeAlertBar,
  };
}
