import { HoldOrdersRestoreState, HoldOrdersRestoreAction, HoldOrdersRestoreActions } from "../@types/holdOrdersRestore";

const initialState: HoldOrdersRestoreState = {
    holdOrdersChanges: [],
};

export function holdOrdersRestoreReducer(
    state = initialState,
    action: HoldOrdersRestoreAction
): HoldOrdersRestoreState {
    switch (action.type) {
        case HoldOrdersRestoreActions.LOAD_HOLD_ORDERS_CHANGES_DATA:
            return { ...state, holdOrdersChanges: action.payload }
        default:
            return state;
    }
}