type SET_OA_OFFER_APPLIED = "SET_OA_OFFER_APPLIED";

type actionType = {
    SET_OA_OFFER_APPLIED: SET_OA_OFFER_APPLIED;
};

export const OffersActions: actionType = {
    SET_OA_OFFER_APPLIED: 'SET_OA_OFFER_APPLIED',
};

export type OffersAction = { type: SET_OA_OFFER_APPLIED, payload: boolean }
