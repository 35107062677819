import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';

const getMeasurementId = () => {
    const loc = window.location.origin;
    if (loc.includes("sandbox") || loc.includes("sandboxapp")){
        return "";
    } else if (loc.includes("beta")) {
        return "";
    } else if (loc.includes("spa")) {
        return "";
    } else if (loc.includes("testapp")) {
        return "G-12W5TKPGMT"; // dev test GA4 measurement id
    } else if (loc.includes("app")) {
        //app.shipvista.com
        return "G-H8MXYQQW7P"; // prod GA4 measurement id
    } else if (loc.includes("localhost:3000")) {
        return "G-JN7YNY4ESD"; // dev test GA4 measurement id
    } else {
        //shipvista.com
        return "G-H8MXYQQW7P"; // prod GA4 measurement id
    }
}

const getGTMId = () => {
    const tagManagerArgs = {
        gtmId: '',
    }
    const loc = window.location.origin;
    if (loc.includes("sandbox") || loc.includes("sandboxapp")){
        tagManagerArgs.gtmId = "GTM-KGTWZ5M"; // dev GTM id
    } else if (loc.includes("beta")) {
        tagManagerArgs.gtmId = "GTM-KGTWZ5M"; // dev GTM id
    } else if (loc.includes("spa")) {
        tagManagerArgs.gtmId = "GTM-KGTWZ5M"; // dev GTM id
    } else if (loc.includes("testapp")) {
        tagManagerArgs.gtmId = "GTM-KGTWZ5M"; // dev GTM id
    } else if (loc.includes("app")) {
        //app.shipvista.com
        tagManagerArgs.gtmId = "GTM-NDJNBPD"; // prod GTM id
    } else if (loc.includes("localhost:3000")) {
        tagManagerArgs.gtmId = "GTM-KGTWZ5M"; // dev GTM id
    } else {
        //shipvista.com
        tagManagerArgs.gtmId = "GTM-NDJNBPD"; // prod GTM id
    }


    return tagManagerArgs;
}

function init() {
  // Enable debug mode on the local development environment
  const isDev = false; //!process.env.NODE_ENV || process.env.NODE_ENV === "development";
  const measurementId = getMeasurementId();
  if (measurementId){
      ReactGA.initialize(measurementId, { testMode: isDev});
      return true;
  }
  return false;
}

function initGTM() {
    const tagManagerArgs = getGTMId();
    if (tagManagerArgs.gtmId){
        TagManager.initialize(tagManagerArgs);
        return true;
    }
    return false

}

function sendEvent(payload) {
  ReactGA.event(payload)
}

function sendPageview(path: string) {
  ReactGA.send({ hitType: "pageview", page: path })
}

export default {
  init,
  sendEvent,
  sendPageview,
  initGTM,
}