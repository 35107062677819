import { manageUserSubscriptionMapper } from "../mappers/subscription/manageUserSubscriptionMapper";
import shipvistaAPI from "./shipvistaAPI";

export const getUserSubscription = () => {
  return shipvistaAPI.get(`/subscription/GetUserSubscription`);
};

export const getAllSubscriptionPlans = () => {
  return shipvistaAPI.get(`/subscription/GetAllSubScriptionPlans`);
};

export const changeUserSubscription = (
  _data: any,
  _subscriptionPlanId,
  _promoId
) => {
  const req = {
    subscriptionPlanId: _subscriptionPlanId,
    billingDetails: _data,
    promoId: _promoId,
  };

  return shipvistaAPI.post(`/subscription/ManageUserSubscription`, req);
};

export const getInvoices = (
  pageIndex: number = 1,
  pageSize: number = 20,
  sortVal?: string,
  sortType?: "ASC" | "DESC" | null,
  searchText?: string,
  dateFilter?: string,
  type?: string
) => {
  const params = [];

  params.push({ key: "Limit", value: pageSize });

  if (pageIndex) {
    params.push({ key: "Page", value: pageIndex });
  }

  if (sortType) {
    params.push({ key: "SortType", value: sortType });
    params.push({ key: "SortColumn", value: sortVal });
  }

  if (searchText) {
    params.push({ key: "SearchText", value: searchText });
  }
  if (dateFilter) {
    let startDate = "";
    let endDate = "";

    if (dateFilter.includes("-")) {
      const arrDates = dateFilter.split(" - ");

      startDate = arrDates[0].replaceAll("/", "-");
      endDate = arrDates[1].replaceAll("/", "-");
    } else {
      startDate = endDate = dateFilter.replaceAll("/", "-");
    }

    params.push({ key: "MinDate", value: startDate });
    params.push({ key: "MaxDate", value: endDate });
  }

  if (type) {
    params.push({ key: "InvoiceType", value: type });
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return shipvistaAPI.get(`/subscription/GetAllInvoice${paramString}`);
};

export const updateUserBillingDetails = (_billingDetails) => {
  let req = {
    billingDetails: {
      paymentMethodId: _billingDetails,
    },
  };
  return shipvistaAPI.post(`/subscription/UpdateUserBillingDetails`, req);
};

export const manageUserSubscription = (data) => {
  let request = manageUserSubscriptionMapper(data);
  return shipvistaAPI.post(`/subscription/ManageUserSubscription`, request);
};

export const encryptCardNumber = (_cardNumber) => {
  return shipvistaAPI.post(
    `/subscription/encrypt-decrypt?input=1&val=${_cardNumber}`
  );
};
