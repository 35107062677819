import React from "react";

// The solution is from https://github.com/tannerlinsley/react-table/discussions/1989

interface Props {
    indeterminate?: boolean;
    name: string;
    isChecked?: boolean;
  }
  
  const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
    const targetRef = React.useRef();
  
    React.useEffect(() => {
      refs.forEach(ref => {
        if (!ref) return;
  
        if (typeof ref === 'function') {
          ref(targetRef.current);
        } else {
          ref.current = targetRef.current;
        }
      });
    }, [refs]);
  
    return targetRef;
  };
  
  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, Props>(
    ({ isChecked, indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef(null);
      const combinedRef = useCombinedRefs(ref, defaultRef);
  
      React.useEffect(() => {
        if (combinedRef?.current) {
          combinedRef.current.indeterminate = indeterminate ?? false;
        }
      }, [combinedRef, indeterminate]);
  
      return (
        <React.Fragment>
          <input type="checkbox" checked={isChecked} ref={combinedRef} {...rest} />
        </React.Fragment>
      );
    }
  );
  
  export default IndeterminateCheckbox;