import moment from "moment";

import { getCountry } from "../../functionUtilities/getCountryCode";

// the library has bug when taking parameter to transform in array item
var objectMapper = require("object-mapper");

export const getAllOrdersMapper = (data: any) =>
  objectMapper(data, map).map((order) => {
    let orderPre = order;

    if (!orderPre.orderItems) {
      orderPre.orderItems = [];
    }

    if (!orderPre.orderTags) {
      orderPre.orderTags = [];
    }

    if (!orderPre.fulfillmentInfo) {
      orderPre.fulfillmentInfo = {};
    }

    if (!orderPre.customDetails) {
      orderPre.customDetails = {};
    }

    return {
      ...orderPre,
      age: ageMapper(order.orderDate),
      shipTo: {
        ...order.shipTo,
        country: getCountry(order.shipTo?.countryCode)
          ? getCountry(order.shipTo?.countryCode)
          : "",
        countryCode: getCountry(order.shipTo?.countryCode)
          ? order.shipTo?.countryCode
          : "",
      }, // if the countryCode is null or cannot be found, default value is CA (to avoid the problem for calling discover service api)
      internalNotes: order.internalNotes[0]
        ? {
            id: order.internalNotes[0].id,
            message: order.internalNotes[0].message,
          }
        : { message: "" },
    }; // workaround to fix mappers error
  });

const map = {
  "[].id": "[].id",
  "[].age": "[].age",
  "[].importedOn": "[].created_at",
  "[].marketPlaceOrderId": "[].orderNo",
  "[].purchaseDate": "[].orderDate",
  "[].salesChannel": "[].store",
  "[].storeName": "[].storeName",
  "[].numberOfItems": "[].qty",
  "[].amount.currency": "[].orderTotal.currency",
  "[].fulfillmentInfo.status": "[].status",
  "[].shippingServiceLevel": "[].requested",
  "[].amount.amount": "[].orderTotal.amount",
  "[].internalNotes": "[].internalNotes",
  "[].serviceType": "[].serviceType",
  "[].shipFromId": "[].shipFromId",
  "[].shippingCarrier": "[].shippingCarrier",
  "[].marketPlaceAccountId": "[].marketPlaceAccountId",

  "[].orderItems[].id": "[].orderItems[].id",
  "[].orderItems[].title": "[].orderItems[].itemName",
  "[].orderItems[].sellerSKU": "[].orderItems[].itemSKU",
  "[].orderItems[].asin": "[].orderItems[].ASIN",
  "[].orderItems[].giftMessage": "[].orderItems[].giftMessage",
  "[].orderItems[].isGift": "[].orderItems[].isGift",
  "[].orderItems[].quantity": "[].orderItems[].quantity",
  "[].orderItems[].price": "[].orderItems[].price",
  "[].orderItems[].imageUrl": "[].orderItems[].imageUrl",
  "[].orderItems[].weight": "[].orderItems[].weight",
  "[].orderItems[].dimensions.length": "[].orderItems[].dimensions.length",
  "[].orderItems[].dimensions.width": "[].orderItems[].dimensions.width",
  "[].orderItems[].dimensions.height": "[].orderItems[].dimensions.height",

  "[].customDetails[].id": "[].customDetails[].id",
  "[].customDetails[].currency": "[].customDetails[].currency",
  "[].customDetails[].orderId": "[].customDetails[].orderId",
  "[].customDetails[].countryOfOrigin": "[].customDetails[].countryOfOrigin",
  "[].customDetails[].numberOfUnits": "[].customDetails[].numberOfUnits",
  "[].customDetails[].reasonForExport": "[].customDetails[].reasonForExport",
  "[].customDetails[].unitOfMeasurement":
    "[].customDetails[].unitOfMeasurement",
  "[].customDetails[].valuePerUnit": "[].customDetails[].valuePerUnit",
  "[].customDetails[].weight": "[].customDetails[].weight",
  "[].customDetails[].description": "[].customDetails[].description",
  "[].customDetails[].hsCode": "[].customDetails[].harmonizationCode",

  "[].lineItem.length": "[].lineItem.length",
  "[].lineItem.height": "[].lineItem.height",
  "[].lineItem.width": "[].lineItem.width",
  "[].lineItem.weight": "[].lineItem.weight",
  "[].lineItem.packagingType": "[].lineItem.packagingType",
  "[].lineItem.unitOfMeasurement": "[].lineItem.unitOfMeasurement",
  "[].lineItem.insuranceType": "[].lineItem.insuranceType",
  "[].lineItem.declaredValue.currency": "[].lineItem.declaredValue.currency",
  "[].lineItem.declaredValue.amount": "[].lineItem.declaredValue.amount",

  "[].buyerNote": "[].buyerNotes",
  "[].buyer.email": "[].soldTo.email",
  "[].buyer.buyerName": "[].soldTo.name",
  "[].buyer.phone": "[].soldTo.phone",

  "[].shippingAddress.name": "[].shipTo.name",
  "[].shippingAddress.companyName": "[].shipTo.companyName",
  "[].shippingAddress.streetAddress": "[].shipTo.streetAddress",
  "[].shippingAddress.streetAddress2": "[].shipTo.streetAddress2",
  "[].shippingAddress.city": "[].shipTo.city",
  "[].shippingAddress.countryCode": "[].shipTo.countryCode",
  "[].shippingAddress.stateCode": "[].shipTo.stateCode",
  "[].shippingAddress.postalCode": "[].shipTo.postalCode",
  "[].shippingAddress.email": "[].shipTo.email",
  "[].shippingAddress.phone": "[].shipTo.phone",

  "[].orderActivities[].orderId": "[].orderActivities[].id",
  "[].orderActivities[].timeStamp": "[].orderActivities[].activityTime",
  "[].orderActivities[].name": "[].orderActivities[].person",
  "[].orderActivities[].activity": "[].orderActivities[].activityNotes",

  "[].fulfillmentInfo.carrier": "[].fulfillmentInfo.carrier",
  "[].fulfillmentInfo.trackingNumber": "[].fulfillmentInfo.trackingNo",
  "[].fulfillmentInfo.shipDate": "[].fulfillmentInfo.shipDate",
  "[].fulfillmentInfo.labelLinks": "[].fulfillmentInfo.labelLinks",
  "[].fulfillmentInfo.holdUntil": "[].fulfillmentInfo.holdUntil",

  "[].orderTags[].id": "[].orderTags[].id",
  "[].orderTags[].name": "[].orderTags[].name",
  "[].orderTags[].color": "[].orderTags[].color",
};

export const ageMapper = (creationDate: string) => {
  const totalHours = moment().diff(new Date(creationDate + "Z"), "hours", true);

  const days = Math.floor(totalHours / 24);
  const hours = Math.floor(totalHours % 24);
  const minutes = Math.floor(60 * ((totalHours % 24) - hours));

  if (days > 0) {
    return `${days}d ${hours}h ago`;
  } else {
    if (hours > 0) {
      return `${hours}h ${minutes}m ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return "few seconds ago";
    }
  }
};
