import { labelBatchStateType } from "../@types/orders/labelBatchState";

export const initializeLabelBatchDialog = (labelState: labelBatchStateType) => dispatch => {
    return dispatch({
        type:"INITIALIZE_LABEL_BATCH_DIALOG", 
        payload: labelState
    });
}

export const setLabelBatchDialogVisibility = (show:boolean) => dispatch => {
    return dispatch({type:"SET_LABEL_BATCH_DIALOG_VISIBILITY", payload: show});
}

export const setOrderSuccessNumber = (type:string, num:number) => dispatch => {
    return dispatch({type:"SET_ORDER_SUCCESS_NUMBER", payload: {type:type, num:num}});
}

export const setOrderErrorsNumber = (type:string, num:number) => dispatch => {
    return dispatch({type:"SET_ORDER_ERRORS_NUMBER", payload: {type:type, num:num}});
}

export const setOrderItemCount = (num:number) => dispatch => {
    return dispatch({type:"SET_ORDER_ITEM_COUNT", payload: num});
}
export const resetLabelBatchDialog = () => dispatch => {
    return dispatch({type:"RESET_LABEL_BATCH_DIALOG"});
}
export const setLabelBatchDialogStatus = (status) => dispatch => {
    return dispatch({type:"SET_LABEL_BATCH_DIALOG_STATUS", payload:status});
}

