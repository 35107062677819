import { useState, useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import SubscriptionContext from './SubscriptionContext';
import SelectCardDropdown from './SelectCardDropdown';
import FixedDialogWrapper from '../../../../utilities/FixedDialogWrapper';
import { MasterPageContext } from '../../../masterPageContext';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      height: 240,
      padding: 30,
    },
    icon: {
      width: 70,
    },
  }),
);

const AddBillingInformationDialog = ({ open, setOpen, onSubmit = (e) => { } }) => {
  const classes = useStyles();

  const [defaultCard, setDefaultCard] = useState(null);
  const [submitCardIsLoading, setSubmitCardIsLoading] = useState(false);

  const { cardFunctions: { creditCardData, setAddCardDialogVisible } } = useContext(MasterPageContext);

  const submitHandler = () => {
    onSubmit(defaultCard);
    setSubmitCardIsLoading(false);
    setOpen(false)

  };

  const handlingAddNewCard = (e) => {
    onSubmit(e)
    setOpen(false);
  };

  return (
    <FixedDialogWrapper
      dialogId="add-billing-information-dialog"
      title="Add Billing Information"
      maxWidth="sm"
      open={open}
      setOpen={setOpen}
      buttonDefinitions={[
        {
          label: 'Add New Card',
          color: 'secondary',
          onClick: () => setAddCardDialogVisible(true)
        },
        { label: 'Add', color: 'primary', disabled: submitCardIsLoading || !defaultCard, loading: submitCardIsLoading, onClick: submitHandler },
      ]}
      closeDisabled={submitCardIsLoading}
    >
      <div className={classes.root}>
        <img
          className={classes.icon}
          src="/images/subscription/credit-card.png"
          alt="credit card icon"
        />
        <SelectCardDropdown
          selectedCard={defaultCard}
          setSelectedCard={setDefaultCard}
          cardData={creditCardData}
          onSubmit={(e) => { handlingAddNewCard(e); }}
        />
      </div>
    </FixedDialogWrapper>
  );
};

export default AddBillingInformationDialog;
