import { useDispatch } from "react-redux";
import { Address } from "../../@type/Orders/Address";
import { Order, OrderFilterData } from "../../@type/Orders/Order";
import { OrdersActions } from "../@types/orders/ordersActions";

export function useOrdersActions() {
    const dispatch = useDispatch();

    function loadOrdersData(ordersData: Order[]) {
        return dispatch({ type: OrdersActions.LOAD_ORDERS_DATA, payload: ordersData });
    }

    function setOrderDetailsOrder(order: Order) {
        return dispatch({ type: OrdersActions.SET_ORDER_DETAILS_ORDER, payload: order });
    }

    function setOrdersTableLoading(isLoading: "Loading" | "Failed" | "Success" | "NoOrder" | "NoOrderShipped" | "NoOrderCanceled") {
        return dispatch({ type: OrdersActions.SET_ORDERS_TABLE_LOADING, payload: isLoading });
    }

    function setShipFromAddresses(addresses: Address[]) {
        return dispatch({ type: OrdersActions.SET_SHIP_FROM_ADDRESSES, payload: addresses });
    }

    function setOrdersPage(page: "awaitingShipment" | "shipped" | "canceled"|"onHold"|"awaitingPayment") {
        return dispatch({ type: OrdersActions.SET_ORDERS_PAGE, payload: page });
    }
    function setOrdersFilterData(filter:OrderFilterData) {
        return dispatch({ type: OrdersActions.SET_ORDERS_FILTER, payload: filter });
    }
    function removeOrder(id:number) {
        return dispatch({ type: OrdersActions.REMOVE_ORDER, payload: id });
    }

    return {
        loadOrdersData,
        setOrderDetailsOrder,
        setOrdersTableLoading,
        setShipFromAddresses,
        setOrdersPage,
        setOrdersFilterData,
        removeOrder
    }
}