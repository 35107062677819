type LOAD_HOLD_ORDERS_CHANGES_DATA = "LOAD_HOLD_ORDERS_CHANGES_DATA";

type actionType = {
    LOAD_HOLD_ORDERS_CHANGES_DATA: LOAD_HOLD_ORDERS_CHANGES_DATA;
};

export const HoldOrdersRestoreActions: actionType = {
    LOAD_HOLD_ORDERS_CHANGES_DATA: "LOAD_HOLD_ORDERS_CHANGES_DATA",
};

export type HoldOrdersRestoreAction =
    | { type: LOAD_HOLD_ORDERS_CHANGES_DATA, payload: number[] }