import { useState, useRef, useEffect, useContext, forwardRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Settings,
  ArrowDropDown,
  Menu,
  ArrowBack,
  AccountCircle,
} from "@material-ui/icons";
import {
  Tooltip,
  Popover,
  List,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import HeadsetMic from "@material-ui/icons/HeadsetMic";

import smallLogo from "../../images/ModalLogo.png";
import { useUserActions } from "../../redux/actions";
import MasterPageSidebar from "./MasterPageSidebar";
import { MasterPageContext } from "./masterPageContext";
import { MENU_MAP, SIDEBAR_MENU_MAP } from "./common";
import { useLoseFocus } from "../utilities/useLoseFocus";
import { APP_COLOR } from "../../environment/env_dev";
import RefreshStores from "./RefreshStores/RefreshStores";
import MasterPageNotification from "./MasterPageNotification";
import { RootState } from "../../redux/reducers";
import UsernameDropdownContent from "./UsernameDropdownContent";
import { roundAfterDecimalPoint } from "../../utils/commonFunctions";

const MASTER_PAGE_HEADER_STYLE = {
  backgroundColor: APP_COLOR.appLightBlue,
};

const MASTER_PAGE_HEADER_MENU_SELECTED_STYLE = {
  backgroundColor: "white",
  color: APP_COLOR.appLightBlue,
};

const HAMBURGER_MENU_STYLE = {
  display: "flex",
  position: "fixed",
  top: "60px",
  left: "-250px",
  opacity: 0.95,
  zIndex: 99,
  transition: "all 0.25s",
  WebkitTransition: "all '0.25s'",
};

const HAMBURGER_MENU_STYLE_SHOW = {
  ...HAMBURGER_MENU_STYLE,
  left: 0,
};

const HAMBURGER_MENU_ICON_STYLE = {
  marginLeft: "5px",
  fontSize: "30px",
};

const ResponsivePopover = withStyles(() =>
  createStyles({
    paper: {
      width: "500px",
      "@media (max-width:720px)": {
        width: "100%",
        maxWidth: "100%",
      },
    },
  })
)(Popover);

const useStyles = makeStyles(() =>
  createStyles({
    usernameDropdownItem: {
      textAlign: "center",
    },
  })
);

const {
  ALL: SIDEBAR_MENU_DATA,
  SETTINGS: SETTINGS_MENU_DATA,
  CUSTOMER_SUPPORT: CUSTOMER_SUPPORT_MENU_DATA,
} = SIDEBAR_MENU_MAP;

const LeftMenuItem = ({ selected, label, onClick }) => {
  return (
    <div
      className={`master-page-header-menu-left-item${
        selected ? " selected" : ""
      }`}
      style={selected ? MASTER_PAGE_HEADER_MENU_SELECTED_STYLE : {}}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

const RightMenuItemComponent = (
  { selected = false, onClick = () => {}, children },
  ref
) => {
  return (
    <div
      ref={ref}
      className={`master-page-header-menu-right-item${
        selected ? " selected" : ""
      }`}
      style={selected ? MASTER_PAGE_HEADER_MENU_SELECTED_STYLE : {}}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const RightMenuItem = forwardRef(RightMenuItemComponent);

const MasterPageHeader = ({
  userProfileName = "Loading...",
  selectedKey = MENU_MAP.DASHBOARD.key,
  onChange = (key, route) => {},
  onLogoClick = () => {},
  onProfileMenuClick = () => {},
  onLogoutClick = () => {},
  onBalanceClick = () => {},
  isMobileView = false,
}) => {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const classes = useStyles();
  const userBalanceSelected = useSelector(
    (state: RootState) => state.user.user?.accountBalance
  );
  const usUserBalanceSelected = useSelector(
    (state: RootState) => state.user.user?.usAccountBalance
  );
  const activeOffer = useSelector(
    (state: RootState) => state.user.user?.activeOffer
  );
  const { fetchUser } = useUserActions();
  const {
    resolveSelectedMenuKey,
    resolveHeaderMenuKey,
    cardFunctions: { setAddFundsDialogVisible },
  } = useContext(MasterPageContext);

  const [usernameDropdownVisible, setUsernameDropdownVisible] = useState(false);
  const [settingsDropdownVisible, setSettingsDropdownVisible] = useState(false);
  const [customerSupportDropdownVisible, setCustomerSupportDropdownVisible] =
    useState(false);
  const [notificationDropdownVisible, setNotificationDropdownVisible] =
    useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const usernameDropdownRef = useRef();
  const settingsDropdownRef = useRef();
  const customerSupportDropdownRef = useRef();
  const notificationDropdownRef = useRef();
  const hamburgerMenuRef = useRef();
  const hamburgerMenuLoseFocusRef = useRef(false);

  const [notificationDropdownRefState, setNotificationDropdownRefState] =
    useState();
  const [userBalance, setUserBalance] = useState(0);
  const [usUserBalance, setUSUserBalance] = useState(0);

  useEffect(() => {
    setShowHamburgerMenu(false);
    setUsernameDropdownVisible(false);
    setSettingsDropdownVisible(false);
    setCustomerSupportDropdownVisible(false);
    setUserBalance(user.user?.accountBalance?.amount);
    setUSUserBalance(user.user?.usAccountBalance?.amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  useEffect(() => {
    setNotificationDropdownRefState(notificationDropdownRef.current);
  }, []);

  useLoseFocus(hamburgerMenuRef, () => {
    hamburgerMenuLoseFocusRef.current = false;

    if (!showHamburgerMenu) {
      return;
    }

    hamburgerMenuLoseFocusRef.current = true;
    setShowHamburgerMenu(false);
  });

  const onMenuClick = (key, route) => {
    if (key === MENU_MAP.ORDERS.key) {
      onChange(key, MENU_MAP.ORDERS_AWAITING_SHIPMENT.route);
    } else {
      onChange(key, route);
    }
  };

  const onUsernameDropdownClick = async () => {
    const { response, accountBalance, usAccountBalance } = await fetchUser();

    if (response.status) {
      setUserBalance(accountBalance?.amount);
      setUSUserBalance(usAccountBalance?.amount);
      setUsernameDropdownVisible(!usernameDropdownVisible);
    }
  };

  const onSettingsDropdownClick = () => {
    setSettingsDropdownVisible(!settingsDropdownVisible);
  };

  // const onCustomerSupportDropdownClick = () => {
  //   setCustomerSupportDropdownVisible(!customerSupportDropdownVisible)
  // };

  const renderMenu = () => {
    const textMenus = [
      MENU_MAP.ONBOARD,
      MENU_MAP.DASHBOARD,
      MENU_MAP.ORDERS,
      MENU_MAP.SHIPMENTS,
      MENU_MAP.WAREHOUSING,
      MENU_MAP.ANALYTIC,
    ];

    const leftMenus = [];

    for (let i = 0, ii = textMenus.length; i < ii; i++) {
      const textMenu = textMenus[i];

      leftMenus.push(
        <LeftMenuItem
          key={`text-menu-${i}`}
          selected={selectedKey === textMenu.key}
          label={textMenu.label}
          onClick={() => onMenuClick(textMenu.key, textMenu.route)}
        />
      );
    }

    const renderWalletBalance = () => {
      let cadBalance = "",
        usdBalance = "";

      if (userBalanceSelected === undefined) {
        cadBalance = "Error";
      } else {
        if (activeOffer?.amount.currency === "CAD") {
          cadBalance = `${
            userBalanceSelected.currency
          } ${roundAfterDecimalPoint(
            userBalanceSelected.amount + activeOffer?.amount.amount
          )}`;
        } else {
          cadBalance = `${
            userBalanceSelected.currency
          } ${roundAfterDecimalPoint(userBalanceSelected.amount)}`;
        }
      }

      if (usUserBalanceSelected === undefined) {
        usdBalance = "Error";
      } else {
        if (activeOffer?.amount.currency === "USD") {
          usdBalance = `${
            usUserBalanceSelected.currency
          } ${roundAfterDecimalPoint(
            usUserBalanceSelected?.amount + activeOffer?.amount.amount
          )}`;
        } else {
          usdBalance = `${
            usUserBalanceSelected.currency
          } ${roundAfterDecimalPoint(usUserBalanceSelected?.amount)}`;
        }
      }

      return `${cadBalance} / ${usdBalance}`;
    };

    return (
      <div
        className="master-page-header-menu"
        style={{ overflowX: isMobileView ? "scroll" : "hidden" }}
      >
        <div className="master-page-header-menu-left">
          {!isMobileView && leftMenus}
        </div>
        <div className="master-page-header-menu-right">
          <RightMenuItem
            selected={selectedKey === MENU_MAP.REFERRAL_PROGRAM.key}
            onClick={() => {
              onMenuClick(
                MENU_MAP.REFERRAL_PROGRAM.key,
                MENU_MAP.REFERRAL_PROGRAM.route
              );
            }}
          >
            <div
              style={{
                display: "flex",
                color: "#E1A800",
                textDecoration: "underline",
                fontWeight: "bold",
                fontFamily: "Comic Sans MS, Comic Sans, cursive",
                fontSize: "1.1rem",
                padding: 0,
              }}
            >
              💰
              {!isMobileView && <span>&nbsp;Referral Program</span>}
            </div>
          </RightMenuItem>
          <RightMenuItem>
            <RefreshStores />
          </RightMenuItem>
          <RightMenuItem>
            <Tooltip title="Add Funds to your Account" arrow>
              <div onClick={() => setAddFundsDialogVisible(true)}>
                <CreditCardIcon />
              </div>
            </Tooltip>
          </RightMenuItem>
          {!isMobileView && (
            <RightMenuItem
              ref={settingsDropdownRef}
              selected={
                (!isMobileView && selectedKey === MENU_MAP.SETTINGS.key) ||
                settingsDropdownVisible
              }
              onClick={() => {
                if (!isMobileView) {
                  onMenuClick(MENU_MAP.SETTINGS.key, MENU_MAP.SETTINGS.route);

                  return;
                }

                onSettingsDropdownClick();
              }}
            >
              <Tooltip title="Settings" arrow>
                <Settings />
              </Tooltip>
            </RightMenuItem>
          )}

          <RightMenuItem
            ref={notificationDropdownRef}
            selected={notificationDropdownVisible}
            onClick={() =>
              setNotificationDropdownVisible(!notificationDropdownVisible)
            }
          >
            <MasterPageNotification
              anchorEl={notificationDropdownRefState}
              isMobileView={isMobileView}
              open={notificationDropdownVisible}
              setOpen={(e) => {
                setNotificationDropdownVisible(e);
              }}
            />
          </RightMenuItem>

          {/* Customer Support Menu in Page Header */}
          <RightMenuItem
            ref={customerSupportDropdownRef}
            selected={
              (!isMobileView &&
                selectedKey === MENU_MAP.CUSTOMER_SUPPORT.key) ||
              customerSupportDropdownVisible
            }
            onClick={() => {
              if (!isMobileView) {
                onMenuClick(
                  MENU_MAP.CUSTOMER_SUPPORT.key,
                  MENU_MAP.CUSTOMER_SUPPORT.route
                );

                return;
              }

              // onCustomerSupportDropdownClick();
            }}
          >
            <HeadsetMic />
            {isMobileView && <ArrowDropDown />}
          </RightMenuItem>

          <RightMenuItem
            ref={usernameDropdownRef}
            selected={
              (!isMobileView &&
                selectedKey === MENU_MAP.SETTINGS_ACCOUNT_MY_PROFILE.key) ||
              usernameDropdownVisible
            }
            onClick={() => {
              onUsernameDropdownClick();
            }}
          >
            <div className={classes.usernameDropdownItem}>
              <div>
                {isMobileView && <AccountCircle />}
                {!isMobileView && userProfileName}
              </div>
              <div style={{ fontSize: "12px" }}>
                {!isMobileView && renderWalletBalance()}
              </div>
            </div>
            <ArrowDropDown />
          </RightMenuItem>
        </div>
      </div>
    );
  };

  const renderHamburgerMenuIcon = () => {
    return (
      <div
        style={{ display: "flex", position: "fixed", cursor: "pointer" }}
        onClick={() => {
          if (hamburgerMenuLoseFocusRef.current) {
            hamburgerMenuLoseFocusRef.current = false;

            return;
          }

          setShowHamburgerMenu(!showHamburgerMenu);
        }}
      >
        {!showHamburgerMenu && <Menu style={HAMBURGER_MENU_ICON_STYLE} />}
        {showHamburgerMenu && <ArrowBack style={HAMBURGER_MENU_ICON_STYLE} />}
      </div>
    );
  };

  const getSelectedHamburgerMenuKey = () => {
    const currentRoute = window.location.pathname;

    if (currentRoute === MENU_MAP.ORDERS.route) {
      return MENU_MAP.ORDERS_AWAITING_PAYMENT.key;
    }

    if (currentRoute === MENU_MAP.SHIPMENTS.route) {
      // return MENU_MAP.SHIPMENTS_QUICK_QUOTE.key;
      return MENU_MAP.SHIPMENTS_CREATE_SHIPMENT.key;
    }

    if (currentRoute === MENU_MAP.SETTINGS.route) {
      return MENU_MAP.SETTINGS_ACCOUNT_MY_PROFILE.key;
    }

    if (currentRoute === MENU_MAP.ANALYTIC.route) {
      return MENU_MAP.ANALYTIC_OVERVIEW.key;
    }

    return (
      Object.values(MENU_MAP).find((e) => e.route === currentRoute)?.key ?? ""
    );
  };

  const handlePageChange = (key, route) => {
    resolveHeaderMenuKey(route);
    resolveSelectedMenuKey(route);
    history.push(route);
    setShowHamburgerMenu(false);
  };

  const renderHamburgerMenu = () => {
    return (
      <div ref={hamburgerMenuRef}>
        <MasterPageSidebar
          data={SIDEBAR_MENU_DATA}
          selectedKey={getSelectedHamburgerMenuKey()}
          onChange={handlePageChange}
          style={
            isMobileView && showHamburgerMenu
              ? HAMBURGER_MENU_STYLE_SHOW
              : HAMBURGER_MENU_STYLE
          }
          id="hamburger"
        />
      </div>
    );
  };

  const renderUsernameDropdown = () => {
    return (
      <ResponsivePopover
        id="username-dropdown"
        open={usernameDropdownVisible}
        anchorEl={usernameDropdownRef.current}
        onClose={() => {
          setUsernameDropdownVisible(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        marginThreshold={isMobileView ? 0 : 16}
      >
        <UsernameDropdownContent
          userProfileName={userProfileName}
          settingsMenuData={SETTINGS_MENU_DATA}
          onPageChange={handlePageChange}
          userBalance={userBalanceSelected?.amount || userBalance}
          usAccountBalance={usUserBalance}
          newUserOffer={activeOffer ?? null}
          onBalanceClick={onBalanceClick}
          onLogoutClick={onLogoutClick}
          onProfileMenuClick={onProfileMenuClick}
          setUsernameDropdownVisible={setUsernameDropdownVisible}
        />
      </ResponsivePopover>
    );
  };

  const renderSettingsDropdown = () => {
    const menus = [];

    for (let i = 0, ii = SETTINGS_MENU_DATA.length; i < ii; i += 1) {
      const { key, label, children } = SETTINGS_MENU_DATA[i];

      menus.push(
        <ListItem
          key={`settings-dropdown-menu-${key}`}
          style={{ background: "#E6F7FF" }}
        >
          <ListItemText style={{ fontWeight: "bold" }} primary={label} />
        </ListItem>
      );

      for (let j = 0, jj = children.length; j < jj; j += 1) {
        const child = children[j];
        const { key: childKey, route: childRoute, label: childLabel } = child;

        menus.push(
          <ListItem
            button
            key={`settings-dropdown-menu-${childKey}`}
            onClick={() => {
              onMenuClick(childKey, childRoute);
              setSettingsDropdownVisible(false);
            }}
          >
            <ListItemText secondary={childLabel} />
          </ListItem>
        );
      }
    }

    return (
      <Popover
        id="settings-dropdown"
        open={settingsDropdownVisible}
        anchorEl={settingsDropdownRef.current}
        onClose={() => {
          setSettingsDropdownVisible(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List dense aria-label="dropdownList">
          {menus}
        </List>
      </Popover>
    );
  };

  const renderCustomerSupportDropdown = () => {
    const menus = [];

    for (let i = 0, ii = CUSTOMER_SUPPORT_MENU_DATA.length; i < ii; i += 1) {
      const { key, label, route } = CUSTOMER_SUPPORT_MENU_DATA[i];

      menus.push(
        <ListItem
          button
          key={`customer-support-dropdown-menu-${key}`}
          onClick={() => {
            onMenuClick(key, route);
            setCustomerSupportDropdownVisible(false);
          }}
        >
          <ListItemText secondary={label} />
        </ListItem>
      );
    }

    return (
      <Popover
        id="customer-support-dropdown"
        open={customerSupportDropdownVisible}
        anchorEl={customerSupportDropdownRef.current}
        onClose={() => {
          setCustomerSupportDropdownVisible(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List dense aria-label="dropdownList">
          {menus}
        </List>
      </Popover>
    );
  };

  return (
    <div className="master-page-header" style={MASTER_PAGE_HEADER_STYLE}>
      <div className="master-page-header-logo" onClick={onLogoClick}>
        <img
          src={isMobileView ? smallLogo : "/shipvista.png"}
          alt="shipvista logo"
          width={isMobileView ? 50 : ""}
        />
      </div>
      {renderMenu()}
      {isMobileView && renderHamburgerMenuIcon()}
      {renderUsernameDropdown()}
      {renderSettingsDropdown()}
      {renderCustomerSupportDropdown()}
      {renderHamburgerMenu()}
    </div>
  );
};

export default MasterPageHeader;
