import { useDispatch } from 'react-redux';
import { SET_USER } from '../@types/user/userAction';
import { getUserService } from '../../apis/userService';
import { getReferralInfoData } from '../thunk/referralProgramInfoThunk';

export const setUser = (data) => ({
  type: SET_USER, data,
});

export function useUserActions() {
  const dispatch = useDispatch();

  const fetchUser = async () => {
    try {
      const result = await getUserService();

      if (!result.data) {
        return null;
      }

      dispatch(setUser(result.data));

      return result.data;
    } catch (err) {

      return null;
    }
  };

  const fetchUserReferralData = async () => {
    await dispatch(getReferralInfoData());
    return null;
  } 

  return { 
    fetchUser: fetchUser, 
    fetchUserReferralData: fetchUserReferralData
  };
}
