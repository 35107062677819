import { WarningAction, WarningState, WarningActions, initialWs } from "../@types/warning";
import * as _ from "lodash";
const initialState: WarningState = initialWs;

export function warningReducer(
    state = initialState,
    action: WarningAction
): WarningState {
    switch (action.type) {
        case WarningActions.TOGGLE_WARNING:
            const warning = action.payload.warning.warning;
            const open = action.payload.isOpen;
            const data = action.payload.warning.data;
            const newState = _.clone(state);
            if (open) {
                if (data){
                    newState.globalData = {...newState.globalData, [warning]: data}
                }
                newState.warningList = newState.warningList.filter(x => x !== warning);
                newState.warningList.push(warning);
            }else{
                newState.warningList = newState.warningList.filter(x => x !== warning);
            }
            return newState;
        case WarningActions.CLOSE_ALL_WARNINGS:
            return { ...state, warningList: initialState.warningList } 
        default:
            return state;
    }
}