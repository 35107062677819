import { combineReducers } from "redux";
import { carrierSetupReducer } from "./carrierSetupReducer";
import { dialogReducer } from "./dialogReducer";
import { alertReducer } from "./alertReducer";
import { popoverReducer } from "./popoverReducer";
import { getQuoteReducer } from "./quickQuote/getQuoteReducer";
import { carrierServiceReducer } from "./quickQuote/carrierServiceReducer";
import { storeSetupReducer } from "./storeSetupReducer";
import { ordersReducer } from "./ordersReducer";
import { tokenReducer } from "./tokenReducer";
import { userReducer } from "./userReducer";
import { boxesReducer } from "./boxesReducer";
import { notificationReducer } from "./notificationReducer";
import { feedsReducer } from "./feedsReducer";
import {userSubscriptionReducer} from './userSubscriptionReducer'
import { referralCodeReducer } from "./referralCodeReducer";
import { loginSelectedTabReducer } from "./loginSelectedTabReducer";
import {allSubscriptionPlanReducer} from "./userSubscriptionReducer"
import { openLoginDialogStateReducer } from "./openLoginDialogReducer";
import labelBatchReducer from "./labelBatchReducer";
import { batchesHistoryReducer } from "./batchesHistoryReducer";
import { warningReducer } from "./warningReducer";
import { holdOrdersRestoreReducer } from "./holdOrdersRestoreReducer";
import { offersReducer } from "./offersReducer";


import { referralProgramInfoReducer } from "./referralProgramInfoReducer";

export const rootReducer = combineReducers({
    dialog: dialogReducer,
    carrierSetup: carrierSetupReducer,
    alert: alertReducer,
    popover: popoverReducer,
    getQuote: getQuoteReducer,
    carrierService: carrierServiceReducer,
    storeSetup: storeSetupReducer,
    orders: ordersReducer,
    token: tokenReducer,
    user: userReducer,
    boxes: boxesReducer,
    offers: offersReducer,
    notification: notificationReducer,
    feeds: feedsReducer,
    userSubscription:userSubscriptionReducer,
    referralCode:referralCodeReducer,
    loginSelectedTab:loginSelectedTabReducer,
    allSubscriptionPlans:allSubscriptionPlanReducer,
    openLoginDialogState:openLoginDialogStateReducer,
    labelBatch: labelBatchReducer,
    batchHistory: batchesHistoryReducer,
    warning: warningReducer,
    holdOrdersRestore: holdOrdersRestoreReducer,

    referralProgramInfo: referralProgramInfoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;