import { CarrierSetupAction, CarrierSetupActions, CarrierSetupState } from "../@types/carrierSetup";

const initialState: CarrierSetupState = {
    yourAccounts: [],
    shipvistaAccounts: [],
    tabPanelValue: 0,
    currentYourAccount: null,
    currentShipVistaAccount: null,
    currentYourAccountImage: null,
    svCarrierInfo: null
};

export function carrierSetupReducer(
    state = initialState,
    action: CarrierSetupAction
): CarrierSetupState {
    switch (action.type) {
        case CarrierSetupActions.UPDATE_SHIPVISTA_ACCOUNTS_DATA:
            const newSvDataUpdate = state.shipvistaAccounts.map(account =>
                account.id === action.payload.id ? action.payload : account
            );
            return { ...state, shipvistaAccounts: newSvDataUpdate }
        case CarrierSetupActions.UPDATE_YOUR_ACCOUNTS_DATA:
            const newYourDataUpdate = action.payload.is_deleted
                ? state.yourAccounts.filter(account => account.id !== action.payload.id)
                : state.yourAccounts.map(account =>
                    account.id === action.payload.id ? action.payload : account);
            return { ...state, yourAccounts: newYourDataUpdate }
        case CarrierSetupActions.GO_TO_YOUR_ACCOUNTS:
            return { ...state, tabPanelValue: 1 }
        case CarrierSetupActions.SET_TAB_PANEL_VALUE:
            return { ...state, tabPanelValue: action.payload }
        case CarrierSetupActions.LOAD_SV_ACCOUNTS:
            return { ...state, shipvistaAccounts: action.payload }
        case CarrierSetupActions.LOAD_YOUR_ACCOUNTS:
            return { ...state, yourAccounts: action.payload }
        case CarrierSetupActions.FETCH_CURRENT_YOUR_ACCOUNT:
            return { ...state, currentYourAccount: action.payload }
        case CarrierSetupActions.FETCH_CURRENT_SHIPVISTA_ACCOUNT:
            return { ...state, currentShipVistaAccount: action.payload }
        case CarrierSetupActions.FETCH_CURRENT_YOUR_ACCOUNT_IMAGE:
            return { ...state, currentYourAccountImage: action.payload }
        case CarrierSetupActions.PASS_SHIPVISTA_INFO: {
            return { ...state, svCarrierInfo: action.payload }
        }
        default:
            return state;
    }
}