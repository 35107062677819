import { ShippingAccount } from "../../../@type/ShippingAccount";
import { ShipvistaAccountsInfo } from "../../../@type/ShipvistaAccountsInfo";

type UPDATE_SHIPVISTA_ACCOUNTS_DATA = "UPDATE_SHIPVISTA_ACCOUNTS_DATA";
type UPDATE_YOUR_ACCOUNTS_DATA = "UPDATE_YOUR_ACCOUNTS_DATA";
type GO_TO_YOUR_ACCOUNTS = "GO_TO_YOUR_ACCOUNTS";
type SET_TAB_PANEL_VALUE = "SET_TAB_PANEL_VALUE";
type LOAD_YOUR_ACCOUNTS = "LOAD_YOUR_ACCOUNTS";
type LOAD_SV_ACCOUNTS = "LOAD_SV_ACCOUNTS";
type FETCH_CURRENT_YOUR_ACCOUNT = "FETCH_CURRENT_YOUR_ACCOUNT";
type FETCH_CURRENT_SHIPVISTA_ACCOUNT = "FETCH_CURRENT_SHIPVISTA_ACCOUNT";
type FETCH_CURRENT_YOUR_ACCOUNT_IMAGE = "FETCH_CURRENT_YOUR_ACCOUNT_IMAGE";
type PASS_SHIPVISTA_INFO = "PASS_SHIPVISTA_INFO";

type actionType = {
  UPDATE_SHIPVISTA_ACCOUNTS_DATA: UPDATE_SHIPVISTA_ACCOUNTS_DATA;
  UPDATE_YOUR_ACCOUNTS_DATA: UPDATE_YOUR_ACCOUNTS_DATA;
  GO_TO_YOUR_ACCOUNTS: GO_TO_YOUR_ACCOUNTS;
  SET_TAB_PANEL_VALUE: SET_TAB_PANEL_VALUE;
  LOAD_SV_ACCOUNTS: LOAD_SV_ACCOUNTS;
  LOAD_YOUR_ACCOUNTS: LOAD_YOUR_ACCOUNTS;
  FETCH_CURRENT_YOUR_ACCOUNT: FETCH_CURRENT_YOUR_ACCOUNT;
  FETCH_CURRENT_SHIPVISTA_ACCOUNT: FETCH_CURRENT_SHIPVISTA_ACCOUNT;
  FETCH_CURRENT_YOUR_ACCOUNT_IMAGE: FETCH_CURRENT_YOUR_ACCOUNT_IMAGE;
  PASS_SHIPVISTA_INFO: PASS_SHIPVISTA_INFO;
};

export const CarrierSetupActions: actionType = {
  UPDATE_SHIPVISTA_ACCOUNTS_DATA: "UPDATE_SHIPVISTA_ACCOUNTS_DATA",
  UPDATE_YOUR_ACCOUNTS_DATA: "UPDATE_YOUR_ACCOUNTS_DATA",
  GO_TO_YOUR_ACCOUNTS: "GO_TO_YOUR_ACCOUNTS",
  SET_TAB_PANEL_VALUE: "SET_TAB_PANEL_VALUE",
  LOAD_SV_ACCOUNTS: "LOAD_SV_ACCOUNTS",
  LOAD_YOUR_ACCOUNTS: "LOAD_YOUR_ACCOUNTS",
  FETCH_CURRENT_YOUR_ACCOUNT: "FETCH_CURRENT_YOUR_ACCOUNT",
  FETCH_CURRENT_SHIPVISTA_ACCOUNT: "FETCH_CURRENT_SHIPVISTA_ACCOUNT",
  FETCH_CURRENT_YOUR_ACCOUNT_IMAGE: "FETCH_CURRENT_YOUR_ACCOUNT_IMAGE",
  PASS_SHIPVISTA_INFO: "PASS_SHIPVISTA_INFO"
};

export type CarrierSetupAction =
  | { type: UPDATE_SHIPVISTA_ACCOUNTS_DATA, payload: ShippingAccount }
  | { type: UPDATE_YOUR_ACCOUNTS_DATA, payload: ShippingAccount }
  | { type: GO_TO_YOUR_ACCOUNTS }
  | { type: SET_TAB_PANEL_VALUE, payload: Number }
  | { type: LOAD_SV_ACCOUNTS, payload: ShippingAccount[] }
  | { type: LOAD_YOUR_ACCOUNTS, payload: ShippingAccount[] }
  | { type: FETCH_CURRENT_YOUR_ACCOUNT, payload: ShippingAccount }
  | { type: FETCH_CURRENT_SHIPVISTA_ACCOUNT, payload: ShippingAccount }
  | { type: FETCH_CURRENT_YOUR_ACCOUNT_IMAGE, payload: string }
  | { type: PASS_SHIPVISTA_INFO, payload: ShipvistaAccountsInfo };