import {GET_SELECTED_TAB, SAVE_SELECTED_TAB} from '../actions/loginSelectedTabActions'

const initialState=0

export const loginSelectedTabReducer=(state=initialState,action)=>{
    switch(action.type){
        case GET_SELECTED_TAB:
            // const referralCode=action.payload   
            // return referralCode
            return state

        case  SAVE_SELECTED_TAB:
            const setRef=action.payload   
            return setRef
        default:
            return state;
    }
}