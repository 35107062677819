import { GET_ALL_SUBSCRIPTION_PLANS, LOAD_USER_SUBSCRIPTION, SET_DEFAULT_USER_SUBSCRIPTION } from "../@types/user/userSubscriptionAction"

interface UserSubscription {
    value: any
}

const initialState: UserSubscription = {
    value: {}
}

const initialAllSubscriptionPlans = {
    value: {}
}

export const userSubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_USER_SUBSCRIPTION:
            const userSubscription = action.payload
            return userSubscription
        case SET_DEFAULT_USER_SUBSCRIPTION:
            const starterId = action.payload
            return starterId
        default:
            return state;
    }
}

export const allSubscriptionPlanReducer = (state = initialAllSubscriptionPlans, action) => {
    switch (action.type) {
        case GET_ALL_SUBSCRIPTION_PLANS:
            let allSubscriptionPlans = action.payload
            return allSubscriptionPlans
        default:
            return state;
    }
}