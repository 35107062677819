import { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
interface Props {

}
const ScrollToTop = ({ history } : Props & RouteComponentProps<any>) => {

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [history])

  return (null);
};

export default withRouter(ScrollToTop);
