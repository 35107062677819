import { forwardRef } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import FixedDialog from "./FixedDialog";

import "./FixedDialogWrapper.scss";

interface Props {
  open: boolean;
  children: any;
  title: string;
  maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl";
  disableFullWidth?: boolean;
  setOpen: (value: boolean) => void;
  dialogId: string;
  buttonDefinitions?: Array<any>;
  closeDisabled?: boolean;
  disableEnforceFocus?: boolean;
  consentAgreement?: any;
  justifyContentFooter?:
    | any
    | "flex-start"
    | "flex-end"
    | "center"
    | "normal"
    | "right";
  overFlowOption?: any;
  marginOption?: any;
  disableBackdropAndEsc?: boolean;
}

const FixedDialogWrapper = (props: Props, ref) => {
  const { children, buttonDefinitions, consentAgreement } = props;

  const generateButtons = () => {
    const buttons = [];

    for (let i = 0, ii = buttonDefinitions.length; i < ii; i++) {
      const b = buttonDefinitions[i];

      buttons.push(
        <div
          key={`fixed-dialog-wrapper-button-${i}`}
          style={{ position: "relative" }}
        >
          <Button
            variant={b.variant ?? "contained"}
            color={b.color}
            disabled={b.disabled}
            onClick={b.onClick}
            className={b.className}
          >
            {b.label}
          </Button>
          {b.isLoading && (
            <CircularProgress
              size={24}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </div>
      );

      if (i < buttonDefinitions.length - 1) {
        buttons.push(
          <div
            key={`fixed-dialog-wrapper-separator-${i}`}
            style={{ width: "10px" }}
          ></div>
        );
      }
    }

    return buttons;
  };

  return (
    <FixedDialog ref={ref} {...props}>
      <div className="fixed-dialog-wrapper">
        {children}
        {buttonDefinitions && buttonDefinitions.length > 0 && (
          <div className="fixed-dialog-wrapper-footer">
            {consentAgreement ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  {consentAgreement}
                </div>
                <div style={{ display: "flex" }}>{generateButtons()}</div>
              </div>
            ) : (
              generateButtons()
            )}
          </div>
        )}
      </div>
    </FixedDialog>
  );
};

export default forwardRef(FixedDialogWrapper);
