import { DialogOption } from "./dialogState";

type CLOSE_DIALOG = "CLOSE_DIALOG";
type OPEN_CONTENT_PAGE = "OPEN_CONTENT_PAGE";

type actionType = {
  CLOSE_DIALOG: CLOSE_DIALOG;
  OPEN_CONTENT_PAGE: OPEN_CONTENT_PAGE;
};

export const DialogActions: actionType = {
  CLOSE_DIALOG: "CLOSE_DIALOG",
  OPEN_CONTENT_PAGE: "OPEN_CONTENT_PAGE",
};

export type DialogAction =
  | { type: CLOSE_DIALOG }
  | { type: OPEN_CONTENT_PAGE, payload: DialogOption };
