import { Route } from "react-router-dom";

import { getLandingPageURL } from "../../environment/env_dev";
import isAccessTokenExpired from "../../functionUtilities/checkAccessTokenExpiry";

const PrivateRoute = ({
  component: Component,
  componentProps,
  ...rest
}: any) => {
  let currentRoute = window.location.pathname;
  let searchParams = window.location.search;

  const redirectToHome = () => {
    window.location.href = `${getLandingPageURL()}?redirect=${
      currentRoute + searchParams.trim()
    }&e=logout`;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAccessTokenExpired() ? (
          <Component {...componentProps} {...props} />
        ) : (
          <>{redirectToHome()}</>
        )
      }
    />
  );
};

export default PrivateRoute;
