import { loadReferralInfoData, fetchingReferralInfoData } from '../actions/referralProgramInfoActions'
import { getReferralProgramInfo } from '../../apis/referralServices';

export const getReferralInfoData = ()=>async(dispatch)=>{
    try{
        dispatch(fetchingReferralInfoData());
        const response = await getReferralProgramInfo();
        if (response.data){
            dispatch(loadReferralInfoData({ info: response.data.data, infoError: response.data.message, infoIsSuccess: response.data.status }));
        }else{
            dispatch(loadReferralInfoData({ info: null, infoError: response.statusText, infoIsSuccess: false }));
        }
    }catch(err){
        dispatch(loadReferralInfoData({ info: null, infoError: (err?.message ?? String(err)), infoIsSuccess: false }));
    }
}
