import { SERVER_URL, getAxiosConfig } from "../environment/env_dev";
import { ResponseInfo } from "../@type/ResponseInfo";
import shipvistaAPI from "./shipvistaAPI";

export const loginService = (loginData: any) => {
  return shipvistaAPI.post(`/Login`, loginData);
};

export const refreshService = (refreshToken: string, accessToken: string) => {
  return shipvistaAPI.post(`/Login/refresh-token`, {
    refreshToken,
    accessToken,
  });
};

export const resendCodeService = (data: any) => {
  return shipvistaAPI.post(`/register/resendcode`, data);
};

export const registerService = (data: any) => {
  // return shipvistaAPI.post(`/register`, data, { withCredentials: true });
  // using fetch instead of axios because axios has a bug where you cannot set cookies from the backend
  return fetch(`/register`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data),
  }).then<ResponseInfo<{ referralDepositToken?: string }>>((r) => r.json());
};

export const forgotPasswordService = (_password: any) => {
  return shipvistaAPI.post(`/Login/forgot-password`, _password);
};

export const verifyForgotPasswordService = (verifyForgotPasswordData) => {
  return shipvistaAPI.get(
    `/Login/verify-forgot-password?token=${verifyForgotPasswordData}`
  );
};

export const updateForgotPasswordService = (updateForgotPasswordData: any) => {
  return shipvistaAPI.post(
    `/Login/update-forgot-password`,
    updateForgotPasswordData
  );
};

export const verifyRegisterEmailAddressService = (verifyRegisterEmailData) => {
  return shipvistaAPI.get(
    `/register/verifyemailaddress?token=${verifyRegisterEmailData}`
  );
};

export const verifyReferralCode = (_refCode: string) => {
  return shipvistaAPI.get(
    `/register/verifyReferralCode?referralCode=${_refCode}`
  );
};

export const processReferralLinkWithCode = (_refCode: string) => {
  // this will add a cookie on the backend if the cookie already doesn't exist and begin tracking the user
  //return shipvistaAPI.post(`/register/processReferralCode?referralCode=${_refCode}`, { ...getAxiosConfig(), withCredentials: true, })
  // axios seems to be dropping all cookies from response
  return fetch(`${SERVER_URL}/api/register/processReferralCode?referralCode=${_refCode}`, {
    headers: getAxiosConfig().headers,
    credentials: "include",
    method: "POST",
  }).then<{ data: any }>((r) => r.json());
};

export const changePasswordService = (body: any) => {
  return shipvistaAPI.post(`/Login/change-password`, body);
};

export const claimReferralBonus = (body: {
  currency: string;
  claimType: number;
}) => {
  return shipvistaAPI.post(`/affiliate/ClaimReferralBonus`, body);
};
