import amazonca from "../marketplaces/marketplaceImages/amazonca.png";
import amazoncom from "../marketplaces/marketplaceImages/amazoncom.png";
import amazonmx from "../marketplaces/marketplaceImages/amazonmx.png";
import ebay from '../marketplaces/marketplaceImages/ebay.png';
import newegg from "../marketplaces/marketplaceImages/newegg.png";
import neweggca from "../marketplaces/marketplaceImages/neweggca.svg";
import neweggbus from "../marketplaces/marketplaceImages/neweggbus.svg";
import shopify from "../marketplaces/marketplaceImages/shopify.png";
import etsy from "../marketplaces/marketplaceImages/etsy.png";
import woocommerce from "../marketplaces/marketplaceImages/woocommerce.svg";
import wix from "../marketplaces/marketplaceImages/wix.svg";
import stripe from "../marketplaces/marketplaceImages/stripe.png";
import PayPal from "../marketplaces/marketplaceImages/PayPal.png";
import bigcommerce from "../marketplaces/marketplaceImages/bigcommerce.png";
import Square from "../marketplaces/marketplaceImages/Square.png";
import wish from "../marketplaces/marketplaceImages/wish.png";
import magento from "../marketplaces/marketplaceImages/magento.png";
import bonanza from "../marketplaces/marketplaceImages/bonanza.svg";
import ecwid from "../marketplaces/marketplaceImages/ecwid.svg";
import walmart from "../marketplaces/marketplaceImages/walmart.svg";
import shipvista from "../marketplaces/marketplaceImages/shipvista.png";
import { MarketplaceName } from "../../@type/StoreSetup/MarketplaceNames";

import amazonIcon from "../marketplaces/marketplaceIcons/amazonIcon.png";
import ebayIcon from '../marketplaces/marketplaceIcons/ebayIcon.png';
import newEggIcon from "../marketplaces/marketplaceIcons/newEggIcon.png";
import shopifyIcon from "../marketplaces/marketplaceIcons/shopifyIcon.png";
import etsyIcon from "../marketplaces/marketplaceIcons/etsyIcon.png";
import woocommerceIcon from "../marketplaces/marketplaceIcons/woocommerceIcon.png";
import wixIcon from "../marketplaces/marketplaceIcons/wixIcon.png";
import stripeIcon from "../marketplaces/marketplaceIcons/stripeIcon.png";
import paypalIcon from "../marketplaces/marketplaceIcons/paypalIcon.png";
import bigCommerceIcon from "../marketplaces/marketplaceIcons/bigCommerceIcon.png";
import squareIcon from "../marketplaces/marketplaceIcons/squareIcon.png";
import wishIcon from "../marketplaces/marketplaceIcons/wishIcon.png";
import magentoIcon from "../marketplaces/marketplaceIcons/magentoIcon.png";
import bonanzaIcon from "../marketplaces/marketplaceIcons/bonanzaIcon.png";
import ecwidIcon from "../marketplaces/marketplaceIcons/ecwidIcon.png";
import walmartIcon from "../marketplaces/marketplaceIcons/walmartIcon.png";
import shipvistaIcon from "../marketplaces/marketplaceIcons/shipvistaIcon.png";

export const marketplaceImages = [
    {
        name: MarketplaceName.amazonca,
        imgUrl: amazonca,
        iconUrl: amazonIcon
    },
    {
        name: MarketplaceName.amazoncom,
        imgUrl: amazoncom,
        iconUrl: amazonIcon
    },
    {
        name: MarketplaceName.amazonmx,
        imgUrl: amazonmx,
        iconUrl: amazonIcon
    },
    {
        name: MarketplaceName.ebay,
        imgUrl: ebay,
        iconUrl: ebayIcon
    },
    {
        name: MarketplaceName.newegg,
        imgUrl: newegg,
        iconUrl: newEggIcon
    },
    {
        name: MarketplaceName.neweggcanada,
        imgUrl: neweggca,
        iconUrl: newEggIcon
    },
    {
        name: MarketplaceName.neweggbusiness,
        imgUrl: neweggbus,
        iconUrl: newEggIcon
    },
    {
        name: MarketplaceName.shopify,
        imgUrl: shopify,
        iconUrl: shopifyIcon
    },
    {
        name: MarketplaceName.etsy,
        imgUrl: etsy,
        iconUrl: etsyIcon
    },
    {
        name: MarketplaceName.woocommerce,
        imgUrl: woocommerce,
        iconUrl: woocommerceIcon
    },
    {
        name: MarketplaceName.wix,
        imgUrl: wix,
        iconUrl: wixIcon
    },
    {
        name: MarketplaceName.stripe,
        imgUrl: stripe,
        iconUrl: stripeIcon
    },
    {
        name: MarketplaceName.paypal,
        imgUrl: PayPal,
        iconUrl: paypalIcon
    },
    {
        name: MarketplaceName.bigcommerce,
        imgUrl: bigcommerce,
        iconUrl: bigCommerceIcon
    },
    {
        name: MarketplaceName.square,
        imgUrl: Square,
        iconUrl: squareIcon
    },
    {
        name: MarketplaceName.wish,
        imgUrl: wish,
        iconUrl: wishIcon
    },
    {
        name: MarketplaceName.magento,
        imgUrl: magento,
        iconUrl: magentoIcon
    },
    {
        name: MarketplaceName.bonanza,
        imgUrl: bonanza,
        iconUrl: bonanzaIcon
    },
    {
        name: MarketplaceName.ecwid,
        imgUrl: ecwid,
        iconUrl: ecwidIcon
    },
    {
        name: MarketplaceName.walmart,
        imgUrl: walmart,
        iconUrl: walmartIcon
    },
    {
        name: MarketplaceName.shipvista,
        imgUrl: shipvista,
        iconUrl: shipvistaIcon
    }
]