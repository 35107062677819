import { useState, useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import AddCardDialog from '../../../../add-card-dialog/AddCardDialog';

import DropdownWrapper from '../../../../utilities/DropdownButton/DropdownWrapper';
import { MasterPageContext } from '../../../masterPageContext';
import { getPaymentMethods } from '../../../../../apis/creditCard';
import { useAlertActions } from '../../../../../redux/actions';

const useStyles = makeStyles(() =>
  createStyles({
    dropdownButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      lineHeight: '100%',
    },
    dropdownList: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    bold: {
      fontWeight: 600,
    },
  }),
);

const SelectCardDropdown = ({
  selectedCard,
  setSelectedCard,
  cardData = [],
  initialDefault = false,
  onSubmit = (value) => { },
}) => {
  const classes = useStyles();
  const { setAddFundsDialogVisible } = useContext(MasterPageContext);
  const [addCardDialogOpen, setAddCardDialogOpen] = useState(false);
  const { openAlertBar } = useAlertActions();

  const formatCreditCard = (value) => {
    return `xxxx xxxx xxxx ${value.substring(value.length - 4, value.length)}`;
  };

  const doFetchCreditCardData = async () => {
    try {
      const { data } = await getPaymentMethods();

      if (!data.status) {
        openAlertBar(data.message, false);
        return null;
      }
      return data.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;

      openAlertBar(JSON.stringify(errors) ?? 'Something happened', false);

      return null;
    }
  };

  const cardListFormatter = (index) => {

    const { cardNumber: number, cardHolderName: name } = cardData[index];

    return (
      <div className={classes.dropdownList}>
        <div>{formatCreditCard(number)}</div>
        <div>{name}</div>
      </div>
    );
  };

  const DropdownButton = () => {
    if (!selectedCard) {
      return (
        <div className={classes.dropdownButton}>
          <div>Select Card</div>
        </div>
      );
    }

    const { cardNumber: number, cardHolderName: name } = selectedCard;

    return (
      <div className={classes.dropdownButton}>
        <div>{formatCreditCard(number)}</div>
        <div>{name}</div>
      </div>
    );
  };

  return (
    <>
      <div>Select Card as the default card for the payment method of the subscription</div>
      <hr style={{ flexBasis: "100%", height: 0, margin: 0, border: 0, }} />
      <DropdownWrapper
        btnName="Select Card"
        btnNameCustomEl={<DropdownButton />}
        color="default"
        variant="outlined"
        size="large"
        dynamicWidth
        list={[...Object.values(cardData)]}
        listFormatter={cardListFormatter}
        setSelect={(value) => {

          setSelectedCard(value);
        }}
      />
      {
        addCardDialogOpen && (
          <AddCardDialog
            isOpen={addCardDialogOpen}
            setIsOpen={setAddCardDialogOpen}
            fetchCreditCardData={doFetchCreditCardData}
            setAddFundsDialogVisible={setAddFundsDialogVisible}
          />
        )
      }
    </>
  );
};

export default SelectCardDropdown;
