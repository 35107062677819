import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import {
    Payment,
    AccountCircle,
    Receipt,
    LocalOffer,
    AccountBalanceWallet,
    CardMembership,
    Flight,
    Store,
    LocalShipping,
    ImportContacts,
    AddBox,
    List as ListIcon,
    People,
    Clear
} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MENU_MAP } from './common';

const subMenuIcons: { [key: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">> } = {
    "my-profile": AccountCircle,
    "payments": Receipt,
    "offers-available": LocalOffer,
    "transaction-history": AccountBalanceWallet,
    "subscription": CardMembership,
    "airmiles": Flight,
    "store-setup": Store,
    "carrier-setup": LocalShipping,
    "address-book": ImportContacts,
    "box-library": AddBox,
    "bulk-orders-feed": ListIcon,
    "bulk-affiliate-user-feed": People,
};

const SubMenuButton = withStyles(() =>
    createStyles({
        root: {
            height: "100px",
            width: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            '@media (max-width: 720px) and (orientation: landscape)': {
                height: "50px",
                width: "100px",
            },

        },
        outlined: {
            padding: "1px 3px",
        },
        label: {
            height: "100%",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0.5em",
            fontSize: "0.75rem",
            '@media (max-width: 720px) and (orientation: landscape)': {
                padding: "0px",
            },
        },
    }),
)(Button);

const MenuTabs = withStyles(() =>
    createStyles({
        root: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        flexContainer: {
        },
        indicator: {
        }
    }),
)(Tabs);

const MenuTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 90,
        },
        selected: {
            fontWeight: 700,
        },
    }),
)(Tab);

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "100%",
            '@media (max-width:720px)': {
                maxWidth: "100%",
            },
        },
        balanceLabel: {
            margin: "0",
            padding: "0",
            fontSize: "0.75rem",
        },
        balanceAmount: {
            fontWeight: 700,
        },
        panelGrid: {
            margin: "0 auto",
            '@media (max-width: 720px)': {
                maxHeight: "120px",
                overflowY: "auto",
            }
        },
        subMenuButtonLabel: {
            display: "block",
            marginTop: "1.5em",
            lineHeight: "0.75rem",
        },
        menuNavigation: {
            backgroundColor: "#f0f0f0",
        },
        svgMenuIcon: {
            '@media (max-width: 720px) and (orientation: landscape)': {
                display: "none",
            },
        },
        tabPanel: {
        },
        refreshButton: {
            padding: "0px",
        }

    }),
);

interface TabPanelProps {
    index: any;
    value: any;
    subMenuData: {
        key: string;
        route: string;
        label: string;
    }[];
    onPageChange: (key, route) => void,
    setUsernameDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>,
}


const TabPanel = ({ value, index, subMenuData, onPageChange, setUsernameDropdownVisible, ...other }: TabPanelProps) => {
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid
                    container
                    spacing={1}
                    justify="flex-start"
                    alignItems="center"
                    className={classes.panelGrid}
                >
                    {subMenuData.map((item) => (
                        <Grid key={item.key} item xs="auto" >
                            <SubMenuButton
                                variant="outlined"
                                onClick={() => { onPageChange(item.key, item.route); setUsernameDropdownVisible(false); }}
                            >
                                <SvgIcon
                                    fontSize="large"
                                    component={subMenuIcons[item.key] || Clear}
                                    className={classes.svgMenuIcon}
                                />
                                <span className={classes.subMenuButtonLabel} >
                                    {item.label}
                                </span>
                            </SubMenuButton>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

interface BalanceSectionProps {
    label: string;
    amount: number;
    xs?: boolean | GridSize;
}

const BalanceSection = ({ label, amount, xs }: BalanceSectionProps) => {
    const classes = useStyles();
    return (
        <Grid xs={xs} item>
            <Typography className={classes.balanceLabel}>
                {label}
            </Typography>
            <b className={classes.balanceAmount}>{currencyFormatter.format(amount)}</b>
        </Grid>
    )
}

interface Props {
    userProfileName: string,
    userBalance: number,
    usAccountBalance: number,
    onBalanceClick: () => void,
    setUsernameDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>,
    onProfileMenuClick: () => void,
    onLogoutClick: () => void,
    settingsMenuData?: {
        children: {
            key: string;
            route: string;
            label: string;
        }[];
        key: string;
        route: string;
        label: string;
    }[],
    newUserOffer: any,
    onPageChange?: (key, route) => void,
}

const UsernameDropdownContent = ({
    userBalance,
    usAccountBalance,
    onBalanceClick,
    setUsernameDropdownVisible,
    onLogoutClick,
    settingsMenuData = [],
    onPageChange = (key, route) => { },
    newUserOffer
}: Props) => {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);
    const { user } = useSelector((state: RootState) => state.user)

    return (
        <List dense className={classes.root} >
            <ListItem>
                <Grid container wrap="nowrap" spacing={1} justify="space-between">
                    <Grid item xs={6} container spacing={1} >
                        <BalanceSection label="Available (CAD)" amount={userBalance} xs={6} />
                        <BalanceSection label="Available (USD)" amount={usAccountBalance} xs={6} />
                        {newUserOffer && (
                            <>
                                {newUserOffer.status === "EMPTY" ?
                                    <Tooltip title="Click to view details"
                                        placement='top'>
                                        <Button
                                            component={Link}
                                            to={MENU_MAP.SETTINGS_ACCOUNT_OFFERS_AVAILABLE.route}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setUsernameDropdownVisible(false)}
                                            startIcon={<CardGiftcardIcon />}
                                            style={{ margin: "5px 0 10px 0", color: "white" }}
                                        >
                                            NEW Account Offer
                                        </Button>
                                    </Tooltip> :
                                    <BalanceSection
                                        label={`Bonus (${newUserOffer.amount.currency})`}
                                        amount={newUserOffer.amount.amount}
                                        xs={6} />
                                }
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        container
                        alignItems="center"
                        justify="flex-end"
                    >
                        <b>Account No: {user.account_no}</b>
                        <Button
                            onClick={() => {
                                onBalanceClick();
                                setUsernameDropdownVisible(false);
                            }}
                            style={{ marginTop: "8px" }}
                            variant="outlined"
                            color="primary"
                            startIcon={<Payment />}
                        >
                            Add Fund
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider component="li" />
            <ListItem className={classes.menuNavigation}>
                <Grid
                    container
                    wrap="nowrap"
                    spacing={1}
                    direction="column"
                >
                    <Grid item >
                        <MenuTabs
                            value={tabIndex}
                            onChange={(e, i) => setTabIndex(i)}
                            aria-label="menu navigation tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {settingsMenuData.map((settingsMenuItem, index) => (
                                <MenuTab key={settingsMenuItem.key} label={settingsMenuItem.label} {...a11yProps(index)} />
                            ))}
                        </MenuTabs>
                    </Grid>
                    <Grid item>
                        {settingsMenuData.map((settingsMenuItem, index) => (
                            <TabPanel
                                key={settingsMenuItem.key}
                                value={tabIndex}
                                index={index}
                                subMenuData={settingsMenuItem.children}
                                onPageChange={onPageChange}
                                setUsernameDropdownVisible={setUsernameDropdownVisible}
                            />

                        ))}
                    </Grid>
                </Grid>
            </ListItem>
            <Divider component="li" />
            <ListItem
                button
                onClick={() => {
                    onLogoutClick();
                    setUsernameDropdownVisible(false);
                }}
            >
                <ListItemText primary="Logout" />
            </ListItem>
        </List>
    )
}

export default UsernameDropdownContent

