import { PopoverAction, PopoverState, PopoverActions } from "../@types/popover";

const initialState: PopoverState = {
    anchorEl: null,
    popoverMsg: ""
};

export function popoverReducer(
    state = initialState,
    action: PopoverAction
): PopoverState {
    switch (action.type) {
        case PopoverActions.OPEN_POPOVER:
            return { ...state, anchorEl: action.payload, popoverMsg: action.payload2 }
        case PopoverActions.CLOSE_POPOVER:
            return { ...state, anchorEl: null }
        default:
            return state;
    }
}