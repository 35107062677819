import { OrdersAction, OrdersState, OrdersActions } from "../@types/orders";

const initialState: OrdersState = {
    orders: [],
    orderDetailsOrder: null,
    isTableLoading: "Success",
    shipFromAddresses: [],
    ordersPage: null,
    ordersFilterData:{
        countries:[],
        stores:[],
        storeNames:[],
        tags:[]
    }
};

export function ordersReducer(
    state = initialState,
    action: OrdersAction
): OrdersState {
    switch (action.type) {
        case OrdersActions.LOAD_ORDERS_DATA:
            return { ...state, orders: action.payload }
        case OrdersActions.SET_ORDER_DETAILS_ORDER:
            return { ...state, orderDetailsOrder: action.payload }
        case OrdersActions.SET_ORDERS_TABLE_LOADING:
            return { ...state, isTableLoading: action.payload }
        case OrdersActions.SET_SHIP_FROM_ADDRESSES:
            return { ...state, shipFromAddresses: action.payload }
        case OrdersActions.SET_ORDERS_PAGE:
            return { ...state, ordersPage: action.payload }
        case OrdersActions.SET_ORDERS_FILTER:
            return { ...state, ordersFilterData: action.payload }
        case OrdersActions.REMOVE_ORDER:
            return { 
                ...state, 
                orders: state.orders.filter(x => x.id !== action.payload)
            }
        default:
            return state;
    }
}