import { Feeds } from "../../../@type/Feeds";

type LOAD_FEEDS_DATA = "LOAD_FEEDS_DATA";
type SET_FEED_DETAILS_FEED = "SET_FEED_DETAILS_FEED";
type SET_FEEDS_TABLE_LOADING = "SET_FEEDS_TABLE_LOADING";

type actionType = {
    LOAD_FEEDS_DATA: LOAD_FEEDS_DATA;
    SET_FEED_DETAILS_FEED: SET_FEED_DETAILS_FEED;
    SET_FEEDS_TABLE_LOADING: SET_FEEDS_TABLE_LOADING; 
};

export const FeedsActions: actionType = {
    LOAD_FEEDS_DATA: "LOAD_FEEDS_DATA",
    SET_FEED_DETAILS_FEED: "SET_FEED_DETAILS_FEED",
    SET_FEEDS_TABLE_LOADING: "SET_FEEDS_TABLE_LOADING" 
};

export type FeedsAction =
    | { type: LOAD_FEEDS_DATA, payload: Feeds[] }
    | { type: SET_FEED_DETAILS_FEED, payload: Feeds }
    | { type: SET_FEEDS_TABLE_LOADING, payload: "Loading" | "Failed" | "Success" | "NoFeed"   } 
