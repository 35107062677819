import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import FixedDialogWrapper from '../../../../utilities/FixedDialogWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '80vh',
    },
  })
);

const stylesPDF = StyleSheet.create({
  page: {
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: 'lightblue',
    fontFamily: 'Open Sans',
    fontSize: 20,
    fontWeight: 600,
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 10,
    fontSize: 8,
    color: '#1E3C70',
  },
  logo: {
    width: 120,
  },
  billingSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  billingAddress: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  billingAddressDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    marginLeft: 16,
  },
  invoiceSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  invoiceDetails: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  invoiceDetailsLabel: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  invoiceDetailsValue: {
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceStatusSection: {
    display: 'flex',
    flexDirection: 'row',
    height: 64,
    marginTop: 10,
    borderWidth: 1,
    borderColor: 'lightgray',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  invoiceStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    backgroundColor: '#96c96f',
    color: 'white',
  },
  invoiceStatusPrice: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
  },
  dateSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: 'lightblue',
  },
  dateColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  paymentsSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 4,
  },
  paymentsDetailsSection: {
    width: '50%',
  },
  paymentsDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  paymentsDetailsRow1: {
    width: '30%',
  },
  paymentsDetailsRow2: {
    width: '70%',
  },
  paymentsTotalSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  paymentsTotalRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  paymentsTotalRow1: {
    width: '50%',
  },
  paymentsTotalRow2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
  },
  thankYouSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  thankYouBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 100,
    borderWidth: 1,
    borderColor: 'lightblue',
  },
  thankYouTitle: {
    fontSize: 10,
    fontFamily: 'Open Sans',
    fontStyle: 'italic',
    lineHeight: 2.5,
  },
  thankYouSubtitle: {
    fontSize: 8,
    fontFamily: 'Open Sans',
  },
  grandTotalSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    height: 30,
    borderWidth: 1,
    borderColor: 'lightblue',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 4,
    width: '100%',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: '#1E3C70',
    position: 'absolute',
    bottom: 20,
    left: 30,
    color: '#1E3C70',
  },
  footerColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerColumnLogo: {
    height: 10,
    marginRight: 4,
  },
  text1: {
    fontSize: 10,
    fontFamily: 'Open Sans',
  },
  textBold1: {
    fontSize: 10,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  },
  text2: {
    fontSize: 10,
    fontFamily: 'Open Sans',
    lineHeight: 1.75,
  },
  textBold2: {
    fontSize: 10,
    fontFamily: 'Open Sans',
    lineHeight: 1.75,
    fontWeight: 600,
  },
  text3: {
    fontSize: 10,
    fontFamily: 'Open Sans',
    lineHeight: 2.5,
  },
  textBold3: {
    fontSize: 10,
    fontFamily: 'Open Sans',
    lineHeight: 2.5,
    fontWeight: 600,
  },
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const DATE_FORMAT = 'MMM D, YYYY';

const TYPE = {
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
};

// register font
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf', fontStyle: 'italic', },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

const formatPrice = (value, withCurrency = true) => {
  if (withCurrency) {
    let subtotalText = `${currencyFormatter.format(Math.abs(value))}`;
    subtotalText = value < 0 ? `(${subtotalText})` : subtotalText;
    subtotalText = `${subtotalText} CAD`;

    return subtotalText;
  }

  let subtotalText = `${Math.abs(value).toFixed(2)}`;
  subtotalText = value < 0 ? `(${subtotalText})` : subtotalText;

  return subtotalText;
};

const HeaderSection = ({ data }) => {
  const { type } = data;

  return (
    <View style={stylesPDF.header}>
      <View style={stylesPDF.logoSection}>
        <Image style={stylesPDF.logo} src="/images/subscription/shipvista.png" />
        <Text>9-9 Royalcrest Rd, Etobicoke, ON</Text>
        <Text>M9V 2L6 Canada - (855) 434-3477</Text>
      </View>
      <Text>SUBSCRIPTION {type}</Text>
    </View>
  );
};

const BillingSection = ({ data }) => {
  const {
    addressDetails, invoiceNo, billedOn, dueOn,
    issuedOn, refundedInvoiceNo,
    status, subtotal, type, discountedPrice
  } = data;

  const formattedBilledOn = moment(billedOn).format(DATE_FORMAT);
  const formattedDueOn = moment(dueOn).format(DATE_FORMAT);
  const formattedIssuedOn = moment(issuedOn).format(DATE_FORMAT);

  return (
    <View style={stylesPDF.billingSection}>
      <View style={stylesPDF.billingAddress}>
        <Text style={stylesPDF.textBold2}>Invoice To</Text>
        <View style={stylesPDF.billingAddressDetails}>
          {
            addressDetails.map((e, i) => (
              <Text key={`address-details-${i}`} style={stylesPDF.text2}>{e}</Text>
            ))
          }
        </View>
      </View>
      <View style={stylesPDF.invoiceSection}>
        <View style={stylesPDF.invoiceDetails}>
          <View style={stylesPDF.invoiceDetailsLabel}>
            <Text style={stylesPDF.textBold2}>Invoice No</Text>
            <Text style={stylesPDF.textBold2}>{type === TYPE.REFUND ? 'Issued On' : 'Billed On'}</Text>
            <Text style={stylesPDF.textBold2}>{type === TYPE.REFUND ? 'Refunded Invoice No' : 'Due On'}</Text>
          </View>
          <View style={stylesPDF.invoiceDetailsValue}>
            <Text style={stylesPDF.text2}>{`2014${invoiceNo}`}</Text>
            <Text style={stylesPDF.text2}>{type === TYPE.REFUND ? formattedIssuedOn : formattedBilledOn}</Text>
            <Text style={stylesPDF.text2}>{type === TYPE.REFUND ? refundedInvoiceNo : formattedDueOn}</Text>
          </View>
        </View>
        <View style={stylesPDF.invoiceStatusSection}>
          <View style={stylesPDF.invoiceStatus}>
            <Text>{status}</Text>
          </View>
          <View style={stylesPDF.invoiceStatusPrice}>
            <Text>{formatPrice(subtotal - discountedPrice)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const DateSection = ({ data }) => {
  const { dateDetails, subtotal } = data;
  const { startDate, endDate, description, type } = dateDetails;

  const formattedStartDate = moment(startDate).format(DATE_FORMAT);
  const formattedEndDate = moment(endDate).format(DATE_FORMAT);

  return (
    <View style={stylesPDF.dateSection}>
      <View style={stylesPDF.dateColumn}>
        <Text style={stylesPDF.textBold2}>Date</Text>
        <Text style={stylesPDF.text2}>{`${formattedStartDate} - ${formattedEndDate}`}</Text>
      </View>
      <View style={stylesPDF.dateColumn}>
        <Text style={stylesPDF.textBold2}>Description</Text>
        <Text style={stylesPDF.text2}>{description} Plan</Text>
      </View>
      <View style={stylesPDF.dateColumn}>
        <Text style={stylesPDF.textBold2}>Type</Text>
        <Text style={stylesPDF.text2}>{type}</Text>
      </View>
      <View style={stylesPDF.dateColumn}>
        <Text style={stylesPDF.textBold2}>Subtotal</Text>
        <Text style={stylesPDF.text2}>{formatPrice(subtotal)}</Text>
      </View>
    </View>
  );
};

const PaymentsSection = ({ data }) => {
  const { paymentsDetails, subtotal, total, creditApplied, paid, discountedPrice } = data;

  return (
    <View style={stylesPDF.paymentsSection}>
      <View style={stylesPDF.paymentsDetailsSection}>
        <Text style={stylesPDF.textBold3}>Payments</Text>
        {
          paymentsDetails.map((e, i) => {
            const { date, description } = e;

            return (
              <View key={`payments-details-row-${i}`} style={stylesPDF.paymentsDetailsRow}>
                <View style={stylesPDF.paymentsDetailsRow1}>
                  <Text style={stylesPDF.text3}>{moment(date).format(DATE_FORMAT)}</Text>
                </View>
                <View style={stylesPDF.paymentsDetailsRow2}>
                  <Text style={stylesPDF.text3}>{description}</Text>
                </View>
              </View>
            );
          })
        }
      </View>
      <View style={stylesPDF.paymentsTotalSection}>
        <View style={stylesPDF.paymentsTotalRow}>
          <View style={stylesPDF.paymentsTotalRow1}>
            <Text style={stylesPDF.text3}>Subtotal</Text>
          </View>
          <View style={stylesPDF.paymentsTotalRow2}>
            <Text style={stylesPDF.text3}>C$</Text>
            <Text style={stylesPDF.text3}>{formatPrice(subtotal, false)}</Text>
          </View>
        </View>
        <View style={stylesPDF.paymentsTotalRow}>
          <View style={stylesPDF.paymentsTotalRow1}>
            <Text style={stylesPDF.text3}>Discount</Text>
          </View>
          <View style={stylesPDF.paymentsTotalRow2}>
            <Text style={stylesPDF.text3}>C$</Text>
            <Text style={stylesPDF.text3}>{- formatPrice(discountedPrice, false)}</Text>
          </View>
        </View>
        <View style={stylesPDF.paymentsTotalRow}>
          <View style={stylesPDF.paymentsTotalRow1}>
            <Text style={stylesPDF.text3}>Total</Text>
          </View>
          <View style={stylesPDF.paymentsTotalRow2}>
            <Text style={stylesPDF.text3}>C$</Text>
            <Text style={stylesPDF.text3}>{formatPrice(total, false)}</Text>
          </View>
        </View>
        {
          (creditApplied !== null || creditApplied !== undefined) && creditApplied !== 0 && (
            <View style={stylesPDF.paymentsTotalRow}>
              <View style={stylesPDF.paymentsTotalRow1}>
                <Text style={stylesPDF.text3}>Credit Applied</Text>
              </View>
              <View style={stylesPDF.paymentsTotalRow2}>
                <Text style={stylesPDF.text3}>C$</Text>
                <Text style={stylesPDF.text3}>{formatPrice(creditApplied, false)}</Text>
              </View>
            </View>
          )
        }
        <View style={stylesPDF.paymentsTotalRow}>
          <View style={stylesPDF.paymentsTotalRow1}>
            <Text style={stylesPDF.text3}>Paid</Text>
          </View>
          <View style={stylesPDF.paymentsTotalRow2}>
            <Text style={stylesPDF.text3}>C$</Text>
            <Text style={stylesPDF.text3}>{formatPrice(paid, false)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const ThankYouSection = ({ data }) => {
  const { subtotal, creditApplied, paid, discountedPrice, type } = data;

  let grandTotal = subtotal + paid - discountedPrice;

  if (creditApplied) {
    grandTotal += creditApplied;
  }

  return (
    <View style={stylesPDF.thankYouSection}>
      <View style={stylesPDF.thankYouBox}>
        <Text style={stylesPDF.thankYouTitle}>Thank You For Using Shipvista!</Text>
        <Text style={stylesPDF.thankYouSubtitle}>Please contact us if you have any questions.</Text>
      </View>
      <View style={stylesPDF.grandTotalSection}>
        <View style={stylesPDF.paymentsTotalRow}>
          <View style={stylesPDF.paymentsTotalRow1}>
            <Text style={stylesPDF.textBold1}>{type === TYPE.REFUND ? 'Credit Balance' : 'Amount Due'}</Text>
          </View>
          <View style={stylesPDF.paymentsTotalRow2}>
            <Text style={stylesPDF.text1}>C$</Text>
            <Text style={stylesPDF.textBold1}>{formatPrice(grandTotal, false)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const FooterSection = () => {
  return (
    <View style={stylesPDF.footer}>
      <View style={stylesPDF.footerColumn}>
        <Image style={stylesPDF.footerColumnLogo} src="/images/subscription/globe.png" />
        <Text style={stylesPDF.textBold1}>www.shipvista.com</Text>
      </View>
      <View style={stylesPDF.footerColumn}>
        <Image style={stylesPDF.footerColumnLogo} src="/images/subscription/cellphone.png" />
        <Text style={stylesPDF.textBold1}>1(855) 434-3477</Text>
      </View>
      <View style={stylesPDF.footerColumn}>
        <Image style={stylesPDF.footerColumnLogo} src="/images/subscription/phone.png" />
        <Text style={stylesPDF.textBold1}>(905) 346-8044</Text>
      </View>
      <View style={stylesPDF.footerColumn}>
        <Image style={stylesPDF.footerColumnLogo} src="/images/subscription/email.png" />
        <Text style={stylesPDF.textBold1}>customercare@shipvista.com</Text>
      </View>
    </View>
  );
};

const PrintSubscriptionInvoiceDialog = ({
  open,
  setOpen,
  data,
}) => {
  const classes = useStyles();

  return (
    <FixedDialogWrapper
      dialogId="print-subscription-invoice-dialog"
      title="Print Subscription Invoice"
      maxWidth="md"
      open={open}
      setOpen={setOpen}
    >
      <PDFViewer className={classes.root}>
        <Document title="Subscription Invoice">
          <Page style={stylesPDF.page} size="A4">
            <HeaderSection data={data} />
            <BillingSection data={data} />
            <DateSection data={data} />
            <PaymentsSection data={data} />
            <ThankYouSection data={data} />
            <FooterSection />
          </Page>
        </Document>
      </PDFViewer>
    </FixedDialogWrapper>
  );
};

export default PrintSubscriptionInvoiceDialog;
