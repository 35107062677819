import { labelBatchStateType } from "../@types/orders/labelBatchState";

const initialState: labelBatchStateType = {
  completion_status: "",
  isOpen: false,
  orders_count: 0,
  order_success: 0,
  order_errors: 0,
  batchId: 0,
  data: null,
  progress_percentage: 0,
  shipDate: "",
  processDate: "",
};

const labelBatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INITIALIZE_LABEL_BATCH_DIALOG":
      return { ...state, ...action.payload };
    case "SET_LABEL_BATCH_DIALOG_VISIBILITY":
      return { ...state, isOpen: action.payload };
    case "SET_ORDER_SUCCESS_NUMBER": {
      if (state.completion_status === "PROCESSING") {
        if (action.payload.type === "ADD")
          return {
            ...state,
            order_success: state.order_success + action.payload.num,
          };
        else return { ...state, order_success: action.payload.num };
      } else {
        return state;
      }
    }
    case "SET_ORDER_ERRORS_NUMBER": {
      if (state.completion_status === "PROCESSING") {
        if (action.payload.type === "ADD")
          return {
            ...state,
            order_errors: state.order_errors + action.payload.num,
          };
        else return { ...state, order_errors: action.payload.num };
      } else {
        return state;
      }
    }
    case "SET_ORDER_ITEM_COUNT":
      return { ...state, orders_count: action.payload };
    case "RESET_LABEL_BATCH_DIALOG":
      return { ...state, ...initialState };
    case "SET_LABEL_BATCH_DIALOG_STATUS":
      return { ...state, completion_status: action.payload };
    default:
      return state;
  }
};

export default labelBatchReducer;
