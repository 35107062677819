type OPEN_ALERT_BAR = "OPEN_ALERT_BAR";
type CLOSE_ALERT_BAR = "CLOSE_ALERT_BAR";

type actionType = {
    OPEN_ALERT_BAR: OPEN_ALERT_BAR;
    CLOSE_ALERT_BAR: CLOSE_ALERT_BAR;
};

export const AlertActions: actionType = {
    OPEN_ALERT_BAR: "OPEN_ALERT_BAR",
    CLOSE_ALERT_BAR: "CLOSE_ALERT_BAR"
};

export type AlertAction =
    | { type: OPEN_ALERT_BAR, payload: string, payload2: boolean, payload3: string }
    | { type: CLOSE_ALERT_BAR };

