import { SET_USER } from '../@types/user/userAction';

const initialState = {
  user: {},
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.data };
    default:
      return state;
  }
}
