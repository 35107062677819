import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { rootReducer } from "../reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
function configureStore() {
  // persist configuration
  //  can add whitelist/blacklist rootReducers 
  const persistConfig = {
    key: 'root',
    whitelist: [],
    storage
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  let persistor = persistStore(store)

  return { store, persistor };
}

export default configureStore;

export const store = configureStore();
