import { useEffect, useMemo, useCallback } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
/**
 * A custom hook that is used for removing overflow on the master page on certain routes so that sticky position could work
 * @param {string[]} routes A list of route paths to disable overflow. It should be memoized with useMemo if defined inside a component.
*/
export const useAllowStickyPosition = (routes: string[]) => {
    const history = useHistory();

    const checkShouldAllow = useCallback((pathname: string) => {
        if (routes.find(e => matchPath(pathname, { path: e, exact: true }) !== null)){
            return true;
        }
        return false;
    }, [routes])

    // perform initial check of routes to see if the initial 
    // page route that is navigated to a soon as app loads is any of the routes passed
    let disableOverFlow = useMemo(() => {
        return checkShouldAllow(window.location.pathname);
    }, [checkShouldAllow]);
        


    // attach a listener to the history object so that whenever user navigates to a page that 
    // should/shouldn't allow sticky position overflow is toggled off/on on the master page element
    useEffect(() => {
        const allowStickyPosition = () => {
            // disable overflow:hidden to allow sticky position on below pages
            const disableOverFlow = checkShouldAllow(window.location.pathname);
            
            const masterPageElement = document.getElementsByClassName("master-page");

            if (masterPageElement.length > 0){

                if (disableOverFlow){ 
                    if (!masterPageElement[0].classList.contains('no-overflow')){
                        masterPageElement[0].classList.add('no-overflow');
                    }
                }else{
                    masterPageElement[0].classList.remove('no-overflow');
                }
            }
            
        }
        
        const unlisten = history.listen(allowStickyPosition);
        
        return () => {
            unlisten();
        }
    }, [history, checkShouldAllow])


    return { allowStickyOnInitial : disableOverFlow };

}