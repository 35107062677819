import { useDispatch } from "react-redux";
import { Store } from "../../@type/StoreSetup/Store";
import { MarketplaceName } from "../../@type/StoreSetup/MarketplaceNames";
import { StoreSetupActions } from "../@types/storeSetup";

export function useStoreSetupActions() {
    const dispatch = useDispatch();

    function setStores(stores: Store[]) {
        return dispatch({ type: StoreSetupActions.SET_STORES, payload: stores });
    }

    function showInactiveStores(isActive: boolean) {
        return dispatch({ type: StoreSetupActions.SHOW_INACTIVE_STORES, payload: isActive });
    }

    function updateCurrentStore(currentStore: Store) {
        return dispatch({ type: StoreSetupActions.UPDATE_CURRENT_STORE, payload: currentStore });
    }

    function setCurrentMarketplaceName(marketplaceName: MarketplaceName) {
        return dispatch({ type: StoreSetupActions.SET_CURRENT_MARKETPLACE_NAME, payload: marketplaceName });
    }

    function setLoading(isLoading: boolean) {
        return dispatch({ type: StoreSetupActions.SET_LOADING, payload: isLoading });
    }

    function setZeroStore(isZeroStore: boolean) {
        return dispatch({ type: StoreSetupActions.SET_ZERO_STORE, payload: isZeroStore });
    }

    function setStoresRefresh(storesRefresh: any) {
        return dispatch({ type: StoreSetupActions.SET_STORES_REFRESH, payload: storesRefresh });
    }

    function updateStoreRefresh(id, updateStatus, lastUpdatedOn = null, storeUpdateStatus = null) {
        return dispatch({ type: StoreSetupActions.UPDATE_STORE_REFRESH, payload: {
            id,
            updateStatus,
            lastUpdatedOn,
            storeUpdateStatus,
        } });
    }

    return {
        setStores,
        showInactiveStores,
        updateCurrentStore,
        setCurrentMarketplaceName,
        setLoading,
        setZeroStore,
        setStoresRefresh,
        updateStoreRefresh,
    }
}